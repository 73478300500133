import SectionCard from '~/components/SectionCard.vue'
import { useSchema } from '~/composables/useSchema'
import completionDateAndSurveyForm from '~/schemas/components/completion-date-and-survey/completion-date-and-survey-form'

export default useSchema({
  id: 'completion-date-and-survey',
  title: 'Completion Date & Survey',
  description: 'To help plan your move please indicate your ideal moving (completion) date.',
  autosave: false,
  steps: false,
  signature: false,
  properties: [
    {
      $cmp: SectionCard,
      children: completionDateAndSurveyForm(),
    },
  ],
})
