import section_1_1 from '~/schemas/components/pif/boundaries/section_1_1'
import section_1_2 from '~/schemas/components/pif/boundaries/section_1_2'
import section_1_3 from '~/schemas/components/pif/boundaries/section_1_3'
import section_1_4 from '~/schemas/components/pif/boundaries/section_1_4'
import section_1_5 from '~/schemas/components/pif/boundaries/section_1_5'
import section_1_6 from '~/schemas/components/pif/boundaries/section_1_6'
import section_1_7 from '~/schemas/components/pif/boundaries/section_1_7'
import section_1_8 from '~/schemas/components/pif/boundaries/section_1_8'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                section_1_1(parentPath),
                section_1_2(parentPath),
                section_1_3(parentPath),
                section_1_4(parentPath),
                section_1_5(parentPath),
                section_1_6(parentPath),
                section_1_7(parentPath),
                section_1_8(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
