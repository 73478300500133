import { fullOccupierName, isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`),
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            children: '(11.3) Please provide their full names and ages',
            attrs: {
              class: 'tezt-sm mt-4 mb-2',
            },
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 5 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: "(11.3.1) 1st Occupier's Title", required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.occupier1.title`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElCol',
                props: { span: 9 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: "(11.3.2) 1st Occupier's Forename", required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.occupier1.forename`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: 'ElCol',
                props: { span: 10 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: { label: "(11.3.3) 1st Occupier's Surname", required: true },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.occupier1.surname`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(11.3.4) Is " + ${fullOccupierName(parentPath)} + " a tenant or lodger?"`,
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.occupier1.occupier_tenant_or_lodger`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(11.3.5) Has " + ${fullOccupierName(parentPath)} + " agreed to leave prior to completion (i.e. before the move date)?"`,
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.occupier1.occupier_agreed_to_leave_prior_to_completion`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: `$: "(11.3.6) Has " +  (${fullOccupierName(parentPath)}) + " agreed to sign the bottom of the sale contract to confirm they will vacate on or before the completion date?"`,
                          required: true,
                        },

                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.occupier1.occupier_agreed_to_sign_contract`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            options: [
                              { label: 'Yes', value: 'yes' },
                              { label: 'No', value: 'no' },
                            ],
                          },
                        ],
                      },
                      {
                        if: `${isTrueCondition(`${parentPath}.occupier1.occupier_agreed_to_sign_contract === 'no'`)}`,
                        $cmp: 'el-form-item',
                        props: {
                          label: `$: "(11.3.6.1) If " + ${fullOccupierName(parentPath)} + " will not sign you will be breaching your contract of sale to the buyer as you will not be giving vacant possession. Do you intend to provide additional evidence to show the property will be vacant on the completion date?"`,
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.occupier1.provide_additional_evidence`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            options: [
                              { label: 'Attached', value: 'attached' },
                              { label: 'To Follow', value: 'toFollow' },
                            ],
                          },
                        ],
                      },
                      {
                        if: isTrueCondition(`${parentPath}.occupier1.provide_additional_evidence === 'attached'`),
                        $cmp: 'el-form-item',
                        props: {
                          label: '(11.3.6.1.1) Please upload the evidence',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'file-upload',
                            name: `${parentPath}.occupier1.provide_additional_evidence_files`,
                            fileName: `${parentPath}.occupier1.provide_additional_evidence_files`,
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        if: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`),
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: {
                        if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                        then: `$: "(11.3.7) Is there any other occupier aged 17 or over apart from the owner(s) and " + (${fullOccupierName(parentPath)}) + " that lives at the property?"`,
                        else: `$: "(11.3.5) Is there any other occupier aged 17 or over apart from the owner(s) and " + (${fullOccupierName(parentPath)}) + " that lives at the property?"`,
                      },
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.other_occupier_aged_17_or_over`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`),
                    $cmp: 'ElRow',
                    props: {
                      gutter: 20,
                    },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'ElRow',
                            props: {
                              gutter: 20,
                            },
                            children: [
                              {
                                $cmp: 'ElCol',
                                props: { span: 5 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: {
                                        if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                                        then: "(11.3.7.1) 2nd Occupier's Title",
                                        else: "(11.3.5.1) 2nd Occupier's Title",
                                      },
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-input',
                                        name: `${parentPath}.occupier2.title`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                $cmp: 'ElCol',
                                props: { span: 9 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: {
                                        if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                                        then: "(11.3.7.2) 2nd Occupier's Forename",
                                        else: "(11.3.5.2) 2nd Occupier's Forename",
                                      },
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-input',
                                        name: `${parentPath}.occupier2.forename`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                $cmp: 'ElCol',
                                props: { span: 10 },
                                children: [
                                  {
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: {
                                        if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                                        then: "(11.3.7.3) 2nd Occupier's Surname",
                                        else: "(11.3.5.3) 2nd Occupier's Surname",
                                      },
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-input',
                                        name: `${parentPath}.occupier2.surname`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: {
                                if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                                then: `$: "(11.3.7.4) Is " + ${fullOccupierName(parentPath, 'occupier2')} + " a tenant or lodger?"`,
                                else: `$: "(11.3.5.4) Is " + ${fullOccupierName(parentPath, 'occupier2')} + " a tenant or lodger?"`,
                              },
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.occupier2.occupier_tenant_or_lodger`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                options: [
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                            $el: 'div',
                            children: [
                              {
                                $cmp: 'el-form-item',
                                props: {
                                  label: `$: "(11.3.7.5) Has " + ${fullOccupierName(parentPath, 'occupier2')} + " agreed to leave prior to completion (i.e. before the move date)?"`,
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.occupier2.occupier_agreed_to_leave_prior_to_completion`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    options: [
                                      { label: 'Yes', value: 'yes' },
                                      { label: 'No', value: 'no' },
                                    ],
                                  },
                                ],
                              },
                              {
                                $cmp: 'el-form-item',
                                props: {
                                  label: `$: "(11.3.7.6) Has " + ${fullOccupierName(parentPath, 'occupier2')} + " agreed to sign the bottom of the sale contract to confirm they will vacate on or before the completion date?"`,
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.occupier2.occupier_agreed_to_sign_contract`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    options: [
                                      { label: 'Yes', value: 'yes' },
                                      { label: 'No', value: 'no' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.occupier2.occupier_agreed_to_sign_contract === 'no'`),
                                $cmp: 'el-form-item',
                                props: {
                                  label: {
                                    if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                                    then: `$: "(11.3.7.6.1) If " + ${fullOccupierName(parentPath, 'occupier2')} + " will not sign you will be breaching your contract of sale to the buyer as you will not be giving vacant possession. Do you intend to provide additional evidence to show the property will be vacant on the completion date?"`,
                                    else: `$: "(11.3.5.6.1) If " + ${fullOccupierName(parentPath, 'occupier2')} + " will not sign you will be breaching your contract of sale to the buyer as you will not be giving vacant possession. Do you intend to provide additional evidence to show the property will be vacant on the completion date?"`,
                                  },
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.occupier2.provide_additional_evidence`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    options: [
                                      { label: 'Attached', value: 'attached' },
                                      { label: 'To Follow', value: 'toFollow' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.occupier2.provide_additional_evidence === 'attached'`),
                                $cmp: 'el-form-item',
                                props: {
                                  label: {
                                    if: isTrueCondition(`${parentPath}.property_sold_with_vacant_possession === 'yes'`),
                                    then: '(11.3.7.6.1.1) Please upload the evidence',
                                    else: '(11.3.5.6.1.1) Please upload the evidence',
                                  },
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'file-upload',
                                    name: `${parentPath}.occupier2.provide_additional_evidence_files`,
                                    fileName: `${parentPath}.occupier2.provide_additional_evidence_files`,
                                    validation: 'required',
                                    validationLabel: 'File upload',
                                    validationVisibility: 'live',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        if: isBaspiOnly(),
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
              class: 'pl-6',
            },
            children: [
              {
                if: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`),
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(11.3.8) What is " + ${fullOccupierName(parentPath)} + "'s Age"`,
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.occupier1.occupiers_age`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`),
                    $cmp: 'el-form-item',
                    props: {
                      label: `$: "(11.3.9) What is " + ${fullOccupierName(parentPath, 'occupier2')} + "'s Age"`,
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        name: `${parentPath}.occupier2.occupiers_age`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
