import Repeater from '~/components/Repeater.vue'
import SectionCard from '~/components/SectionCard.vue'
import { useSchema } from '~/composables/useSchema'
import Title from '~/schemas/components/title.js'

export default useSchema({
  id: 'test',
  title: 'Testing Schema',
  description: 'Any input testing will go here',
  signature: true,
  autosave: true,
  properties: [
    {
      $cmp: SectionCard,
      children: [
        {
          $cmp: 'el-form',
          props: {
            labelPosition: 'top',
          },
          children: [
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Basic Input',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'input',
                  inputType: 'text',
                  clearable: true,
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Text Area',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'textarea',
                  inputType: 'textarea',
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Password',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'password',
                  inputType: 'password',
                  showPassword: true,
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Number Input',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'number',
                  inputType: 'number',
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Switch with Conditional Card',
              },
              children: [
                {
                  $formkit: 'el-switch',
                  name: 'switch',
                  activeText: 'Open',
                  inactiveText: 'Close',
                },
                {
                  $cmp: 'ElCard',
                  if: '$formData.data.switch',
                  children: 'Toggle the switch above to hide me',
                  props: {
                    class: 'mt-4 w-full',
                  },
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Date Picker',
              },
              children: [
                {
                  $formkit: 'el-date-picker',
                  name: 'datePicker',
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Input Number',
              },
              children: [
                {
                  $formkit: 'el-input-number',
                  name: 'inputNumber',
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Input Number Steps',
              },
              children: [
                {
                  $formkit: 'el-input-number',
                  name: 'inputNumberSteps',
                  step: 3,
                  stepStrictly: true,
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Positioned Input Number',
              },
              children: [
                {
                  $formkit: 'el-input-number',
                  name: 'inputNumberPositioned',
                  controlsPosition: 'right',
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Checkbox',
              },
              children: [
                {
                  $formkit: 'el-checkbox',
                  name: 'checkbox',
                  text: 'This is a cool checkbox',
                },
              ],
            },

            {
              $cmp: 'el-form-item',
              props: {
                label: 'Autocomplete',
              },
              children: [
                {
                  $formkit: 'el-autocomplete',
                  name: 'autocomplete',
                  options: [
                    { value: 'vue', label: 'Vue' },
                    { value: 'element-plus', label: 'Element Plus' },
                    { value: 'formkit', label: 'Formkit' },
                    { value: 'vite', label: 'Vite' },
                    { value: 'vue-router', label: 'Vue Router' },
                    { value: 'vuex', label: 'Vuex' },
                    { value: 'nuxt', label: 'NuxtJS' },
                  ],
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Select',
              },
              children: [
                {
                  $formkit: 'el-select',
                  name: 'select',
                  options: [
                    { value: 'vue', label: 'Vue' },
                    { value: 'element-plus', label: 'Element Plus' },
                    { value: 'formkit', label: 'Formkit' },
                    { value: 'vite', label: 'Vite' },
                    { value: 'vue-router', label: 'Vue Router' },
                    { value: 'vuex', label: 'Vuex' },
                    { value: 'nuxt', label: 'NuxtJS' },
                  ],
                },
              ],
            },
            {
              $cmp: 'el-form-item',
              props: {
                label: 'Radio Group',
              },
              children: [
                {
                  $formkit: 'el-radio-group',
                  name: 'radioGroup',
                  options: [
                    { value: 'vue', label: 'Vue' },
                    { value: 'element-plus', label: 'Element Plus' },
                    { value: 'formkit', label: 'Formkit' },
                    { value: 'vite', label: 'Vite' },
                    { value: 'vue-router', label: 'Vue Router' },
                    { value: 'vuex', label: 'Vuex' },
                    { value: 'nuxt', label: 'NuxtJS' },
                  ],
                },
              ],
            },
            {
              $cmp: Repeater,
              props: useProps({
                prop: 'items',
                buttonText: '+ Add Item',
                removeButton: true,
                min: 1,
              }),
              children: [
                {
                  $el: 'div',
                  attrs: {
                    class: 'border-solid border-[1px] p-4 pt-8',
                  },
                  children: [
                    {
                      $cmp: 'el-form-item',
                      props: {
                        label: 'Radio Group',
                      },
                      children: [
                        {
                          $formkit: 'el-radio-group',
                          name: 'radioGroup',
                          options: [
                            { value: 'vue', label: 'Vue' },
                            { value: 'element-plus', label: 'Element Plus' },
                            { value: 'formkit', label: 'Formkit' },
                            { value: 'vite', label: 'Vite' },
                            { value: 'vue-router', label: 'Vue Router' },
                            { value: 'vuex', label: 'Vuex' },
                            { value: 'nuxt', label: 'NuxtJS' },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            Title('(f) Please upload a bank statement dated within the last 3 months.', 'my-4'),
            {
              $formkit: 'file-upload',
              name: `test_uploader.files`,
              fileName: `test_uploader.files`,
            },
          ],
        },
      ],
    },
  ],
})
