import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import RadioGroup from '~/schemas/components/radio-group'
import SellingSharesClient from '~/schemas/components/sof/source-of-funds-sections/selling-shares-client'
import SellingSharesDetails from '~/schemas/components/sof/source-of-funds-sections/selling-shares-details'

export default function () {
  function generateCondition() {
    const keys = ['client', 'client2', 'client3', 'client4']
    const condition = keys.map((key, index) => {
      const lastKey = keys.length - 1 === index
      let con = `(($formData.data.source_of_funds.selling_shares.${key}_shares === "no" && $conveyancingCase.${key}.name !== undefined) || $conveyancingCase.${key}.name === undefined)`
      if (!lastKey) con += ' && '
      return con
    })

    return condition.join('')
  }

  return {
    $el: 'div',
    props: { title: 'Shares' },
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      TitleSectionRadioGroup(
        '7',
        'Shares',
        'source_of_funds.selling_shares.selling_shares',
        [
          {
            $el: 'div',
            attrs: {
              class: 'section-indent',
            },
            if: '$formData.data.source_of_funds.selling_shares.selling_shares === "yes"',
            children: [
              RadioGroup('Are these shares in all your joint names?', 'source_of_funds.selling_shares.in_joint_names', true),
              {
                $el: 'div',
                attrs: {
                  class: 'section-indent',
                  key: 'joint-names',
                },
                if: '$formData.data.source_of_funds.selling_shares.in_joint_names === "yes"',
                children: [SellingSharesDetails('source_of_funds.selling_shares.joint_name_details', '$conveyancingCase.client.name')],
              },
              {
                $el: 'div',
                attrs: {
                  class: 'section-indent',
                },
                if: '$formData.data.source_of_funds.selling_shares.in_joint_names === "no"',
                children: [
                  SellingSharesClient('$conveyancingCase.client', 'client_shares', 'client_share_details', generateCondition()),
                  SellingSharesClient('$conveyancingCase.client2', 'client2_shares', 'client2_share_details', generateCondition()),
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-red-500 mt-4',
                    },
                    if: generateCondition(),
                    children: [
                      {
                        $el: 'div',
                        children: "You should select yes to at least one of the client's question above.",
                      },
                      {
                        $formkit: 'hidden',
                        name: 'source_of_funds.selling_shares.not_allowed',
                        validation: 'required',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        'Are you selling the shares to fund the purchase of your property?',
      ),
    ],
  }
}
