import { getCaseVariable } from '~/schemas/components/common/helpers.js'
import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Proceeds Distribution', visible: () => getCaseVariable('client2') },
    children: [
      TitleSectionRadioGroup(
        '7',
        'Proceeds Distribution',
        'potential_issues.distribute_proceeds.distribute_proceeds',
        [
          {
            $el: 'div',
            attrs: {
              class: 'section-indent',
            },
            if: '$formData.data.potential_issues.distribute_proceeds.distribute_proceeds === "yes"',
            children: [
              {
                $formkit: 'client-sale-distribution',
                name: 'potential_issues.distribute_proceeds.shares',
              },
              {
                $formkit: 'hidden',
                if: '$formData.data.potential_issues.distribute_proceeds.not_equal_100 === "yes"',
                name: 'potential_issues.distribute_proceeds.not_allowed',
                validation: 'required',
              },
            ],
          },
        ],
        'Do you want us to distribute your Net Sale Proceeds unequally between yourselves? Note this is unusual, so please provide details requested below.',
      ),
    ],
  }
}
