<template>
  <el-card body-class="px-6" class="box-card">
    <article class="prose max-w-full text-sm">
      <h1 class="mb-6 text-xl font-medium">Explanatory Notes for Buyers</h1>
      <ol class="numeric-slash">
        <li>Do not expect the seller to provide information about events or issues that occurred before they owned the property.</li>
        <li>
          The seller can only disclose information they are aware of. They may not possess detailed knowledge of legal or technical issues or the contents of
          certain reports.
        </li>
        <li>
          You must inform your conveyancer if you intend to rely on any information when purchasing the property outside of the replies in this form (whether in
          writing or conversation from the seller or via their estate agent).
        </li>
        <li>
          For the most current information, you must conduct your own enquiries and investigations or arrange for these to be carried out on your behalf. You
          need to ask your own conveyancer for advice, but they include necessary searches and a survey to confirm the physical and structural condition of the
          property. Even if the seller provides guarantees or other relevant documents, they are not offering a warranty on the property’s condition. Note: If
          you have a lender, their homebuyer report is solely for their valuation purposes and does not constitute a survey.
        </li>
        <li>
          Consult your own conveyancer regarding this document as no legal advice is given within this document and nor do we accept legal responsibility for
          anything set out within this document notes or replies.
        </li>
      </ol>
    </article>
  </el-card>
</template>
