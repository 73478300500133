export default function (name, title, formItemClass = '', required = null) {
  return [
    {
      $cmp: 'ElRow',
      props: { gutter: 20 },
      children: [
        {
          $cmp: 'ElCol',
          props: { span: 24 },
          children: [
            {
              $cmp: 'ElFormItem',
              props: {
                label: title,
                class: `flex font-bold w-full md:flex-row flex-col ${formItemClass}`,
              },
              children: [
                {
                  $formkit: 'el-switch',
                  name: name,
                  activeText: 'Yes',
                  inactiveText: 'No',
                  inlinePrompt: true,
                  attrs: {
                    class: '-mt-4',
                  },
                  validation: required ? 'required' : null,
                  validationMessages: {
                    required: 'This field is required',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
