import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '30.1.',
            text: `The foregoing restrictions are considered reasonable by
            the partner and shall be treated as separate obligations and shall be
            severally enforceable as such but in the event that any restriction or
            restrictions shall be or become or be found to be unenforceable or
            void in whole or in part but would be enforceable and valid if some
            part, therefore, were deleted or the period or areas of application
            reduced such restriction or restrictions shall apply with such
            deletion or modification as may be necessary to make such restriction
            or restrictions enforceable and valid and such deletion or
            modification shall not affect the enforceability for all such parts
            therefore as remain not so deleted or modified.`,
          },
        ]),
      ],
    },
  ]
}
