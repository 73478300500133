import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '',
            text: `<u>clause obligation from Employee</u> regarding confidential
            information you have had access to via AVRillo.
            <u>You are bound by the secrecy and confidentiality obligations</u>
            set out in this contract both during and post termination. This
            means you agree to not to disclose, but keep private, confidential
            information and intellectual (and surrender such information at our
            request, and post termination)`,
          },
          {
            number: '19.1.',
            text: `<u>Definition examples of confidential information include:</u>`,
          },
          {
            number: '19.1.(a).',
            text: `Individual salary and commission (where offered) is
            confidential and will be subject to for e.g., commission rights, and
            raises in salary being lost or varied down to pre salary review if the
            Employee discloses such to other Employees or third parties.`,
          },
          {
            number: '19.1.(b).',
            text: `Information in respect of the business and financing
            of the Company, and its dealings, transactions, and affairs, and
            likewise in relation to its customers, contacts, or clients, all of
            which information is confidential as the Employee is aware and
            acknowledges that in the course of the appointment the Employee will
            have access to and be entrusted with the aforesaid information. For
            the avoidance of doubt,`,
          },
          {
            number: '19.1.(c).',
            text: `The Employer’s policies and protocols, contract
            provisions of the Employee, information in the office manual, and all
            Employer ('Company') business information is regarded as confidential
            as well as being subject to data protection, including under the
            relevant Data Protection Act as at the date of the breach. This means
            all information (whether or not recorded in documentary form or stored
            on the server, or Talent LMS, or video or local pc, drive or any other
            form of data storage) relating to the business, products, affairs and
            finances of the Company and trade secrets including, for example,
            technical data and know-how relating to the Employer's workflows
            including both legal, communication, management of clients.`,
          },
          {
            number: '19.1.(d).',
            text: `How the partners run and manage the Company, its
            staff, its HR, and its training scheme and course, its clients, estate
            agents it deals with, to the way the firm run its files (not only with
            soft skills it teaches but also on AVRillo protocols, such as set for
            example, in its case management software (i.e., trigger stages,
            ranging from contracts out on a sale, automated correspondence and
            updates, letter content, linked actions), to precedents and documents
            of this firm; to work practices on how the firm brings on new work
            (including pitching and marketing to new estate agents, to how it uses
            data and strategies to win new business), to the firm’s
            ideas/plans/innovations/workflows planned, etc.,`,
          },
          {
            number: '19.1.(e).',
            text: `Work practices such as the Company’s proactive steps
            techniques such as conference calls, chain chasing, settlements of
            complaints, guidelines including legal, training manuals, notes.`,
          },
          {
            number: '19.1.(f).',
            text: `Data relating to the Employer's suppliers, such as
            estate agents as contained in spreadsheets, excel, google drives etc.,
            incorporated for example target or actual supplier details).`,
          },
          {
            number: '19.1.(g).',
            text: `Company Software (both current and future ideas
              discussed with the partners and/or other Employees of the Employer,
              whether or not recorded in writing and/ or video, or verbally and/or
              formal workflows). It also includes software used by the Company
              and/or developed for the Employee whilst working for the Company, such
              a PHP coding or proclaim coding, or whether licenced to the Company by
              a third-party provider, but adopted or integrated by the Company, for
              example by coding.`,
          },
          {
            number: '19.2.',
            text: `<u>The Employee shall:</u>`,
          },
          {
            number: '19.2.(a).',
            text: `Take precautions to ensure third parties, including
            members of your family, visitors or other persons visiting or residing
            in your home, or future Employers, do not become aware of any
            information which is confidential. Information must not be left
            unattended when you are working and when materials are not in use they
            should be locked away in a secure place. Similar precautions must be
            taken when transporting documents in the course of your work.`,
          },
          {
            number: '19.2.(b).',
            text: `Report to the Company if the Employee has any reason
            to believe that Company information is lost, altered or has been
            accessed by any unauthorised person.`,
          },
          {
            number: '19.2.(c).',
            text: `Always take steps to protect company records against
            loss, unauthorised access, alteration, or destruction, and make the
            Company aware immediately if the Employee suspects or actually
            discovers that there has been a data breach.`,
          },
          {
            number: '19.2.(d).',
            text: `Agree that the contact details of business contacts
            made during the Employee's employment shall be the Company's
            Confidential Information. Consequently, the Employee agrees, on
            termination of employment, to destroy such information from any data
            access, including online, and they shall provide the Company with a
            copy of all such information, delete all such information from their
            computers etc., and personal accounts, including social networking
            accounts (including Facebook, Twitter, and LinkedIn) and destroy any
            further copies of such information that they may have.`,
          },
          {
            number: '19.2.(e).',
            text: `Use of any computer equipment owned by the Company,
            its software and computer discs are limited to you alone and to
            business applications. Peripheral equipment such as printer and modem
            may not be connected to any of the Company's computers other than
            those issued to you by the Company. Information personal to you should
            not be stored on the computer.`,
          },
          {
            number: '19.3.',
            text: `You undertaking to respect and preserve the
            confidentiality as illustrated aforesaid and both during and post
            termination, you shall not:`,
          },
          {
            number: '19.3.(a).',
            text: `communicate, or otherwise make available, the Company
            Information to any third party; or`,
          },
          {
            number: '19.3.(b).',
            text: `divulge to any person (including but not limited to
              their new Employer, or other third party) or otherwise, make use of
              (and shall use their best endeavours to prevent the publication or
              disclosure of) any Confidential Information of the Company, or any of
              its suppliers, agents, distributors, contacts, referrers, or clients.`,
          },
          {
            number: '19.3.(c).',
            text: `use the Company Information or the Company Software
            for any commercial, industrial, or other purpose whatsoever; or`,
          },
          {
            number: '19.3.(d).',
            text: `copy, adapt or otherwise reproduce the Company
            Information or the Company Software except as strictly necessary for
            the purposes of the Objective or as permitted by law.`,
          },
          {
            number: '19.3.(e).',
            text: `You are required to take special care to secure all
            records and to prevent unauthorised disclosure of any Company or other
            business information. Customer contact information is particularly
            sensitive as customers have a legal right to expect personal
            information held about them to be held in the utmost confidence. On
            behalf of the Company, it is your legal obligation to ensure these
            rights are protected.`,
          },
          {
            number: '19.4.',
            text: `<u>Post-termination obligations in respect of confidential information:</u>`,
          },
          {
            number: '',
            text: `The Employee (i.e., former Employee after you are no longer employed
              by the Employer) is subject to keeping confidential information secret
              (as defined and obligations set out above) or be subject to Court
              proceedings.`,
          },
          {
            number: '19.5.',
            text: `<u>Breach of these confidentiality</u> conditions
            includes court proceedings and warnings, and/or if disclosing the
            Employee salary with other Employees, the amount of salary may be
            subject to variation and/or commission rights lost.`,
          },
          {
            number: '19.6.',
            text: `<u>The only exceptions to confidentiality</u> disclosure
            either during or post-employment are for disclosure required by a
            court of law or is disclosed under the Public Interest Disclosure Act
            1998, or any information which is already in, or comes into, the
            public domain other than through your unauthorised disclosure, or is
            protected disclosure within the meaning of Section 43A of the
            Employment Rights Act 1996.`,
          },
        ]),
      ],
    },
  ]
}
