import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(3.1) Have you received or sent any notices or other correspondence from/to any third party or conversations or negotiations affecting or relating to this property or a different property nearby? eg from government department, council or neighbour?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.notices_correspondence`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.notices_correspondence === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 50 },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(3.1.1) Please select',
                    },
                    children: [
                      {
                        if: isTrueCondition(`${parentPath}.notices_correspondence === 'yes'`),
                        $cmp: 'ElRow',
                        props: { gutter: 50 },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                $formkit: 'el-checkbox-group',
                                name: `${parentPath}.notices_correspondence_select`,
                                attrs: { class: 'flex flex-col' },
                                options: [
                                  { label: 'Planning Application that could affect the property', value: 'planning_application' },
                                  {
                                    label: 'Notice informing you that maintenance-repairs or improvements are required to your property',
                                    value: 'maintenance_repairs',
                                  },
                                  { label: 'Listed building application', value: 'listed_building' },
                                  {
                                    label: 'Infrastructure project, proposed or in progress (i.e. wind turbine, railway, fracking, etc)',
                                    value: 'infrastructure_project',
                                  },
                                  { label: 'Notice under the Party Wall Act 1996 in respect of any shared or party boundaries?', value: 'party_wall_act' },
                                  { label: 'Other', value: 'other' },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    if: `${isTrueCondition(`${parentPath}.notices_correspondence_select`)} && ${isTrueCondition(`${parentPath}.notices_correspondence_select.includes('other')`)}`,
                    $cmp: 'el-form-item',
                    props: {
                      label: '(3.1.1.1) Please give details',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-input',
                        inputType: 'textarea',
                        name: `${parentPath}.notices_correspondence_other_details`,
                        rows: 6,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.notices_correspondence === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 50 },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(3.1.2) Do you have a copy of this notice and/or correspondence?',
                      class: 'pl-6',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.notices_correspondence_copy`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.notices_correspondence_copy === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 50 },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: {
                              label: '(3.1.2.1) Please provide a copy of this notice and/or correspondence',
                              class: 'pl-6',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.notices_correspondence_copy_provided`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'Did not obtain', value: 'didNotObtain' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.notices_correspondence_copy_provided === 'attached'`),
                            $cmp: 'el-form-item',
                            props: {
                              label: '(3.1.2.1.1) Please provide a copy of this notice and/or correspondence',
                              class: 'pl-6',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.notices_correspondence_copy_files`,
                                fileName: `${parentPath}.notices_correspondence_copy_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
