import innerComponents from '~/schemas/components/common/inner-component-loop'
import roleBdm from '~/schemas/components/staff-contract/roles/BDM'
import roleDeveloper from '~/schemas/components/staff-contract/roles/Developer'
import rolePartner from '~/schemas/components/staff-contract/roles/Partner'
import roleQuoteGiver from '~/schemas/components/staff-contract/roles/QuoteGiver'
import roleTitleChecker from '~/schemas/components/staff-contract/roles/TitleChecker'
import roleTitleCheckerExtra from '~/schemas/components/staff-contract/roles/TitleCheckerExtra'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '34.1.',
            text: `Illustration of your role, subject to variation as the
            legal market progresses, including with innovation, regulatory changes
            and in protocols (i.e., as you learn on the job, as you train, develop
            and as our protocols, guidelines, change to meet new demands and
            challenges in our conveyancing sector of the legal market and the clients’
            needs)`,
          },
        ]),
        {
          if: '$employeeCase.job_title === "Business Development"',
          $el: 'span',
          children: roleBdm(),
        },
        {
          if: '$employeeCase.job_title === "Quote Giver"',
          $el: 'span',
          children: roleQuoteGiver(),
        },
        {
          if: '$employeeCase.job_title === "Title Checker" || $employeeCase.job_title === "Conveyancer"',
          $el: 'span',
          children: roleTitleChecker(),
        },
        {
          if: '$employeeCase.job_title === "Title Checker"',
          $el: 'span',
          children: roleTitleCheckerExtra(),
        },
        {
          if: '$employeeCase.job_title === "Developer"',
          $el: 'span',
          children: roleDeveloper(),
        },
        {
          if: '$employeeCase.job_title === "Conveyancing Solicitor Salaried Partner" || $employeeCase.job_title === "Licenced Conveyancer Salaried Partner"',
          $el: 'span',
          children: rolePartner(),
        },
      ],
    },
  ]
}
