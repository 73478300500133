import { isTrueCondition, trueValidation } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $cmp: 'el-form-item',
            props: {
              required: true,
            },
            children: [
              {
                $formkit: 'el-checkbox',
                attrs: {
                  class: 'required',
                },
                text: '(4.2) Please confirm that you have correctly attached all documents',
                name: `${parentPath}.all_documents_attached_confirm`,
                validation: 'required|trueValidation',
                validationRules: { trueValidation },
                validationMessages: {
                  required: 'The field is required',
                  trueValidation: 'You have to confirm that you have correctly attached all documents',
                },
                validationVisibility: 'live',
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'no'`),
            $cmp: 'el-form-item',
            children: [
              {
                $el: 'div',
                children: '(4.2) No documents required for 4.1',
                props: { class: 'text-sm italic' },
              },
            ],
          },
        ],
      },
    ],
  }
}
