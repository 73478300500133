import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import InheritanceClient from '~/schemas/components/sof/source-of-funds-sections/inheritance-client'

export default function () {
  function generateCondition() {
    const keys = ['client', 'client2', 'client3', 'client4']
    const condition = keys.map((key, index) => {
      const lastKey = keys.length - 1 === index
      let con = `(($formData.data.source_of_funds.inheritance.${key}_inheritance === "no" && $conveyancingCase.${key}.name !== undefined) || $conveyancingCase.${key}.name === undefined)`
      if (!lastKey) con += ' && '
      return con
    })

    return condition.join('')
  }

  return {
    $el: 'div',
    props: { title: 'Inheritance' },
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      TitleSectionRadioGroup(
        '9',
        'Inheritance',
        'source_of_funds.inheritance.inheritance',
        [
          {
            $el: 'div',
            attrs: {
              class: 'section-indent',
            },
            if: '$formData.data.source_of_funds.inheritance.inheritance === "yes"',
            children: [
              InheritanceClient('$conveyancingCase.client', 'client_inheritance', 'client_inheritance_details', generateCondition()),
              InheritanceClient('$conveyancingCase.client2', 'client2_inheritance', 'client2_inheritance_details', generateCondition()),
              InheritanceClient('$conveyancingCase.client3', 'client3_inheritance', 'client3_inheritance_details', generateCondition()),
              InheritanceClient('$conveyancingCase.client4', 'client4_inheritance', 'client4_inheritance_details', generateCondition()),
              {
                $el: 'div',
                attrs: {
                  class: 'text-red-500 mt-4',
                },
                if: generateCondition(),
                children: [
                  {
                    $el: 'div',
                    children: "You should select yes to at least one of the client's question above.",
                  },
                  {
                    $formkit: 'hidden',
                    name: 'source_of_funds.inheritance.not_allowed',
                    validation: 'required',
                  },
                ],
              },
            ],
          },
        ],
        '(i.e., will you be receiving inheritance money to buy your property?)',
      ),
    ],
  }
}
