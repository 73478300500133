import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label:
                        '(10.1) Do you have to contribute towards the shared cost of a jointly-used service such as a shared driveway, road, parking area, garden or drain (other than council tax, utility charges, ground rent or service charges)?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.shared_cost`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.shared_cost === 'yes'`),
                    $el: 'div',
                    attrs: { class: 'pl-6' },
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label:
                            '(10.1.1) Please give details including who collects payments and organises the work, the amount of the payments in the last year and whether they are regular payments or only when maintenance work is required',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.shared_cost_details`,
                            inputType: 'textarea',
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                          },
                        ],
                      },
                      {
                        $cmp: 'ElRow',
                        props: {
                          gutter: 20,
                        },
                        children: [
                          {
                            $cmp: 'ElCol',
                            props: { span: 24 },
                            children: [
                              {
                                if: isTrueCondition(`${parentPath}.shared_cost === 'yes'`),
                                $cmp: 'el-form-item',
                                props: {
                                  label:
                                    '(10.1.2) Is the ownership of your property a managed Freehold (ie also sometimes known as a management company who maintain the estate)?',
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-radio-group',
                                    name: `${parentPath}.managed_freehold`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                    options: [
                                      { label: 'Yes', value: 'yes' },
                                      { label: 'No', value: 'no' },
                                    ],
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.managed_freehold === 'yes'`),
                                $cmp: 'el-form-item',
                                props: {
                                  label:
                                    '(10.1.2.1) Please confirm the following: Amount of current annual maintenance contribution (otherwise known as estate rentcharge/service charge)',
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-input',
                                    name: `${parentPath}.annual_maintenance_contribution`,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                  },
                                ],
                              },
                              {
                                if: isTrueCondition(`${parentPath}.managed_freehold === 'yes'`),
                                $cmp: 'el-form-item',
                                props: {
                                  label: '(10.1.2.2) Please kindly provide the name, address and email of the rentcharge owner or managing agent',
                                  required: true,
                                },
                                children: [
                                  {
                                    $formkit: 'el-input',
                                    name: `${parentPath}.rentcharge_owner_details`,
                                    inputType: 'textarea',
                                    rows: 6,
                                    validation: 'required',
                                    validationMessages: {
                                      required: 'This field is required',
                                    },
                                  },
                                ],
                              },
                              {
                                if: isBaspiOnly(),
                                $el: 'div',
                                children: [
                                  {
                                    if: isTrueCondition(`${parentPath}.managed_freehold === 'yes'`),
                                    $cmp: 'el-form-item',
                                    props: { label: '(10.1.2.3) Are you aware of any additional costs payable on sale or letting', required: true },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.additional_costs_payable`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        options: [
                                          { label: 'Yes', value: 'yes' },
                                          { label: 'No', value: 'no' },
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    if: isTrueCondition(`${parentPath}.additional_costs_payable === 'yes'`),
                                    $cmp: 'el-form-item',
                                    props: {
                                      label: '(10.1.2.3.1) please provide details of any costs that you are aware of and state what these relate to',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-input',
                                        name: `${parentPath}.additional_costs_payable_details`,
                                        inputType: 'textarea',
                                        rows: 6,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                      },
                                    ],
                                  },
                                  {
                                    if: isTrueCondition(`${parentPath}.managed_freehold === 'yes'`),
                                    $cmp: 'el-form-item',
                                    props: {
                                      label:
                                        '(10.1.2.4) Is the owner of the property required to become a director in the management company for the maintenance of shared amenities.',
                                      required: true,
                                    },
                                    children: [
                                      {
                                        $formkit: 'el-radio-group',
                                        name: `${parentPath}.owner_required_to_become_director`,
                                        validation: 'required',
                                        validationMessages: {
                                          required: 'This field is required',
                                        },
                                        options: [
                                          { label: 'Yes', value: 'yes' },
                                          { label: 'No', value: 'no' },
                                          { label: 'Not known', value: 'notKnown' },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
