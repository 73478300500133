import section_14_1 from '~/schemas/components/pif/transaction-information/section_14_1'
import section_14_2 from '~/schemas/components/pif/transaction-information/section_14_2'
import section_14_3 from '~/schemas/components/pif/transaction-information/section_14_3'
import section_14_4 from '~/schemas/components/pif/transaction-information/section_14_4'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [section_14_1(parentPath), section_14_2(parentPath), section_14_3(parentPath), section_14_4(parentPath)],
            },
          ],
        },
      ],
    },
  ]
}
