import DatePicker from '~/schemas/components/date-picker.js'
import RadioGroup from '~/schemas/components/radio-group'
import TextInput from '~/schemas/components/text-input'

export default function (client, radioProp, dataProp, errorIf) {
  return {
    $el: 'div',
    attrs: {
      class: 'mb-6',
    },
    if: client,
    children: [
      RadioGroup(
        `$: "Is " + ${client}.name + " one of the names currently shown in the land registry as the owner?"`,
        `potential_issues.name_on_deeds.${radioProp}`,
        true,
        [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
        '',
        errorIf,
      ),
      {
        $el: 'div',
        attrs: {
          class: 'section-indent',
        },
        if: `$formData.data.potential_issues.name_on_deeds.${radioProp} === "no"`,
        children: [
          RadioGroup(
            `$: "Why is " + ${client}.name + "'s name different from the name currently show in the land registry as owner?"`,
            `${dataProp}.why_is_name_different`,
            true,
            [
              {
                value: 'change_of_name',
                label: 'Change of name (by Change of Name Deed or by Marriage etc.) from your name which is currently shown on your deed as owner.',
              },
              { value: 'executor', label: 'You are an executor (i.e., you act under a Power of Attorney) for the registered owner.' },
              { value: 'selling', label: 'You are selling as a Personal Representative.' },
              { value: 'other', label: 'Other reason of your name on the instruction form and the name on the office copy being different' },
            ],
            'flex flex-col items-start',
          ),
          {
            $el: 'div',
            attrs: {
              class: 'mt-6',
            },
            if: `$formData.data.${dataProp}.why_is_name_different`,
            children: [
              // If Change of name
              {
                $cmp: 'el-form-item',
                props: { required: true },
                attrs: {
                  key: 'change_of_name',
                },
                if: `$formData.data.${dataProp}.why_is_name_different === "change_of_name"`,
                children: [
                  {
                    $formkit: 'file-upload',
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                    title: 'Please provide your Change of Name Certificate or Marriage Certificate (if the change of name is because of marriage).',
                    name: `${dataProp}.change_of_name.files`,
                    fileName: `${dataProp}.change_of_name.files`,
                  },
                ],
              },
              // If Executor
              {
                $cmp: 'el-form-item',
                props: { required: true },
                attrs: {
                  key: 'executor',
                },
                if: `$formData.data.${dataProp}.why_is_name_different === "executor"`,
                children: [
                  {
                    $formkit: 'file-upload',
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                    title: 'Please provide your certified power of attorney.',
                    name: `${dataProp}.executor.files`,
                    fileName: `${dataProp}.executor.files`,
                  },
                ],
              },
              // If Selling
              {
                $el: 'div',
                attrs: {
                  key: 'selling',
                },
                if: `$formData.data.${dataProp}.why_is_name_different === "selling"`,
                children: [
                  RadioGroup('Have you already obtained a Grant of Probate?', `${dataProp}.selling.grant_of_probate.have_grant_of_probate`, true),
                  {
                    $el: 'div',
                    if: `$formData.data.${dataProp}.selling.grant_of_probate.have_grant_of_probate`,
                    children: [
                      // Got grant of probate
                      {
                        $cmp: 'el-form-item',
                        props: { required: true },
                        attrs: {
                          key: 'grant_of_probate',
                        },
                        if: `$formData.data.${dataProp}.selling.grant_of_probate.have_grant_of_probate === "yes"`,
                        children: [
                          {
                            $formkit: 'file-upload',
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                            title: 'Please provide your Grant of Probate.',
                            name: `${dataProp}.selling.grant_of_probate.files`,
                            fileName: `${dataProp}.selling.grant_of_probate.files`,
                          },
                        ],
                      },
                      // No grant of probate
                      {
                        $el: 'div',
                        attrs: {
                          key: 'no_grant_of_probate',
                          class: 'el-form--label-top',
                        },
                        if: `$formData.data.${dataProp}.selling.grant_of_probate.have_grant_of_probate === "no"`,
                        children: [
                          DatePicker(
                            'Please indicate the approximate date when you will obtain Grant of Probate.',
                            `${dataProp}.selling.approximate_date`,
                            true,
                          ),
                          TextInput('Please provide any other details', `${dataProp}.selling.other_details`, 'textarea', false, { validation: 'required' }),
                        ],
                      },
                    ],
                  },
                ],
              },
              // If Other
              {
                $el: 'div',
                attrs: {
                  key: 'other',
                  class: 'el-form--label-top',
                },
                if: `$formData.data.${dataProp}.why_is_name_different === "other"`,
                children: [
                  TextInput(
                    'Please set out reasons why your name on your instruction form is different from the name shown in the land registry office copy as a registered owner.',
                    `${dataProp}.other.reasons`,
                    'textarea',
                    false,
                    { validation: 'required' },
                  ),
                  {
                    $cmp: 'el-form-item',
                    props: { required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                        title:
                          'Please upload the relevant document to evidence to your buyer solicitors why you have the capacity to deal with this sale (i.e., because the name on your instruction form is different from the owner’s name shown on the land registry office copies).',
                        name: `${dataProp}.other.files`,
                        fileName: `${dataProp}.other.files`,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
