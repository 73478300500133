import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '23.1.',
            text: ``,
          },
          {
            number: '23.1.(a).',
            text: `Warnings.
            <p>You will receive a warning if you are entitled to a warning under statute and your
              period of employment (e.g., over two years of employment). Where you are entitled
              to a warning, as aforesaid, you will receive this warning from FLG or partner or
              your team leader. Note, there is no distinction between a written and verbal warning
              for the purposes of this contract.</p>`,
          },
          {
            number: '23.1.(b).',
            text: `Dismissal notice
            <p>With regards to a dismissal notice, you will receive such information in that notice
              as is required to be given under statute (e.g., over two years of employment. In such
              a case, if required, this notice will be given by FLG or a partner. You as the Employee
              will be informed as soon as possible of the date on which the employment contract will end.  </p>`,
          },
          {
            number: '23.2.',
            text: `If applicable under statute (two years or more of employment with AVRillo), then this clause gives
            guidance on when, why and how a warning and/or dismissal will be conducted.
            <p>For breach of any clause in this contract, including the Company being dissatisfied with your
            work and/or conduct, and/or your performance and/or you acted contrary to the conditions of
            your contract, protocols, and guidelines etc. Prior to a warning, the Company will carry out
            an investigation of that issue, either on paper and/or by meeting (whether by phone, webcam,
            or in-person). If you request a representative, they must be first pre-approved by the Employer
            (they can be, for example, another Employee as they may better understand the breach). The warning may
            include a notice to you to improve (to help you avoid a future similar breach) unless that warning is
            capable of termination. The notice may include the action, breach, misconduct, or poor performance etc.
            and the change in behaviour or improvement in performance required by you immediately (unless a longer
            timescale is stated). Failure to improve your performance may result in a further or final warning
            and/or directly lead to summary dismissal. Note also, it may be appropriate to move directly to a
            final warning and/or your action may amount to summary dismissal if, for example, the Employee's
            breach is repetitive or is liable to have a serious or harmful impact on other Employees or the
            Company. Such breaches include misconduct (including breach of this contract, the Company protocols,
            e.g., office manual, policies, procedures, guidelines, systems, workflows etc.) as well as poor
            performance, such as not delivering goals, expectations either to the Company and/or a client of the
            Company and/or to third parties such as estate agents, lenders etc., and/or you not being consistent
            in your work, results, attendance and/or you not being open to feedback and/or collaborative with your
            team, other employees, or management. Pending investigation, the partners can, at their discretion,
            choose to ask you to not go to work on full pay.</p>`,
          },
          {
            number: '23.3.',
            text: `<u>What misconduct can lead to a warning/s before dismissal?</u>`,
          },
          {
            number: '',
            text: `Note, these examples below which can lead to a warning can also lead
            to instant summary dismissal, without notice, depending on the
            severity of the consequences to the business (its profit, reputation
            etc., and/or whether you have previously been informed that you had
            previously not fully followed the protocols, procedures, training,
            guidelines, etc. or you have made an error in the conduct of your file
            procedure/ protocol, training, guidelines etc., and/or depending on
            the consequence of the said error, for example, leading to an effect
            on the way a client and/or estate agent perceives the quality of the
            service the firm provides, burning a lead etc.
            <u>Breach of this conduct can include,</u> for e.g.:`,
          },
          {
            number: '23.3.(a).',
            text: `<u>Not complying</u> with one or more of the
            Employer’s protocols, obligations and procedures (described elsewhere
            in this contract) on how to conduct a file transaction, run a case,
            approach a client, manage a client, approach or manage an estate agent
            lead, or any other matter which the Employer has made the Employee
            aware of either verbally and/or in writing and/or set out in this
            contract and/or Company guides, and/or training emails or guides,
            and/or videos, audio, Talent LMS training portals, and/or set out in
            workflows in or outside the Employer's portals and case management
            systems, such as Proclaim/Access system.`,
          },
          {
            number: '23.3.(b).',
            text: `<u>Poor performance</u> by not performing tasks in
            accordance with AVRillo working practice, protocols, and procedures,
            as set out as aforesaid, i.e., in training, guidelines, or anywhere
            else during your employment and/or as set out in the Lexcel manual
            and/or in this contract. Examples (non-exhaustive) include making
            mistakes and/or making the same error, not following procedure,
            protocol etc., even after being notified of that previous error,
            non-following of procedure or protocol. Not communicating with clients
            and/or estate agents and/or to the standard and protocols set out and
            trained to the Employee by the Company, and/or the Employee not
            treating the clients and estate agents with the level of care expected
            of AVRillo staff and expected of not only a regulated CLC firm (and
            those of the Law Society) but also the standards expected by working
            for a Company with award winning service and accreditations, including
            ESTAS, customer care, wellbeing, leadership, training, and other
            standards such as the Guardian's Great Places to Work etc.`,
          },
          {
            number: '23.3.(c).',
            text: `<u>Not fully working during office hours unless authorised</u> or
            unless on contractual, statutory breaks, lunches etc. More than one
            breach of this clause could lead to summary dismissal.`,
          },
          {
            number: '23.3.(d).',
            text: `<u>
              Giving an undertaking other than the standard protocol Law Society
              undertaking
            </u>
            regarding a mortgage on a sale. The only exception to this is if the
            Employee obtains written exception in the way laid out below:`,
          },
          {
            number: '(d.i)',
            text: `Making a formal written request to one of the partners.`,
          },
          {
            number: '(d.ii)',
            text: `That by making the request, the Employee has herewith
            undertaken to the Employer that the Employee has both specifically and
            impliedly confirmed, by their request, that they have the ability and
            control to discharge the said undertaking they have requested to
            provide.`,
          },
          {
            number: '(d.iii)',
            text: `The Employee provides, if challenged after the
            undertaking, the actual written authorisation from the partner for the
            Employee undertaking.`,
          },
          {
            number: '(d.iv)',
            text: `That the Employee complies with the Company’s formal
            undertaking procedure (as set out in the office manual) including
            filing the undertaking with the central notice of non-protocol
            undertakings, and then filing a request for that undertaking to be
            taken off the register of undertakings after the Employee has received
            written confirmation of discharge from the recipient of that
            undertaking.`,
          },
          {
            number: '23.3.(e).',
            text: `<u>
              Taking any "documents" (as described in this contract) off the
              firm's premises
            </u> without the written prior consent of the partners.`,
          },
          {
            number: '23.3.(f).',
            text: `<u>Poor timekeeping.</u>`,
          },
          {
            number: '23.3.(g).',
            text: `<u>Breach of any other aspect of your employment,</u>
            and/or as described in the office manual, will be subject to a warning
            of summary dismissal depending on the severity of the conduct and/or
            severity of the loss.`,
          },
          {
            number: '23.4.',
            text: `<u>What misconduct can lead to an instant summary dismissal?</u>`,
          },
          {
            number: '',
            text: `<u>Examples</u> of Employee misconduct or actions that are so serious
            as to amount to summary dismissal including any of those in 23.3
            above, and for the avoidance of doubt, some acts are so serious in
            themselves or have such serious consequences that they may call for
            instant summary dismissal without warning or notice depending on their
            severity and consequences; examples include (but are not limited to)
            those below:`,
          },
          {
            number: '23.4(a).',
            text: `<u>Unbefitting conduct</u> whilst working, including
            being under the influence of alcohol or illegal drugs, fighting,
            physical or verbal assaults, bullying, harassment.`,
          },
          {
            number: '23.4(b).',
            text: `<u>
              Theft, fraud, deliberate falsification of records, untruthful or
              misleading statements.
            </u>`,
          },
          {
            number: '23.4.(c).',
            text: `<u>Improper use of or disclosure of information</u>&nbsp;
            concerning the Company and/or individual partners and/or the clients
            of the firm which may come to the Employees by reason of their
            employment.`,
          },
          {
            number: '23.4.(d).',
            text: `<u>Improper use of or disclosure of documents and precedents</u>
            (hereinafter called the "documents") created or compiled on the firm's
            time or taken from documents prepared by the firm or taken from case
            management software operated by the firm. Further, it will be a breach
            of the rules, which may result in instant dismissal without warning by
            any partner where any "documents" and/or information is taken off the
            premises for any use unless by previous consent given by the partners
            in writing.`,
          },
          {
            number: '23.4.(e).',
            text: `<u>The use and/or procurement of financial information</u> which is
            not the subject of public knowledge, including documents and/or
            figures either personally recorded by you or calculated using the
            firm's software and using the same to make inaccurate assumptions
            about the financial status of the firm and/or communicating this to
            any other person will amount to an act capable of summary dismissal
            (for example, where assumptions are made as to the personal losses
            and/or profits of the firm and/or discussing this information with
            third parties or fellow Employees).`,
          },
          {
            number: '23.4.(f).',
            text: `<u>The Employee disclosing their security details</u>
            (passwords, codes, usernames etc., hereinafter collectively referred
            to as password/s) to any person either not employed by the Company,
            but also those employed by the Company unless to IT or the partners.
            Because of the severity of loss, this disclosure breach is strict
            liability, so it will be an employee breach whether innocently,
            recklessly or negligently disclosed by them. For example, by allowing
            their passwords to be hacked by not following the Company's security
            and cybercrime guidelines. Or by allowing another person to find their
            password by not being secure enough, working remotely and allowing the
            neighbourhood to hack their remote session or remote working using a
            third-party internet or Wi-Fi connection. Also, e.g., by not having
            sufficient and up to date anti-virus software on their laptop, pc,
            mobile or other similar devices.`,
          },
          {
            number: '23.4.(g).',
            text: `<u>Breach of the confidentiality clause and agreements</u>, including
            as set out in this contract.`,
          },
          {
            number: '23.4.(h).',
            text: `<u>
              Discussing with persons not employed by this firm, any internal
              procedures of this firm.
            </u>
            For example: The AVRillo Conveyancing system, including our protocol
            on how we work our files, with trigger stages such as contracts out,
            automated correspondence, letter content etc., and/or any precedents
            and documents of this firm, including those on the case management
            workflows devised and/or coded by AVRillo (itself or project managed
            by AVRillo, or on its behalf by third parties) into our Conveyancing
            AVRillo system.`,
          },
          {
            number: '23.4.(i).',
            text: `<u>Passing on confidential information</u> (e.g.,
              discussing the firm's business outside of the firm and to others
              outside the firm (including, for example, discussing the firm's
              procedures/marketing campaigns/marketing
              ideas/plans/innovations/workflow plans/current systems of work and
              workflows/ideas mooted on future workflows/internal procedures/the
              firm's contacts and strategies to win new business and clients/its
              databases/precedents and documents used by the firm, including those
              on the case management system developed by and/or unique to the firm.`,
          },
          {
            number: '23.4.(j).',
            text: `<u>Discussing a client's business outside the firm.</u> In addition,
            disclosure of confidential information of its business, as well as
            other matters, set out in the disciplinary and grievance policy as
            found in the office manual. In addition, disclosure of confidential
            information of its business as well as other matters relating to its
            Company, Employees, and partners.`,
          },
          {
            number: '23.4.(k).',
            text: `<u>
              Not following the firm's protocols, guidelines, training, workflows,
              or work procedures,
            </u>
            which you have been informed of and/or alerted to previously and/or
            you should have been aware of, and you had not followed and are
            serious enough depending on its consequences to amount to breach
            sufficient to lead to summary dismissal (e.g., if causing financial
            loss and/or loss of reputation to the Company).`,
          },
          {
            number: '23.4.(l).',
            text: `<u>
              Giving an undertaking other than the standard protocol Law Society
            </u>
            undertaking regarding a mortgage on a sale (as set out in further
            described elsewhere in this contract).`,
          },
          {
            number: '23.4.(m).',
            text: `<u>The Employee making an untrue statement</u>
            against the Company and/or Employee and/or partner. Noting, in such a
            case, Court action may be taken by the Employer against the Employee
            including a claim for Defamation and loss, even after the Employee is
            no longer working for the Company.`,
          },
          {
            number: '23.4.(n).',
            text: `<u>
              Any conduct, i.e., act and/or statement which may have the effect of
              bringing the integrity and reputation
            </u>
            of the firm into disrepute (as assessed by the partners), including
            criticism of the firm and/or its partners and/or its staff, whether or
            not loss is proved.`,
          },
          {
            number: '23.4.(o).',
            text: `Our culture of providing a voice for Employees and
              mental wellbeing objective means you follow our
              <u>Wellbeing and Harmony</u>
              clause. This clause is founded on our culture of mutual respect,
              honesty, collaboration, unity and team spirit, where every individual
              is free from discrimination and is treated equally, giving them the
              opportunity to create greater happiness and wellbeing for themselves
              and those they work with.`,
          },
          {
            number: '(o.i)',
            text: `To allow you the freedom to say what you feel is right,
            thereby encouraging mental wellbeing, we ask you to communicate your
            open feedback (including reporting wrongdoing, a complaint, an honest
            criticism or any other issues). Whether this be firstly, about the
            firm (our protocols, policies, procedures, conduct, including
            partners) or secondly, about you or another in the firm. This clause
            reduces the mental stress of thinking you have to compromise your
            integrity and professional ethics by not speaking up or feeling
            pressured to keep a secret by covering up where things are wrong.
            Simply communicate by notifying the partners as they have the power to
            investigate and try to resolve such problems. If criticism is
            regarding one of the partners, notify one of the other partners you
            feel more comfortable to notify or the FLG.`,
          },
          {
            number: '(o.ii)',
            text: `You must not, however, cause adverse mental wellbeing
            against others by bullying, including gossiping about them to others,
            when your channel of resolution is to notify the partners who have the
            power to help. Therefore, do not communicate (verbally or in writing)
            false comments about an individual or the firm (including behind their
            back, in or outside of work, including on social media) as this can
            not only affect their reputation, wellbeing and the team harmony, but
            also causes you being defamatory and in breach of your contract and
            dragging the person you communicate to into similar misconduct.
            Illustrations of this conduct include:`,
          },
          {
            number: '(o.ii).1',
            text: `Malicious whispering, ganging up, gossiping about another.`,
          },
          {
            number: '(o.ii).2.',
            text: `Not challenging 'gossip', (i.e., where the gossiper
              talks about someone to you, but you continue to listen and/or you do
              not ask them to stop and/or ask them not to involve you and/or to
              notify the partners instead, are all breaches. So, for example, not
              saying something along the lines of "I do not want to hear about that
              as I cannot address it and suggest you speak to the partners or
              management, and I will now need to let them know”. This is to
              encourage a zero tolerance in breaching this wellbeing and harmony
              clause.`,
          },
          {
            number: '(o.ii).3.',
            text: `You are asking someone else within the firm to keep a
            secret about someone else’s misconduct, breach of protocol, procedure,
            policy, contract, gossiping.`,
          },
        ]),
      ],
    },
  ]
}
