import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import savingsClientDetails from '~/schemas/components/sof/source-of-funds-sections/savings-client-details'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Savings' },
    children: [
      TitleSectionRadioGroup('2', 'Savings', 'source_of_funds.savings', [
        {
          $el: 'div',
          if: '$formData.data.source_of_funds.savings === "yes"',
          children: [
            savingsClientDetails('$conveyancingCase.client', '$conveyancingCase.client.name', 'source_of_funds.client_using_savings'),
            savingsClientDetails('$conveyancingCase.client2', '$conveyancingCase.client2.name', 'source_of_funds.client2_using_savings'),
            savingsClientDetails('$conveyancingCase.client3', '$conveyancingCase.client3.name', 'source_of_funds.client3_using_savings'),
            savingsClientDetails('$conveyancingCase.client4', '$conveyancingCase.client4.name', 'source_of_funds.client4_using_savings'),
          ],
        },
      ]),
    ],
  }
}
