import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '34.2.',
            text: `To work on research and developing and innovating
            workflows and systems for the conveyancing industry as well as
            integrations into case management systems with third parties as well
            as developing our own and all projects as directed by the equity
            partners. This includes increasing efficiency in case management such
            as speed, automation, proactive workflows to reduce manual work of the
            lawyers and support team in conveyancing. The role includes creating,
            developing, testing, and fixing code to make it as efficient and
            automated as possible, fixing issues as and when they are found on the
            code. It also includes techniques to reach that goal such as mapping,
            thinking outside the box to find solutions different from other firms
            using case management to make the most efficient and innovative case
            management system on the market. Our aim is to deliver a
            ground-breaking case management end-user experience, along with the
            principle, for example, created by the likes of Apple, which created
            ground-breaking innovative concepts for the end-user, theirs being in
            the computer and mobile market, ours being for conveyancers, their
            support team, as well as clients and owners etc.`,
          },
          {
            number: '34.3.',
            text: `To delegate non-development and coding etc., as
            aforesaid, to others, such as ETI Cloud or Proclaim/Access support
            teams (and you do not do their work).`,
          },
          {
            number: '34.4.',
            text: `To ask questions without embarrassment or guilt of being
            judged as the team will not judge you if you do not know something as
            we have an open learning value of enhancing our knowledge to develop
            and innovate by sharing ideas and solutions. This means you must ask
            questions immediately you know you are stuck (i.e., after spending no
            more than 30 minutes in trying to sort out your solution on your own
            but without success). We do not want you to reinvent the wheel, but
            instead for you to ask the team as you will often find one of them
            will have had experience and can shortcut the time you spend on it.
            The reason is that everyone is busy, and everyone needs you to
            contribute equally by not taking longer than another team member would
            take if by asking your question, they could save your time. You must
            ask, you must listen, take notes and a video of the explanation
            session (so you can go back and practice that potential solution).`,
          },
          {
            number: '34.5.',
            text: `To manage your work as part of the development team
            around you (and pursuant to our principles of mutual care, respect,
            and support of each other), you shall follow the Company’s guidelines,
            such as using identical approved documentation of work workflows and
            coding. Project managing your work by using the Company’s four folder
            priority task working system, so you and everyone in the team are
            working on similar priority work first (before non-priority
            workflows), as directed by the Company in conjunction with your
            colleagues around you. This principle is why you need to use project
            managing software as authorised by the firm (as of the date of this
            contract, the principal system in Trello). To help you, your
            colleagues, and the Company, manage projects, you will set out each
            task you are given onto the Trello board, on the lists allocated to
            your name. You shall create a separate card for each task and add to
            that card, firstly, the date it is given to you/ you place it into
            your Trello list (00/00/00); secondly, a time guesstimate
            (best-measured guess) on how many hours that task will take you to
            fully complete; thirdly, the best guesstimate date you will start date
            the project; fourthly, the best estimate of the end date. You will
            keep this updated as a live document, which means at least daily, and
            in any event marking projects as completed and archiving them when
            signed off by the Company. It also means updating the partners and
            tyrosine working with you Kim work done, including via Trello as well
            as email and daily conferences as required. You will also maintain
            cards in your working on today column, so everyone in the team and the
            partners can follow what is being done, with time estimated so they
            can project manage the overall work and deadlines, tasks, and
            priorities needed by the Company. It will also allow you sometimes to
            come in and out of projects in case of new priorities that arise from
            time to time that require urgent attention as a priority off work.`,
          },
          {
            number: '34.6.',
            text: `To help colleagues in the coding and development team
            when they ask you questions, including training them for up to 30
            minutes (if you are working on a non-priority matter) but providing
            prior to you helping them, they agree to our guidelines that they
            firstly take notes of the session and a video, and they send you a
            copy of each, so you know they have understood. If the request for
            training etc., will interfere with your priority tasks, then at the
            daily meeting, you must ask your team colleagues if they have less
            priority work on so they can use their time instead, but if all have
            equal priority work than to ask the partners for prior authority on
            who shall train and answer the question for the person requesting
            this. The only exception is if the question can be answered during the
            daily brainstorming coding sessions, often held at midday, where
            everyone must explain what they are each working on, their priorities,
            share ideas, knowledge, and solutions. These daily team meetings must
            be used to be very open and ask questions, brainstorm problems, or ask
            where you are stuck for or are taking longer than it would take
            another team member.`,
          },
          {
            number: '34.7.',
            text: `Not to make amendments to master documents, workflows,
            systems etc., unless authorised by the partners (who try to maintain a
            daily overview of the company requirements) or the partners delegate
            someone to work with you to delegate those changes to you.`,
          },
        ]),
      ],
    },
  ]
}
