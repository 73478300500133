export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(6.3) Does the Landlord insure the building if the property is a flat?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.landlord_insures_building`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
