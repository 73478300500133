import { isBaspiOnly } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isBaspiOnly(),
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(13.9) Liquid Petroleum Gas (LPG) provider if applicable please provide details as to whether the property is connected or the date it will be connected and the supplier details (if known, if not please state "not known")',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.liquid_petroleum_gas_provider`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(13.9) Oil provider if applicable please provide details as to whether the property is connected or the date it will be connected and the supplier details (if known, if not please state "not known")',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.oil_provider`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
