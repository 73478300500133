import {
  compareTwoValues,
  isClientExpression,
  isFalseCondition,
  isTrueCondition,
  phoneFormatValidation,
  phoneValidation,
} from '~/schemas/components/common/helpers'
import inlineSwitcher from '~/schemas/components/common/inline-switcher.js'

export default function (parentPath, otherClientPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: 'pl-7 pr-6 pt-6' },
      children: [
        {
          if: isClientExpression(parentPath, 2),
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: { label: 'Is there second client?' },
                  children: [
                    {
                      $formkit: 'el-switch',
                      name: `${parentPath}.is_there_second_client`,
                      activeText: 'Yes',
                      inactiveText: 'No',
                      inlinePrompt: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          if: isClientExpression(parentPath, 3),
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: { label: 'Is there third client?' },
                  children: [
                    {
                      $formkit: 'el-switch',
                      name: `${parentPath}.is_there_third_client`,
                      activeText: 'Yes',
                      inactiveText: 'No',
                      inlinePrompt: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          if: isClientExpression(parentPath, 4),
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: { label: 'Is there fourth client?' },
                  children: [
                    {
                      $formkit: 'el-switch',
                      name: `${parentPath}.is_there_fourth_client`,
                      activeText: 'Yes',
                      inactiveText: 'No',
                      inlinePrompt: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          if: `${isClientExpression(parentPath, 1)} || ${isTrueCondition(`${parentPath}.is_there_second_client`)} || ${isTrueCondition(`${parentPath}.is_there_third_client`)} || ${isTrueCondition(`${parentPath}.is_there_fourth_client`)}`,
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  if: isClientExpression(parentPath, 2),
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'What is the relationship of the 2nd joint buyer to 1st joint buyer?',
                            required: true,
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: `${parentPath}.relationship_to_first_client`,
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                              options: {
                                husband: 'Husband',
                                wife: 'Wife',
                                sibling: 'Sibling',
                                parent: 'Parent',
                                other: 'Other',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  if: isClientExpression(parentPath, 3),
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'What is the relationship of the 3rd joint buyer to 1st joint buyer?',
                            required: true,
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: `${parentPath}.relationship_to_first_client`,
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                              options: {
                                husband: 'Husband',
                                wife: 'Wife',
                                sibling: 'Sibling',
                                parent: 'Parent',
                                other: 'Other',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  if: isClientExpression(parentPath, 4),
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'What is the relationship of the 4th joint buyer to 1st joint buyer?',
                            required: true,
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: `${parentPath}.relationship_to_first_client`,
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                              options: {
                                husband: 'Husband',
                                wife: 'Wife',
                                sibling: 'Sibling',
                                parent: 'Parent',
                                other: 'Other',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24, sm: 24, md: 4 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: { label: 'Title', required: true },
                          children: [
                            {
                              $formkit: 'el-input',
                              name: `${parentPath}.title`,
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24, sm: 24, md: 8 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: { label: 'Forename (as on your passport)', required: true },
                          children: [
                            {
                              $formkit: 'el-input',
                              name: `${parentPath}.forename`,
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24, sm: 24, md: 6 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: { label: 'Middle Name' },
                          children: [
                            {
                              $formkit: 'el-input',
                              name: parentPath + '.middle_name',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24, sm: 24, md: 6 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: { label: 'Surname', required: true },
                          children: [
                            {
                              $formkit: 'el-input',
                              name: parentPath + '.surname',
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24, sm: 12 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: { label: 'Mobile', required: true },
                          children: [
                            {
                              $formkit: 'el-input',
                              name: `${parentPath}.mobile`,
                              validation: `required|phoneFormatValidation|(500)phoneValidation|(500)compareTwoValues:${otherClientPath}.mobile`,
                              validationRules: { phoneFormatValidation, phoneValidation, compareTwoValues },
                              validationMessages: {
                                phoneValidation: 'Phone is not a valid UK mobile number',
                                required: 'The phone number is required',
                                phoneFormatValidation: 'Phone format is not valid.',
                                compareTwoValues: 'The phone number should be different from the other client phone number',
                              },
                              validationVisibility: 'live',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24, sm: 12 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: { label: 'Email', required: true },
                          children: [
                            {
                              $formkit: 'el-input',
                              name: parentPath + '.email',
                              validation: 'required|email',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  if: isClientExpression(parentPath, 2),
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { span: 24 },
                      children: [
                        ...inlineSwitcher(
                          `${parentPath}.is_address_same_as_first_client`,
                          "Is the 1st client's residing address, identical to 2nd client address?",
                        ),
                      ],
                    },
                  ],
                },
                {
                  if: isClientExpression(parentPath, 3),
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { span: 24 },
                      children: [
                        ...inlineSwitcher(
                          `${parentPath}.is_address_same_as_first_client`,
                          "Is the 1st client's residing address, identical to 3rd client address?",
                        ),
                      ],
                    },
                  ],
                },
                {
                  if: isClientExpression(parentPath, 4),
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { span: 24 },
                      children: [
                        ...inlineSwitcher(
                          `${parentPath}.is_address_same_as_first_client`,
                          "Is the 1st client's residing address, identical to 4th client address?",
                        ),
                      ],
                    },
                  ],
                },
                {
                  if: `${isClientExpression(parentPath, 1)} || ${isFalseCondition(`${parentPath}.is_address_same_as_first_client`)}`,
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { span: 24 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: { label: 'Search Address', required: true },
                          children: [
                            {
                              $formkit: 'address-form-autocomplete',
                              name: `${parentPath}.address`,
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { span: 24 },
                      children: [
                        {
                          if: isClientExpression(parentPath, 1),
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'Do you have a valid passport ?',
                            required: true,
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: `${parentPath}.has_valid_passport`,
                              options: {
                                yes: 'Yes',
                                no: 'No',
                              },
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                        {
                          if: isClientExpression(parentPath, 2),
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'Does the 2nd client have a valid passport ?',
                            required: true,
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: `${parentPath}.has_valid_passport`,
                              options: {
                                yes: 'Yes',
                                no: 'No',
                              },
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                        {
                          if: isClientExpression(parentPath, 3),
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'Does the 3rd client have a valid passport ?',
                            required: true,
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: `${parentPath}.has_valid_passport`,
                              options: {
                                yes: 'Yes',
                                no: 'No',
                              },
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                        {
                          if: isClientExpression(parentPath, 4),
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'Does the 4th client have a valid passport ?',
                            required: true,
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: `${parentPath}.has_valid_passport`,
                              options: {
                                yes: 'Yes',
                                no: 'No',
                              },
                              validation: 'required',
                              validationMessages: {
                                required: 'This field is required',
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
