import Repeater from '~/components/Repeater.vue'
import TotalCounter from '~/components/TotalCounter.vue'
import { useProps } from '~/composables/useProps'
import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import GiftFromAThirdPartyGift from '~/schemas/components/sof/source-of-funds-sections/gift-from-a-third-party-gift'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Gift from a third party' },
    children: [
      TitleSectionRadioGroup(
        '1',
        'Gift from a third party',
        'source_of_funds.gift_from_a_third_party',
        [
          {
            $el: 'div',
            if: '$formData.data.source_of_funds.gift_from_a_third_party === "yes"',
            children: [
              {
                $el: 'div',
                children: [
                  {
                    $cmp: Repeater,
                    props: useProps({
                      prop: 'source_of_funds.gifts',
                      buttonText: '+ Add new gift',
                      removeButton: true,
                      min: 1,
                      max: 4,
                    }),
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'border-solid border-[1px] p-4 pt-8',
                        },
                        children: [GiftFromAThirdPartyGift()],
                      },
                    ],
                  },
                  {
                    $cmp: TotalCounter,
                    props: {
                      dataToCountKey: 'amount_of_gift',
                      dataArrayKey: 'source_of_funds.gifts',
                      label: 'Total gift to be received =',
                    },
                  },
                ],
              },
            ],
          },
        ],
        '(i.e., family)',
      ),
    ],
  }
}
