import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '27.1.',
            text: `During the period of 4 months after the last day of
            employment with AVRillo, the Employee will not canvass, solicit,
            entice or approach any client or third-party referrer of work to the
            Company (such as estate agent, mortgage broker or other contact) to
            provide them with Conveyancing and/or other services, either yourself
            or for your new employers or to another company for which you obtain a
            benefit or payment for that instruction. Note: This clause is included
            to protect our work from being poached by you and/or your new Employer
            after you start working with them. So, for the avoidance of doubt,
            AVRillo will infer
            <i>
              (and you agree as prima facie evidence of you breaching this
              restrictive covenant)
            </i>
            that you will have breached this clause if, during the period of the
            said 4 months following termination, your next Employer and/or another
            company you get to benefit from, received referrals and/or work from
            one of our supplier referrer agents as at your leaving date, even if
            your new Employer allocates that said work to someone other than you.`,
          },
          {
            number: '27.2.',
            text: `During the period of 4 months after the termination of
            your employment with the firm, you will not canvass and/or solicit
            and/or approach any other Employee of AVRillo or its associated
            companies during that period for the purpose of enticing that Employee
            away from the Company to work elsewhere or become self-employed. Note:
            This clause is included to prevent our staff from being poached in the
            event of termination. So, for the avoidance of doubt, AVRillo will
            infer that you will have breached this clause (and you agree) if,
            during the period of the said 4 months following termination, you,
            your new Employer,
            <i>or any other business you become associated or involved with,</i>
            approaches any Employee of AVRillo for the purposes of enticing them
            to leave AVRillo and, e.g., work or be involved with the conveyancing
            and estate agency industry elsewhere.`,
          },
        ]),
      ],
    },
  ]
}
