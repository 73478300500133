import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import TextInput from '~/schemas/components/text-input'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Mortgage Loan' },
    children: [
      TitleSectionRadioGroup('4', 'Mortgage Loan', 'source_of_funds.mortgage_loan.mortgage_loan', [
        {
          $el: 'div',
          if: '$formData.data.source_of_funds.mortgage_loan.mortgage_loan === "yes"',
          attrs: {
            class: 'grid grid-cols-3 gap-4 section-indent',
          },
          children: [
            {
              $el: 'div',
              attrs: {
                class: 'col-span-2 el-form--label-top',
              },
              children: [
                TextInput('Amount', 'source_of_funds.mortgage_loan.amount', 'number', true, { validation: 'required' }),
                {
                  $formkit: 'sale-lenders-input',
                  if: '$formData.data.source_of_funds.mortgage_loan.lender_not_in_list !== true',
                  validation: 'required',
                  name: 'source_of_funds.mortgage_loan.lender_name',
                  title: 'Your Lender Name',
                  subTitle: '(scroll options in the box below)',
                },
                {
                  $el: 'div',
                  if: '$formData.data.source_of_funds.mortgage_loan.lender_not_in_list === true',
                  children: [
                    TextInput('Your Lender Name', 'source_of_funds.mortgage_loan.lender_name', '', false, { validation: 'required' }),
                    {
                      $el: 'div',
                      attrs: {
                        class: 'text-red-500',
                      },
                      children: 'Type your lender name in the box above',
                    },
                  ],
                },
                {
                  $formkit: 'el-checkbox',
                  name: 'source_of_funds.mortgage_loan.lender_not_in_list',
                  text: 'My Lender is not in the list',
                },
              ],
            },
          ],
        },
      ]),
    ],
  }
}
