export default function (parentPath) {
  return {
    $cmp: 'ElCol',
    props: { span: 24, class: 'mt-6' },
    children: [
      {
        $cmp: 'ElFormItem',
        children: [
          {
            $formkit: 'el-checkbox-group',
            attrs: {
              class: 'flex flex-inline',
            },
            name: `${parentPath}.no_move_no_cost_60_days_free`,
            options: [{ label: "Tick to opt for our 'No Move No Costs' protect and our ‘Free 60 Day Money Back Happy Service Guarantee’:", value: 'accepted' }],
          },
        ],
      },
      {
        $el: 'div',
        attrs: { class: 'text-sm' },
        children: [
          {
            $el: 'p',
            attrs: { class: 'mb-2' },
            children:
              'I understand that if I tick this option then, firstly, I automatically receive, for free, the AVRillo 60 Day Money-Back ‘Happy Service Guarantee’. This means I can pull out if I am unhappy in any way, no questions asked by AVRillo, no solicitor costs paid to AVRillo. Secondly, by ticking this box, I will automatically be eligible for AVRillo’s ‘No Move No Fee’ product. This means regardless of how many months AVRillo work for me, this is all for free if the otherside pulls out on me. I understand this is a real risk in the England (because 1 in 3 of sellers and buyers fail to complete). Without the protection I would be liable to pay all legal fees as well as losing out on property price changes (these can go up as well as down in this current property market). I understand that if I don’t tick the box, I take the risk of paying full solicitor costs (as would a normal client). I understand there is no upfront payment for ‘no move no fee’ product (it is simply added to the end -see estimate attached, £97 plus vat) which secures my costs protection to the end and I understand the first 60 Day money back guarantee is completely free.',
          },
        ],
      },
    ],
  }
}
