<script setup lang="ts"></script>
<template>
  <div class="prose max-w-full text-sm">
    <p class="text-md italic' mb-4 mt-4 bg-gray-100 p-3 font-semibold text-black">Note for the vendor / seller, please read:</p>
    <div class="pl-6">
      <p>
        Attach all your documents referred in 4 below, as they are required by your buyer solicitors will need you referred to in section 4 below, such as
        planning permission and Building Regulation approval and Completion Certificates.
      </p>
      <p>
        Where to find your documents? If alterations were carried out before you purchased your property, our previous solicitors should have provided you with
        this paperwork. If you have made your alterations since your ownership, you will have been providing with this paperwork by your contractor.
      </p>
      <p>
        If you have lost your documents, then you can download most of these, e.g. your local authority portal, which will hold planning permission and Building
        Regulation approval and Completion Certificates etc. If these are missing, do not apply for retrospective consent without notifying us first. After
        notice to the authorities, you cannot obtain indemnity insurance for missing documents that the buyers’ conveyancers may accept potentially for your
        lost documents.
      </p>
    </div>
  </div>
</template>
