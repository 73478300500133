import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(1.5) Is there a flying freehold? (NOTE: a flying freehold is when part of the property overhangs a neighbour’s property or an accessway – eg a terrace house where part of the upstairs is over an accessway which belongs to someone else)',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.flying_freehold`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.flying_freehold === "yes"`),
            $cmp: 'el-form-item',
            props: {
              label: '(1.5.1) Please kindly provide information as to what section of the property you are referring to?',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.flying_freehold_clarify`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                inputType: 'textarea',
                rows: 6,
              },
            ],
          },
        ],
      },
    ],
  }
}
