import BuyingProperty from '~/schemas/components/pid/potential-issues-sections/buying-property'
import DistributeProceeds from '~/schemas/components/pid/potential-issues-sections/distribute-proceeds'
import JointBankAccount from '~/schemas/components/pid/potential-issues-sections/joint-bank-account'
import NameOnDeeds from '~/schemas/components/pid/potential-issues-sections/name-on-deeds'
import PartnershipIssues from '~/schemas/components/pid/potential-issues-sections/partnership-issues'
import PotentialProblems from '~/schemas/components/pid/potential-issues-sections/potential-problems'
import ThirdPartyPayment from '~/schemas/components/pid/potential-issues-sections/third-party-payment'

export default function () {
  return [NameOnDeeds(), ThirdPartyPayment(), PotentialProblems(), PartnershipIssues(), BuyingProperty(), JointBankAccount(), DistributeProceeds()]
}
