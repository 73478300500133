import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (data) {
  return {
    $el: 'div',
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-1 md:grid-cols-2 gap--4',
        },
        children: [
          TextInput('(a) How much re-mortgage funds do you expect to receive?', data + '.how_much_re_mortgage_funds', 'number', true, {
            validation: 'required',
          }),
        ],
      },
      TextInput('(b) Provide full address details of the property you are re-mortgaging.', data + '.address_of_property', 'textarea', false, {
        rows: 4,
        validation: 'required',
      }),
      Title(
        '(c) Please obtain a letter (on their letterhead) from the solicitor acting on your re-mortgaging to confirm the amount of money they will be send to you (so you can then transfer to us)',
        'mb-4',
      ),
      {
        $cmp: 'el-form-item',
        props: { required: true },
        children: [
          {
            $formkit: 'file-upload',
            validation: 'required',
            validationLabel: 'File upload',
            validationVisibility: 'live',
            name: `${data}.letter.files`,
            fileName: `${data}.letter.files`,
          },
        ],
      },
    ],
  }
}
