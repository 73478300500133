export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50, class: 'mt-6' },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $formkit: 'el-checkbox-group',
            attrs: {
              class: 'flex flex-inline',
            },
            name: `${parentPath}.priority_fast_track_to_exchange`,
            options: [
              {
                label:
                  "Press for 'Priority Fast Track to Exchange’ service; helping reduce risk of you failing to complete from the 33% UK average, to AVRillo’s 9% average:",
                value: 'accepted',
              },
            ],
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children: 'AVRillo will fast track my case to exchange.',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '1) Benefit. This will reduce my risk of being one of the 48% of people who fail to move every year because of solicitor ‘delays’. Note. The Liverpool and Victoria’s Legal’ survey found 112,000 of failed cases were due to solicitor ‘delays’ (that’s ½ million aborted cases over 5 years blamed on lawyers).Our fast track reduces that risk to just 9%).',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '2) What money can I save with Fast Track? A fall through will cost you money. Not only on lost fees but also on property price changes (these can go up or down by tens of thousands of pounds in between your losing your first move, and your second attempt at moving).',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '3) How does Fast Track work? Where the average solicitor can take up to between 5 to 10 days to consider each piece of correspondence, AVRillo’s Priority Fast Track takes less than 24 working hours to consider each piece of correspondence. This reduces delays and increases your chance of moving from 63% to 91%.',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '4) Cost? If you tick this option box now, the cost will be limited to a one off discounted figure of £97.00 plus vat. There is no upfront payment for this optional protection; it is simply added at the end of the case (see estimate attached). 5) Don’t forget, until Exchange the other party can pull out at any time, and with no reason. The faster your solicitor fast tracks you to exchange, the best chance you have of moving without loss.',
          },
        ],
      },
    ],
  }
}
