import SectionCard from '~/components/SectionCard.vue'
import { useSchema } from '~/composables/useSchema'
import amlDeclaration from '~/schemas/components/gift-declaration/aml-declaration'
import declarationOfCybercrime from '~/schemas/components/gift-declaration/declaration-of-cybercrime'
import esignDeclaration from '~/schemas/components/gift-declaration/esign-declaration'
import firstSignature from '~/schemas/components/gift-declaration/first-signature'
import giftSolvencyDeclaration from '~/schemas/components/gift-declaration/gift-solvency-declaration'
import giftUnderstandingDeclaration from '~/schemas/components/gift-declaration/gift-understanding-declaration'
import letterToLender from '~/schemas/components/gift-declaration/letter-to-lender'
import letterToTheDoneeLender from '~/schemas/components/gift-declaration/letter-to-the-donee-lender'

export default useSchema({
  id: 'gift',
  title: 'GIFT DECLARATION',
  autosave: true,
  steps: false,
  signature: false,
  properties: [
    {
      $cmp: SectionCard,
      children: [
        {
          $el: 'div',
          children:
            'I confirm that I have read, understood, and agree the following statements (made within the following sections in this declaration) are accurate and represent my true intentions in making my gift to the Donee.',
          attrs: {
            class: 'text-xs mt-4 mb-4',
          },
        },
      ],
    },
    giftUnderstandingDeclaration(),
    giftSolvencyDeclaration(),
    amlDeclaration(),
    declarationOfCybercrime(),
    letterToTheDoneeLender(),
    esignDeclaration(),
    firstSignature(),
    letterToLender(),
  ],
})
