import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  const articleTextExtra = (employee_role) => {
    switch (employee_role) {
      case 'Conveyancer':
        return 'If you are also title checking please refer to clause 34 as an illustration part of your role when title checking.'
      case 'Business Development':
      case 'Quote Giver':
      case 'Developer':
      case 'Title Checker':
        return 'An illustration of your role is set out in clause 34.'
    }
  }

  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '5.1.',
            text: `$: "Your appointment is for the role defined in our offer
            letter: <span class='text-primary font-bold'>" + $employeeCase.job_title + "</span> and as redirected by the partners and as you progress in your
            development over time."`,
          },
          {
            number: '5.2.',
            text: {
              if: `${`$employeeCase.job_title`} === 'Conveyancer'`,
              then: `A role is subject to change firstly, as the legal
              market changes and innovates, and secondly, as you learn and
              develop in your role (i.e., learn on the job, progress your skills
              to allow a review in pay). If you are also title checking please refer to clause 34 as an illustration part of your role when title checking.`,
              else: {
                if: `${`$employeeCase.job_title`} === 'Business Development' || ${`$employeeCase.job_title`} === 'Quote Giver' ||  ${`$employeeCase.job_title`} === 'Developer' ||  ${`$employeeCase.job_title`} === 'Title Checker'`,
                then: `A role is subject to change firstly, as the legal
                market changes and innovates, and secondly, as you learn and
                develop in your role (i.e., learn on the job, progress your skills
                to allow a review in pay). An illustration of your role is set out in clause 34.`,
                else: `A role is subject to change firstly, as the legal
                market changes and innovates, and secondly, as you learn and
                develop in your role (i.e., learn on the job, progress your skills
                to allow a review in pay).`,
              },
            },
          },
        ]),
      ],
    },
  ]
}
