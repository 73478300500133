import section_11_1 from '~/schemas/components/pif/occupier-information/section_11_1'
import section_11_2 from '~/schemas/components/pif/occupier-information/section_11_2'
import section_11_3 from '~/schemas/components/pif/occupier-information/section_11_3'
import section_11_4 from '~/schemas/components/pif/occupier-information/section_11_4'
import section_11_5 from '~/schemas/components/pif/occupier-information/section_11_5'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [section_11_1(parentPath), section_11_2(parentPath), section_11_3(parentPath), section_11_4(parentPath), section_11_5(parentPath)],
            },
          ],
        },
      ],
    },
  ]
}
