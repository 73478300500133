import section_10_1 from '~/schemas/components/pif/other-charges/section_10_1'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [section_10_1(parentPath)],
            },
          ],
        },
      ],
    },
  ]
}
