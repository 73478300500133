import RadioGroup from '~/schemas/components/radio-group'
import GiftFromAThirdPartyGiftDetails from '~/schemas/components/sof/source-of-funds-sections/gift-from-a-third-party-gift-details'
import TextInput from '~/schemas/components/text-input'

export default function () {
  return {
    $el: 'div',
    children: [
      RadioGroup('Is this gift from a joint party (i.e., mother AND father) or not a joint party (i.e., mother OR father)?', 'gift_from_a_joint_party', true),
      {
        $el: 'div',
        if: '$value.gift_from_a_joint_party',
        children: [
          {
            $el: 'div',
            children: 'Please provide full email, mobile & name of all Donors to avoid them failing government Anti-Money Laundering regulations.',
            attrs: {
              class: 'font-bold text-sm mb-4 bg-red-100 rounded p-1 text-red-400 mt-4',
            },
          },
          {
            $el: 'div',
            attrs: {
              class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'flex-grow',
                },
                children: [TextInput('Amount of the gift', 'amount_of_gift', 'number', true, { validation: 'required' })],
              },
              {
                $el: 'div',
                attrs: {
                  class: 'flex-grow',
                },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      class: 'el-form-item--large',
                      label: 'Who is receiving this gift?\n' + '(see the scroll options in the box below)',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'clients-select',
                        validation: 'required',
                        name: 'who_is_receiving_gift',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $el: 'div',
            children: {
              if: '$value.gift_from_a_joint_party === "yes"',
              then: [
                ...GiftFromAThirdPartyGiftDetails('1st', 'donor1'),
                {
                  $el: 'hr',
                  attrs: {
                    class: 'my-4',
                  },
                },
                ...GiftFromAThirdPartyGiftDetails('2nd', 'donor2'),
              ],
              else: GiftFromAThirdPartyGiftDetails('', 'donor1'),
            },
          },
        ],
      },
    ],
  }
}
