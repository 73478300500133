import RadioGroup from '~/schemas/components/radio-group'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (clientName, parentPath) {
  const savingsInName = '$value.savings_in_your_name'
  return {
    $el: 'div',
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'mb-4',
        },
        children: [RadioGroup(`$: "Are the savings in an account in your name under " + ${clientName} + "?"`, 'savings_in_your_name', true)],
      },
      {
        $el: 'div',
        if: '$value.savings_in_your_name === "no"',
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'flex-grow',
            },
            children: [
              TextInput(`$: "(a) What the exact account name is shown if not: " + ${clientName} + "?"`, 'account_name', '', false, { validation: 'required' }),
              TextInput(`$: "(b) Why is your savings account not in your name: " + ${clientName} + "?"`, 'why_is_account_not_in_your_name', 'textarea', false, {
                validation: 'required',
              }),
            ],
          },
        ],
      },
      {
        $el: 'div',
        if: '$value.savings_in_your_name',
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'flex-grow',
                },
                children: {
                  if: '$value.savings_in_your_name === "yes"',
                  then: TextInput('(a) What is the bank name?', 'bank_name', '', false, { validation: 'required' }),
                  else: TextInput('(c) What is the bank name?', 'bank_name', '', false, { validation: 'required' }),
                },
              },
              {
                $el: 'div',
                attrs: {
                  class: 'flex-grow',
                },
                children: {
                  if: '$value.savings_in_your_name === "yes"',
                  then: TextInput('(b) What is the sort code?', 'sort_code', '', false, { validation: 'required' }),
                  else: TextInput('(d) What is the sort code?', 'sort_code', '', false, { validation: 'required' }),
                },
              },
            ],
          },
          {
            $el: 'div',
            attrs: {
              class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
            },
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'flex-grow',
                },
                children: {
                  if: '$value.savings_in_your_name === "yes"',
                  then: TextInput('(c) What is the account number?', 'account_number', '', false, { validation: 'required' }),
                  else: TextInput('(e) What is the account number?', 'account_number', '', false, { validation: 'required' }),
                },
              },
              {
                $el: 'div',
                attrs: {
                  class: 'flex-grow',
                },
                children: {
                  if: '$value.savings_in_your_name === "yes"',
                  then: TextInput('(d) What amount is coming from your savings account above?', 'amount', 'number', true, { validation: 'required' }),
                  else: TextInput('(f) What amount is coming from your savings account above?', 'amount', 'number', true, { validation: 'required' }),
                },
              },
            ],
          },
          {
            $el: 'div',
            attrs: {
              class: 'mb-4 flex-grow',
            },
            children: {
              if: '$value.savings_in_your_name === "yes"',
              then: Title('(e) Please upload a bank statement from your savings account.\n' + '(dated within the last 3 months)', 'required-label'),
              else: Title('(g) Please upload a bank statement from your savings account.\n' + '(dated within the last 3 months)', 'required-label'),
            },
          },
          {
            $cmp: 'el-form-item',
            props: { required: true },
            children: [
              {
                $formkit: 'file-upload',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: 'bank_statement',
                fileName: `$: ${parentPath} + "bank_statement" + $index + ".files"`,
              },
            ],
          },
        ],
      },
    ],
  }
}
