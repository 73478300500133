import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import RadioGroup from '~/schemas/components/radio-group'
import ReMortgagingClient from '~/schemas/components/sof/source-of-funds-sections/re-mortgaging-client'
import ReMortgagingDetails from '~/schemas/components/sof/source-of-funds-sections/re-mortgaging-details'

export default function () {
  function generateCondition() {
    const keys = ['client', 'client2', 'client3', 'client4']
    const condition = keys.map((key, index) => {
      const lastKey = keys.length - 1 === index
      let con = `(($formData.data.source_of_funds.re_mortgaging_a_property.${key}_re_mortgage === "no" && $conveyancingCase.${key}.name !== undefined) || $conveyancingCase.${key}.name === undefined)`
      if (!lastKey) con += ' && '
      return con
    })

    return condition.join('')
  }

  return {
    $el: 'div',
    props: { title: 'Re-mortgaging a property' },
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      TitleSectionRadioGroup('6', 'Re-mortgaging a property', 'source_of_funds.re_mortgaging_a_property.re_mortgaging', [
        {
          $el: 'div',
          attrs: {
            class: 'section-indent',
          },
          if: '$formData.data.source_of_funds.re_mortgaging_a_property.re_mortgaging === "yes"',
          children: [
            RadioGroup('Is this a mortgage in all your joint names?', 'source_of_funds.re_mortgaging_a_property.in_joint_names', false),
            {
              $el: 'div',
              attrs: {
                class: 'section-indent',
              },
              if: '$formData.data.source_of_funds.re_mortgaging_a_property.in_joint_names === "yes"',
              children: [ReMortgagingDetails('source_of_funds.re_mortgaging_a_property.join_names_details')],
            },
            {
              $el: 'div',
              attrs: {
                class: 'section-indent',
              },
              if: '$formData.data.source_of_funds.re_mortgaging_a_property.in_joint_names === "no"',
              children: [
                ReMortgagingClient('$conveyancingCase.client', 'client_re_mortgage', 'client_details', generateCondition()),
                ReMortgagingClient('$conveyancingCase.client2', 'client2_re_mortgage', 'client2_details', generateCondition()),
                ReMortgagingClient('$conveyancingCase.client3', 'client3_re_mortgage', 'client3_details', generateCondition()),
                ReMortgagingClient('$conveyancingCase.client4', 'client4_re_mortgage', 'client4_details', generateCondition()),
                {
                  $el: 'div',
                  attrs: {
                    class: 'text-red-500 mt-4',
                  },
                  if: generateCondition(),
                  children: [
                    {
                      $el: 'div',
                      children: "You should select yes to at least one of the client's question above.",
                    },
                    {
                      $formkit: 'hidden',
                      name: 'potential_issues.source_of_funds.re_mortgaging_a_property.not_allowed',
                      validation: 'required',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]),
    ],
  }
}
