import Repeater from '~/components/Repeater.vue'
import { useProps } from '~/composables/useProps'
import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import TextInput from '~/schemas/components/text-input'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Do you anticipate any matrimonial/co-habitation/partnership issues?' },
    children: [
      TitleSectionRadioGroup(
        '4',
        'Do you anticipate any matrimonial/co-habitation/partnership issues?',
        'potential_issues.partnership_issues.partnership_issues',
        [
          {
            $el: 'div',
            if: '$formData.data.potential_issues.partnership_issues.partnership_issues === "yes"',
            children: [
              {
                $cmp: Repeater,
                props: useProps({
                  prop: `potential_issues.partnership_issues.issues`,
                  buttonText: '+ Add another issue',
                  removeButton: true,
                  min: 1,
                }),
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'border-solid border-[1px] p-4 pt-8',
                    },
                    children: [
                      {
                        $cmp: 'ElFormItem',
                        props: {
                          label: 'Please select potential issue',
                          class: 'el-form-item--large',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-select',
                            validation: 'required',
                            name: 'issue',
                            options: ['Matrimonial', 'Co-habitation', 'Loan', 'Partnership'],
                          },
                        ],
                      },
                      TextInput('Please provide further details in the box below', 'details', 'textarea', false, { validation: 'required' }),
                    ],
                  },
                ],
              },
            ],
          },
        ],
      ),
    ],
  }
}
