import SectionCard from '~/components/SectionCard.vue'

export default function amlDeclaration() {
  return {
    $cmp: SectionCard,
    props: useProps({ tab: 5, title: "Letter to the Donee's Lender" }),
    children: [
      {
        $el: 'div',
        children:
          "This letter will be drafted in my name and for the Donee's lender. It is set out below for my e-signature, which authorise AVRillo to send to the lender. It confirms my gift is not a loan and therefore does not affect the lender's security over the property and not compete with their mortgage.",
        attrs: {
          class: 'text-xs mt-4',
        },
      },
    ],
  }
}
