export default function completionDateAndSurvey() {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: 'bold-label' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: { label: 'Your Ideal Completion Date?' },
          children: [
            {
              $formkit: 'el-date-picker',
              name: 'completion_date',
              class: 'w-full',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'On a scale of 1-10 how likely are you to recommend AVRILLO to friends and family? (10 being the highest).' },
          children: [
            {
              $el: 'div',
              attrs: { class: 'w-full flex items-center gap-6' },
              children: [
                {
                  $el: 'div',
                  children: 'Not at all likely',
                  attrs: { class: 'px-3 py-1 rounded-full text-red-600 font-medium bg-red-300 text-xs whitespace-nowrap' },
                },
                {
                  $el: 'div',
                  attrs: { class: 'flex flex-col sm:flex-row' },
                  children: [
                    {
                      $formkit: 'el-radio-group',
                      name: 'rating',
                      class: 'justify-between flex-wrap',
                      radioClasses: '!mr-0',
                      options: Array.from({ length: 10 }, (_, i) => ({ value: (i + 1).toString(), label: `${i + 1}` })),
                    },
                  ],
                },
                {
                  $el: 'div',
                  children: 'Extremely likely',
                  attrs: { class: 'px-3 py-1 rounded-full text-green-600 font-medium bg-green-300 text-xs whitespace-nowrap' },
                },
              ],
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Why?' },
          if: '$formData.data.rating > 0',
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              name: 'why',
              rows: 4,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $el: 'p',
          attrs: { class: 'text-xs' },
          children: "By pressing 'Submit' you confirm that this substitutes your signature as confirming that you agree our terms of business.",
        },
      ],
    },
  ]
}
