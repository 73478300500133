import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(12.1) Have any of the electrical installations been tested by a qualified and registered electrician?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.electrical_installations_tested`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.electrical_installations_tested === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(12.1.1) State the year it was tested.', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.electrical_installations_tested_year`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.electrical_installations_tested === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(12.1.2) Please upload a copy of the test certificate.', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.electrical_installations_tested_certificate`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Attached', value: 'attached' },
                  { label: 'To Follow', value: 'toFollow' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.electrical_installations_tested_certificate === 'attached'`),
            $cmp: 'el-form-item',
            props: { label: '(12.1.2.1) Please upload the evidence', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'file-upload',
                name: `${parentPath}.electrical_installations_tested_certificate_files`,
                fileName: `${parentPath}.electrical_installations_tested_certificate_files`,
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
