import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isBaspiOnly(),
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              required: true,
              label:
                '(5.4) Do you have any title defect insurance policies eg for breach of planning permission, building regulations, restrictions, chancel repair etc',
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.title_defect_insurance`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.title_defect_insurance === 'yes'`),
            $el: 'div',
            attrs: {
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'el-form-item',
                props: { label: '(5.4.1) Please provide details of the policy that you have and upload a copy', required: true },
                children: [
                  {
                    $formkit: 'el-input',
                    name: `${parentPath}.title_defect_insurance_details`,
                    inputType: 'textarea',
                    rows: 6,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                props: { label: '(5.4.2) Please upload a copy of this', required: true },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.title_defect_insurance_documents`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    options: [
                      { label: 'Attached', value: 'attached' },
                      { label: "Can't locate", value: 'cantLocate' },
                      { label: 'Did not obtain', value: 'didNotObtain' },
                      { label: 'To Follow', value: 'toFollow' },
                    ],
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.title_defect_insurance_documents === 'attached'`),
                $cmp: 'el-form-item',
                props: { label: '(5.4.2.1) Please upload a copy of this', required: true },
                children: [
                  {
                    $formkit: 'file-upload',
                    name: `${parentPath}.title_defect_insurance_documents_files`,
                    fileName: `${parentPath}.title_defect_insurance_documents_files`,
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
