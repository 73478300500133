import JointBankAccountClientDetails from '~/schemas/components/pid/potential-issues-sections/joint-bank-account-client-details'

export default function () {
  return {
    $el: 'div',
    attrs: {
      class: 'grid gap-6',
    },
    children: [
      JointBankAccountClientDetails('$conveyancingCase.client', 'potential_issues.joint_bank_account.client'),
      JointBankAccountClientDetails('$conveyancingCase.client2', 'potential_issues.joint_bank_account.client2'),
      JointBankAccountClientDetails('$conveyancingCase.client3', 'potential_issues.joint_bank_account.client3'),
      JointBankAccountClientDetails('$conveyancingCase.client4', 'potential_issues.joint_bank_account.client4'),
    ],
  }
}
