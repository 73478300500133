export default function signatureDeclaration() {
  return {
    $el: 'div',
    children: [
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "1a"',
        name: 'signature_declaration_donor_1a',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "1b"',
        name: 'signature_declaration_donor_1b',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "2a"',
        name: 'signature_declaration_donor_2a',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "2b"',
        name: 'signature_declaration_donor_2b',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "3a"',
        name: 'signature_declaration_donor_3a',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "3b"',
        name: 'signature_declaration_donor_3b',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "4a"',
        name: 'signature_declaration_donor_4a',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$conveyancingCase.donor_key === "4b"',
        name: 'signature_declaration_donor_4b',
        validation: 'required',
        validationLabel: 'This field',
      },
    ],
  }
}
