export default function (parentPath) {
  return [
    {
      $cmp: 'ElRow',
      props: { gutter: 20 },
      children: [
        {
          $cmp: 'ElCol',
          props: { span: 24 },
          children: [
            {
              $el: 'div',
              attrs: { class: 'text-xs  mb-2 mt-4' },
              children: [
                {
                  $el: 'span',
                  children: 'FLATS: ',
                  attrs: { class: 'font-bold' },
                },
                {
                  $el: 'span',
                  children: 'For Flats we will assume the property is entirely on one level, if not on one level please state the number of levels',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: {
                label: 'Flats: Do not complete this box unless applicable to you',
                class: 'font-bold mt-4',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: parentPath + '.flats',
                  inputType: 'text',
                  placeholder: 'Flats',
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
