import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '21.1.',
            text: `To <u>keep documents within the firm's premises</u>
            (and/or remote working office if home working based) unless with the
            prior written consent of the partners (documents as described in this
            contract).`,
          },
          {
            number: '21.2.',
            text: `To comply <u>with the firm’s electronic communications systems</u> (as found in
              our office manual and our guidelines) to reduce risk of hacking,
              cybercrime attack, virus etc. So only use your pc, laptop we supply
              you for work purposes, do not download non legal related software onto
              the aforesaid not on any remote session is installed on your local
              machine or you ensure you ask ET cloud to keep this updated for you
              and you report any suspicious email or hacking to the partners and our
              support services such as ETI Cloud, our IT.`,
          },
          {
            number: '21.3.',
            text: `To not be <u>declared bankrupt</u> or make any
            <u>arrangement</u> with or for the benefit of your creditors or have a
            <u>county court administration order</u> made against you under the
            County Courts Act 1984.`,
          },
          {
            number: '21.4.',
            text: `To not be <u>convicted of any criminal offence</u> (other
              than an offence under any road traffic legislation in the UK or
              elsewhere for which a fine or non-custodial penalty is imposed) and to
              disclose such prior to commencement and offer of the employment.`,
          },
          {
            number: '21.5.',
            text: `<u>To not give away your time for free</u> as it has
            severe consequences, including:`,
          },
          {
            number: '21.5.(a).',
            text: `Affecting your <u>ability to treat all your clients equally</u> because if you give
            away time for one client, it means the client paying in full (as they
            should under regulatory guidelines) is actually subsidising the client
            you are giving away your time to for free. This is not only a
            professional breach but also ethically wrong.`,
          },
          {
            number: '21.5.(b).',
            text: `<u>By giving time unequally between clients</u> means
            you stretch your resources and time by giving one client time for free
            and risk providing <u>unequal communication</u> to your clients.`,
          },
          {
            number: '21.5.(c).',
            text: `<u>You risk working more extended hours outside</u>
            your regular working hours to make up for the time you have given away
            for free for no reason,
            <u>thereby affecting your work-life balance</u>, which we do not want
            to happen.`,
          },
          {
            number: '21.5.(d).',
            text: `You risk <u>affecting colleagues</u> in the firm
            (from those in your direct team to support and admin members), as they
            work longer hours to support your decision to give away time for
            free—this affecting their work-life balance too.`,
          },
          {
            number: '21.5.(e).',
            text: `<u>
            You risk being bullied into doing work that you should not be doing.
          </u>
          The judge of whether this is happening is to ask yourself why you are
          not charging for this extra time. If you feel the client would not pay
          it because it's work, they do not actually need legally, then don't do
          it or explain you will charge for it. If it's additional as a result
          of a defect, then notify them and allow them to choose whether to ask
          you to try to fix that problem or not.`,
          },
          {
            number: '21.5.(f).',
            text: `<u>
            pressured into pleasing the client by taking unnecessary steps just
            to appease them.
          </u>
          In contrast, your professional duty is to explain the reason for the
          additional work is not part of your remit (such as ringing the lender
          when UK Finance Act asks you to write, or over chasing the client's
          management company when they have the contract with them, not you, or
          other administrative, non-legal work).`,
          },
          {
            number: '21.5.(g).',
            text: `You risk <u>charging your client more overall</u> by
            trying to work for free.`,
          },
          {
            number: '',
            text: `Your professional duty is to deliver the problem openly, honestly,
            directly, and immediately to the client. Offering them your expertise,
            allowing them to choose the legal route or practical or combination of
            the two, if available. Trying to push yourself to do work for free by
            throwing free time at the situation risks your management of the
            client to spiral you out of control as you risk them keep on asking
            you to do work, and then you have to charge by the hour as you are
            eating into other clients' time. Instead, giving them instantly the
            time you know of the additional work will allow your client to choose
            whether they want to instruct you on that additional work or not. We
            work on the principle of delivering good news fast, so-called bad news
            even faster. Your professional duty is to set out the problem, the
            pain (i.e., consequences of that problem) and solution (either legal
            or practical if available). This is you need to use the potential
            sign-off procedure (PSO's) and orange sheets in week 5, so you can
            narrow down issues and give clients solutions if available at week 5.
            The overriding principle is as followed by the regulator. To give your
            clients the option to choose after you explain each option's costs/
            benefit analysis (including estimating cost and any delay in each
            option, thereby leaving the ultimate choice to your client). Your
            professional duty does not ask you to give your own subjective view on
            what you would do personally if you were in their situation with a
            choice of options.`,
          },
          {
            number: '21.5.(h).',
            text: `<u>
            You risk the job security of colleagues by the firm making a loss on
              the hours you give away for free.
            </u>
            This affects all employees as firms that give time away for free are
            more likely to make a loss and potentially go into administration or
            be forced to merge with another firm for financial reasons).`,
          },
          {
            number: '21.6.',
            text: `<u>
              Do not do work outside your remit, our guidelines, our terms, office
              manual.
            </u>`,
          },
          {
            number: '21.6.(a).',
            text: `<u>
              Do not work outside your day-to-day expertise. This risks you, or
              the firm, not insured for.
            </u> So, for example, work where an independent expert in that field with
            more relevant day to day experience can be referred to who is also
            insured for that work. Therefore, do not prepare a trust deed, power
            of attorney, agricultural land, company work, and so on (see client
            terms and our office manual). Do not think doing work outside your
            day-to-day experience will be doing your client a favour as it is more
            likely to result in an error, however small, and cost your client
            more. Also, it risks costing them by paying you more by the hour,
            rather than instructing a lawyer who does this work day-to-day and
            will take less hourly rate time. Think of it akin to a solicitor using
            a barrister to deal with certain niche areas they know the barrister
            would deal with day-in-day-out. You need to explain this to the client
            honestly and openly so they understand you are giving them the right
            advice by asking them to sub that out, even if they insist you are
            doing that work, as overall, it will not help them.`,
          },
          {
            number: '21.6.(b).',
            text: `<u>Do not do additional work unless</u> it is
            required, and/or the client wants, and you have notified them of that
            extra time you are spending as otherwise, the client will not
            understand the effort and time you have to put in, and you risk being
            pressured into doing that work, and at unreasonable speed and which
            may result in a complaint if they think it is taking too long, when in
            fact you need to explain it is taking longer because it is additional
            work. If the client requires that additional work, you must act as a
            professional supplier, and as such, the regulator confirms you can
            charge for additional work.`,
          },
          {
            number: '(b.i)',
            text: `<u>How much to charge?</u> We comply with the government's cost
            transparency laws which so many lawyers are breaching as we have been
            audited by the CLC and passed their transparency rules. Also, we try
            to limit our additional costs. So, whilst the regulator allows you to
            charge by time, complexity, the urgency required by the client, by the
            hour etc. AVRillo tries to apply a cheaper discounted menu of costs
            for set categories of potential additional work. Examples include gift
            work, lender work (which the lender asks the client to budget for
            separately in their mortgage offer). Instead, we believe in limiting
            additional fees as much as the Company can afford to discount. As
            such, we have set out in our terms a small potential list of
            additional work options outside a straightforward transaction and a
            discounted menu of fixed reduced costs for items within that list.
            Anything outside the list you should not do. Still, if insisted upon
            by the client (and we are equally experienced as an independent
            conveyancer or expert elsewhere and insured for that work), we will do
            it at an hourly rate.`,
          },
          {
            number: '(b.ii)',
            text: `<u>
              Work covered under insurance, and automatically allows you to comply
              with the transparency costs notification,
            </u>
            is additional work in the discounted costs menu providing you use our
            case management workflows and letter.`,
          },
          {
            number: '',
            text: `You do not have to notify your clients by phone of the extra work and
            charge as this will be automatically notified if you use it. Any
            additional work that we have a trigger and workflow in our case
            management system (CMS), you can be assured that we are covered. By
            following those triggered workflows, you will also be covered in the
            regulatory obligation to give notice of those additional charges. This
            is because they automatically trigger some additional workflows, with
            automatic invoicing and additional cost letters to the client. These
            letters comply with the regulator obligations. They explain additional
            work within our discounted costs menu (which the client will already
            have received upfront in their terms and can only instruct after they
            have signed to confirm they have understood our terms). For example,
            where the client wishes to expedite exchange and completion with less
            than 15 working days between. If you use our triggered workflows
            required of you, you will comply with the transparency rules. If not,
            then not only have you breached this clause by not activating the
            correct workflow, but you must also verbally notify the additional
            work and cost to the client. Otherwise, you will breach the
            transparency rules.`,
          },
          {
            number: '21.6.(c).',
            text: `Do not carry out additional work not within our
            standard discounted costs menu in our terms, so for example, if our
            terms say you must charge by the hour, it usually means that hourly
            rate work should not be done as likely to be outside our guidelines,
            or beyond our insurance cover. If you wish to do it, you must first
            obtain prior authority from the partners, as we may not be covered
            under our insurance. If we are covered, and you are authorised to do
            it, you must explain the additional time estimate to be spent and
            charges. You must ask for money on account for that additional work,
            as it is outside our normal remit and should be charged at an hourly
            rate as it is unlikely to be in our terms menu under discounted
            optional work.`,
          },
          {
            number: '',
            text: `<u>
              To deal with complaints and service issues immediately. For example
            </u>
            , actual or potential complaints, including you having primary
            responsibility to send our complaints procedure, prepare detailed
            draft responses to the clients, analyse for the firm and/or the
            adjudicator and draft a reply for any online review such as trust
            pilot etc., (this means you need to use precedent draft house style
            replies). To follow the "see the train coming" principle, i.e., to
            spot potential issues coming and reduce the potential risk of
            complaints. Not to over promise and under deliver but to be realistic
            in your time estimates and work you will do and/or can do.`,
          },
          {
            number: '',
            text: `<u>
              To treat each party, you interact with the level of care and service
            </u>
            expected of AVRillo staff and expected of a regulated CLC firm and
            their standards and to those of the Law Society and/or the standards
            expected by the Company as an award-winning firm including in its
            customer care, wellbeing, leadership, training, and other standards;
            whether internally within the Company (with fellow Employees etc.) and
            externally (e.g., with estate agents).`,
          },
          {
            number: '21.7.',
            text: `<u>
              The Employee not to work at either overcapacity or under capacity.
            </u>`,
          },
          {
            number: '',
            text: `Employees work on the basis of mutual respect and support of each
            other. As such, each Employee must expect their colleagues to be at
            capacity to proactively ensure work is shared equally and not burden
            one Employee over the other, unless by prior arrangement between the
            Employee and the Company e.g., if working part-time.`,
          },
          {
            number: '21.7.(a).',
            text: `<u>
              You must not work at overcapacity as it risks firstly, not only you
              (but others in your team and colleagues in the firm) not being able
              to cope and therefore risking an effect on trying to improve work
              life balance
            </u>
            (which is contrary to what we want for our team as want to aim to be
            different from the standard of wellbeing in many other conveyancing
            firms). Secondly, it risks prejudicing your client by not giving them
            sufficient time that you may soon reduce your service standards due to
            being overstretched in time. Thirdly, it can lead to mistakes being
            made by being overworked, negligence, complaints against you, the
            firm. You must notify (by email, followed up by phone) to the partners
            and your team leader if you are not currently able to cope with your
            workload or anticipate not coping soon. By telling us in sufficient
            notice we can try to help by adjusting your files, your workload,
            carry out refresh training to manage and use our protocols and systems
            to the letter which will help reduce unnecessary time spent on your
            case. We want you to be honest, direct, and fast-acting if you are
            about to be overloaded as we do not mind, providing you notify us in
            time. It is only if you notify us late and this prejudices the
            aforesaid parties that it will make the breach more serious.`,
          },
          {
            number: '21.7.(b).',
            text: `<u>You must work at under-capacity unless by prior agreement.</u>`,
          },
          {
            number: '',
            text: `The Company works on the structure of teamwork where every Employee
            works proactively during their working day and to capacity; this to
            ensure equality between all Employees, good team moral and mental
            wellbeing found in the spirit of equality. If the Employee, therefore,
            has run out of work, or is low on work, at under capacity for their
            role, the Employee must notify the team leader by phone and email, and
            cc the partners immediately. There is always something to do, to
            learn, do more training, to assist colleagues. So, if you are low or
            are running out of work do not stretch out your work beyond reasonable
            timescales for the work you have but notify us setting out: the date
            you first anticipated you run out of work, or you were low on work,
            whether you have asked other colleagues if you can assist them, who
            you asked, when. If they said no, then what you have been doing since
            you asked them.`,
          },
          {
            number: '21.8.',
            text: `<u>To follow client call surgery guidelines.</u>`,
          },
          {
            number: '',
            text: `Follow call surgery guidelines designed to help you better manage
            client expectations and reduce unreasonable pressure and deadlines
            they may try to impose on you by calling you or expecting you to
            contact them outside the call surgery hours. This will help you
            complete your necessary work during working hours, on best practice
            guidelines, with reduced exposure to you of taking unnecessary,
            unplanned calls, which distract and disrupt you fulfilling your role
            and meeting the client's overriding objective to move with a
            sufficiently good title.`,
          },
          {
            number: '',
            text: `Examples of the call surgery guideline protocol you will be trained on
            include:`,
          },
          {
            number: '',
            text: `<b>E.g., 1.</b> To be available to take all calls booked into your
            call surgery by your clients. Note. At the date of this contract`,
          },
          {
            number: '',
            text: `<b>E.g., 2.</b> To only make and take calls during call surgery hours:
            10 to 11 am (morning session) and 2 to 4 pm (afternoon session).
            Noting these can be varied by the partners from time to time.`,
          },
          {
            number: '',
            text: `<b>E.g., 3.</b> To not overrun the booking time (currently 10 minutes
              booking as at the date of this contract, but which is subject to
              variation by the partners).`,
          },
          {
            number: '',
            text: `<b>E.g., 4.</b> To manage the clients time to those 10 minutes by
            explaining you have purposely allowed 10 minutes (longer than is
            expected), leaving them with more than sufficient time before your
            next scheduled meeting after 10 minutes.`,
          },
          {
            number: '',
            text: `<b>E.g., 5.</b> To summarise next steps so the call can end on or
            before the 10 minutes expires. The next step plan will include the
            next steps, date of the next, what they need to do if they do not hear
            by your planned next step date, that will contact them sooner if you
            hear sooner, generally to be upfront and honest, but reassure them you
            are in control of communication updates to them at those next steps
            stages you plan for them.`,
          },
          {
            number: '',
            text: `<b>E.g., 6.</b> That if it becomes clear that a further session is
            needed after 10 minutes, you will explain that you need thinking time
            to consider the complexities or issues raised, and you will either
            write to them or invite them for a further scheduled future booking,
            by a specific date you set out at the end of your 10-minute call.`,
          },
          {
            number: '',
            text: `<b>E.g., 7.</b> If a client tries to call you on a non-call surgery
            time, you must not take it. You must instead email them by the end of
            the day, as follows: "Thank you for contacting me. I will email to
            update you soon. Please bear with us whilst I am working on your file.
            <u>Email is best.</u> If your question is urgent, email me with your
            full question to give you a better answer after I grab your notes,
            read your question, and review your file. <u>Call surgery</u> in the
            unlikely event that your question is so urgent that you need to talk.
            That's fine, but to give you my undivided attention, I am happy to
            invite you to book a call surgery appointment by choosing a date in my
            personal appointment diary. Simply press
            <a href='https://avrillo.co.uk/legal-surgery/ ' target="_blank">
              https://avrillo.co.uk/legal-surgery/
            </a>
            to book in a 10-minute slot. This is longer than is normally needed,
            but please book this if you need a double slot. This service is an
            added benefit, the only one of its kind within UK Conveyancing firms.
            It avoids an unplanned call when I may be engaged in meetings or
            carrying out legal work. It also avoids us missing each other if
            either of us is engaged when we try to speak without a booking.
            Finally, it allows me to give you a better reply by grabbing your file
            and considering your question in advance. If you book, please set out
            your full questions so I can read your notes in preparation."`,
          },
          {
            number: '',
            text: `<b>E.g., 8.</b> Do not book the client slot yourself. Train the client
            to book their own appointment, giving them the freedom to choose in
            their own time and allowing you to spend time working on cases, not
            making bookings.`,
          },
          {
            number: '',
            text: `<b>E.g., 9.</b> The only exception to take or make a call outside call
            surgery hours is to avert a risk of negligence or a complaint, but on
            the issue of a risk of a complaint, it is not a truly urgent and
            important 'priority' matter, you will need to explain to the client
            you have taken the call to explain and book at a future time actually
            to speak about the issues raised`,
          },
          {
            number: '21.9.',
            text: `<u>To communicate within our timescales,</u> not within
            undue pressure deadlines clients try to unreasonably impose on you as
            otherwise this can lead to not managing clients' expectations and
            affecting your mental wellbeing. That means acknowledging and replying
            to clients and third parties within a reasonable time as guided by our
            protocols or training and guidelines. The objective will be to help
            you not only manage your clients but also reduce the risk of you being
            pressured into making errors or impacting on your work life balance
            without having to work under unnecessary pressure and/or deadlines
            and/or doing unreasonably demanded work from clients and third
            parties. E.g., not wasting time and their costs by chasing the lender
            by telephone when the UK Finance Handbook says not to. Overall, this
            encourages better wellbeing, better client experience, and reduction
            in complaints for actual or perceived insufficient communication.`,
          },
          {
            number: '21.10.',
            text: `<u>To only give the authorised undertakings</u> so you
            can be in control and not be subject to risk and stress of not
            complying with the discharge of undertakings. This means you must
            firstly, only provide the standard Law Society undertakings regarding
            a mortgage on a sale; secondly, where on a purchase the lawyers acting
            for the buyers, the lender asks for an undertaking for the mortgage
            advance to be held to their order pending completion; thirdly, the
            Help to Buy mortgage undertaking. The only exception to these three
            authorised undertakings is if the Employee makes a formal request to
            one of the partners, and the partners give prior written consent
            before the employee gives that additional undertaking. Note, upon
            receiving such a request from the Employee, the partner will assume
            that the Employee has made that request on the strict basis that the
            Employee personally undertakes to the Company that they can discharge
            the undertaking they wish to have authority to give outside the three
            authorised ones above. This means that the Employee can only make such
            a request for an undertaking on condition that the Employee has
            impliedly confirmed, by their request, that they can discharge the
            said undertaking and is within the Employee's total control to
            discharge. The Employee must then only give that undertaking on
            condition that the Employee follows the Company's formal undertaking
            procedure (as set out in the office manual) which includes filing the
            request for undertaking with reason, filing the actual undertaking in
            the central notice of non-protocol undertakings, and then filing a
            request for that undertaking to be taken off the register of
            undertakings after the Employee has received written confirmation of
            discharge from the recipient of that undertaking. If the Employee
            makes any undertaking outside of the three authorised undertakings
            without following the proper procedure as outlined above, this will
            amount to a breach of contract and potential summary dismissal
            depending on the severity of the conduct and/or the severity of the
            risk of loss.`,
          },
          {
            number: '21.11.',
            text: `<u>
              To act with full diligence, honestly and in a manner which will not
              make you or us breach regulatory body codes, best practice, ethical
              codes or Lender Exchange conditions and further you not to breach
              your contract of employment.
            </u>
            So, for example, we ask that:`,
          },
          {
            number: '21.11.(a).',
            text: `You follow all our protocols in full:`,
          },
          {
            number: '21.11.(b).',
            text: `You must say no (i.e., you take a zero-tolerance) to
            being influenced or pressured by client or third parties that instead
            you do the right thing according to conveyancing protocols, our
            protocols, regulatory guidance, and best practice ethics. That you
            look out for signs of, and you notify the client immediately that you
            will not participate in, stamp duty avoidance planning schemes, delay
            to the AML process, Lender Exchange conditions and UK Finance Act
            Handbook (CML) conditions etc. , as this not saying no risks both you
            and the firm being compromised and penalised. Breaches of any of the
            aforesaid are strict liability, so they will be a breach whether
            undertaken innocently, recklessly, or negligently. You must
            immediately self-report any breach to the partners.`,
          },
          {
            number: '21.12.',
            text: `<u>
                To conduct yourself in a manner which does not affect the wellbeing
                of others
              </u>
              and the harmony of the firm
              <i>
                (hereinafter also referred to as the wellbeing and harmony clause or
                harmony clause or disharmony clause).
              </i>
              Failure to conduct yourself in this manner can lead to instant
              dismissal. Please report any such conduct to the partners who can look
              into this for you as we have a zero tolerance to this misconduct due
              to its effect on wellbeing.`,
          },
          {
            number: '',
            text: `Note, the reason for the wellbeing and harmony clause is because
            AVRillo is founded on a 'family' environment where Employees are free
            from discrimination, where they are supported and are treated equally.
            This is a key to the Company’s success and its smooth running, as well
            as the reason for a happy and engaged team, and behind its previous
            Investors in People, Top 100 placements in Great Places to Work as
            well as great engagement levels and support throughout the Company
            staff. The firm is founded on principles of honesty, openness, support
            and respect for each other and the promotion of each to the team
            spirit of unity.`,
          },
          {
            number: '',
            text: `Note, for your protection and protection of others, breach of this
            clause includes conduct which, inter alia, causes disruption to the
            harmony and productivity of fellow Employees and the firm, including
            through for example, discrimination, bullying, harassment, gossip,
            adverse comments made to fellow Employees about co-workers and/or
            management and/or partners of the firm.`,
          },
          {
            number: '21.13.',
            text: `To
            <u>
              act in a manner that will not bring the integrity and reputation of
              the firm into disrepute
            </u>
            by not conducting yourself in a manner befitting of your working for a
            legal practice, both during working hours and outside working hours
            (and includes your conduct on review sites and social media etc);
            which conduct potentially risks the reputation of AVRillo. This
            includes the Employee not
            <u>making untrue or misleading statements</u> in relation any of their
            fellow employees, partners, clients, or estate agent referrers, and
            not to criticise the aforesaid. If you have a grievance, a complaint,
            an objection to any conduct by a fellow employee or the firm, then
            simply raise this with one of the partners or activate our internal
            grievance procedure so we can investigate this independently for you.
            Note, breach of this clause can lead to an action against you, and
            this clause shall apply whether loss resulting from your conduct is
            proofed or not, but if it is likely); such action can include a claim
            for Defamation and loss.`,
          },
          {
            number: '21.14.',
            text: `<u>
              To follow and participate in our training to e.g., better serve
              clients, improve your work life balance and comply with regulatory
              obligations.
            </u>`,
          },
          {
            number: '21.14.(a).',
            text: `<u>
                To help you manage your work, your workload and clients, thereby
                encouraging a better work life balance, you need to attend training
                and learn how to follow
              </u>
              our protocols, guidelines and client management principles. Training
              includes you attending when requested and participating by actively
              listening, including you taking contemporaneous training notes so you
              can review later, give feedback, including by email after the training
              by setting out top lessons taken, emailing your post training notes so
              we can review and give feedback from time to time to assist your
              learning (or being cross checked by your colleagues as we encourage a
              pass forward principle to support colleagues around you by discussing
              training and learnings).`,
          },
          {
            number: '21.14.(b).',
            text: `<u>
              Examples of training you have to learn and follow, so you can
              benefit from, include those in our protocol clause, and also below
              as once trained on, these are part of the firm’s protocols you have
              to follow:
            </u>`,
          },
          {
            number: '',
            text: `<b>E.g., 1.</b> To acknowledge and reply to clients and third parties
            within a "reasonable time'' (not immediately by return as this sets
            unreasonable expectations of the speed of your subsequent replies and
            encourages complaints).`,
          },
          {
            number: '',
            text: `<b>E.g., 2.</b> To assess “reasonable time” by using the Company's 4
            folder priority of order system (based on Steven Covey's four
            quadrants). This will help you manage the priority of work you need to
            do, in which order.`,
          },
          {
            number: '',
            text: `<b>E.g., 3.</b> To understand that to "reply" means to reply in full
            by replying to all points raised by the clients or third party. If,
            when covering each issue raised by the sender, you cannot fully
            respond to each one at one time, that you explain why, for example,
            one or more need more due to complexity or resources. If you cannot
            provide an answer to any point or more as it is outside your knowledge
            expertise, then you explain this and give the option of instructing a
            third party on this point, such as an SDLT expert, tax advisor,
            inheritance, or trust lawyer, or barrister etc.`,
          },
          {
            number: '',
            text: `<b>E.g., 4.</b> To ensure that when you do reply, you follow best
            practice managing client expectations guidelines to reduce complaints
            for insufficient communication allegations made by a client:`,
          },
          {
            number: '(i)',
            text: `Respond to each point raised if you can on that one reply,`,
          },
          {
            number: '(ii)',
            text: `if you cannot respond to each point raised on that one
            reply (i.e., if multiple points and some will be more complex, or may
            take more investigation or simply more time), then go through each
            point. Dissect the ones you can reply to in that response and set out
            the ones you will need to reply at a later date.`,
          },
          {
            number: '(iii)',
            text: `Give a time estimate for you to reply to those subsequent
            points, and be direct on why, for example explain the reason is that
            an issue has arisen which is complex or needs research. you are not
            expected to know or have a solution to everything at your fingertips.
            you just need to explain that. not doing so can give rise to a
            complaint from them, stress on you.`,
          },
          {
            number: '(iv)',
            text: `In any event, always give the person receiving your
            response a next step plan. This plan includes you setting out: what
            those next steps are; whether they are your steps to take, or theirs
            to take; set out a specific date (e.g., end of business by x (fee earner insert) date) by
            which the next step is due to be taken; explain what will happen after
            that date. So, for example, if they do not reply to your request, you
            can explain your hands are tied and the file cannot progress. If you
            cannot reply by the date, you then set out illustrations such as you
            still waiting on a third party to get back to you, to explain when you
            do here you will notify them (i.e., A take on the principle of don’t
            call us we’ll call you when we have a relevant update that will
            progress their case); or you can even invite them to book a call
            surgery appointment if the date you set expires and it is likely to
            affect the progress of their case.`,
          },
          {
            number: '',
            text: `<b>E.g., 5.</b> To not let the client or third party manage you, but
            you manage them.`,
          },
          {
            number: '',
            text: `You set out what is expected, you provide realistic timeframes. Do not
            over promise and under deliver as this causes complaints as you will
            not have managed client or third-party expectations.`,
          },
          {
            number: '',
            text: `<b>E.g., 6.</b> To be direct, frank, and upfront when you explain
            potential problems using the known principle of PPS to benefit your
            client. Not to hide problems or notify clients later (because you fear
            their reaction. Do not notify them after you try to fix a problem a
            certain way without first giving your potential client options to sign
            off (if non-UK finance handbook). or without your clients' prior
            consent and instructions. It is not your decision to choose options or
            shelter them from problems. Instead, you must notify them using the
            principle of PPS. We will train you on PPS, namely, to provide the
            client with, firstly, the 'problem' as soon as you are aware. To
            explain the potential 'pain' of not considering the problem, i.e.,
            consequences of the problem. To provide potential 'solution/s', they
            may or may not have open to them. Also, to explain any additional work
            and costs in that additional problem encountered, so no longer making
            it a straightforward Conveyancing transaction.`,
          },
          {
            number: '',
            text: `<b>E.g., 7.</b> to explain immediately if the client's expectations of
            you or their request are outside your remit, retainer or protocol and
            guidelines. For example, to explain why you cannot chase the lender by
            phone when outside the CML, UK finance handbook; to explain the
            principles of the costs benefit analysis to the client if they ask you
            to do work the other conveyances are required to do etc.`,
          },
          {
            number: '',
            text: `<b>E.g., 8.</b> To acknowledge client communication to reassure them
            that you will reply in full later, within a reasonable time. Your
            acknowledgement should be sent on the same, or the next, working day.
            Alternatively, within two working days, depending on your workload. If
            you want (or anticipate you may take) more than two working days, you
            shall notify FLG and the partners so they can try and assist if they
            can.`,
          },
          {
            number: '',
            text: `<b>E.g., 9.</b> When you acknowledge or reply to any communication,
            whether by email or telephone, you must set out your next steps and
            timescales for those in plan format. Whether a matter of days or
            weeks. You must be honest, cautious, and realistic in your time
            frames. The exception to timeframes described above is where the
            four-folder system requires you to reply or acknowledge earlier if, by
            doing so, you can reduce the risk of a complaint against you (i.e., by
            a client for lack of communication or where they show anxiety), or to
            avert a risk of negligence or a breach of one of our protocols (e.g.,
            procedures, guidelines, and training rules).`,
          },
          {
            number: '',
            text: `<b>E.g., 10.</b> Internal Communication to and from partners and FLG,
            and where AVRillo acts on the other are expected within the day based
            on our mutual respect and support principle. If you cannot, you must
            at least email to explain why you cannot. If a request from a partner
            or FLG is marked as urgent, or with a time needed for the reply, you
            need to reply by that time as they will have an overview of the
            business and will have marked the deadline for the good of the firm;
            alternatively, email to provide an overriding priority of why you
            cannot reply in time.`,
          },
          {
            number: '',
            text: `<b>E.g., 11.</b> Examples of subjects we train (become protocols) and
            we ask you to pass forward, can range from legal to management
            techniques (such as to get more of a work-life balance, how to
            communicate more effectively, how to provide best customer service,
            how not to be bullied by an unreasonable client, how to be more
            proactive rather than reactive; how to be more confident when speaking
            to clients, how to be direct and open with clients when giving advice,
            how to plan more effective calls, how to use Steven Covey's famous
            four folder time management priority quadrants; better structure of
            your time at work to give you more time at home; managing clients
            through the call surgery; when to email or when to call; when to offer
            a practical as well as a legal solution, how to role-play difficult
            situations so you can better cope with real-life example problem
            scenarios you may meet in practice, with less pressure and fewer
            complaints etc., how to record your time from time to time, so you can
            better analyse good practices so achieve a better work-life balance by
            not giving away your time for free to clients. Also, to use our case
            management system (CMS) triggers, screens, precedents, and all
            workflows and protocols.`,
          },
          {
            number: '',
            text: `<b>E.g., 12.</b> Other example protocols to learn as part of your
            training include those at 21.16.`,
          },
          {
            number: '21.15.',
            text: `<u>You must comply with all Company protocols,</u>`,
          },
          {
            number: '21.15.(a).',
            text: `Definition of a protocol to be complied with shall
            include all the Company's guidelines, procedures, rules, policies,
            workflows, including those given in our CMS, case management system,
            precedent letters triggered by workflows within the CMS screens, and
            all other work triggered by the CMS, meant to replicate and assist
            your complying with regulator and our protocols, training given,
            whether in person, on Microsoft Teams, on the phone, via audio,
            webinar, via , Talent LMS portal, in our training content, training
            manuals, intellectual property, our office manual (a copy of which is
            available on Talent LMS or from the partners and/or from FLG).`,
          },
          {
            number: '',
            text: `Protocols include training and guidelines relating to the Company’s
            legal and soft skills guidance such as on how to, for example manage
            clients, your time, how to be direct with clients and the outside
            world, how to prevent complaints or potential issues, how to manage
            clients, how to be direct with clients as well as being respectful,
            how to comply with your duty not to over promise but be realistic in
            what you are can and cannot do, what is within your remit and not
            etc., ., to take calls on time, to follow procedures such as call
            surgeries etc., to comply with all Company training. The only
            exception to breaching such protocols is in exceptional circumstances
            (which in any event do not cause a complaint and negligence act, and
            only on condition of the Employer providing prior written consent).
            Breach of a protocol will be classed as wrongdoing.`,
          },
          {
            number: '21.15.(b).',
            text: `Examples (not exhaustive list by illustrations) of
            our protocol (guidelines etc.) you must follow to ensure you comply
            with your employee obligations and the regulators' outcome focused
            objectives to manage files, clients' expectations, your time and
            workload, as well as our own aim to help you build a better work-life
            balance and wellbeing for you and all in the firm.`,
          },
          {
            number: '',
            text: `<b>E.g., 1.</b> To allow your client to make an informed choice. This
            means you must provide your client with all options to problems or
            issues (legal and practical) which may delay them in their move. These
            options must be included in potential sign off options (PSO’s). You
            must also set out any risk, including financial. You must then let the
            make the choice of option they want to select, and you not you make
            that choice for them as you are not buying or selling that property;
            unless there is something which is illegal or they will be breaching,
            e.g., they must not breach lender and UK Financial Act conditions
            etc., by them instructing you in a certain way; but noting that you
            must not give any such illegal or potential conflict option in any
            event. Noting also that if the client instructs you to put them in
            conflict with the lender, you must notify them that you will notify
            the lender of the conflict and they will ask you to stop acting for
            the lender. Also, if the client asks you to deal with an illegal act,
            such as stamp duty tax evasion scheme etc., you must stop acting
            immediately and explain why.`,
          },
          {
            number: '',
            text: `<b>E.g., 2.</b> For you to manage your clients and your time to reduce
            the risk of complaints or potential issues against you, the firm:`,
          },
          {
            number: '',
            text: `<b>E.g., 3.</b> To not work outside your remit as instructed in the
            initial instruction form and/ act outside your expertise as
            conveyancer, and ensure clients and third parties, e.g., estate agents
            understand the reasons for this if they put you under pressure to act
            outside that remit by referring them to the guidelines.`,
          },
          {
            number: '',
            text: `<b>E.g., 4.</b> To use soft skills you are trained on by Company so
            you can better manage your time, your resources, your wellbeing, your
            clients and third parties such as estate agents, including treating
            each party you interact with, with the level of care, service and
            outcome focused principles expected of a regulated CLC firm (and
            equivalent Law Society etc., ) and expected by the Company as an
            award-winning firm including in its customer care, wellbeing,
            leadership, training.`,
          },
          {
            number: '',
            text: `<b>E.g., 5.</b> To follow the company’s workflows such as its trigger
            stages. Examples ranging from entering information into the CMS (Case
            Management System) fields in the relevant screens at each stage of the
            transaction from start to post completion. To what buttons to trigger
            at each stage, to what to do with the screens, for example completing
            the AE additional enquiry maintenance screen on a purchase as well as
            use of PSO’s (potential sign offs) and SO’s (sign offs) and all other
            title checking fields, as well as practicing best practice outcome
            focused conveyancing where you must give clients choices and options
            and allow them to decide, sign off if they are able to, etc. and warn
            them of the consequences of both signing off and not signing off etc.`,
          },
          {
            number: '',
            text: `<b>E.g., 6.</b> To deliver bad news and fast. Do not be scared to give
            so called bad news (i.e., legal problems). You must be honest and
            direct. You must give practical options available (such as indemnity
            insurance and its cost). You must deliver bad news fast. Do not put
            off what your guidelines or the regulators’ outcome focused
            principles, ask you to do. If you do not do this, you will be seen as
            giving the wrong advice, potentially because you are worried on how
            the client will react against you and the person delivering that bad
            news, but this will be failing your duty to be professional if you
            know a practical solution was available (potentially also with better
            costs benefit) for the client to choose from. Do not be scared
            therefore of the bad news you need to give, as it is for you to give
            it and the client to expect it and decide what to do with the options
            you give them to choose from, both legal and practical (PSO’s etc.)
            must be offered.`,
          },
          {
            number: '',
            text: `<b>E.g., 7.</b> To manage time expectations for communication such as
            emails and calls by taking call surgery calls on time, not taking
            calls outside call surgery, not to overrun on call surgery times and
            work estimates).`,
          },
          {
            number: '',
            text: `<b>E.g., 8.</b> To manage estate agents, be direct, be upfront, and
            involve them as they can help progress your clients' move thereby
            helping you meet the regulator clients focused outcome, i.e., to move
            them.`,
          },
          {
            number: '',
            text: `<b>E.g., 9.</b> Not to panic (but be respectful and firm to client and
              explain to them) if they try to pressure or influence you in doing
              something too fast and/or to take steps or deal with issues which are
              not within your control, not legally required of you, or outside our
              guidelines and best practice protocols. As such, explain that such
              conduct risks mistakes and increases in delay, work, and costs (or the
              bullying, harassment and under undue pressure so severe as to the
              regulator asking you to stop working for the client). If you do not
              control these requests and say no, you risk not only making mistakes
              and being negligence, but also receiving a complaints because you have
              not done the right thing, adding unnecessarily costs to the client
              (which you need to charge for your time spent) as well as breaching
              your role, when by simply being open and direct you could
              professionally explain why those steps may not help, why they are not
              normal, and why it would add to their costs, thereby allowing the
              client to choose if they still want you to take these steps, but
              subject always you being able to do them within our guidelines and
              regulator protocols. Example of steps you should not do are doing work
              the other conveyance on the other side should do or do work the client
              should do by chasing their lender, their management company for
              apportionments, steps which add to the clients' costs which the client
              would not be happy to pay when you explain to them before you take
              those additional steps, they ask of you. Giving non-Law Society
              conveyancing undertakings, or those not allowed by our guidelines or
              outside your full control to discharge.`,
          },
          {
            number: '',
            text: `<b>E.g., 10.</b> Be upfront and say no if you are asked to act
            unreasonably, are bullied, spoken to rudely, aggressively etc. If they
            continue to be unreasonable you must then explain you may have to
            terminate your retainer, and you are encouraged to do so by phone, but
            do this as early in the transaction (not prior to exchange or report
            on title without speaking to the partners first) as regulator asks you
            to not to leave this to the last moment when client has less choice to
            start again.`,
          },
          {
            number: '',
            text: `<b> E.g., 11.</b> Do not do more for a client who shouts the loudest
            or puts pressure on you to do their work in priority to other clients
            even if their work does not take priority. Such a client must not
            affect your work and time resources available to you to do other
            clients work as such you must prioritise work on the basis of our
            4-folder priority quadrant system and not to favour the client who
            shouts the loudest, threatens or makes or threatens complaints etc.
            Except if a complaint is threatened you must prioritise a call only to
            explain their pressure is causing undue pressure and influence which
            could lead to a mistake and is unreasonable according to the regulator
            and as such you can even invite them to terminate if they expect you
            do work beyond the CLC and SRA guidelines; and if still they put
            pressure on then you must explain that as they have lost trust in your
            ability to work according to the regulator standards, the regulator
            asks that you must terminate their retainer.`,
          },
          {
            number: '',
            text: `<b>E.g., 12.</b> Do not carry out that additional work which you would
            not normally do unless you are confident the client will be happy to
            pay those additional costs. If you feel they would not be happy but
            you just carry out the extra work because you feel the client would be
            upset if you do not carry out that work then speak to the partners who
            will help you and support you further (as you are already trained not
            to give time away just because you feel the client would be upset with
            you, when they will not because the problem is theirs). You must not
            therefore do extra work for free simply because you feel scared to say
            no. If you get to this point and are worried, please ask the partners
            and/FLG for advice as whilst you are trained on this, we will be happy
            to hold your hand and give you support to do the right thing. If the
            client continues to instruct you do so such work outside your legal
            work, you must explain the cost benefit analysis of asking you to do
            that work and potential risk of delay and other issues arising, such
            as being put at the back of the queue if you chase the lender by phone
            etc., as you have opened another, but identical ticket with a late
            date than the first, so both go back to the latter date chaser. The
            only exception to the aforesaid is if you have advance written
            authority from the client that they still want you to do that
            additional work (and they put in you in advance funds on account of
            that work) and you charge for that work by the hour.`,
          },
          {
            number: '21.16.',
            text: `<u>
              To notify us if you do not understand our training or do not agree
              to our protocols and guidelines.
            </u>`,
          },
          {
            number: '',
            text: `We will not judge you or think negatively of you for notifying us if
            you do not understand or agree or object to a protocol or a subject
            trained on. This is because we operate an open-door policy of being
            honest.`,
          },
          {
            number: '21.16.(a).',
            text: `<u>
              You must simply notify the partners and FLG immediately, if you do
              not understand (or do not agree) with any element
            </u>
            training, or any procedure, policy, guideline, workflow, system, case
            management case system triggers, screen etc.`,
          },
          {
            number: '(a.i)',
            text: `If you have not understood, then we will retrain on the
            point you do not understand in return for you taking notes, ensuring
            you understand and let us know at the end, if you still do not
            understand, so we can repeat that exercise immediately.`,
          },
          {
            number: '(a.ii)',
            text: `If you do not agree with a protocol, training element,
            then we consider if your objection is reasonable. If it is, we will
            look to amend our guidelines. If we find the protocol reasonable, we
            will explain why our guideline stands and the reasons behind this
            decision (generally because the protocol is in the best interest of
            the regulator's outcome-based focus and reducing the risk of
            negligence).`,
          },
          {
            number: '21.16.(b).',
            text: `<u>
              You must notify the partners if you do not understand or agree
              before omitting to follow the protocol?
            </u>
            We do not mind providing you notify and speak to the partners before
            omitting to follow, but not following without notifying is a breach.
            Notification must be by email to the partners and FLG. If neither
            replies within 2 working days, call one of them directly as your
            notices may have been mislaid. Depending on the urgency, keep chasing
            until you receive a reply, and earlier than 2 days if a complaint or
            negligence risk arises if not dealt with earlier. Note, when we give
            you a further explanation, you need to take notes of the explanation,
            type them out and send them to the person giving you the explanation
            and keep them safe so you can follow them next time,`,
          },
          {
            number: '21.16.(c).',
            text: `<u>
              If you do not notify us when we have invited you to, as above, we
              will assume you have understood and agreed
            </u>
            with our protocols, guidelines, procedures, workflows, and training
            etc. As such, if you do not follow these, you will be in breach. This
            goes to performance-related records on your personal file or warning
            or summary dismissal, depending on the severity of the breach.
            Therefore, the Company actively encourages questions and attendance at
            all training and reading around, or questions on, protocols,
            guidelines, and systems as failing which you may increase your risk to
            client complaint, mistake and financial loss resulting.`,
          },
          {
            number: '21.17.',
            text: `<u>
              To not act in a manner, or omit to do something, which is
              inconsistent with our protocol. this becomes serious misconduct if
              this breach gives risk to an error, service issue, complaint,
              negligence, or financial loss,
            </u>
            (e.g., including for poor service due to, for example, not managing
            the client, estate agent, other third party, not being direct, not
            managing time scales, inadequate communication, not dealing with all
            tasks expected of your role in accordance with our protocols and not
            comply with any reasonable and lawful direction of the Partners to
            help manage the client avoid the aforesaid. This includes you not
            cheating the case management system by not following our workflows,
            triggers on screens, not filling in fields, such as leasehold screens,
            and others as directed in our working practices.`,
          },
          {
            number: '21.18.',
            text: `<b>You must self-report a breach of protocol,</b>
            including your own actual or potential wrongdoing and that of a fellow
            a fellow Employee/s and/or partner as its crucial for the beneficial
            benefit of all Employees to ensure protocols are not breached as these
            can lead to complaints, claim, reputation loss such as negative online
            review. The breach or suspected breach must be notified by email,
            within the hour, or end of the day unless this delay causes loss, to
            the partners, FLG, and your team leader. You must include in that
            notice the said breach/ potential breach; how it was (or how you
            suspect it will) breached, potential consequences of that breach. The
            same applies if you have been negligent or breached any other
            conditions or have or will potentially incur the employer in financial
            loss or loss of reputation. The same principle applies (but to the
            partners only) if they have been charged with a criminal offence etc.
            You must report such conduct or omission to the partners immediately,
            including breaches of our protocols`,
          },
          {
            number: '21.19.',
            text: `<u>To allow the Employer to supervise and monitor</u>
            you by, for example, recording any use that you make of the Company's
            remote servers, electronic communications systems, information
            technology, telephone systems, teams, webcam conferences etc., for the
            purpose of ensuring that you are complying with all protocols, company
            rules and procedures and for legitimate business purposes. You must
            prepare reports on your work where requested so we can help monitor
            your progression and development, and identify further training needs,
            if any. We actively encourage you to update us if we do not ask this
            of you. These reports include your feedback on your role, files etc.`,
          },
          {
            number: '21.20.',
            text: `To deal <b>with any improvement recommendations</b> we
            give to assist you. These can range from simple feedback on how to
            better perform and meeting protocol and best practice to warnings. Any
            such performance recommendations must be adhered by you within the
            timescales we give to enable clients or fellow colleagues or the firm
            to be prejudiced.`,
          },
          {
            number: '21.21.',
            text: `<u>
              To be available to work to comply with our equality between
              employees' values.
            </u>`,
          },
          {
            number: '21.21.(a).',
            text: `You must not, whether directly or indirectly,
            engage in any other business or be concerned or interested in any
            other business.`,
          },
          {
            number: '21.21.(b).',
            text: `To be on time for work, and not to take time off
            during working hours, unless with prior authority from the partners
            (unless, of course, for lunch etc.).`,
          },
          {
            number: '21.21.(c).',
            text: `We are supportive of Employees who are unwell and
            cannot work. We ask you to contact us on the morning you are unable to
            come in, prior to office hours by email, or after the lines open (by
            you are a member of your family etc, if able), giving a time estimate
            of when you will be back. This will allow us to help you manage your
            work, your clients, deadlines etc whilst you are off sick.`,
          },
          {
            number: '21.21.(d).',
            text: `As further support, we ask you to arrange a
            return-to-work interview as soon as you are ready to return to work so
            we can help you back in. We discuss matters such as, whether you are
            able to carry out normal hours and duties required of your role? Are
            you on any medication which may affect your performance? Did you
            attend your GP? Were you signed off sick by your GP? Did you provide a
            GP sick note? Has the GP provided a return to fitness note to confirm
            you can return to your normal working hours and your normal working
            role? If not, what hours does the GP confirm you can do, what duties
            etc.? Can we try to accommodate you if you cannot return to full time
            work or to the role? What action have you taken to avoid any future
            occurrence, did you consult a doctor or other medical practitioner,
            can we assist you in care, if you want to join, and eligible to join
            our health scheme,`,
          },
          {
            number: '21.21.(e).',
            text: `The Company conducts itself on mutual trust between
            the Employee and Employer and between Employee and Employee. As such,
            if an Employee makes up or exaggerates an illness or injury, commonly
            known as "pulling a sickie" it becomes a serious breach of the mutual
            trust and confidence that is the foundation of an employment
            relationship between Employee and fellow Employer, and Employee and
            Employer. For the purposes of this contract, this is an example of
            "gross misconduct" (e.g., including dishonesty, fraudulently claiming
            sick pay).`,
          },
          {
            number: '21.22.',
            text: `<u>To protect and extend the business of the Company</u> by working
            according to our protocols and guidelines, managing clients'
            expectations as directed and efficient communication with providers of
            work such as estate agents, including speaking with them on Microsoft
            Teams, telephone and in person by mutual agreement.`,
          },
          {
            number: '21.23.',
            text: `<u>
              To report IT, case management, internet or other system errors to
              enable you to work during office hours:
            </u>
            You must immediately report any system error, internet failure etc.,
            which either slows your normal progress or stops you. Firstly, to your
            team leader, then to FLG, and the partners. Then immediately report
            such incidences to ETI cloud (for non-proclaim related issues), and
            our internal IT department or proclaim itself, if proclaim related,
            and fix your issues if the problems are related to your remote working
            broadband or other associated issues.`,
          },
          {
            number: '21.23.(a).',
            text: `<u>Reporting EMPLOYER </u>system errors,`,
          },
          {
            number: '',
            text: `Firstly, <b>you must report</b> as follows:`,
          },
          {
            number: '(a.i)',
            text: `<u>Non proclaim issues:</u>`,
          },
          {
            number: '',
            text: `<u>report your issue to ETI Cloud</u> (known as ETI) for internet and
            IT issues not involving proclaim or our portals):
            <i>
              <a class='text-primary font-bold' href='helpdesk@everythingthatis.cloud'>
                helpdesk@everythingthatis.cloud
              </a>
              (follow up calls to
              <span class='text-primary font-bold'>0333 358 2222</span>).
            </i>`,
          },
          {
            number: '(a.ii)',
            text: `<u>Proclaim issues:</u>`,
          },
          {
            number: '(a.ii).1.',
            text: `<u>External Proclaim (most common).</u>`,
          },
          {
            number: '',
            text: `Report your issue to the Access/Proclaim Eclipse helpdesk if proclaim
            issues arise. E.g., freezes, crashes etc. Do this by logging into the
            Proclaim/Access portal by registering at
            <a class="text-primary" href='https://access-support.force.com/Support/s/login/SelfRegister.' target="_blank">
              https://access-support.force.com/Support/s/login/SelfRegister.
            </a>
            i). Wait for confirmation email saying account is ready (to log ticket
            before a/c ready email Support@eclipselegal.co.uk; ii). After account
            ready go to
            <a class="text-primary" href='https://access-support.force.com/Support/s/login/' target="_blank">
              https://access-support.force.com/Support/s/login/
            </a>
            ; Click & sign in/ Click ‘Create case’/ Select ‘urgent’/ set out
            detailed problem, including case ref, screen name, button you pressed,
            document code, attach screenshots). <br /> <u>Note</u>. You must chase
            Access if they do not fix the error within the hour, by chasing them
            by phone (followed by an email for the record) if their error stops
            you working. You must include an explanation of the consequences of
            their delay, e.g., risk of client complaint, negligence. If the error
            does not stop you working on the day, raise the error as above but
            allow Access one to two days to fix, depending on difficulty the error
            puts you and the client in. If still not resolved then chase as above
            except also add in the subject header of your email how many days have
            gone by since you first raised the support ticket with them. When
            chasing, ensure you also copy these chasers to
            <a class="text-primary font-bold" href='itteam@avrillo.co.uk'>itteam@avrillo.co.uk</a>
            and <a class="text-primary font-bold" href='aap@avrillo.co.uk'>aap@avrillo.co.uk</a> and notify them
            when Access fix the error.`,
          },
          {
            number: '(a.ii).2.',
            text: `<u>Internal Proclaim issues</u> (less likely)
            relating to our bespoke coding. Report to
            <a class="text-primary font-bold" href='itteam@avrillo.co.uk'>itteam@avrillo.co.uk</a>`,
          },
          {
            number: '(a.iii)',
            text: `<u>Portal issues, e-sign, or protocol forms</u> such as
            PIF, are to be reported to
            <a class="text-primary font-bold" href='portal@avrillo.co.uk'>portal@avrillo.co.uk</a>`,
          },
          {
            number: '(a.iv)',
            text: `<u>How to report your error? </u>`,
          },
          {
            number: '',
            text: `Firstly, explain the error, enclose a screenshot of the error etc., if
            possible. Explain time of the error, date, what happened. Secondly, if
            an error is not fixed within 30 minutes, call the relevant helpline to
            request an urgent fix as it is stopping the Employee from proactively
            working on their daily work, and at the same time notify the team
            leader and partners. At the same time, continue with other work, and
            if not other work, then ask the team leader for work, and if not
            available ask another colleague in a different team and notify the
            partners. Thirdly, call within 1 hour if still not fixed, and follow
            up with a further notification email saying, "without your fix, I am
            unable to work, and this may cause the client to complain, and my not
            being paid my wages for not doing any work due to the error on your
            system". This email should be copied to your team leader and partners.
            Thereafter repeat this step every following hour. On condition that
            you comply with the above conditions, you will be paid, but not if you
            do not, and you can make up your hours if a client etc., is
            inconvenienced if you do not.`,
          },
          {
            number: '21.23.(b).',
            text: `<u>Reporting EMPLOYEE </u> system errors,`,
          },
          {
            number: '',
            text: `<u>If the fault is due to you or within your control,</u> e.g., remote
            working, i.e., if you cannot work due to your own internet, electrical
            issues etc., you must notify the partners and FLG immediately,
            explaining your problem and the steps you have taken (e.g., by moving
            offsite to a stronger broadband with a secure IP address if your
            broadband is down) etc. Depending on the circumstances, the partners
            may allow you to work back any lost hours at times outside the working
            day, so no deductions are made if the hours are made up and the client
            has not suffered loss or inconvenience etc. or had other colleagues
            have to give up their time to look after them etc.`,
          },
        ]),
      ],
    },
  ]
}
