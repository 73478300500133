<script setup>
const props = defineProps({
  prependText: {
    type: String,
    default: '',
  },
  className: {
    type: String,
    default: 'text-xs',
  },
  appendText: {
    type: String,
    default: '',
  },
})

const store = useConveyancingCaseStore()

const donors = computed(() => {
  return store.donors
})
</script>

<template>
  <div :class="className">
    <span v-if="prependText">{{ prependText }}</span>
    <span v-for="(donor, donorIndex) in donors" :key="donorIndex">{{ donor.full_name_company_name }}{{ donors.length === donorIndex + 1 ? '' : ', ' }}</span>
    <span v-if="appendText">{{ appendText }}</span>
  </div>
</template>

<style scoped></style>
