import RadioGroup from '~/schemas/components/radio-group'
import DivorceSettlementDetails from '~/schemas/components/sof/source-of-funds-sections/divorce-settlement-details'

export default function (client, radioProp, dataProp, errorIf = 'false') {
  return {
    $el: 'div',
    attrs: {
      class: 'mb-6',
    },
    if: client,
    children: [
      RadioGroup(
        `$: "Is " + ${client}.name + " using divorce settlement to purchase the property?"`,
        `source_of_funds.divorce_settlement.${radioProp}`,
        true,
        [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
        '',
        errorIf,
      ),
      {
        $el: 'div',
        attrs: {
          class: 'section-indent',
        },
        if: `$formData.data.source_of_funds.divorce_settlement.${radioProp} === "yes"`,
        children: [DivorceSettlementDetails(`source_of_funds.divorce_settlement.${dataProp}`, `${client}.name`)],
      },
    ],
  }
}
