export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            children: 'PART A: Information that, regardless of outcome, is always considered material for all properties regardless of location:',
            attrs: {
              class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
            },
          },
          {
            $cmp: 'el-form-item',
            props: {
              label: '(15.1) Please confirm the Council tax band',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.council_tax_band`,
                options: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
                validation: 'required',
                value: '$saleReadyPack.report.propertyDetails.councilTax.taxBand',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
