import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '33.1.',
            text: `This agreement and any dispute or claim (including
              non-contractual disputes or claims) arising out of or in connection
              with it or its subject matter or formation shall be governed by and
              construed in accordance with the law of England and Wales. Further,
              each party irrevocably agrees that the courts of England and Wales
              shall have jurisdiction to settle any dispute or claim (including
              non-contractual disputes or claims) arising out of or in connection
              with this agreement.`,
          },
        ]),
      ],
    },
  ]
}
