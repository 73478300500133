<script setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
})

const store = useConveyancingCaseStore()

const clients = computed(() => {
  return store.clients
})
</script>

<template>
  <div class="text-sm font-medium text-black">
    {{ text }}
    <span v-for="(client, clientIndex) in clients" :key="clientIndex">{{ client.name }}{{ clients.length === clientIndex + 1 ? '?' : ', ' }}</span>
  </div>
</template>

<style scoped></style>
