<script setup>
const props = defineProps({
  html: {
    type: String,
    default: '',
  },
})

const store = useConveyancingCaseStore()

const clients = computed(() => {
  return store.clients
})
</script>

<template>
  <span v-html="html"></span>
</template>

<style scoped></style>
