import Repeater from '~/components/Repeater.vue'
import TotalCounter from '~/components/TotalCounter.vue'
import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import TextInput from '~/schemas/components/text-input'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Other Income' },
    children: [
      TitleSectionRadioGroup(
        '3',
        'Other Income',
        'source_of_funds.other_income.other_income',
        [
          {
            $el: 'div',
            if: '$formData.data.source_of_funds.other_income.other_income === "yes"',
            attrs: {
              class: 'mt-4',
            },
            children: [
              {
                $cmp: Repeater,
                props: useProps({
                  prop: 'source_of_funds.other_income.source_of_income',
                  buttonText: '+ Add Income',
                  removeButton: true,
                  min: 1,
                }),
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'border-solid border-[1px] p-4 pt-8',
                    },
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
                        },
                        children: [
                          {
                            $el: 'div',
                            attrs: {
                              class: 'flex-grow',
                            },
                            children: [TextInput('Source of income', 'source', '', false, { validation: 'required' })],
                          },
                          {
                            $el: 'div',
                            attrs: {
                              class: 'flex-grow',
                            },
                            children: [TextInput('Annual Income (Gross - Before Tax)', 'income', 'number', true, { validation: 'required' })],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $cmp: TotalCounter,
                props: {
                  dataToCountKey: 'income',
                  dataArrayKey: 'source_of_funds.other_income.source_of_income',
                  label: 'Total Annual Income (Gross - Before Tax) =',
                },
              },
            ],
          },
        ],
        '(i.e., rent, dividend, interest, trust or other)',
      ),
    ],
  }
}
