import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '24.1.',
            text: `As an employee going through training with the intention
            to obtain a qualification, e.g., trainee solicitor, trainee licensed
            conveyancer, you will benefit from our discounted capped clawback if
            you ‘leave’ (meaning either before or within 24 months after
            qualification, as follows:`,
          },
          {
            number: '24.1.(a).',
            text: `<u>For internal training and supervision.</u> The clawback is an
            agreed predetermined £2,900 if you leave us. No clawback is payable if
            we terminate your employment unless you have been in breach of
            contract. This £2,900 is not a penalty, but a discount of the higher
            true cost of over £30,000 based on the hourly rate for the lawyer/s
            time spent on overseeing your work, training, mentoring, and
            developing you.`,
          },
          {
            number: '24.1.(b).',
            text: `<u>
              For external course costs, exam costs, and time off work to study
              and for exams:
            </u>
            The clawback will be the actual cost spent on you, including on
            external courses, the time you take off work to attend those courses,
            study time taken off work, examination time taken off work, and the
            costs of the actual exams etc.`,
          },
        ]),
      ],
    },
  ]
}
