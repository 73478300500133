import DatePicker from '~/schemas/components/date-picker.js'
import RadioGroup from '~/schemas/components/radio-group'
import BankDetails from '~/schemas/components/sof/source-of-funds-sections/bank-details'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (data, clientName) {
  return {
    $el: 'div',
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
        },
        children: [
          TextInput(
            `$: "How much pension lump sum is " + ${clientName} + " cashing in to pay towards the purchase of the property?"`,
            data + '.how_much_money',
            'number',
            true,
            { validation: 'required' },
          ),
        ],
      },
      RadioGroup(
        `$: "Has " + ${clientName} + " already cashed in the Pension Fund and has that money been transferred to their personal account?"`,
        data + '.already_cashed_in_pension',
        true,
      ),
      {
        $el: 'div',
        attrs: {
          class: 'section-indent',
        },
        children: [
          {
            $cmp: 'el-form-item',
            props: { required: true },
            if: '$formData.data.' + data + '.already_cashed_in_pension === "yes"',
            children: [
              ...BankDetails(data + '.bank_details'),
              Title(
                '(c) Please obtain a letter (on their letterhead) from the solicitor acting on the provide to confirm the amount of money they sent to you.',
                'mb-4 mt-4',
              ),
              {
                $formkit: 'file-upload',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: `${data}.solicitor_letter.files`,
                fileName: `${data}.solicitor_letter.files`,
              },
            ],
          },
          {
            $el: 'div',
            if: '$formData.data.' + data + '.already_cashed_in_pension === "no"',
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'grid grid-cols-2 gap-4',
                },
                children: [
                  DatePicker(
                    'When is the approximate date do you expect the Pension Fund money to be in your account?',
                    data + '.date_pension_in_account',
                    true,
                  ),
                ],
              },
              Title(
                ' Please upload a letter or validation from your pension provider showing the lump sum you will be receiving from them. If they have not already sent this document, please call them to request it and then upload when you receive it.',
                'mb-4',
              ),
              {
                $cmp: 'el-form-item',
                props: { required: true },
                children: [
                  {
                    $formkit: 'file-upload',
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                    name: `${data}.pension_provider.files`,
                    fileName: `${data}.pension_provider.files`,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
