import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '9.1.',
            text: `In the event of pregnancy, the relevant maternity and
            paternity provisions set out in the Employment Rights will apply.
            These regulations will be explained in detail should the need
            arise.`,
          },
        ]),
      ],
    },
  ]
}
