import Repeater from '~/components/Repeater.vue'
import RadioGroup from '~/schemas/components/radio-group'
import SellingSharesDetailsRepeater from '~/schemas/components/sof/source-of-funds-sections/selling-shares-details-repeater'

export default function (client, radioProp, dataProp, errorIf = 'false') {
  return {
    $el: 'div',
    attrs: {
      class: 'mb-6',
    },
    if: client,
    children: [
      RadioGroup(
        `$: "Is " + ${client}.name + " selling shares to use the money towards to purchase of the property?"`,
        `source_of_funds.selling_shares.${radioProp}`,
        true,
        [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
        '',
        errorIf,
      ),
      {
        $el: 'div',
        attrs: {
          class: 'section-indent',
        },
        if: `$formData.data.source_of_funds.selling_shares.${radioProp} === "yes"`,
        children: [
          {
            $cmp: Repeater,
            props: useProps({
              prop: `source_of_funds.selling_shares.${dataProp}`,
              buttonText: '+ Add another Share',
              removeButton: true,
              min: 1,
            }),
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'border-solid border-[1px] p-4 pt-8',
                },
                children: [SellingSharesDetailsRepeater(`${client}.name`)],
              },
            ],
          },
        ],
      },
    ],
  }
}
