import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '15.1.',
            text: `<u>Within the 12-week probationary period:</u>
            The Employer will comply with the statutory contractual notice
            legislation. The probationary period will allow the Employee to
            ensure the Employer is the right fit for them, and vice versa for
            the Employer to assess if the Employee is the right fit for them.
            Termination can be during the probationary period, by either party`,
          },
          {
            number: '15.2.',
            text: `<u>After the probationary period of 12 weeks:</u>
            the Employer will, as goodwill, double the statutory notice period
            (applicable in law at the start of this contract) to Employees
            employed up to 2 years. This will work to your benefit. For those
            employed over 2 years, we will pay the statutory required notice
            period applicable in law, at termination.`,
          },
          {
            number: '15.3.',
            text: `<u> During the notice period</u> we shall have the discretion to
            assign, reduce, or provide alternative duties or no duties at all
            to the you, Employee, as well as prevent you from discussing our
            affairs with any third party (such as other Employees, other legal
            firms, estate agents, clients, or customers). During this period,
            you shall continue to be bound by the same obligations as you owed
            at commencement of the notice period, including the employee not
            working for any third parties or for yourself.`,
          },
          {
            number: '15.4.',
            text: `<u>
            You have full statutory notice period without any waivers
            unless,</u>
            firstly, by mutual agreement between you and us, or secondly, by
            your misconduct or performance so poor that it risks the client or
            the firm suffering a service issue or complaint or risk of loss,
            or damage to reputation or negligence or breach of protocols.`,
          },
          {
            number: '15.5.',
            text: `<u>Serious breach of conduct dismissal:</u><br /><br />In the case of you being instantly dismissed for a breach of the
            Conduct Rules (set out in clause 22 of this contract) you will not
            be entitled to be paid for any period beyond that date of
            dismissal by way of future salary, holiday pay or otherwise,
            subject to any statutory rights you may have at the date of said
            termination. If for example, you are dismissed on the 2nd day of
            the month you will not be entitled to pay (calculated as above)
            beyond the time worked on the 2nd.`,
          },
        ]),
      ],
    },
  ]
}
