import BankDetails from '~/schemas/components/pid/potential-issues-sections/bank-details'

export default function (client, data) {
  return {
    $el: 'div',
    if: client,
    attrs: {
      class: 'border-solid border-[1px] p-4 el-form--label-top',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'text-primary font-bold mb-4',
        },
        children: `$: ${client}.name + "'s Bank Account Details"`,
      },
      ...BankDetails(data),
    ],
  }
}
