import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '8.1.',
            text: `<u>Pension: </u>
            The Employer will provide the Employee with a Workplace Pension
            Scheme which is fully compliant with government legislation. The
            Employer will pay a proportion of contributions the Employee pays
            into the scheme (as set out when the Employee discusses what they
            want this scheme to look like, as the Employee has options on
            whether they want to. Further details can be found at
            <a href='http://www.workplacepensions.gov.uk/Employee/' target='_blank' class='text-primary font-bold'>
              http://www.workplacepensions.gov.uk/Employee/
            </a>`,
          },
        ]),
      ],
    },
  ]
}
