import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.insure_property === 'no'`),
            $cmp: 'el-form-item',
            props: { label: '(6.2) Please explain why not', required: true },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.insure_property_explain`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
