import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (data) {
  return [
    {
      $el: 'div',
      attrs: {
        class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
      },
      children: [
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [TextInput('Enter your name as shown in this account.', `${data}.bank_account_name`, '', false, { validation: 'required' })],
        },
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [TextInput('Sort Code', `${data}.sort_code`, '', false, { validation: 'required' })],
        },
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [TextInput('Account Number', `${data}.account_number`, '', false, { validation: 'required' })],
        },
      ],
    },
    Title('Please upload a bank statement dated within the last 3 months.', 'mb-4'),
    {
      $cmp: 'el-form-item',
      props: { required: true },
      children: [
        {
          $formkit: 'file-upload',
          key: 'bank_statement',
          validation: 'required',
          validationLabel: 'File upload',
          validationVisibility: 'live',
          name: `${data}.bank_statement.files`,
          fileName: `${data}.bank_statement.files`,
        },
      ],
    },
  ]
}
