import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import CryptoCurrencyClient from '~/schemas/components/sof/source-of-funds-sections/crypto-currency-client'

export default function () {
  const client1Name = '$formData.data.general_property_information.clients.client1.name'
  const client2Name = '$formData.data.general_property_information.clients.client2.name'

  return {
    $cmp: SectionCard,
    props: useProps({ tab: '11', title: 'Cryptocurrency', sectionClass: 'mb-4' }),
    children: [
      CryptoCurrencyClient('source_of_funds.crypto_currency.client_using_crypto', `$conveyancingCase.client`),
      CryptoCurrencyClient('source_of_funds.crypto_currency.client2_using_crypto', `$conveyancingCase.client2`),
      CryptoCurrencyClient('source_of_funds.crypto_currency.client3_using_crypto', `$conveyancingCase.client3`),
      CryptoCurrencyClient('source_of_funds.crypto_currency.client4_using_crypto', `$conveyancingCase.client4`),
    ],
  }
}
