export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: { label: '(10.1) Have you owned the property for two years or more?', class: 'mt-4', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'enfranchisement.have_you_owned_the_property_for_two_years_or_more',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(10.2) Have you taken steps to buy the freehold or extend the lease?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'enfranchisement.have_you_taken_steps_to_buy_the_freehold_or_extend_the_lease',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details of steps taken to buy the freehold or extend the lease.', class: 'section-indent', required: true },
          if: `$formData.data.enfranchisement.have_you_taken_steps_to_buy_the_freehold_or_extend_the_lease === "Yes"`,
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'enfranchisement.please_provide_details_of_steps_taken_to_buy_the_freehold_or_extend_the_lease',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide a copy', class: 'section-indent', required: true },
          if: `$formData.data.enfranchisement.have_you_taken_steps_to_buy_the_freehold_or_extend_the_lease === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'enfranchisement.provide_a_copy_step_taken_to_buy_the_freehold_or_extend_the_lease_options',
              options: ['Attached', "Can't Locate", 'Did not obtain', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.enfranchisement.provide_a_copy_step_taken_to_buy_the_freehold_or_extend_the_lease_options === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `enfranchisement.provide_a_copy_step_taken_to_buy_the_freehold_or_extend_the_lease_files`,
              fileName: `enfranchisement.provide_a_copy_step_taken_to_buy_the_freehold_or_extend_the_lease_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(10.3) Do you know if there are steps or potential steps for the collective purchase of the freehold by a group of tenants (i.e. leaseholders in the building)?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'enfranchisement.steps_or_potential_steps_for_the_collective_purchase_of_the_freehold_by_a_group_of_tenants',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          if: `$formData.data.enfranchisement.steps_or_potential_steps_for_the_collective_purchase_of_the_freehold_by_a_group_of_tenants === "Yes"`,
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'enfranchisement.provide_details_of_steps_or_potential_steps_for_the_collective_purchase_of_the_freehold_by_a_group_of_tenants',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(10.4) Please inform if you have  had a response to any notices served to buy the freehold, extend the lease, collective purchase or right to self-manage.',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'enfranchisement.info_if_you_have_had_a_response_to_any_notices_served_to_buy_the_freehold_extend_the_lease_collective_purchase_or_right_to_self_manage',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'Please supply a copy', class: 'section-indent', required: true },
          if: `$formData.data.enfranchisement.info_if_you_have_had_a_response_to_any_notices_served_to_buy_the_freehold_extend_the_lease_collective_purchase_or_right_to_self_manage === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'enfranchisement.provide_a_copy_notices_served_to_buy_the_freehold_extend_the_lease_collective_purchase_or_right_to_self_manage_options',
              options: ['Attached', "Can't Locate", 'Did not obtain', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.enfranchisement.provide_a_copy_notices_served_to_buy_the_freehold_extend_the_lease_collective_purchase_or_right_to_self_manage_options === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `enfranchisement.provide_a_copy_notices_served_to_buy_the_freehold_extend_the_lease_collective_purchase_or_right_to_self_manage_files`,
              fileName: `enfranchisement.provide_a_copy_notices_served_to_buy_the_freehold_extend_the_lease_collective_purchase_or_right_to_self_manage_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
      ],
    },
  ]
}
