import { defineStore } from 'pinia'
import axiosService from '~/services/axios.proclaim.service.js'

export const useMobileStore = defineStore({
  id: 'searchMobile',
  state: () => ({}),
  actions: {
    async getMobileVerification(phoneNumber) {
      return await axiosService
        .post(`https://proclaim.avrillo.co.uk/api/phone-verification?phone_number=${encodeURIComponent(phoneNumber)}`)
        .then((res) => res.data?.validation_result === 'Valid')
        .catch(() => false)
    },
  },
})
