export default function innerComponents(options, extraClasses = '') {
  return options.map((option) => ({
    $el: 'div',
    children: [
      {
        $el: 'span',
        children: option.number,
        attrs: {
          class: `font-semibold text-xs ${option.extraClasses}`,
        },
      },
      {
        $el: 'span',
        children: option.text,
        attrs: {
          class: `text-xs ml-1 ${option.extraClasses}`,
        },
      },
    ],
    attrs: {
      class: 'flex justify-content mt-4',
    },
  }))
}
