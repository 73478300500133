export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: 'grid grid-cols-1 md:grid-cols-2 gap-6' },
      children: [
        {
          $el: 'div',
          children: [
            {
              $el: 'div',
              for: ['client', 'key', '$clients'],
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: {
                    label: {
                      if: '$conveyancingCase.matter_type_code === "P"',
                      then: 'Full name of the 1st buyer',
                      else: 'Full name of the 1st seller',
                    },
                  },
                  children: [
                    {
                      $cmp: 'ElInput',
                      props: { value: '$client.name', disabled: true },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          $el: 'div',
          children: [
            {
              $cmp: 'ElFormItem',
              props: { label: 'Address of the property' },
              children: [
                {
                  $cmp: 'ElInput',
                  props: {
                    type: 'textarea',
                    rows: 6,
                    disabled: true,
                    value: {
                      if: '$conveyancingCase.matter_type_code === "P"',
                      then: '$conveyancingCase.purchase_property.full_address',
                      else: '$conveyancingCase.sale_property.full_address',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
