import TextInput from '~/schemas/components/text-input'

export default function (data) {
  return [
    TextInput('Bank Name', `${data}.bank_name`, '', false, { validation: 'required' }),
    {
      $cmp: 'el-form-item',
      props: { required: true },
      attrs: {
        class: 'grid grid-cols-3 gap-4',
      },
      children: [
        TextInput('Account Name', `${data}.account_name`, '', false, { validation: 'required' }),
        TextInput('Sort Code', `${data}.sort_code`, '', false, { validation: 'required' }),
        TextInput('Account Number', `${data}.account_number`, '', false, { validation: 'required' }),
      ],
    },
    {
      $cmp: 'el-form-item',
      props: { required: true },
      children: [
        {
          $formkit: 'file-upload',
          validation: 'required',
          validationLabel: 'File upload',
          validationVisibility: 'live',
          title: 'Please upload your Bank Statement dated in last 3 months',
          name: `${data}.bank_account.files`,
          fileName: `${data}.bank_account.files`,
        },
      ],
    },
  ]
}
