import { isFalseCondition, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(13.5) Mains Water Provider',
              class: 'mb-0',
              required: true,
            },
            children: [
              {
                if: `$formData.data.${parentPath}.mains_water_provider_not_connected_checkbox !== true && $formData.data.${parentPath}.mains_water_provider_other_checkbox !== true`,
                $formkit: 'select-autocomplete',
                name: `${parentPath}.mains_water_provider`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  'Thames Water',
                  'Severn Trent',
                  'United Utilities',
                  'South West Water',
                  'Yorkshire Water',
                  'Anglian Water',
                  'Northumbrian Water',
                  'Welsh Water',
                  'Scottish Water',
                ],
              },
            ],
          },
          {
            $el: 'div',
            attrs: { class: 'flex gap-4 w-1/2' },
            children: [
              {
                if: isFalseCondition(`${parentPath}.mains_water_provider_other_checkbox`),
                $formkit: 'el-checkbox',
                name: `${parentPath}.mains_water_provider_not_connected_checkbox`,
                text: 'Not connected',
              },
              {
                if: isFalseCondition(`${parentPath}.mains_water_provider_not_connected_checkbox`),
                $formkit: 'el-checkbox',
                name: `${parentPath}.mains_water_provider_other_checkbox`,
                text: 'Other',
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.mains_water_provider_other_checkbox`),
            $cmp: 'el-form-item',
            props: {
              label: '(13.5.1) Please provide the name of your supplier',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.mains_water_provider_name`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.mains_water_provider_not_connected_checkbox`),
            $cmp: 'el-form-item',
            props: {
              label:
                '(13.5.1) Please clarify why the property is not connected and the date when this was disconnected or the date that this will be connected?',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.mains_water_provider_not_connected`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
