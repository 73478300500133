import Title from '~/schemas/components/title'

export default function (prop) {
  return {
    $el: 'div',
    children: [
      Title('Identification Check', 'mb-4 font-normal text-xl'),
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-3 gap-4',
        },
        children: [
          {
            $cmp: 'ElFormItem',
            props: { label: 'Date of birth', class: 'col-span-2', required: true },
            children: [
              {
                $formkit: 'el-date-picker',
                name: `${prop}.dob`,
                class: 'w-full',
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
      {
        $cmp: 'ElFormItem',
        props: {
          label: 'Upload either the 2nd and 3rd page of their passport or UK Photo Driving License',
          required: true,
        },
        children: [
          {
            $formkit: 'el-radio-group',
            class: 'w-full',
            name: `${prop}.document_type`,
            validation: 'required',
            validationMessages: {
              required: 'This field is required',
            },
            options: [
              { value: 'passport', label: 'Passport' },
              { value: 'driving_license', label: 'UK Photo Driving License' },
            ],
          },
        ],
      },
      {
        $cmp: 'ElFormItem',
        if: `$formData.data.${prop}.document_type === "driving_license"`,
        props: {
          key: 'driving_license_has_address',
          label: 'Is your photo driving license showing your current address',
          class: 'pl-6',
          required: true,
        },
        children: [
          {
            $formkit: 'el-radio-group',
            class: 'w-full',
            name: `${prop}.driver_license_has_address`,
            validation: 'required',
            validationMessages: {
              required: 'This field is required',
            },
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          },
        ],
      },
      {
        $cmp: 'ElRow',
        props: { gutter: 20 },
        children: [
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                $cmp: 'el-form-item',
                if: `$formData.data.${prop}.document_type === "driving_license" && $formData.data.${prop}.driver_license_has_address !== undefined`,
                props: {
                  class: 'pl-6',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'file-upload',
                    title: 'Upload their UK Photo Driving License',
                    name: `${prop}.driving_license.files`,
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                    fileName: `${prop}.driving_license.files`,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        $cmp: 'ElRow',
        props: { gutter: 20 },
        children: [
          {
            $cmp: 'ElCol',
            props: { span: 24 },
            children: [
              {
                $cmp: 'ElFormItem',
                if: `$formData.data.${prop}.document_type === "passport"`,
                props: {
                  key: 'passport',
                  label: 'Upload the 2nd and 3rd page of their passport',
                  class: 'pl-6',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'file-upload',
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                    name: `${prop}.passport.files`,
                    fileName: `${prop}.passport.files`,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
