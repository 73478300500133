import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '4.1.',
            text: `$: "Your commencement date of employment: <span class='text-primary font-bold'>" + $formData.data.commencement_date + "</span>. Any employment with a previous Employer does not count as part of your period of continuous employment with this firm."`,
          },
        ]),
      ],
    },
  ]
}
