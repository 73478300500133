export default function (parentPath) {
  return [
    {
      $cmp: 'ElRow',
      props: { gutter: 20 },
      children: [
        {
          $cmp: 'ElCol',
          props: { span: 24 },
          children: [
            {
              $el: 'div',
              attrs: { class: 'text-xs  mb-2 mt-4' },
              children: [
                {
                  $el: 'span',
                  children: 'ALTERATIONS: ',
                  attrs: { class: 'font-bold' },
                },
                {
                  $el: 'span',
                  children:
                    'Purchase only. Have any changes or alterations been made or added to the property, for example: extensions, conversions, change of use, new glazing, roof lights/ roof windows, replacement windows, boiler and other similar alterations etc? Note 1. If yes to \'alterations\' then please describe in full, exactly what these are (e.g. Loft/ Extension -side, single storey) Note 2. If you leave the box blank then we assume that your reply is "no" there are no alterations',
                },
              ],
            },
            {
              $cmp: 'ElFormItem',
              props: {
                label: 'Alterations: Do not complete this box unless applicable to you',
                class: 'font-bold mt-4',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: parentPath + '.alterations',
                  inputType: 'text',
                  placeholder: 'Alterations',
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
