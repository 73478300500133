import GeneralHeader from '~/components/GeneralHeader.vue'
import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import { useSchema } from '~/composables/useSchema'
import { isStandalone } from '~/schemas/components/common/helpers.js'
import contactDetails from '~/schemas/components/slifa/contact-details'
import maintenanceServiceCharges from '~/schemas/components/slifa/maintenance-service-charges'
import ownership from '~/schemas/components/slifa/ownership'
import relevantDocuments from '~/schemas/components/slifa/relevant-documents'
import yourLeaseholdProperty from '~/schemas/components/slifa/your-leasehold-property'
import alterations from './components/slifa/alterations'
import buildingSafetyCladdingDeedOfCert from './components/slifa/building-safety-cladding-deed-of-cert'
import complaints from './components/slifa/complaints'
import consents from './components/slifa/consents'
import enfranchisement from './components/slifa/enfranchisement'
import notices from './components/slifa/notices'

export default useSchema({
  id: 'slifa',
  title: 'Sellers Leasehold Information Form',
  autosave: true,
  steps: true,
  signature: true,
  properties: [
    {
      $cmp: GeneralHeader,
      props: useProps({ title: 'General Information', visible: () => !isStandalone() }),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1', title: 'Your Leasehold Property' }),
      children: yourLeaseholdProperty(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '2', title: 'Ownership' }),
      children: ownership(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '3', title: 'Relevant Documents', is_section_seen: 'relevant_documents' }),
      children: relevantDocuments(),
    },
    { $cmp: SectionCard, props: useProps({ tab: '4', title: 'Contact Details' }), children: contactDetails() },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '5', title: 'Maintenance / Service Charges' }),
      children: maintenanceServiceCharges(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '6', title: 'Notices' }),
      children: notices(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '7', title: 'Consents' }),
      children: consents(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '8', title: 'Complaints' }),
      children: complaints(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '9', title: 'Alterations' }),
      children: alterations(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '10', title: 'Enfranchisement' }),
      children: enfranchisement(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '11', title: 'Building Safety, Cladding, Deed of Certificate' }),
      children: buildingSafetyCladdingDeedOfCert(),
    },
  ],
})
