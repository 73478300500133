<script setup>
import { computed } from 'vue'
import { useFormStore } from '~/stores/forms'

const props = defineProps({
  dataToCountKey: {
    type: String,
    required: true,
  },
  dataArrayKey: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  showIfOnlyOne: {
    type: Boolean,
    default: false,
  },
})

const payload = computed(() => useFormStore().payload)

const valueArray = computed(() => payload.value[props.dataArrayKey] ?? [])

const totalValue = computed(() => {
  let value = 0

  if (!valueArray.value) {
    return value
  }

  valueArray.value.forEach((element) => {
    const elementValue = parseFloat(element[props.dataToCountKey])
    if (!isNaN(elementValue)) {
      value = value + elementValue
    }
  })

  return value
})
</script>

<template>
  <div v-if="valueArray.length > 1 || showIfOnlyOne">
    <hr class="my-4" />
    <div class="flex justify-center text-lg font-bold text-primary">{{ label }} £{{ isNaN(totalValue) ? '0' : totalValue }}</div>
    <hr class="my-4" />
  </div>
</template>

<style scoped></style>
