import { isTrueCondition } from '~/schemas/components/common/helpers.js'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(11.2) Does anyone aged over 17, other than the owner(s), live at the property?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `occupier_information.anyone_aged_over_17_lives_at_property`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`occupier_information.anyone_aged_over_17_lives_at_property === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(11.2.1) Is the property being sold with vacant possession?', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `occupier_information.property_sold_with_vacant_possession`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
