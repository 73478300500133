import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import { useSchema } from '~/composables/useSchema'
import clientInformation from '~/schemas/components/common/client-information'
import ClientInformationSection from '~/schemas/components/common/client-information-checks.js'
import { getCaseVariable } from '~/schemas/components/common/helpers.js'
import PotentialIssues from '~/schemas/components/pid/potential-issues'

export default useSchema({
  id: 'pid',
  title: 'COMPLIANCE AND POTENTIAL ISSUES DECLARATION',
  description: 'This form will help you to pass government AML regulations',
  autosave: true,
  steps: true,
  signature: true,
  properties: [
    {
      $cmp: SectionCard,
      props: useProps({ title: 'Client Information' }),
      children: clientInformation(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ title: `Client 1 Details`, visible: () => getCaseVariable('client') }),
      children: ClientInformationSection('client', '$conveyancingCase.client.name'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ title: 'Client 2 Details', visible: () => getCaseVariable('client2') }),
      children: ClientInformationSection('client2', '$conveyancingCase.client2.name'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ title: 'Client 3 Details', visible: () => getCaseVariable('client3') }),
      children: ClientInformationSection('client3', '$conveyancingCase.client3.name'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ title: 'Client 4 Details', visible: () => getCaseVariable('client4') }),
      children: ClientInformationSection('client4', '$conveyancingCase.client4.name'),
    },
    ...PotentialIssues(),
  ],
})
