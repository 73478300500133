import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(5.3) To your knowledge, has there been any preventative work for, or treatment of dry rot, wet rot or damp carried out at the property?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.preventative_work`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                  { label: 'Not known', value: 'notKnown' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.preventative_work === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(5.3.1) Please supply a copy of the relevant treatment plans and/or guarantees', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.preventative_work_documents`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Attached', value: 'attached' },
                  { label: "Can't locate", value: 'cantLocate' },
                  { label: 'Did not obtain', value: 'didNotObtain' },
                  { label: 'To Follow', value: 'toFollow' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.preventative_work_documents === 'attached'`),
            $cmp: 'el-form-item',
            props: { label: '(5.3.1.1) Please upload a copy of the guarantee', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'file-upload',
                name: `${parentPath}.preventative_work_documents_files`,
                fileName: `${parentPath}.preventative_work_documents_files`,
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
              },
            ],
          },
        ],
      },
    ],
  }
}
