export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: 'bold-label' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: { label: 'What have we done well so far?' },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              name: 'have_we_done_well_so_far',
              rows: 4,
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'What could we do better?' },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              name: 'could_we_do_better',
              rows: 4,
            },
          ],
        },
        {
          $el: 'p',
          attrs: { class: 'text-xs' },
          children: "By pressing 'Submit' you confirm that this substitutes your signature as confirming that you agree our terms of business.",
        },
      ],
    },
  ]
}
