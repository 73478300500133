import ExplanatoryNotesForBuyers from '~/components/ExplanatoryNotesForBuyers.vue'
import ExplanatoryNotesForSellers from '~/components/ExplanatoryNotesForSellers.vue'
import GeneralHeader from '~/components/GeneralHeader.vue'
import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import { useSchema } from '~/composables/useSchema'
import { isStandalone } from '~/schemas/components/common/helpers.js'
import additionalQuestions from '~/schemas/components/pif/additional-questions.js'
import alterationsToTheProperty from '~/schemas/components/pif/alterations-to-the-property.js'
import boundaries from '~/schemas/components/pif/boundaries.js'
import disputesComplaints from '~/schemas/components/pif/disputes-complaints.js'
import environmentalMatters from '~/schemas/components/pif/environmental-matters.js'
import guaranteesWarranties from '~/schemas/components/pif/guarantees-warranties.js'
import insurances from '~/schemas/components/pif/insurances.js'
import noticesProposals from '~/schemas/components/pif/notices-proposals.js'
import occupierInformation from '~/schemas/components/pif/occupier-information.js'
import otherCharges from '~/schemas/components/pif/other-charges.js'
import parking from '~/schemas/components/pif/parking.js'
import rightsAndInformalArrangements from '~/schemas/components/pif/rights-and-informal-arrangements.js'
import transactionInformation from '~/schemas/components/pif/transaction-information.js'
import utilitiesAndServiceList from '~/schemas/components/pif/utilities-and-service-list.js'
import utilitiesAndServices from '~/schemas/components/pif/utilities-and-services.js'

export default useSchema({
  id: 'pif',
  title: 'Property Information Form',
  description: 'This form details the property information and is required for the sale of the property.',
  autosave: true,
  steps: true,
  signature: true,
  properties: [
    {
      $cmp: ExplanatoryNotesForSellers,
      props: useProps({ title: 'Explanatory for seller to read before completing' }),
    },
    {
      $cmp: ExplanatoryNotesForBuyers,
      props: useProps({ title: 'Explanatory for buyer to read' }),
    },
    {
      $cmp: GeneralHeader,
      props: useProps({ title: 'Seller Details', visible: () => !isStandalone() }),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '1',
        title: 'Boundaries',
        subtitle: 'If the property is leasehold this section, or part of it, may not apply.',
      }),
      children: boundaries('boundaries'),
    },

    {
      $cmp: SectionCard,
      props: useProps({ tab: '2', title: 'Disputes, Complaints' }),
      children: disputesComplaints('disputes_complaints'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '3', title: 'Notices & Proposals' }),
      children: noticesProposals('notice_proposals'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '4', title: 'Alterations to the property, planning and building control' }),
      children: alterationsToTheProperty('alterations_to_the_property'),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '5',
        title: 'Guarantees/Warranties',
        subtitle:
          'Note: You will need to supply all guarantees and warranties to the buyer solicitors, so please attach them to this form because the buyer will need them to see if they can or cannot rely on them after they purchase.',
      }),
      children: guaranteesWarranties('guarantees_warranties'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '6', title: 'Insurances' }),
      children: insurances('insurances'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '7', title: 'Environmental Matters' }),
      children: environmentalMatters('environmental_matters'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '8', title: 'Rights and Informal Arrangements' }),
      children: rightsAndInformalArrangements('rights_and_informal_arrangements'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '9', title: 'Parking' }),
      children: parking('parking'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '10', title: 'Other Charges' }),
      children: otherCharges('other_charges'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '11', title: 'Occupier Information' }),
      children: occupierInformation('occupier_information'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '12', title: 'Utilities and services' }),
      children: utilitiesAndServices('utilities_and_services'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '13', title: 'Utilities and Service - Please indicate which services are connected to the property' }),
      children: utilitiesAndServiceList('utilities_and_services_list'),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '14',
        title: 'Transaction Information',
      }),
      children: transactionInformation('transaction_information'),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '15',
        title: 'ADDITIONAL QUESTIONS CONSIDERED ‘MATERIAL INFORMATION’',
        subtitle:
          'defined by Consumer Protection from Unfair Trading Regulations 2008 as “information which the average consumer needs, according to the context, to take an informed transactional decision”.',
      }),
      children: additionalQuestions('additional_questions'),
    },
  ],
})
