import cyberSecurityBlock from '~/schemas/components/instruction/terms-and-conditions/cyber-security-block'
import fastTrackBlock from '~/schemas/components/instruction/terms-and-conditions/fast-track-block'
import instructAndTermsAndConditionsBlock from '~/schemas/components/instruction/terms-and-conditions/instruct-and-terms-and-conditions-block'
import mortgageLender from '~/schemas/components/instruction/terms-and-conditions/mortgage-lender'
import noMoveNoCostBlock from '~/schemas/components/instruction/terms-and-conditions/no-move-no-cost-block'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElRow',
      props: { gutter: 20, class: 'text-sm pl-7 pr-6 pt-6 ' },
      children: [
        instructAndTermsAndConditionsBlock(parentPath),
        noMoveNoCostBlock(parentPath),
        fastTrackBlock(parentPath),
        {
          if: `$conveyancingCase.matter_type_code === 'P' || $conveyancingCase.matter_type_code === 'B' || $quoteCase.matter_type_code === 'P' || $quoteCase.matter_type_code === 'B'`,
          $el: 'div',
          attrs: {
            class: 'mt-4',
          },
          children: [mortgageLender(parentPath)],
        },
        cyberSecurityBlock(parentPath), //Cyber Security checkbox
      ],
    },
  ]
}
