export default function (parentPath) {
  return [
    {
      $cmp: 'ElRow',
      props: { gutter: 20 },
      children: [
        {
          $cmp: 'ElCol',
          props: { span: 24 },

          children: [
            {
              $el: 'div',
              attrs: { class: 'text-xs  mb-2 mt-4' },
              children: [
                {
                  $el: 'span',
                  children: 'ACCESS: ',
                  attrs: { class: 'font-bold' },
                },
                {
                  $el: 'span',
                  children: 'Purchase only. We presume that:',
                },
              ],
            },
            {
              $el: 'div',
              attrs: { class: 'text-xs  mb-1' },
              children: 'a) You access the property directly from a main road; and',
            },
            {
              $el: 'div',
              attrs: { class: 'text-xs  mb-1' },
              children: 'b) there is no side or rear access to the property',
            },
            {
              $el: 'div',
              attrs: { class: 'text-xs  mb-1' },
              children: 'If our assumptions are incorrect please set out details here (specify exactly what access - e.g. by car or on foot)',
            },
            {
              $cmp: 'ElFormItem',
              props: {
                label: 'Access: Do not complete this box unless applicable to you',
                class: 'font-bold mt-4',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: parentPath + '.access',
                  inputType: 'text',
                  placeholder: 'Access',
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
