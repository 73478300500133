import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '',
            text: `£4,000 - 1st instalment  - paid at the end of October payroll 2022 anniversary <br />
            £4,000 - 2nd instalment  - paid at the end of October payroll 2023 anniversary <br />
            £4,000 - 3rd instalment  - paid at the end of October payroll 2024 anniversary <br />
            £4,000 - 4th  instalment  - paid at the end of October payroll 2025anniversary <br />
            £4,000 - 5th  instalment  - paid at the end of October payroll 2026 anniversary <br />`,
          },
        ]),
      ],
    },
  ]
}
