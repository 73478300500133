import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(8.2) Do any rights and arrangements exist over neighbouring land (formal or informal) from which the property benefits? (eg taking wheelie bins along an accessway through a neighbour’s back garden, parking, access to maintain the boundaries from the neighbour’s side etc)',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.neighbouring_land_rights`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.neighbouring_land_rights === 'yes'`),
            $el: 'div',
            attrs: { class: 'pl-6' },
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label: '(8.2.1) Please give details of the route of the access, parking etc',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-input',
                    name: `${parentPath}.neighbouring_land_rights_details`,
                    inputType: 'textarea',
                    rows: 6,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                props: { label: '(8.2.2) Please provide a plan showing the route of the access, parking etc', required: true },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.neighbouring_land_rights_plan`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    options: [
                      { label: 'Attached', value: 'attached' },
                      { label: 'To Follow', value: 'toFollow' },
                      { label: 'Not applicable', value: 'notApplicable' },
                    ],
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.neighbouring_land_rights_plan === 'attached'`),
                $cmp: 'el-form-item',
                props: { label: '(8.2.2.1) Please kindly upload a plan', required: true },
                children: [
                  {
                    $formkit: 'file-upload',
                    name: `${parentPath}.neighbouring_land_rights_plan_files`,
                    fileName: `${parentPath}.neighbouring_land_rights_plan_files`,
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
