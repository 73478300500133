import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '2.1.',
            text: 'The word <u>“Employee”</u> is also referred to and interchangeable, for the purposes of this contract, with the words “You” and “Your”',
          },
          {
            number: '2.2.',
            text: {
              if: `${`$employeeCase.company_code`} === '1'`,
              then: `The word <u>“Employer”</u> is also referred to and interchangeable, for the purposes of this contract, with the words: The ‘Company’, ‘AVRillo’, the ‘Equity Partners’, ‘We’, 'it’s’, ‘Their’, ‘Us’ the ‘Firm’, ‘AVR’,. ‘AVRillo LLP , ‘the LLP’`,
              else: `The word <u>“Employer”</u> is also referred to and interchangeable, for the purposes of this contract, with the words: The ‘Company’, ‘AVRillo’, the ‘Equity Partners’, ‘We’, 'it’s’, ‘Their’, ‘Us’ the ‘Firm’, ‘AVR’,. ‘AVRillo R&D LTD, ‘the LTD’`,
            },
          },
          {
            number: '2.3.',
            text: 'Unless the context otherwise requires, words in the <u>singular include the plural</u> and, in the plural, include the singular',
          },
          {
            number: '2.4.',
            text: `The <u>following words are interchangeable</u> with
            each other and are to be interpreted as meaning the same thing:
            ‘e.g.,’ ‘for instance’, ‘i.e.,’ ‘include(s)’, ‘including’, ‘such
            as', ‘etc.’, ‘like’, ‘and the like'. Where these are used in this
            agreement, they are deemed to refer to a non-exhaustive list of
            similar meaning words, where the context permits. The words are
            illustrative and shall not limit the sense of the words preceding
            them.`,
          },
          {
            number: '2.5.',
            text: `A reference to <u>‘notifications’ and/or ‘notice’, ‘report’</u> refers to the
            following procedure to be followed by the Employee when notifying
            or reporting etc. to the Company: The notice to be made in
            writing, by email and followed up with a telephone call to the
            team leader on the day of the email. Further, the said notice
            email must be copied to FLG and the partners if the subject matter
            of the notice is to do with for example a service, legal or any
            other complaint and the like. Examples of notices and reporting to
            be made are set out in this contract.`,
          },
          {
            number: '2.6.',
            text: `<u>Office manual’</u> (is also referred to as the
              Lexcel office manual) and includes the Employer’s protocols, policies,
              systems, procedures and generally all other matters on how the
              Company works, is run and interaction between
              Employer/Employee/third parties.`,
          },
          {
            number: '2.7.',
            text: `<u>The word ‘Protocol/s’</u> is defined in clause
            21.15; but introduced here briefly as the firm’s guidelines,
            procedures, systems, workflows. either in the case management
            coded systems or in writing (within the contract, the office
            manual, training i.e., email, webinars. Microsoft Teams, telephone
            conversations, Talent LMS training portal. The protocols must be
            followed as their objective are to help you better manage your
            clients and your time thereby fulfilling your role and obligations
            professionally and under this contract)`,
          },
          {
            number: '2.8.',
            text: `<u>FLG</u>. Consists of senior members of the Company
            who help to shape the protocols of the Company, and who meet with
            the partners to discuss Company business. They are given rights to
            manage Employees.`,
          },
          {
            number: '2.9.',
            text: `<u>Partners.</u>`,
          },
          {
            number: '',
            text: `<i>
            Salaried partners are defined as partners who are not Equity
            Partners.
          </i>`,
          },
          {
            number: '',
            text: `<i>Equity Partners</i> are defined as those inter alia, who have
            put money into AVRillo to buy a share for example in the
            business and are liable for equity partners' liability,
            including overdrafts, loans, business expenses, professional
            indemnity insurance, take out personal guarantees and continue
            to loan money to the business, and are liable to the partnership
            taxation, on profit as well as taking on liability for losses,
            if any.`,
          },
          {
            number: '2.10.',
            text: `<u>Trainee conveyancer,</u> an Employee supported by
            the firm in their study; further details in clause 24.`,
          },
        ]),
      ],
    },
  ]
}
