import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '',
            text: `The Employee will give the notice of termination of employment as
            follows:`,
          },
          {
            number: '14.1.',
            text: `Notice if Employee terminates within the first 4 weeks of
            employment.`,
          },
          {
            number: '14.2.',
            text: `<u>
              Notice period for any Employee who terminates after the first 4
              weeks of employment
            </u>
            are set out below in clause 12.2.a, with consequences set out in
            14.2.b for the reasons set out in 14.2 c) if notice period is
            breached.`,
          },
          {
            number: '14.2.(a).',
            text: `<u>The notice periods from Employee</u>
              (‘you’) to Employer are defined below (and also include your
              position within the Company), as below:`,
          },
          {
            number: '',
            text: `<li>
              6 weeks’ notice if you work in one of the following non-legal roles,
              such as administration, hospitality, accounts, sales role (i.e.,
              quoting or 360 roles with agents), account manager, BDM, etc., and
              any support roles not referred to in the lists below.
            </li>
            <li>
              8 weeks’ notice if you work in one of the following role types:
              Assistant to a team leader fee earner (whether you are a paralegal,
              assistant, qualified or non-qualified or a trainee, whether you work
              on your own files in a conveyancing team or for any number of teams.
            </li>
            <li>
              10 weeks' notice from you if you run cases in your own name or are a
              title checker or whether you are a team leader, or run a team of up
              to one, or senior coder undertaking your own coding work.
            </li>
            <li>
              12 weeks' notice if you are part of management, FLG, or run a team
              of two or more.
            </li>
            <li>18 weeks’ notice if you are a Salaried Employee Partner.</li>`,
          },
          {
            number: '14.2.(b).',
            text: `<b>
            Consequences of the Employee breach of notice (by terminating with
            less notice than is required under this contract) is the Employee
            pay damages to the Employer calculated by using the predetermined
            assessment of these damages formula
          </b>
            (based on a shortfall in weeks x pay for each week, herein known as
            the formula further set out in 14.2.b.(i) as a commercial
            replacement (as referred to in 14.2 (ii)) for the full losses
            otherwise claimable under the common law principle of consequential
            loss arising from the breach`,
          },
          {
            number: '(b.i)',
            text: `The damages formula is calculated as below: The
            predetermined agreed assessment formula of damages for short notice
            breach of the Employee's contractual notice obligations (herein known
            as the formula) is the number of weeks' shortfall in the notice from
            the Employee, multiplied by their average basic weekly salary, net of
            tax (the month before their leave date). E.g.1, if the Employee's
            contract requires the Employee to give 2 months' notice to the
            Employer, but the Employee only gives 7 weeks' notice; then the
            Employee will need to pay damages to the Employer of one week pay
            (which can be deducted by the Employer from the Employee's final
            payroll if this is sufficient, and if not sufficient, the Employee has
            to repay the balance by their last day of employment). E.g.2., But, if
            the Employee worked their correct 2 months' notice in that example, no
            pay would be deducted for breach of notice as there would not have
            been a breach of notice.`,
          },
          {
            number: '(b.ii)',
            text: `The said formula represents a commercially predetermined
            agreed method to calculate damages payable by you, including for (but
            not limited) to inconvenience and time spent by the Employer because
            of your shorter notice period. Such time includes the Employer having
            to train the replacement to the same practical standard reached by
            you, but in the shorter time given by your breach. Whether the
            replacement is internal or externally recruited, the replacement needs
            the full contract notice to take over your role and knowledge of your
            work and get to understand your commitments as at your leave date, as
            well as shadowing or collaboration time they would have had with you
            had you left the correct notice. The predetermined formula does not
            calculate damages according to the actual, consequential losses
            arising from the breach, which would have included examples of losses
            below. Instead, the formula is a predetermined calculation based on
            the number of weeks of short notice multiplied by the weekly pay
            before the leave date, rather than the hourly rate spent on all time,
             due to your breach of contract, on for example:- time spent in
            advertising, interviewing, pre-, auditing your replacement and more
            hands-on supervision the shorter expedited period because of your
            short notice, upgrading their legal and practical knowledge, the
            AVRillo protocols, workflow, guidance, style, client management, soft
            skill approaches such as confidence, polity, assertiveness, tonality,
            style of communication etc. (which the replacement would have
            benefited in the more personal hand over from you had you given full
            contractual notice so you could have trained your replacement as you
            were trained with a mixture of one to one, group mentoring meetings,
            on the job training when shadowing, work on live cases, critique on
            work and support). Instead, the formula includes all of the above
            losses instead of a full consequential losses claim. The formula also
            accommodates for additional time, inconvenience and cost to appease
            complaints from clients or third parties such as estate agents etc.,
            because of the gap left from your knowledge of your role and workload,
            which had you given correct notice, you could have handed over without
            the inconvenience caused to your clients, third party's and time added
            to other Employees in the firm who have had to assist in the process
            at short notice.`,
          },
          {
            number: '(b.iii)',
            text: `Mutually agreed. For the avoidance of doubt, the
            aforesaid formula is mutually and contractually accepted by Employee
            and Employer as a pre-agreed, predefined calculation of damages for
            breach of the notice period. It is limited only if the conditions are
            met. Otherwise, full damages are claimable as consequential losses
            arising from the breach. Note, the said damages formula considers the
            principle established in the Supreme Court in Makdessi -v- Cavendish
            Square Holdings [2015]. If a penalty clause is legitimately included
            within a contract that is commercially justifiable, the clause will
            not be seen as a penalty. As such, the damages calculation formula is
            not a penalty clause as it represents a pre-agreed formula to
            calculate the Employer's legitimate damages for the Employee's breach
            but at a commercial calculation rather than a full consequential
            breach claim as aforesaid.`,
          },
          {
            number: '14.2.(c).',
            text: `Why the notice period varies between the roles of the
            Employee?`,
          },
          {
            number: '(c.i)',
            text: `Firstly, it accounts for the time spent by the firm on
            training the Employee to the standard at your leave date.The more
            training, the longer the notice period because the Employer will have
            invested more time and hourly rate cost in training and mentoring you
            to reach your experience at the end date. It will then have to
            reinvest that same time in training your replacement; such training
            will cost the Employer substantial time and money, and hence why the
            contract period notice must be served, failing which the Employee is
            liable to pay predetermined formula damages for breach of the same,
            without proof of damages by the Employer.`,
          },
          {
            number: '(c.ii)',
            text: `Secondly, notice periods depend on the responsibility
            and/or position held by the Employee at termination. The guiding
            principle of the length of the Employee notice period will be what is
            in the best interest of their clients and third parties they were
            dealing with and/or involved with when they gave notice. The notice
            period will therefore reflect the time the Employee will need to hand
            over their cases, work, or those they were assisting on, etc., to
            their replacement, when found, so as not to let their clients down,
            upset them, leave them in a worse position than had they given the
            correct full notice to enable them to complete or at least progress
            the client file to the point where it could be handed over to a
            replacement, if available, with full handover notes, and the file
            being in good order, legible and neat. Therefore, a full contract
            notice period exists as aforesaid to reflect the Employee's roles and
            responsibilities at the time of their termination notice.`,
          },
        ]),
      ],
    },
  ]
}
