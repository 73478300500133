import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import { useSchema } from '~/composables/useSchema'
import section1 from './components/staff-contract/section1'
import section10 from './components/staff-contract/section10'
import section11 from './components/staff-contract/section11'
import section12 from './components/staff-contract/section12'
import section13 from './components/staff-contract/section13'
import section14 from './components/staff-contract/section14'
import section15 from './components/staff-contract/section15'
import section16 from './components/staff-contract/section16'
import section17 from './components/staff-contract/section17'
import section18 from './components/staff-contract/section18'
import section19 from './components/staff-contract/section19'
import section2 from './components/staff-contract/section2'
import section20 from './components/staff-contract/section20'
import section21 from './components/staff-contract/section21'
import section22 from './components/staff-contract/section22'
import section23 from './components/staff-contract/section23'
import section24 from './components/staff-contract/section24'
import section25 from './components/staff-contract/section25'
import section26 from './components/staff-contract/section26'
import section27 from './components/staff-contract/section27'
import section28 from './components/staff-contract/section28'
import section29 from './components/staff-contract/section29'
import section3 from './components/staff-contract/section3'
import section30 from './components/staff-contract/section30'
import section31 from './components/staff-contract/section31'
import section32 from './components/staff-contract/section32'
import section33 from './components/staff-contract/section33'
import section34 from './components/staff-contract/section34'
import section4 from './components/staff-contract/section4'
import section5 from './components/staff-contract/section5'
import section6 from './components/staff-contract/section6'
import section7 from './components/staff-contract/section7'
import section8 from './components/staff-contract/section8'
import section9 from './components/staff-contract/section9'
import signature from './components/staff-contract/signature'
import staffInformation from './components/staff-contract/staff-information'

export default useSchema({
  id: 'staff-contract',
  title: 'CONTRACT OF EMPLOYMENT',
  autosave: true,
  steps: false,
  signature: false,
  properties: [
    {
      $cmp: SectionCard,
      props: useProps({ tab: '', title: '' }),
      children: staffInformation(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1', title: 'Rights and terms included within this contract.' }),
      children: section1(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '2', title: 'Example Definitions, References and Titles referred to in this contract (others defined within the contract)' }),
      children: section2(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '3', title: 'Parties' }),
      children: section3(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '4', title: 'Date of employment' }),
      children: section4(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '5', title: 'Appointment' }),
      children: section5(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '6', title: 'Hours and days of work' }),
      children: section6(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '7', title: 'Salary' }),
      children: section7(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '8', title: 'Pension and retirement age' }),
      children: section8(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '9', title: 'Maternity' }),
      children: section9(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '10', title: 'Holidays' }),
      children: section10(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '11', title: 'Sickness' }),
      children: section11(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '12', title: 'Benefits' }),
      children: section12(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '13', title: 'Data protection, including the GDPR legislation.' }),
      children: section13(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '14', title: 'Notice of Termination of Employment by Employee (and consequences)' }),
      children: section14(),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '15', title: 'Notice of Termination by Employer and consequences.' }),
      children: section15(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '16',
        title: 'Pay following termination of employment (including accrued pay and commission due to, or deductions or clawback from, the Employee)',
      }),
      children: section16(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '17',
        title: 'Copyright',
      }),
      children: section17(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '18',
        title: 'Intellectual property',
      }),
      children: section18(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '19',
        title: 'Confidentiality',
      }),
      children: section19(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '20',
        title: 'Employer Obligations to Employee',
      }),
      children: section20(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '21',
        title: 'Employee Obligations to Employer',
      }),
      children: section21(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '22',
        title: 'Grievance procedure from Employee',
      }),
      children: section22(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '23',
        title: 'Dismissal, Warnings and Disciplinary.',
      }),
      children: section23(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '24',
        title: 'Training Clawback for Employees who train with the aim of obtaining a qualification',
      }),
      children: section24(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '25',
        title: 'Covid 19 clause.',
      }),
      children: section25(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '26',
        title: 'Defamation',
      }),
      children: section26(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '27',
        title: 'Restrictive Covenant',
      }),
      children: section27(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '28',
        title: 'Actions for breach of contract and conduct',
      }),
      children: section28(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '29',
        title: 'Entire agreement',
      }),
      children: section29(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '30',
        title: 'Severability',
      }),
      children: section30(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '31',
        title: 'Variation',
      }),
      children: section31(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '32',
        title: 'Third-party rights',
      }),
      children: section32(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '33',
        title: 'Governing law and Jurisdiction',
      }),
      children: section33(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '34',
        title: `Rider -Addendum to your Contract: Example of what's including in your role`,
      }),
      children: section34(),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        tab: '',
        title: `Signature`,
      }),
      children: signature(),
    },
  ],
})
