import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '32.1.',
            text: `No one other than a party to this agreement shall have any
            right to enforce any of its terms.`,
          },
        ]),
      ],
    },
  ]
}
