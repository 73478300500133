import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '34.2.',
            text: `All title checkers (whether you are in the pool or not or
              check your own title as fee earner, must title check in accordance
              with the AVRillo legal and practical guidelines and protocols on title
              checking. These guidelines are set out in writing, within the workflow
              systems and trained on.`,
          },
          {
            number: '34.3.',
            text: `The pool system is where title checkers in that pool
            equally and fairly between themselves take on titles for checking and
            dealing with as set out in our training, and examples are summarised
            below. More information will be provided during training and in our
            guidelines.`,
          },
          {
            number: '34.4.',
            text: `If you feel any part of the guideline is incorrect or you
            object to following, simply, immediately notify the partners. This
            will allow them to consider your objections and analyse if they are
            reasonable; if reasonable, we shall adjust our guidelines; if not, we
            shall explain and re-clarify why our guideline takes need to be
            followed to assist the client overriding objective to move as per the
            CLC and Law Society outcome focused guidelines all lawyers must work
            to when working for a client.`,
          },
          {
            number: '34.5.',
            text: `The guidelines shall be followed to make your title
            checking consistent with all lawyers within the firm, so there is
            consistency, which reduces negligence, increases the service, and also
            complies with insurers and regulatory guidelines to help reduce the
            risk of rogue title checkers incorrectly checking title. It will also
            make your job an easier one as the guidelines contain answers on most,
            if not all, title checking issues. If some are missing, you need to
            notify the partners immediately. If you find that guidelines, or case
            management systems, or workflows, or precedent letters etc. are silent
            on a particular title checking issue, please notify the partners
            immediately and ask them for their guidelines and protocol concerning
            that issue that had not been covered.`,
          },
          {
            number: '34.6.',
            text: `Your role is to title check and look for legal issues
            that affect title and provide options to resolve those legal issues,
            where available, in accordance with our guidelines. These are based on
            SRA, CLC principle of the conveyancer acting to the benefit of the
            client by considering the client's overriding objective, which you
            must follow (i.e., for the client to move, with good title, and not
            being entrenched in unnecessary delays and arguing over options which
            the client may or may not choose to follow, providing you give them
            that choice, it will be theirs to make (if a cash buyer) and which
            options must be subject to the limitations and rules applied by the UK
            Finance Act handbook (CML) and lender conditions if a lender exists.`,
          },
          {
            number: '34.7.',
            text: `This means that not only must you report on title to them
            on any legal title issues, but you must also provide practical options
            they are allowed to take, if available. You must advise on the
            cost-benefit analysis of the choices, e.g., where for example, a
            practical solution exists by the client taking a view, as opposed
            following a pure legal option (i.e., the client may want to pay for
            indemnity insurance rather than pursue retrospective consent). The
            guidelines and system provide these options in their PSO (potential
            sign off section in the system). You must also explain to the client
            the risk of delay by choosing one option over the other.`,
          },
          {
            number: '34.8.',
            text: `When providing options, you must set out consequences of
            each option, what the conveyancing protocols suggest in respect of
            that option, including if the norm is not to take that risk, but
            ultimately explaining the client has the ultimate choice as they are,
            for example, buying the property in what is a essentially a commercial
            transaction to purchase residential property (i.e., which they have
            chosen to buy, have valued and which only they will know if it is a
            bargain both in monetary value, emotional value and physical need for
            that property such in the right school catchment area or many other
            reasons the client has to make that purchase). It is not for you to
            stand in your client’s way by making a commercial choice for them, but
            it is for you to find issues, if any, point these out, their
            consequences, and allow them to make that choice, with all the facts
            available and understood by them.`,
          },
          {
            number: '34.9.',
            text: `The only exception to the clients' freedom of choice to
            take a risk and sign off is if the client has a third-party
            limitation, E.g., where the buyer client is buying with the assistance
            of a loan from their lender. In which case, you must notify the client
            that the lender conditions and/ or UK Finance Act handbook (CML)
            prevent taking those options otherwise open to them if they were a
            cash buyer. As such, they cannot sign off on lender condition
            limitations. If the client continues to instruct you steps that would
            put them and you in conflict with the lender conditions, you must
            unless there is a lender, in which case their lender conditions etc.,
            must be followed, or you need to cease acting for the lender and
            notify the client before you notify the lender.`,
          },
          {
            number: '34.10.',
            text: `You can only proceed to exchange and complete if the
            client has signed off to confirm they have understood and accepted
            that risk and consequences of risk, including financial loss
            potentially. You must point out the consequences of those risks (in
            writing, in straightforward, direct writing, be very clear on the
            risks etc.).`,
          },
          {
            number: '34.11.',
            text: `<u>
              You must use the day-to-day workflows in the case management system,
              training, and guidelines. Example of these include:
            </u>`,
          },
          {
            number: '',
            text: `<ul>
            <li>
              Raising the second set of additional enquiries via the case
              management (e.g., AE2 maintenance screen)
            </li>
            <li>
              Importing in replies from the seller solicitors etc. in respect of
              additional enquiries, into the case management system
            </li>
            <li>
              Marking all potential PSO’s (potential sign offs for the client to
              consider taking a view on, leaving the choice to them) and then re
              marking these as SO (sign offs) if the client has instructed us
              that they want to take a view on those PSO’s or other matters.
            </li>
            <li>
              Marking all the correct fields, these include sending internal
              proclaim messages, inserting notes in notes sections and all other
              case management system work and workflows
            </li>
            <li>
              Pressing the correct workflow buttons when running title checking
              related matters
            </li>
            <li>
              Using our precedent letters, but if you if you wish to change them
              to give reasons so we can make changes to all precedent letters on
              the case management system, not just bespoke each time you run
              that precedent letter or workflow
            </li>
            <li>
              Entering into our case management system all PSO's and entering
              SO’s.
            </li>
            <li>
              where there is no other system in place already, then for you to
              log the mortgage offer; searches and review them.
            </li>
            <li>Correspond with lender </li>
            <li>Log valuation and client survey checks </li>
            <li>
              Check the replies/deal with any indemnity quotes and go back to
              SSL
            </li>
            <li>
              Prepare for and draft the Report on Title, including reviewing any
              searches not yet already received, or mortgage offer not already
              reviewed etc. and reporting on the same if needed/
            </li>
            <li>Sign the COT </li>
            <li>
              Speak to and/or assist fee earners in calls and correspondence
              with seller solicitors or clients or agents, where for example the
              issues are complex, or you have spent time understanding these,
              and therefore easier for you to explain etc.
            </li>
            <li>
              To liaise with the fee earners and speak openly on the title
            </li>
            <li>
              Depending on system chosen you shall either a), limit your title
              check involvement up to dealing with AE 2’s (potentially AE 3’s) ,
              b) same as (a) but also including up to the report on title and
              any other issues which may arise, or c) a hybrid mixture between
              the two. This will be dependent on workloads and resources
              available.
            </li>
          </ul>`,
          },
        ]),
      ],
    },
  ]
}
