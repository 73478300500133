export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            children:
              '1.1. Please indicate who has repaired, or treated as belonging to them, each of the boundaries. Identify each boundary as if you were looking at the property from the road',
            attrs: {
              class: 'text-sm mb-4 mt-4',
            },
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 50,
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(1.1.1) On the left?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.left`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: ['Seller', 'Neighbour', 'Shared', 'Not known'],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(1.1.2) On the right?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.right`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: ['Seller', 'Neighbour', 'Shared', 'Not known'],
                      },
                    ],
                  },
                  {
                    $cmp: 'ElFormItem',
                    props: {
                      label: '(1.1.3) On the rear?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.rear`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: ['Seller', 'Neighbour', 'Shared', 'Not known'],
                      },
                    ],
                  },
                  {
                    $cmp: 'ElFormItem',
                    props: {
                      label: '(1.1.4) At the front?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.front`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: ['Seller', 'Neighbour', 'Shared', 'Not known'],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
