import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(8.1) Do you have to contribute towards the shared cost of a jointly-used service such as a shared driveway, road, parking area, garden or drain?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.shared_cost`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.shared_cost === 'yes'`),
            $cmp: 'el-form-item',
            props: {
              label:
                '(8.1.1) Please give details including who collects payments and organises the work, the amount of the payments in the last year and whether they are regular payments or only when maintenance work is required',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.shared_cost_details`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
