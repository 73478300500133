import DatePicker from '~/schemas/components/date-picker.js'
import RadioGroup from '~/schemas/components/radio-group'
import BankDetails from '~/schemas/components/sof/source-of-funds-sections/bank-details'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (data, clientName) {
  return {
    $el: 'div',
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
        },
        children: [
          TextInput(`$: "How much inherited money is " + ${clientName} + " using to purchase the property?"`, data + '.how_much_money', 'number', true, {
            validation: 'required',
          }),
        ],
      },
      RadioGroup(`$: "Is the inherited money already in an account in your name: " + ${clientName} + "?"`, data + '.is_money_in_account', true),
      {
        $el: 'div',
        attrs: {
          class: 'section-indent',
        },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'mb-8',
            },
            if: '$formData.data.' + data + '.is_money_in_account === "yes"',
            children: [...BankDetails(data + '.bank_details')],
          },
          {
            $cmp: 'el-form-item',
            props: { required: true },
            attrs: {
              class: 'mb-8',
            },
            if: '$formData.data.' + data + '.is_money_in_account === "no"',
            children: [
              Title(
                'Please obtain a letter (on their letterhead) from the solicitor acting on the provide to confirm the amount of money they will send to you (so you can then transfer to us)',
                'mb-4',
              ),
              {
                $formkit: 'file-upload',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: `${data}.letter_from_solicitor.files`,
                fileName: `${data}.letter_from_solicitor.files`,
              },
            ],
          },
        ],
      },
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
        },
        children: [
          {
            $el: 'div',
            children: [
              TextInput('From whom did you inherit?', data + '.who_did_you_inherit_from', '', false, { validation: 'required' }),
              DatePicker(
                'What is the exact or approximate date of inheritance distribution?\n' +
                  '(i.e., what date will the inherited money reach your account approximately)',
                data + '.date_of_inheritance',
                true,
              ),
            ],
          },
        ],
      },
    ],
  }
}
