export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50, class: 'mt-6' },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $formkit: 'el-checkbox-group',
            attrs: {
              class: 'flex flex-inline',
            },
            name: `${parentPath}.cyber_security_guarantee`,
            options: [{ label: 'Cyber security guarantee £97 plus vat ', value: 'accepted' }],
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              'In an era of rising cybercrime, safeguarding your financial transactions is more important than ever. Despite you taking every precaution, your personal device vulnerabilities might still expose you to significant risks, including severe financial losses.',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              'To offer you peace of mind, our optional protective service relates to a situation where during the course of your transaction, due to a cyber-attack on your equipment, you to send money to a fraudsters account rather than send that money to us. If that happens, we commit to paying you £1000.00 (one thousand pounds and not the value of the amount you sent), regardless of the amount that you sent to the wrong account, helping you in a digital world fraught with risks.',
          },
        ],
      },
    ],
  }
}
