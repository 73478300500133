<template>
  <article class="prose max-w-full text-sm">
    <h2 class="text-sm font-semibold">Instructions to you are the seller of your leasehold property.</h2>

    <p>
      You, the seller (including all joint owners if more than one) of the leasehold property you are selling, must complete this form for the buyer’s
      conveyancers to progress their purchase. The same duties and obligations are imposed on you as are set out in the Property Information Form, which you
      also have to complete for the buyer’s conveyancers. The landlord is the holder of the freehold of the property you are selling, and they may or may not
      have a management company appointed in the lease. Documents and information will need to be supplied by you to the buyer’s conveyancers, but your landlord
      and or management company will supply this for you, for a cost they will charge you as their leaseholder. Complete this form first, so we can ensure we
      send the correct request for documents and information, so when they return these, we can send them to your buyer’s conveyancers.
    </p>
  </article>
</template>
