import identificationCheck from '~/schemas/components/common/identification-check'
import OccupationClients from '~/schemas/components/common/occupation-clients'
import proofOfAddress from '~/schemas/components/common/proof-of-address'

export default function (prop, clientName) {
  return [
    {
      $el: 'div',
      attrs: {
        class: 'mt-6 mb-6 text-black',
      },
      children: `$: "Please provide the below details for " + ${clientName}`,
    },
    identificationCheck(`${prop}.id_check`),
    {
      $el: 'hr',
      attrs: {
        class: 'my-6',
      },
    },
    proofOfAddress(`${prop}.proof_of_address`, `${prop}.id_check`),
    {
      $el: 'hr',
      attrs: {
        class: 'my-6',
      },
    },
    OccupationClients(`${prop}.occupation`),
  ]
}
