<script setup>
const props = defineProps({
  prependText: {
    type: String,
    default: '',
  },
  className: {
    type: String,
    default: 'text-xs',
  },
  appendText: {
    type: String,
    default: '',
  },
})

const store = useConveyancingCaseStore()

const clients = computed(() => {
  return store.clients
})
</script>

<template>
  <span :class="className">
    <span v-if="prependText">{{ prependText }}</span>
    <span v-for="(client, clientIndex) in clients" :key="clientIndex">{{ client.name }}{{ clients.length === clientIndex + 1 ? '' : ', ' }}</span>
    <span v-if="appendText">{{ appendText }}</span>
  </span>
</template>

<style scoped></style>
