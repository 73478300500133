import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '34.2.',
            text: `The role also applies to the Business Development team,
            Client Services, Customer Services, Agent Services, 360 Role etc.
            which are interchangeable with the meaning of business development) is
            to introduce estate agents' referrers to the Company, manage them so
            as those agents quote, and those quotes convert to target live cases
            as follows.`,
          },
          {
            number: '34.3.',
            text: `To attend training, take notes, learn understand and
            practice our back-end script of USP’s, and closings, and how to use
            tonality and challenger type language, but not to read it word by word
            as all you need to do is use the relevant parts you master to get you
            to your target conversion, see below.`,
          },
          {
            number: '34.4.',
            text: `Your target is to introduce to the company 4 new estate
            agent suppliers a month, who will refer at least 5 cases a month, for
            the first 3 months to receive a commission (the 1st five live being
            converted within the first full month from the date after the referral
            fee agreement being signed. After which, the BDM shall maintain the
            required relationship with the agent to ensure the agent continues to
            convert at least 5 instructions a month, or at least 50% of the
            agent's one-third of their stock, whichever is the higher, or the
            agent (which belong to AVRillo) will be transferred to another
            relationship builder within the Company, either a BDM or another, and
            the BDM will no longer be paid a proportion of the quote converted to
            live case.`,
          },
          {
            number: '34.5.',
            text: `After the first 3 months. Your success in your role (and
              your ability to continue to earn commission on that agent commission)
              will be measured on the number of live instructions the introducer
              continues with, as follows.`,
          },
          {
            number: '34.5.(a).',
            text: `Five instructions a month at least per calendar
            month, whilst the agent business relationship is growing, in order for
            the BDM to start being paid a proportion of the quote conversion
            commission (shared with the person who converts it, e.g., of quote
            giver) on each quote that converts to a live case.`,
          },
          {
            number: '34.5.(b).',
            text: `An increase in the amount earned in commission by
            reaching your target of at least an increase your live number of new
            instructions each month to 65% of the agent’s one-third stock. For
            example, so if the agent has 30 stock, the BDM must ensure that the
            agent provides at least 5 instructions a month (1/3rd of 30 stock is
            10 instructions, say at @ 65% is 6 instructions, but we will accept 5
            at the start of the relationship, 50%, with that agent).`,
          },
          {
            number: '34.5.(c).',
            text: `A further increase in the amount earnt in commission,
            being paid commission on more new instructions by firstly, increasing
            new instructions at between 65% to 85% of the agents one third stock
            levels; secondly, convincing the agent to instruct directly, e.g., by
            a quote app; thirdly; thirdly, to be instructed on both ends and
            fourthly, the individual client instruct on each of their related sale
            or purchased. Overall, you can increase the 10 cases to 40 live
            instructed cases. For example, if the 1/3rd stock gives 10
            instructions, and if we act on both ends, this will increase to 20
            instructions, and if each has a related case in the chain, that will
            increase to another 20 instructions, making a total of 40 cases. The
            benefit is this will allow you to earn part commission on those cases,
            shared with the quote giver who converts that quote or with the quote
            app etc.`,
          },
          {
            number: '34.5.(d).',
            text: `You will need to keep fully managing the estate agent
            to ensure numbers increase not only do not drop but actually build and
            increase work by bringing on live instructions beyond the one third
            stock principle, as described aforesaid, by managing the agent. This
            includes looking after your allocated agent, weekly meetings by teams
            to discuss openly with your agent as to what is working well, not
            working well; to liaise with the allocated lawyer to ensure they
            follow our guidelines and communication standards, our PSO’s, orange
            sheets, conferences with the agent, client, other lawyers, to ensure
            the agent’s complaints or issues are dealt with, if reasonable. You
            explain to the agent if their expectations are unreasonable. To act as
            the middle person between the conveyancer and the agent on occasions
            of a misunderstanding or of the conveyancer is unreasonable delaying
            or not communicating to the company guidelines and expectations or not
            working on priority cases as per our four-folder system. on occasions
            to be available to the agent to take calls or network with them out of
            hours if reasonable expectation of a BDM role. To also keep building
            rapport and business relationships, keep challenging as per the
            challenger book principles if instructions drop or do reach their
            potential of at least 5 instructed live cases a month, or 65% of the
            one third stock levels (whichever is the higher) over the initial 12
            months, (or even higher % of stock if we act on both ends for the
            agent clients and/or on their own related sale or purchase or others
            in the chain). To work closely with the AVRillo team, including the
            conveyancers, quote givers and helping them covet quotes, especially
            the fort 5, which you should quote on to experience the strength of
            the leads sent to you by the new agent.`,
          },
          {
            number: '34.5.(e).',
            text: `To help build relationships of trust so the agent can
            have more confidence to allow a 90% conversion rate of quotes they
            send to us, thereby converting more live cases from that agent.`,
          },
          {
            number: '34.5.(f).',
            text: `To help deal with complaints from your agents’
            clients, to keep NPS (Net promoter score) at 70% for clients referred
            by your estate agent/s.`,
          },
          {
            number: '34.5.(g).',
            text: `To network and promote the Company by, for example,
            doing demos on our portal, software such as the agent and client
            portals as well as the reporting functions, to attend conferences, or
            entertaining events etc., and an ambassador, only if reasonably
            expected of a BDM. To attend the agent's office and listen and
            feedback to the Company etc.`,
          },
          {
            number: '34.5.(h).',
            text: `After the initial 12 months, the commission may
            reduce or stop depending on how strong your relationship is at the end
            of the 12 months, how much new live instructions you can bring on by
            keeping that agent under your supervision and management, and how you
            are growing that agent and its conversion as referred to above.`,
          },
          {
            number: '34.5.(i).',
            text: `Note, for a live instructed case to be paid on by way
            of commission, it means the sales memo, as well as the instruction
            form, is received in, and the work has started by the first letters
            being sent on it by AVRillo.`,
          },
          {
            number: '34.5.(j).',
            text: `Any commission payable will not be payable in
            arrears. So, for quotes converted to live cases, this is paid at the
            end of the following month so as to calculate the commission
            calculation fully based on instructions to the end of the previous
            calendar date. For commission paid on a new agent, this will be paid
            at the end of the month following the expiry of the 3-month initial
            period with the agent.`,
          },
        ]),
      ],
    },
  ]
}
