import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import OtherFundsClient from '~/schemas/components/sof/source-of-funds-sections/other-funds-client'

export default function () {
  function generateCondition() {
    const keys = ['client', 'client2', 'client3', 'client4']
    const condition = keys.map((key, index) => {
      const lastKey = keys.length - 1 === index
      let con = `(($formData.data.source_of_funds.other_funds.${key}_using_other_funds === "no" && $conveyancingCase.${key}.name !== undefined) || $conveyancingCase.${key}.name === undefined)`
      if (!lastKey) con += ' && '
      return con
    })

    return condition.join('')
  }

  return {
    $el: 'div',
    props: { title: 'Other funds' },
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      TitleSectionRadioGroup('12', 'Other Funds', 'source_of_funds.other_funds.using_other_funds', [
        {
          $el: 'div',
          attrs: {
            class: 'section-indent',
          },
          if: '$formData.data.source_of_funds.other_funds.using_other_funds === "yes"',
          children: [
            OtherFundsClient('$conveyancingCase.client', 'client_using_other_funds', 'client_other_funds_details', generateCondition()),
            OtherFundsClient('$conveyancingCase.client2', 'client2_using_other_funds', 'client2_other_funds_details', generateCondition()),
            OtherFundsClient('$conveyancingCase.client3', 'client3_using_other_funds', 'client3_other_funds_details', generateCondition()),
            OtherFundsClient('$conveyancingCase.client4', 'client4_using_other_funds', 'client4_other_funds_details', generateCondition()),
            {
              $el: 'div',
              attrs: {
                class: 'text-red-500 mt-4',
              },
              if: generateCondition(),
              children: [
                {
                  $el: 'div',
                  children: "You should select yes to at least one of the client's question above.",
                },
                {
                  $formkit: 'hidden',
                  name: 'source_of_funds.other_funds.not_allowed',
                  validation: 'required',
                },
              ],
            },
          ],
        },
      ]),
    ],
  }
}
