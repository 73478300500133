import SectionCard from '~/components/SectionCard.vue'
import TitleWithClientsAppended from '~/components/TitleWithClientsAppended.vue'
import { useProps } from '~/composables/useProps'
import NameOnDeedsClient from '~/schemas/components/pid/potential-issues-sections/name-on-deeds-client'
import RadioGroup from '~/schemas/components/radio-group'

function generateCondition() {
  const keys = ['client', 'client2', 'client3', 'client4']
  const condition = keys.map((key, index) => {
    const lastKey = keys.length - 1 === index
    let con = `(($formData.data.potential_issues.name_on_deeds.${key}_land_registry_owner === "yes" && $conveyancingCase.${key}.name !== undefined) || $conveyancingCase.${key}.name === undefined)`
    if (!lastKey) con += ' && '
    return con
  })

  return condition.join('')
}
export default function () {
  return {
    $el: 'div',
    props: { title: 'Names on deeds' },
    children: [
      {
        $cmp: SectionCard,
        props: useProps({ tab: '1', title: 'Names on deeds', sectionClass: 'mb-0' }),
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'ml-6',
            },
            children: [
              {
                $cmp: TitleWithClientsAppended,
                props: useProps({ text: 'The names on the deeds are showing as: ', class: 'required-label' }),
              },
              RadioGroup('', 'potential_issues.name_on_deeds.name_on_needs_correct', true),
              {
                $el: 'div',
                if: '$formData.data.potential_issues.name_on_deeds.name_on_needs_correct === "no"',
                attrs: {
                  class: 'section-indent',
                },
                children: [
                  NameOnDeedsClient(
                    '$conveyancingCase.client',
                    'client_land_registry_owner',
                    'potential_issues.name_on_deeds.client_land_registry_owner_details',
                    generateCondition(),
                  ),
                  NameOnDeedsClient(
                    '$conveyancingCase.client2',
                    'client2_land_registry_owner',
                    'potential_issues.name_on_deeds.client2_land_registry_owner_details',
                    generateCondition(),
                  ),
                  NameOnDeedsClient(
                    '$conveyancingCase.client3',
                    'client3_land_registry_owner',
                    'potential_issues.name_on_deeds.client3_land_registry_owner_details',
                    generateCondition(),
                  ),
                  NameOnDeedsClient(
                    '$conveyancingCase.client4',
                    'client4_land_registry_owner',
                    'potential_issues.name_on_deeds.client4_land_registry_owner_details',
                    generateCondition(),
                  ),
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-red-500 mt-4',
                    },
                    if: generateCondition(),
                    children: [
                      {
                        $el: 'div',
                        children: 'All your names are already in the land registry. Please select "No" to question 1',
                      },
                      {
                        $formkit: 'hidden',
                        name: 'potential_issues.name_on_deeds.not_allowed',
                        validation: 'required',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
