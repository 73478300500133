export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          children: [
            {
              $el: 'div',
              children: [
                {
                  $el: 'div',
                  children: 'Between',
                  attrs: {
                    class: 'text-xs mt-4 mb-4',
                  },
                },
                {
                  $el: 'span',
                  children: 'The Employer: ',
                  attrs: {
                    class: 'text-xs mt-4 mb-4 font-bold',
                  },
                },
                {
                  if: '$employeeCase.company_code == "1"',
                  $el: 'span',
                  children: 'AVRillo LLP',
                  attrs: {
                    class: 'text-xs mt-4 mb-4 font-bold',
                  },
                },
                {
                  if: '$employeeCase.company_code == "2"',
                  $el: 'span',
                  children: 'IAVRillo R&D Ltd',
                  attrs: {
                    class: 'text-xs mt-4 mb-4 font-bold',
                  },
                },
                {
                  $el: 'div',
                  children: 'And',
                  attrs: {
                    class: 'text-xs mt-4 mb-4',
                  },
                },
                {
                  $cmp: 'el-form-item',
                  props: {
                    label: 'The Employee:',
                    required: true,
                  },
                  children: [
                    {
                      $formkit: 'el-input',
                      name: 'employee_name',
                      inputType: 'text',
                      value: '$employeeCase.name',
                      validation: 'required',
                      validationMessages: {
                        required: 'This field is required',
                      },
                    },
                  ],
                },
                {
                  $cmp: 'el-form-item',
                  props: {
                    label: 'The Commencement Date of this Contract is your start date:',
                    required: true,
                    class: 'mt-4',
                  },
                  children: [
                    {
                      $formkit: 'el-date-picker',
                      name: 'commencement_date',
                      value: '$employeeCase.start_date',
                      validation: 'required',
                      validationMessages: {
                        required: 'This field is required',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
