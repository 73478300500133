import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'text-md mt-4 mb-4 font-semibold bg-gray-100 p-3 text-gray-600 italic',
                    },
                    children: 'Part B: Information that should be established for all properties.',
                  },
                ],
              },
            ],
          },
          {
            $cmp: 'ElRow',
            props: {
              gutter: 20,
            },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(15.3) The property is',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.property_type`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        value: '$saleReadyPack.request.propertyType',
                        className: 'flex flex-col',
                        options: [
                          { label: 'a house or bungalow that is detached', value: 'detached' },
                          { label: 'a semi-detached house', value: 'semi-detached' },
                          { label: 'a terraced house', value: 'terraced' },
                          { label: 'a flat', value: 'flat' },
                          { label: 'studio / maisonette', value: 'studio_maisonette' },
                          { label: 'other', value: 'other' },
                        ],
                      },
                    ],
                  },
                  {
                    if: `${isTrueCondition(`${parentPath}.property_type === 'flat'`)} || ${isTrueCondition(`${parentPath}.property_type === 'studio_maisonette'`)}`,
                    $el: 'div',
                    children: [
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(15.3.1) Please confirm what floor of the building the property is situated',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-radio-group',
                            name: `${parentPath}.property_floor`,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                            options: ['Ground', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20+'],
                          },
                        ],
                      },
                      {
                        $cmp: 'el-form-item',
                        props: {
                          label: '(15.3.2) Please confirm if the property is situated on top of a commercial premises, such as a shop, restaurant, cafe etc',
                          required: true,
                        },
                        children: [
                          {
                            $formkit: 'el-input',
                            name: `${parentPath}.property_situated_on_commercial_premises`,
                            inputType: 'textarea',
                            rows: 6,
                            validation: 'required',
                            validationMessages: {
                              required: 'This field is required',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
