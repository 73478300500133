import TitleWithClientsAppended from '~/components/TitleWithClientsAppended.vue'
import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import RadioGroup from '~/schemas/components/radio-group'
import TextInput from '~/schemas/components/text-input'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Are you also buying a property?' },
    children: [
      TitleSectionRadioGroup('5', 'Are you also buying a property?', 'potential_issues.buying_property.buying_property', [
        {
          $el: 'div',
          attrs: {
            class: 'section-indent el-form--label-top',
          },
          if: '$formData.data.potential_issues.buying_property.buying_property === "yes"',
          children: [
            RadioGroup('Is AVRillo acting on your purchase?', 'potential_issues.buying_property.avrillo_buyer', true),
            {
              $el: 'div',
              attrs: {
                class: 'section-indent',
              },
              if: '$formData.data.potential_issues.buying_property.avrillo_buyer === "no"',
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: {
                    label: 'Are you buying in exactly the same names?',
                    required: true,
                  },
                  children: [
                    {
                      $formkit: 'el-radio-group',
                      name: 'potential_issues.buying_property.buying_in_same_name',
                      validation: 'required',
                      attrs: {
                        class: 'mt-2',
                      },
                      error: {
                        if: '$formData.data.potential_issues.buying_property.confirm_buying_names === "no"',
                        then: true,
                      },
                      options: [
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                      ],
                    },
                  ],
                },
                {
                  $el: 'div',
                  attrs: {
                    class: 'section-indent',
                  },
                  if: '$formData.data.potential_issues.buying_property.buying_in_same_name',
                  children: [
                    {
                      $el: 'div',
                      if: '$formData.data.potential_issues.buying_property.buying_in_same_name === "no"',
                      children: [
                        TextInput(
                          'Please insert the name of the lawyer acting on your purchase for you.',
                          'potential_issues.buying_property.lawyer_name',
                          '',
                          false,
                          { validation: 'required' },
                        ),
                        TextInput(
                          'Insert details of the person you are buying with, in the box below',
                          'potential_issues.buying_property.details_of_person_buying_with',
                          'textarea',
                          false,
                          { validation: 'required' },
                        ),
                      ],
                    },
                    {
                      $el: 'div',
                      if: '$formData.data.potential_issues.buying_property.buying_in_same_name === "yes"',
                      children: [
                        {
                          $el: 'div',
                          children: [
                            {
                              $cmp: TitleWithClientsAppended,
                              props: {
                                text: 'Are you buying in these names: ',
                                class: 'required-label',
                              },
                            },
                            {
                              $formkit: 'el-radio-group',
                              validation: 'required',
                              name: 'potential_issues.buying_property.confirm_buying_names',
                              attrs: {
                                class: 'mt-2',
                              },
                              error: {
                                if: '$formData.data.potential_issues.buying_property.confirm_buying_names === "no"',
                                then: true,
                              },
                              options: [
                                { value: 'yes', label: 'Yes' },
                                { value: 'no', label: 'No' },
                              ],
                            },
                          ],
                        },
                        {
                          $el: 'div',
                          if: '$formData.data.potential_issues.buying_property.confirm_buying_names === "no"',
                          attrs: {
                            class: 'text-red-500 mt-2',
                          },
                          children: [
                            {
                              $el: 'div',
                              children: 'If you are not buying in these names, then you should select "No" to the previous question.',
                            },
                            {
                              $formkit: 'hidden',
                              name: 'potential_issues.buying_property.not_allowed',
                              validation: 'required',
                            },
                          ],
                        },
                        {
                          $el: 'div',
                          if: '$formData.data.potential_issues.buying_property.confirm_buying_names === "yes"',
                          attrs: {
                            class: 'section-indent',
                          },
                          children: [
                            TextInput(
                              'Please insert the name of the lawyer acting on your purchase for you.',
                              'potential_issues.buying_property.lawyer_name',
                              '',
                              false,
                              { validation: 'required' },
                            ),
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]),
    ],
  }
}
