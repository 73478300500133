import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '22.1.',
            text: `If you, the Employee, want to raise such an issue by email to FLG and
            your team leader, but copy in the partners. If your issue is with regard to a member
            of flg, or one of the partners, then notify as aforesaid but if you feel uncomfortable
            including the person you have the issue with, in the email, then exclude that person but
            include the reminder in your notification. Your notice must set out your issues and
            preferred outcome. You will try to send you a preliminary reply within 7 working days.
            If your issue is urgent, please email, explaining why the review must be expedited. If
            the reply to you from the aforesaid person/s do not resolve your issue you have raised,
            then please notify why within 2 days to the partners. If you are still not satisfied, email
            your reasons to Angelo Piccirillo and Tony Piccirillo as the practicing equity partners,
            setting out reasons you are not satisfied with the aforesaid reply and your preferred outcome
            for consideration by them. Note. You are free to raise an issue or concern without fear as
            openness, honesty, wellbeing, and support are encouraged without fear of being judged.`,
          },
        ]),
      ],
    },
  ]
}
