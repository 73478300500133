import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '20.1.',
            text: `<u>To provide tools for you to perform your role,</u>
            including equipment, e.g., pc, phone.`,
          },
          {
            number: '20.2.',
            text: `<u>To carry out annual staff reviews,</u> if not more
            regularly.`,
          },
          {
            number: '20.3.',
            text: `<u>To provide feedback on your performance.</u>`,
          },
          {
            number: '20.4.',
            text: `<u>To listen to your feedback,</u> you provide to us
            providing you provide your honest comments on our feedback to you.`,
          },
          {
            number: '20.5.',
            text: `<b>To deal with issues or concerns raised by you as Employee. </b>
            <p>To try and help you with issues and/or problems you may have, we will consider
              those you notify to the partners (providing you refer these to the members of FLG
              and your team leader and copied to the partners). If you have worked at AVRillo for
              the statutory required length of employment (currently two years) to be eligible to
              a grievance procedure under law, then we you will access to one pursuant to the ACAS
              guidance, relevant at the time. Also see the Employee rights and obligations clause
              below. </p>`,
          },
          {
            number: '20.6.',
            text: `<b>To comply with the statutory requirements under law and this contract</b>
            regarding dismissal, warnings and disciplinary, depending on what rights you are entitled
            to under statute (including depending on your length of employment with AVRillo). `,
          },
          {
            number: '20.7.',
            text: `<b>To make the office procedure available to you as</b> contained in our office manual (historically
              known as the lexcel manual). This manual is contained on Talent LMS or by request to the
              partner and or flg. with regards to a warning. You will receive a warning if you are entitled
              to a warning under statute and your period of employment. Where you are entitled to a warning,
              as aforesaid, you will receive this warning from FLG or partner or your team leader. Note, there
              is no distinction between a written and verbal warning for the purposes of this contract. With
              regards to a dismissal notice, you will receive such information in that notice as is required
              to be given under statute and this sent by from FLG or a partner. You as the Employee will be
              informed as soon as possible of the date on which the employment contract will end. `,
          },
          {
            number: '20.8.',
            text: `<u>To help you help you work safely and fulfil your role providing sufficient supervision and monitoring.</u>
            This includes audit checks to help the Employee meet their reasonable standards required of their
            position and role in AVRillo. Also, to help the Employee be secure, including helping them be protected
            against fraud, cybercrime, and attack. Also, to help the Employee only use applications needed for them
            to carry out their job, such as our case management system, Land Registry, Lender Exchange etc., and to
            help them not use sites outside those as well as helping the Employee reduce the risk of the Employee
            downloading their own applications which they are now allowed to do. As such, the employer uses third
            party HR and technical monitoring software such as recording telephone calls using the AVRillo system,
            as well as mobiles etc., attached to the employer’s, as well as work carried out by the Employee on
            any laptop/pc used by the Employee, whether located locally or used to remotely log into the employer's
            remote working system, in their remote servers, to work on the employer’s work and cases. Further,
            resources to help the above can include monitoring keyboard strokes, web, and webcam conversations etc.,
            to ensure the Employee complies with AVRillo guidelines required of all AVRillo employees, including as
            above in this clause 21, to ensure AVRillo keep its reputation, ethical standards and ensure it complies
            with its regulatory body conditions. The employer has incorporated this policy within this contract for
            the above reasons, including in clause 22 below.`,
          },
          {
            number: '20.9.',
            text: `To comply with any other employer obligations set out within this contract.`,
          },
        ]),
      ],
    },
  ]
}
