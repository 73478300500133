import AlterationsSectionInformation from '~/components/pif/AlterationsSectionInformation.vue'
import section_4_1 from '~/schemas/components/pif/alternations-to-the-property/section_4_1.js'
import section_4_10 from '~/schemas/components/pif/alternations-to-the-property/section_4_10.js'
import section_4_2 from '~/schemas/components/pif/alternations-to-the-property/section_4_2.js'
import section_4_3 from '~/schemas/components/pif/alternations-to-the-property/section_4_3.js'
import section_4_4 from '~/schemas/components/pif/alternations-to-the-property/section_4_4.js'
import section_4_5 from '~/schemas/components/pif/alternations-to-the-property/section_4_5.js'
import section_4_6 from '~/schemas/components/pif/alternations-to-the-property/section_4_6.js'
import section_4_7 from '~/schemas/components/pif/alternations-to-the-property/section_4_7.js'
import section_4_8 from '~/schemas/components/pif/alternations-to-the-property/section_4_8.js'
import section_4_9 from '~/schemas/components/pif/alternations-to-the-property/section_4_9.js'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                {
                  $cmp: AlterationsSectionInformation,
                },
                section_4_1(parentPath),
                section_4_2(parentPath),
                section_4_3(parentPath),
                section_4_4(parentPath),
                section_4_5(parentPath),
                section_4_6(parentPath),
                section_4_7(parentPath),
                section_4_8(parentPath),
                section_4_9(parentPath),
                section_4_10(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
