import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '11.1.',
            text: `Health Scheme to help reduce sickness.`,
          },
          {
            number: '',
            text: `The Company is happy to enrol you in the independent Benenden Health
            scheme on commencement. Simply ask us as some Employees come with
            their own arrangements. We will pay this Benenden benefit for you, but
            you need to declare it. Note also, the provisions of the Social
            Security Contribution and Benefits Act 1992 relating to the payment of
            statutory sick pay will apply.`,
          },
          {
            number: '11.2.',
            text: `Mental wellbeing is important. The Company is fortunate
            that AVRillo staff are supportive of each other and only take genuine
            days off work because they understand that it would be unfair on their
            colleagues (who would need to cover during their absence) if they took
            what are defined in the Oxford dictionary as sickies
            <i>
              “a day when you say that you are ill and cannot go to work when it
              is not really true … to pull/throw/a sickie”.
            </i>
            Therefore, on the rare occasion that an Employee abuses their fellow
            Employees' trust in them by taking a ‘sickie’ then, unfortunately,
            this amounts to Misconduct. However, the Company is fully supportive
            of all Employees in their mental wellbeing; therefore, the Employee
            must instead be honest and direct to the Employer and notify FLG and
            the partners of the underlying reason for wanting the sickie to allow
            the Company to try and help. So, for example, the Company may
            potentially allow the Employee to use one of their holiday days at
            short notice, or work from home, or finish earlier, or not take as
            many calls, or cancel some appointments, if possible, etc.`,
          },
          {
            number: '11.3.',
            text: `Reporting sick - procedure:`,
          },
          {
            number: '',
            text: `To maintain the statutory sickness records and for us to notify
            clients and the team, you shall notify the Company on the morning you
            are sick as follows:`,
          },
          {
            number: '11.3.(a).',
            text: `Inform the management team (for this purpose that
              means your team leader and FLG and/or the partners) by email as soon
              as you know you will be absent.`,
          },
          {
            number: '11.3.(b).',
            text: `Before 9.05 am, please telephone the office to speak
            to your team leader to explain that you will be absent (do not leave
            this message by text or email). During that call you should include:`,
          },
          {
            number: '(b.i)',
            text: `The dates you expect to be off work, how many days you
            expect to be off work, and what date you think you will be back to
            work, so that the team lead can try to arrange short cover and manage
            the remainder of their team, and`,
          },
          {
            number: '(b.ii)',
            text: `The reason you are absent, and the nature of your
            problem. If you feel the reason is too confidential, then once you
            give notice in a) above, please ask to be put through to either one of
            the management team (FLG AND/OR THE PARTNERS). If you still feel
            uncomfortable giving the reason to them, then explain that to them.`,
          },
          {
            number: '(b.iii)',
            text: `Continue to notify the Company on every following sick
            day to help your colleagues plan your work during your absence. If you
            are absent for more than 5 working days, provide a doctor’s note.`,
          },
          {
            number: '11.4.',
            text: `To help wellbeing management, you need to arrange a
            return-to-work planning interview to allow hand back of work and to
            see if we can help you in any way going forward (for example, with GP
            appointments and wellbeing). Any three non-consecutive sick days in 4
            months will result in a wellbeing and planning meeting.`,
          },
          {
            number: '11.5.',
            text: `Sick pay, time off work for appointments etc. Apart from
            SSP, the Company is not obliged to pay for time taken off sick and any
            such absence (whether for the whole or part of the day) will be
            unpaid. To minimise disruption to your work colleagues and clients, we
            encourage you to book non-work appointments (including e.g., GP/Dental
            appointments or personal appointments) outside working hours, if
            possible, unless of course in case of an emergency. If this is not
            possible you must arrange cover for your work by your colleagues and,
            if possible, you should try to make up your time to reduce disruption
            to your work colleagues. (Note, the Partners are prepared to be
            flexible in respect of such requests).`,
          },
        ]),
      ],
    },
  ]
}
