import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import { useSchema } from '~/composables/useSchema'
import npsForm from '~/schemas/components/nps/nps-form'

export default useSchema({
  id: 'nps',
  title: 'NPS Survey',
  autosave: false,
  steps: false,
  signature: false,
  properties: [
    {
      $cmp: SectionCard,
      props: useProps(),
      children: npsForm(),
    },
  ],
})
