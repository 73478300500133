import SectionCard from '~/components/SectionCard.vue'
import innerComponents from '~/schemas/components/gift-declaration/common/inner-component-loop'

export default function amlDeclaration() {
  return {
    $cmp: SectionCard,
    props: useProps({ tab: 3, title: 'AML Declaration' }),
    attrs: {
      class: 'mb-4',
    },
    children: [
      {
        $el: 'div',
        attrs: { class: 'text-xs mt-4 mb-4' },
        children: 'I confirm I will comply with the statements made and steps set out in this AML (anti-money laundering) section of this declaration.',
      },
      ...innerComponents([
        {
          number: '3.1.',
          text: "I confirm that I will comply with passing the government's anti-money laundering laws (AML), introduced by the government to reduce the billions of pounds being laundered when money is transferred when a property is purchased in the UK.",
        },
        {
          number: '3.2.',
          text: 'I confirm I will download the Checkboard independent biometric and GDPR secure App created by Checkboard and made available to me by AVRillo. I understand that Checkboard carries out thousands of daily AML checks for users without the inconvenience of having to visit a lawyer in a face-to-face meeting to prove, firstly, I have legitimately accumulated the money I am transferring, and secondly, I am who I say I am.',
        },
        {
          number: '3.3.',
          text: 'I will have the following items ready to take a photograph off, using the App itself, to upload to the Checkboard App,',
        },
      ]),
      {
        $el: 'ul',
        attrs: { class: 'list-disc list-inside text-xs mt-4 indent-3' },
        children: [
          {
            $el: 'li',
            children: 'My passport (signature and photo page only),',
          },
          {
            $el: 'li',
            children: 'My bank statement of the account I have or will make the gift transfer from (dated within the last 3 months). ',
          },
        ],
      },
    ],
  }
}
