import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '34.12.',
            text: `<u>Targets, and commissions if applicable.</u>`,
          },
          {
            number: '34.12.(a).',
            text: `Time estimate targets for title checking are
            based on you checking a full title including the full contract
            pack, i.e., if received at one time.`,
          },
          {
            number: '34.12.(b).',
            text: `We will introduce a commission scheme, to those
            who want to participate, in respect of these targets and points
            system, which will be further explained as this system is embedded
            and works for all, which we estimate to take 1 to 3 months of its
            commencement, depending on how you work, and the pool work with
            these targets.`,
          },
        ]),
      ],
    },
  ]
}
