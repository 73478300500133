<template>
  <article class="prose max-w-full text-sm">
    <h2 class="text-sm font-semibold">Notice of Instructions to the Seller</h2>

    <ul class="flex flex-col gap-3">
      <li>
        The answers to these questions are requested by the buyer's conveyancers. Therefore, they must be replied to in your own words as owner in deeds (or as
        the legal representative, e.g. under a grant of probate or a power of attorney).
      </li>

      <li>Please reply “not known”, for example, if you do not know or are unsure of the answer.</li>

      <li>
        Your replies must be true to the best of your knowledge and belief, as known to you; this is because your replies will be relied upon by the buyer, as a
        legal representation. This means your replies may form part of your contract obligations to the buyer, and as such, the buyer may attempt to sue you for
        compensation if your replies are inaccurate or untrue. Your representations can include any conversations or emails between you and the buyer either
        directly and/or through the agent; this is why you should limit your communication to the buyer, via AVRillo, as your conveyancer.
      </li>

      <li>
        Please provide your conveyancer with any documents that can or may assist in answering questions, especially those referred to in this form. If not,
        please explain why you are not passing these documents to us, so we can forward your explanation to your buyer's conveyancers. Such documents should
        already be in your possession either from when you bought the property (i.e. sent to you by your previous conveyancer) or from when you should have
        obtained these documents during your ownership of the property. This means you must obtain any missing documentation in your own time, and add your own
        cost, or for you to ask, your previous conveyancer to send documents to you.
      </li>

      <li>
        If you later become aware of information that would affect or alter your original replies, you must immediately email AVRillo, who will then notify the
        buyer’s conveyancers.
      </li>

      <li>
        Where there is more than one seller, the parties will assume that your replies are correct to the knowledge and belief of all the owners, so all owners
        must check the replies given are to the best of their knowledge and belief, and sign the form.
      </li>
    </ul>

    <p>
      The aim of this form is to make clear to the buyer which items are included in the sale. It must be completed accurately by the seller as the form may
      become part of the contract between the buyer and seller.It is important that sellers and buyers check the information in this form carefully.
    </p>

    <h3 class="text-sm font-semibold">Definitions:</h3>

    <ul class="flex flex-col gap-3">
      <li>‘Seller’ means all sellers together where the property is owned by more than one person.</li>

      <li>‘Buyer’ means all buyers together where the property is being bought by more than one person.</li>
    </ul>

    <p>
      This form must be completed accurately by the seller, who should check through all answers before signing it. It may become part of the contract between
      the seller and the buyer.
    </p>

    <h3 class="text-sm font-semibold">In each row, the seller should tick the appropriate box to show whether:</h3>

    <ul class="flex flex-col gap-3">
      <li>The item is included in sale (‘Included’);</li>

      <li>The item is excluded from the sale (‘Excluded’);</li>

      <li>There is no such item at the property (‘None’);</li>
    </ul>

    <p>
      Where an item is excluded from the sale the seller may offer it for sale by inserting a price in the appropriate box. The buyer can then decide whether to
      accept seller’s offers.
    </p>
    <p>
      A seller who inserts a price in this form is responsible for negotiating the sale of that item directly with the buyer or through their estate agent. If
      the seller or buyer instructs their solicitor to negotiate the sale of such an item, there may be an additional charge.
    </p>
    <p>Sellers and buyers should inform their solicitors of any arrangements made about items offered for sale.</p>
    <p>If the sellers remove any fixture, fittings or any contents, the seller should be reasonably careful to ensure that any damage caused is minimized.</p>
    <p>
      Unless stated otherwise, the seller will be responsible for ensuring that all rubbish is removed from the property (including from the loft, garden
      outbuildings, garages and sheds), and that the property is left in a reasonable clean and tidy condition.
    </p>
  </article>
</template>
