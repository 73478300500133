import SectionCard from '~/components/SectionCard.vue'
import { useSchema } from '~/composables/useSchema'
import howAreWeDoingForm from '~/schemas/components/how-are-we-doing/how-are-we-doing-form'

export default useSchema({
  id: 'how-are-we-doing',
  title: 'How are we doing?',
  autosave: false,
  steps: false,
  signature: false,
  properties: [
    {
      $cmp: SectionCard,
      children: howAreWeDoingForm(),
    },
  ],
})
