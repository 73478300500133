<script setup>
import { ref } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import { useFormStatusStore } from '~/stores/form-status.js'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
  prop: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
  },
  min: {
    type: Number,
    required: false,
    default: 0,
  },
  max: {
    type: Number,
    required: false,
    default: null,
  },
  buttonText: {
    type: String,
    required: false,
    default: 'Add Item',
  },
  removeButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  slotParentClass: {
    type: String,
    default: 'p-4',
  },
})

const isEditable = computed(() => useFormStatusStore().isEditable)
const values = ref(props.items || [])

const atMinimum = computed(() => {
  return values.value.length <= props.min
})

const atMaximum = computed(() => {
  return props.max !== null && values.value.length >= props.max
})

function onAddItem() {
  values.value.push({})
}

function onRemoveItem(index) {
  values.value.splice(index, 1)
}
</script>

<template>
  <el-form label-position="top" size="large">
    <form-kit
      v-model="values"
      :disabled="!isEditable"
      :up-control="false"
      :down-control="false"
      :insert-control="false"
      :remove-control="false"
      :add-button="false"
      :name="`${prop}`"
      :min="min"
      type="repeater"
      class="cursor-pointer odd:bg-gray-50 hover:bg-gray-100"
    >
      <template #default="{ index, value }">
        <div class="relative flex w-full flex-col gap-px overflow-hidden rounded-lg">
          <el-button
            v-if="removeButton && !atMinimum"
            :icon="Delete"
            type="danger"
            circle
            class="absolute right-2 top-0"
            :disabled="!isEditable"
            @click="onRemoveItem(index)"
          />
          <div :class="slotParentClass">
            <slot :index="index" :value="value" :remove="onRemoveItem" />
          </div>
        </div>
      </template>
    </form-kit>

    <div class="flex items-center justify-end">
      <el-button v-if="!atMaximum" :disabled="!isEditable" type="primary" @click="onAddItem">
        {{ buttonText }}
      </el-button>
    </div>
  </el-form>
</template>
