import RadioGroup from '~/schemas/components/radio-group'

export default function (data, client) {
  return {
    $el: 'div',
    if: `${client}`,
    attrs: {
      class: 'mb-4',
    },
    children: [
      RadioGroup(
        `$: "Will " + ${client}.name + " use crypto-currency to contribute towards their purchase?"`,
        `${data}.using_crypto`,
        true,
        [
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ],
        '',
        `$formData.data.${data}.using_crypto === 'yes'`,
      ),
      {
        $el: 'div',
        if: `$formData.data.${data}.using_crypto === 'yes'`,
        attrs: {
          class: 'text-red-500 mt-2',
        },
        children: [
          {
            $el: 'div',
            children:
              'Sorry, in line with most other UK Conveyancers, we cannot accept Bitcoin or any crypto-currency due to warnings of fraud risk in the UK using the crypto-currency.',
          },
          {
            $formkit: 'hidden',
            name: `${data}.not_allowed`,
            validation: 'required',
          },
        ],
      },
    ],
  }
}
