export default function signatureDeclaration() {
  return {
    $el: 'div',
    children: [
      {
        $formkit: 'signature-input',
        if: '$routeStore.decodedToken.client == 1',
        name: 'signature_declaration_poa_1',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$routeStore.decodedToken.client == 2',
        name: 'signature_declaration_poa_2',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$routeStore.decodedToken.client == 3',
        name: 'signature_declaration_poa_3',
        validation: 'required',
        validationLabel: 'This field',
      },
      {
        $formkit: 'signature-input',
        if: '$routeStore.decodedToken.client == 4',
        name: 'signature_declaration_poa_4',
        validation: 'required',
        validationLabel: 'This field',
      },
    ],
  }
}
