import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(7.1) To your knowledge, has any part of the property (building / land / garden) ever flooded?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.property_flooded`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.property_flooded === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(7.1.1) What was the date of the flooding that occurred', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.property_flooded_date`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.property_flooded === 'yes'`),
            $cmp: 'el-form-item',
            props: { label: '(7.1.2) Where on the property was flooded?', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.property_flooded_location`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
