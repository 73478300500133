import { isTrueCondition } from '~/schemas/components/common/helpers.js'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $el: 'div',
            attrs: {
              class: 'formkit-label mt-4',
            },
            children: [
              {
                $el: 'span',
                children: '* ',
                attrs: {
                  class: 'text-red-400',
                },
              },
              '(4.5) Please set out details in box below for any other alterations not listed above, whether or not it was carried out by you:',
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $el: 'div',
            attrs: {
              class: 'text-sm mt-4 mb-4',
            },
            children: 'i). Any planning or Building Regulation control issues that need to be resolved.',
          },
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'yes'`),
            $cmp: 'el-form-item',
            attrs: {
              class: 'pl-8',
            },
            props: { required: true },
            children: [
              {
                $formkit: 'el-input',
                inputType: 'textarea',
                name: `${parentPath}.planning_regulation_issues`,
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.alterations_taken_place === 'no'`),
            $cmp: 'el-form-item',
            children: [
              {
                $el: 'div',
                children: '(4.5) Not required for 4.1',
                props: { class: 'text-sm italic' },
              },
            ],
          },
        ],
      },
    ],
  }
}
