const defaultOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
]
export default function (title, name, required = false, options = defaultOptions, additionalClasses = '', errorIf = 'false') {
  return {
    $cmp: 'ElFormItem',
    props: {
      label: title,
      required: required,
    },
    children: [
      {
        $formkit: 'el-radio-group',
        name: name,
        class: 'mt-2 ' + additionalClasses,
        validation: required ? 'required' : undefined,
        error: {
          if: errorIf,
          then: true,
          else: false,
        },
        options: [...options],
      },
    ],
  }
}
