import TextInput from '~/schemas/components/text-input'

export default function (data) {
  return {
    $el: 'div',
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
        },
        children: [TextInput('Amount?', `${data}.amount`, 'number', true, { validation: 'required' })],
      },
      TextInput(
        'Please provide full details of the source, (e.g Help to Buy ISA, or other source) and money to be received.',
        `${data}.details_of_source`,
        'textarea',
        false,
        { validation: 'required' },
      ),
    ],
  }
}
