<script setup></script>
<template>
  <div class="prose max-w-full p-6 text-[13px] text-xs">
    <p class="mb-2 font-bold">AVRillo - TERMS and Conditions of Business</p>
    <div class="mb-2 font-bold italic">Introduction</div>
    <p class="mb-1">
      Please read these terms prior to instructing AVRillo as they form the rules governing the contract between us. If you have any questions relating to these
      please ask before instructing and we would be happy to help.
    </p>
    <p>
      AVRillo is a legal firm specialising in conveyancing. This gives you peace of mind that we are good at what we do, but for extra reassurance, we provide
      for free,
      <span>
        a very high cover of (no less than) £2 million pounds in the unlikely event that we make a legal error causing you take a successful negligence action
        for loss against us.
      </span>
    </p>
    <p>
      We treat all clients equally and comply with Equality and Diversity principles (copy on request) which means we will deal with you in a way which
      encourages equality and respect for diversity and will for example not discriminate on the grounds of disability, age, sex, race, marital status or sexual
      orientation.
    </p>
    <p>
      AVRillo is a Limited Liability Company registered in England & Wales (No. OC3281345) at 257 Green Lanes, London, N13 4XE. The term “partner” denotes a
      “director” (full list available upon request). Principal (or Equity) Partners: Antonino Piccirillo (Solicitor) SRA ID.154187 and Angelo
      Piccirillo(Solicitor) SRA ID: 143982. VAT No. 706444054.
    </p>
    <p>
      Further, for your reassurance, we are authorised and regulated by the Council of Licenced Conveyancers under Practice Licence 3421. This also means our
      costs being transparent as identified in our 'Costs Menu' in section C below describing your case plan of work (we charge as basic costs for a
      straightforward conveyancing transaction) and additional optional costs (for any additional optional services you may want to request from us, or need,
      during the course of your case.
    </p>
    <p>
      <b>
        <u>Index to Terms and Conditions</u>
      </b>
    </p>
    <p>A. Cooling off period for you to cancel</p>
    <p>B. Money Laundering Regulations</p>
    <p>C. Costs Terms:</p>
    <div>
      <p>C(1) Estimate of Basic Costs for straightforward conveyancing transaction.</p>
      <p>C(2). Price/ Costs Menu (but only if you use optional additional services).</p>
    </div>

    <p>D. General Terms - Not relating to costs</p>
    <p>E. Miscellaneous Compliance and Financial matters</p>
    <p>F. More detailed explanation of Option 1 fuller set of searches</p>

    <div class="my-4 w-full border-t-[1.5px] border-dotted border-current"></div>
    <p></p>
    <p></p>
    <p>
      <b><u>A: COOLING OFF Period</u></b>
      <u>:</u>
    </p>
    <p>
      <u><b>A.1 Cooling off period.</b></u>
      The standard Statutory cooling off period is 14 days. As an additional benefit for AVRillo clients only, we will extend this to 60 days cooling off period
      as follows: If you instruct AVRillo without attending our office you are covered by the Consumer Protection (Distance Selling) Regulations. Notice of
      Right to Cancel: Under the Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013 you have the right to cancel this
      contract, in writing, within 14 days of you instructing us. This is called the cancellation period.
    </p>
    <p>
      If you are offered an additional special 60-day money back guarantee i.e. AVRillo’s extended cooling off period, in your estimate then your statutory 14
      days is extended to 60 days.
    </p>
    <p>
      <u><b>A.2 Cooling off period where you obtain a mortgage.</b></u>
      When you are buying with a mortgage your lender will need to give you at least 7 days to reflect on the offer before deciding whether you wish to accept
      it. Your lender or broker will advise you on how long this period is and whether they require you to formally accept the offer. By instructing us you are
      instructing us to bind you to the offer of the mortgage and to dispense with the reflection period if this is still active. If you do not wish for this
      risk to happen it is important to you let us know immediately. If your offer of mortgage is allowed to expire you will be required to apply for a new
      mortgage, as extensions to your existing offer may not be allowed. You should check and monitor the expiry date as we will not accept any liability for
      loss which may arise from the expiry of the offer.
    </p>
    <p>
      <b><u>B. MONEY LAUNDERING REGULATIONS.</u></b>
      Like all legal firms, and further enforced by our firm being regulated by the CLC, we are required to ask clients to send us their evidence to comply with
      their obligations under the UK Money Laundering Laws including identification and source of funds documents. If you do not pass, then UK law will
      unfortunately prevent lawyers from continuing to act for their clients. To assist you we will send our guidelines in the very first letter we send you
      after we open your file.
    </p>
    <p>
      <b>
        <u>C. COSTS TERMS | COSTS MENU</u>
      </b>
    </p>
    <p>Costs are divided into two parts.</p>
    <div>
      <p>C.1. Basic costs and</p>
      <p>
        C.2. Optional costs (but only if you need or use our additional optional services). These exclude 3
        <sup>rd</sup>
        party disbursements (fees paid to third parties such the land registry and therefore our not our costs). All costs below are exclusive of Government VAT
        tax of 20% so please allow for additional vat
      </p>
    </div>
    <p></p>
    <p>
      <b><u>Firstly, brief INDEX to costs</u></b>
    </p>
    <p>
      <b>
        <u>C.1</u>
        Basic Costs estimate for a straightforward freehold transaction:
      </b>
    </p>
    <div>
      <p>i. Basic Costs -what it includes? see case plan.</p>
      <p>ii. Free '60 Day Money Back Guarantee':</p>
      <p>iii. What to budget for?</p>
      <p>iv. Abortive costs.</p>
      <p>v. Discount.</p>
      <p>vi. Case plan identified for a straightforward transaction and including in the basic costs</p>
    </div>

    <p>
      <b>
        <u>C.2</u>
        Optional Services & associated costs menu:
      </b>
    </p>
    <div>
      <p>1. Common to Sale or Purchase</p>
      <p>2. Optional to sales only</p>
      <p>3. Optional to purchase only (including searches)</p>
      <p>4. Hourly Rate</p>
    </div>
    <p>
      <b><u>Secondly COSTS section - in more detail (as per the Index):-</u></b>
    </p>
    <p>
      <b>C.1 Basic Costs.</b>
    </p>
    <div>
      <p>
        i.
        <b><u>Basic costs.</u></b>
        are as estimated for a straightforward conveyancing transaction (which is identified in your case plan in section C.1.5 below). Anything outside is
        charged as additional optional services at prices set out in the C.2 costs menu. An analogy would be like ordering a basic fixed menu in a restaurant
        and then ordering additional drinks or food, which is charged as an additional item. In any event, if you want additional services, we will let you know
        in advance the cost so you can decide whether to use it or not.
      </p>
      <p>
        ii.
        <b><u>60-Day Money Back Guarantee.</u></b>
        For free, if within 60 days you change your mind, you will not be charged any of our basic costs. The only liability will be for third party
        disbursements.
      </p>
      <p>
        iii.
        <b><u>What you should BUDGET for your overall lawyer costs?</u></b>
        If you don't use additional services, then your budget is fixed in the estimate for basic costs. But no solicitor can predict if complications may or
        may not arise. If these arise, we divide our additional costs for those complications. Either at discounted fixed rates for those services falling
        within our C.2 menu of costs list of rates for complications or hourly rate if not within that list. If additional work is needed, we will let you know
        in advance the costs and your options. For example, if you have a lender (estimate between an additional £297 to £397); no move no fee (£97) etc. The
        national average of basic estimated costs is anywhere from £1200 to £3000 +vat and disbursements. If leasehold or if it is a freehold property with
        management company, as there is significant more work involved, even if the property is of low value, the costs are anywhere between £4000 and £7000
        +vat. With leasehold properties and freehold properties with a management company, please expect an additional £1500 of costs that are third party
        costs. So you could be looking to budget for an additional £2000 to £4000 to allow for contingencies, depending on your personal circumstances, how
        risk-averse you are or your lender may be, defects in the title of the property, or you do not tell us of issues at the start of the case.
      </p>
      <p>
        iv.
        <b><u>Abortive costs.</u></b>
        If your matter falls through you will liable to pay full basic costs unless you have ticked the no move no fee option on your instruction form in which
        case you a one-off pay £97 plus vat plus disbursements where the other party pulls out on your transaction (through no fault of your own).
      </p>
      <p>
        v.
        <b><u>Discount.</u></b>
        If you are given a discount if you instruct within 3 days, the discount will be revoked if you instruct late, after 3 days, and will be added back to
        your basic costs fee.
      </p>
      <p>
        vi.
        <b><u>Case plan</u></b>
        identified for what work is included in basic costs charge.
      </p>
    </div>

    <p>
      <b>Purchase CASE PLAN</b>
      for a straightforward registered freehold conveyancing. This will include the following work as part of your basic fixed costs: Corresponding with the
      seller lawyers (not third party such as matrimonial solicitor); preparing a letter to the sellers' lawyers requesting a draft contract, prepare a letter
      to the estate agents to acknowledge that we are acting on your behalf; calculating searches and requesting search payment, checking deeds when received
      from the sellers; checking the protocol questionnaires received from the sellers' lawyers, send a letter to you enclosing the contract and protocol
      questionnaires; checking the local search; check the water search; send a letter to you advising you that the searches are received; raising additional
      legal enquiries required for the property; liaising with you and the seller lawyers and taking instructions from you regarding these additional enquiries;
      reporting to you on your documents and deeds and allowing you to take a commercial decision on whether the documents available; arranging for documents
      required for you to sign to exchange contracts; send a letter to you requesting that you sign the contract, agreeing deposit, requesting and paying this
      in etc.; receiving your instructions of agreed completion date; exchange of contracts; prepare a letter to the sellers' lawyers confirming exchange and
      all relevant parties; prepare our standard requisitions on title; waiting and dealing with one set of replies to our requisitions on title from seller
      lawyers; prepare and send a draft transfer to the sellers' lawyers; calculate a completion statement (with any allowances etc.); send a letter to you
      giving you notice for the funds required to complete; work between exchange and completion to prepare for completion; completion day procedure, sending
      completion funds to the sellers' lawyers; send a letter to the sellers' lawyers to confirm completion; prepare a AP1 form; prepare and send the Land
      Registry application by post; acknowledge the form of discharge from the sellers' lawyers when received; send a DS1 to the Land Registry.
    </p>
    <p>
      <b>
        <u>Note:</u>
        Any work outside the case plan is charged as additional costs.
      </b>
      If you are not sure then ask your solicitor. So for example if leasehold you should add a leasehold supplement of £597, if you have a lender (and we are
      on their panel and allowed by them to act) then add £397. See list below for discounted additional costs. If the costs are not listed below, then an
      hourly charge applies. See C2.4.
    </p>

    <p>
      <b>Sale CASE PLAN</b>
      for a straightforward registered freehold conveyancing. This will include the following work as part of your basic fixed costs:- Corresponding with the
      buyer's lawyers (not third party such as matrimonial solicitor); We will order your land registry documents online from the land registry; we will prepare
      your conveyancing protocol forms including:- property information form, we will prepare fixtures form and overriding interest form; we will draft the
      formal contract of sale to be sent to the buyer lawyers; we will prepare a 'draft TR1' and deal with Requisitions on Title as well as Replies; we will
      send all contract papers to the buyer's lawyers with official copies from the land registry. We will collect and forward on to you ‘additional enquiries’
      and pass on your replies for free. If, however your replies to the additional enquiries are not satisfactory for the buyer's lawyers because you have
      missing documents or information such that they consider the title is defective and their client does not accept these and raises further sets of
      additional enquiries, then an additional fee of £277 applies after the second set of enquiries raised by the buyers lawyers. More detail in C.2(2).
    </p>
    <p>
      <b>
        <u>Note:</u>
        Any work outside the case plan is charged as additional work and therefore additional costs.
      </b>
      If you are not sure then ask your lawyer. So for example if leasehold you should add a leasehold supplement of £597.00. Other examples include if you have
      lender (and we are on their panel and AVRillo are prepared to act for that lender then add £297. See list below for discounted additional costs. If the
      costs are not listed below, then an hourly charge applies. See C2.4.
    </p>

    <p>
      <b><u>C.2. Optional costs for optional additional work.</u></b>
    </p>
    <p>
      <b>C.2(1) OPTIONAL- COMMON to Sale or Purchase (but only if required, no charge otherwise)</b>
    </p>
    <div>
      <p>
        i.
        <b><u>No Move No fee protection:</u></b>
        £97.00 No Move No Fee Protection-covers all your basic solicitor costs if the other party pulls out through no fault of your own. It does not cover you
        if you pull out or if your mortgage offer is not to your liking or your survey is insufficient.
      </p>
      <p>
        ii.
        <b><u>Fast track:</u></b>
        £97.00 Priority/Fast Track (24 working hours for a review by your lawyer). Where the average solicitor can take up to between 5 to 10 days to consider
        each piece of correspondence, our Priority Fast Track takes less than 24 working hours to consider each piece of correspondence. This reduces delays and
        increases your chance of moving from 63% to 91%. If you have not opted for Fast track, whilst we cannot guarantee that we will consider each piece of
        correspondence within 24 working hours, we will consider the correspondence within a reasonable timeframe. Generally, this can be anything between 2-10
        working days depending on the complexity of the correspondence.
      </p>
      <p>
        iii.
        <u><b>Lender work:</b></u>
        <u>&nbsp;On your sale</u>
        , £297 acting for any one lender or registered charge or restriction (if any) registered on your land registry office title, prior to completion,
        preventing you selling that property i.e., which without that additional work being done to release you, the buyer solicitors will refuse to advise
        their client to purchase; £397 acting for your individual lender if you are obtaining one on your purchase (failing which they will not lend to you). If
        you do not want us to work for your lender separately, then on your sale you need to discharge your mortgage and provide proof to the buyer, prior to
        exchange of contracts or appoint another lawyer to do this separate work for you.
        <u>On your purchase,</u>
        you need to appoint a separate lawyer to act for your lender. In each case, you need to notify us upon appointment so we can liaise with them whilst we
        are carrying out your actual conveyancing on your behalf (separate work from that of your lender). Note: Lender work is more onerous and has more
        obligations and work as it also needs that lawyer to not only comply with your instructions but also additional work for your lender who has to consider
        not only the mortgage offer etc but also the CML (Council of Mortgage Lender) special conditions which attach to their loan and for you to ensure these
        are discharged separately by your lawyer separate representation. Note: This work is only carried out if you ask us to either by ticking the box (or
        after by on a purchase naming us on your mortgage application as your lawyer, and we your mortgage offer posted to us and AVRillo down as your lawyers.
        Or you do not appoint a separate lawyer, and we have to give a personal promise to pay off your mortgage on a sale (called an undertaking required by a
        buyer solicitor, without which the buyer will not buy). Plus note, even if we do agree to take on your lender separate work, we cannot do it if we are
        not on their lender panel (no one lawyer is on lender panels, but we are on most of the major high street ones because of our expertise in this field).
        If not, you need to appoint a separate lawyer to act for your lender, and we will deal with your conveyancing part and liaise with them).
      </p>
      <p>
        iv.
        <b><u>Expedited outside the Law Society timing between exchange and completion:</u></b>
        The Expedited Completion is charged but only if you leave less than 15 working days (The Law Society is even longer with 20 working days) between
        exchange and completion. The actual fee depends on the number of working days you leave between exchange and completion 0 days - £227, 1-5 days - £197,
        6-10 days - £127, 11-14 days - £47. That means the greater the difference between the 20 working days in the Law Society contract the greater the
        charge. These charges are applied where you ask us to arrange the completion date, even if you do not actually move on that day. For example of you
        instruct us to arrange an exchange and completion on the same day, but you do not actually move on that day. We reserve the right to charge you for that
        (because we will have done work to arrange at completion on the day you originally requested) and again for the arranging a new date. Furthermore, where
        the time between exchange and completion change, we reserve the right to charge you at a rate relevant for the period of time which was expected to be
        left between exchange and completion.
      </p>
      <p>
        v.
        <b><u>Unusual Instruction Declarations:</u></b>
        These are chargeable at £87.00 plus vat per Declaration we draft on your behalf. These are for any unusual circumstances that are outside of normal
        instructions in a transaction and require additional Declarations to be drafted to protect your interests. Examples include, but are not limited to,
        requesting the unequal distribution of funds, `(such as asking for a split that is not 50/50), bankruptcy/insolvency declarations, no joint account
        declaration (such as asking for payment of the proceeds for a joint sale into one party’s sole account, transferring the proceeds from a joint sale to a
        purchase in one party’s sole name), payment to a third party lawyer on a related purchase declaration.
      </p>
      <p>
        vi.
        <b><u>Arranging indemnity insurance if there is defective</u></b>
        required as a solution if there is legal defect, which prevents sale and purchase (limited to £97 per policy)
      </p>
      <p>
        vii.
        <b><u>Leasehold:</u></b>
        £597.00 Leasehold supplement is applied for straightforward additional lease documents and reviewing a management pack. for the property. Some
        properties may require a second management pack or have substantially more documents, such as a headlease or additional planning documents due to the
        age of the property, than a standard leasehold and we reserve the right to charge further for these transactions. You will be informed if this applies
        during the transaction. Where the property is such that it may be impacted by the Building Safety Act, then we reserve the right to not act for you but
        if we do there will be an additional charge depending on several factors including the building your property is situated in and your lender (if you are
        obtaining a mortgage). See viii below for more details.
        <br />
        <b><u>Exception to</u></b>
        straightforward leasehold includes examples such as but not limited to: Off plan/ New Build; Variation of a lease; Grant of a new lease, multiple
        Management Packs; properties under ten years of age with multiple planning documents, headlease and title; deed of variation or a Lease Extension;
        Retention (*as to a 'Retention.' If this is suggested by the other lawyer, most of our clients prefer the cheaper time cost of agreeing an ‘Allowance’
        which is free of charge rather than being charged for additional time to deal with the retention (we advise not to spend that £397 for additional work,
        but just agree an allowance). If you are unsure, ask your conveyancer to explain.
      </p>
      <div>
        viii.
        <b><u>Building Safety Act.</u></b>
        Building Safety Act 2022. Does AVRillo still act on leasehold blocks on a purchase potentially at risk of being affected by the Building Safety Act
        2022? If we decide to do this additional work for you, what is the additional complexity of the work and the cost to you? See explanation and terms
        below:
        <div>
          <p>
            (1) Like other lawyers, AVRillo will no longer automatically take on a purchase in a leasehold block. If we do take this on, it will be to assist
            existing clients and only those buying in exceptional circumstances as set out below. The reason for UK lawyers stopping this work automatically is
            because of the changes in the law since Grenfell are so ambiguous and onerous on lawyers, that many lawyers are simply turning this work away due to
            the high risk of them being sued, which in turn leads to them being uninsurable, and closing down.
          </p>
          <p>
            (2) Why is it such a high risk? Since the tragedy of Grenfell, the government has spent years considering the severe consequences and risks to
            health and safety for owners of leasehold flats in apartment blocks. As a result, they passed the complex Building Safety Act 2022. Because the Act
            is so fresh, so full of risk of different interpretations and ambiguities as relating to conveyancing transactions and documentation to be obtained
            (which may not even exist or is missing), many lawyers are refusing to act on purchases of flats in any size of an apartment block.
          </p>
          <p>
            (3) Risk to lawyers and why lawyers prefer not to take on Building Safety Act work. Some lawyers are waiting until potentially a lawyer is sued,
            i.e., taken to the High Court by a client or their lender regarding their interpretation and application of this new untested Act. This is how
            legislation is often clarified in the English legal system. This process could take years, potentially until 2026 or later, unless, e.g., the
            government potentially issue further guidelines and therefore will cost the lawyer, taken to Court, its reputation and ability to practice as it
            did.
          </p>
          <p>
            (4) Will AVRillo act on leasehold blocks because of the risk under the Building Safety Act? We are no different to other lawyers, so we too, prefer
            not to act on leasehold apartment blocks because of the new government legislation, complexity, additional work required, and high risk of getting
            sued. There are legal firms who are deciding refusing to carry this out.
          </p>
          <p>
            (5) Exception of where our firm decide to act on a one to one basis, for purchase leaseholds at risk of the Building Safety Act 2022, and associated
            costs: As goodwill, we may make an exception for our existing clients only by dealing with leasehold flats in blocks of specific sizes (as set out
            below), or buyers with certain lenders (as set out below). Note lawyers have different additional charges for this additional work and risk, if they
            decide to do it.
          </p>
          <p>
            (6) Our own costs for Building Safety Act work are set out below (but note: this work will be done as an exception, only at our discretion, and
            clients cannot force us to take on this work if we decide not to deal with that work:
          </p>
          <p>
            Four storeys (i.e. floors) or under 11 metres, £0 additional costs. This work carries extra work and potential risks, but no charge is made as
            goodwill to an existing client. Between 5 to 6 storeys (i.e. floors) or 11 metres or above, £1397 plus vat, because of the additional work,
            complexities and risk so high, that many lawyers are not taking on this work at all. From 7 storeys (i.e. floors) or 18 metres or above, £1997 but
            note, our default position is that AVRillo will not do this work at all, except by prior arrangement, at our discretion because of what lawyers
            consider to be even more of a risk than the other two categories. If we do act, but we. Note: If your lender is Nationwide, Barclays, Mortgage
            Works, HSBC, First Direct because of the further additional risk, and further additional work, and requirements by these lenders, many lawyers,
            including us, will not act, for any blocks with 5 storeys plus or above 11 metres. We will only do so as an exception, at our discretion, and if we
            do, our additional charge will be limited to £1997 as a goodwill exception, but only by prior arrangement with us. We may not act for certain
            lenders where we consider their UK Finance Instructions too onerous.
          </p>
          <p>
            (7) Limits of our retainer if we do act for properties potentially affected by Building Safety Act 2022. Where you are buying with a mortgage, as
            there are over 78 different lenders in the property lending market, with different conditions to pass for them to advance the mortgage borrowing,
            each has different requirements regarding the Building Safety Act. These are always subject to future changes by lenders, including changes in their
            specific requirements, as in the UK Finance Mortgage Lenders Handbook. Suppose you require mortgage funding to purchase the property. In that case,
            there is a risk that your lawyer, e.g., us, will be unable to comply with or satisfy your specific lender's requirements under the Building Safety
            Act, as each lender's requirements differ. If this is the case, your lender may refuse to lend on the property, or we may need to terminate our
            retainer. We also reserve the right to terminate our retainer in the event that we consider the risk of taking this on will impact our ability to
            get or our premium for our Professional Indemnity Insurers; or we deem the transaction too high risk for example where we have acted for another
            client in the same block. Note, like many lawyers, we do not act for certain lenders for Building Safety Act work, e.g., Nationwide, Barclays,
            Mortgage Works, HSBC, First Direct but when you notify us of your lender, we can notify you. Our retainer in respect of our work and liability in
            respect of the Building Safety Act is limited to the following: requesting copies of any Leaseholder or Landlord Certificate or certificates that
            may exist; requesting confirmation as to whether the property has or will be remediated pursuant to the Building Safety Act 2022. Note. If building
            safety remediation works are required (whether pursuant to the Building Safety Act 2022 or otherwise), this will likely incur substantial
            expenditure, which you could be liable to pay.
          </p>
          <p>
            (8) However, you need to discuss with your surveyor and any other additional suitably qualified expert, e.g., recommended by your surveyor, before
            you proceed to an exchange of contracts, the following. This is because, as conveyancing lawyers, we are neither landlord and tenant experts nor
            surveyors, so we do not carry out a physical inspection of the property, which you need to ask your surveyor to do, which means we cannot and do not
            verify or advise you on any of the following: a) the building owner's obligations and whether they have been complied with, b) whether the property
            is a relevant building, higher risk building or otherwise affected by the Building Safety Act 2022, c) any potential risk of remediation works and
            any associated costs involved, d) whether Landlord or Leaseholder certificates should be provided and the nature or effect of any that are, e)
            whether the form and content of any completed certificates are correct and whether the information can be relied upon, or f) whether the lease is a
            qualifying lease to which leaseholder protections apply, g) any information supplied by the seller and their solicitor, landlord/management company
            or agent h) building and fire safety and any associated remediation works are something that you should discuss with your surveyor, i) you should
            ensure you have checked with your surveyor that the building owner/responsible person/accountable and/or principle accountable person has or will
            have complied with their legal obligations in relation to the safety of the building in all aspects, whether or not you will be a qualifying
            leaseholder, what protections (if any) under the Building Safety Act 2022 you will qualify for, what building safety costs (if any) you will be
            liable for and whether this is likely to affect the value and/or marketability of the property in the future.
          </p>
          <div>
            (9) Our work and liability in respect of the Building Safety Act is limited to the following: requesting copies of any Leaseholder or Landlord
            Certificates that may exist; requesting confirmation as to whether the property has or will be remediated pursuant to the Building Safety Act 2022.
            Note. If building safety remediation works are required (whether pursuant to the Building Safety Act 2022 or otherwise), this will likely incur
            substantial expenditure, which you could be liable to pay. It is important that from the outset of our instructions, you understand that we are
            unable to confirm whether your property is protected by the BSA 2022 because we
            <div>
              <p>
                &mdash; Are unable to confirm whether the building within which your flat or apartment is situated is more than 11 metres in height or exceeds 5
                storeys or for higher risk buildings whether the building within which your flat or apartment is situated is more than 18 metres in height or
                exceeds 7 storeys.
              </p>
              <p>
                &mdash; Are unable to confirm whether the landlord certificate or the leaseholder deed of certificate that has been provided contains
                information which can be safely relied upon by you.
              </p>
              <p>
                &mdash; In any event, we are unable as part of the conveyancing process to advise you as to what your liability will be in connection with the
                future annual service charge which you are obliged to pay as a leaseholder. A surveyor or valuer might be able to provide an opinion as to
                future service charge costs, whether the state or condition of the building in which your flat or apartment is situated is likely to lead to
                additional service charge costs or whether remediation works and costs are likely to be incurred which could be subject to the BSA 2022.
              </p>
              <p>
                &mdash; Where you are concerned as to future remediation costs and the impact of those costs on a service charge, we may be able to undertake
                further investigative work or to provide advice relating to investigative work undertaken by you or a third party on your behalf, but this is
                not part of our current work and retainer. It would warrant additional fees to be paid to us and would require us to expressly agree to do that
                work and for our retainer to be extended for that purpose.
              </p>
              <p>
                &mdash; It is extremely important that you are aware that our firm is unable to advise you on the Building Safety Act, which is a complex and
                evolving piece of legislation. The above is given on an information basis only and cannot be relied upon as legal advice. The provisions of the
                BSA 2022 (including the relevant certificates) extend beyond the scope of our retainer. This means we are not instructed to advise you on this
                aspect of your purchase, and you must therefore seek specialist advice.
              </p>
              <p>
                &mdash; Where you, independently of this firm, obtain additional information or external advice on any of the issues specified above and provide
                this to us, we will consider it however, we will not be able to provide you with any advice upon it. If you have any questions or queries
                arising from the additional information or advice you will need to raise it with the advisor who provided it.
              </p>
            </div>
            <p>
              You need to discuss with your surveyor and any other additional suitably qualified expert, e.g anyone recommended by your surveyor, and/or
              building safety/fire expert and a Landlord and Tenant solicitor ( not AVrillo as we deal with conveyancing), before you proceed to an exchange of
              contracts the following below. In the event that you do not instruct a surveyor we may ask you to sign a disclaimer and if you refuse to sign a
              disclaimer in connection with the Building Safety Act please advise as we reserve the right to cease acting for you. This is because, as
              conveyancing lawyers, we are neither landlord and tenant experts nor surveyors, so we do not carry out a physical inspection of the property,
              which you need to ask your surveyor to do, which means we cannot and do not, advise you on any of the included but not limited to any of the
              following;
            </p>
            <div>
              <p>1. Whether there is a building safety risk</p>
              <p>2. Whether any building safety defects exist at the property (as this may not be covered by your survey),</p>
              <p>
                3. Whether the building in which your new property is situated has remediation work required or identified requires or to what extent and at
                what cost remediation work is required. Where we have been informed by the any party such as seller/landlord/your surveyor or valuer that the
                building in which your new property is situated requires remediation work we are not qualified to advise you as to whether this is the case or
                not and cannot advise you on the information presented to us whether these costs can be included in the future service charge and the impact of
                the Building Safety Act 2022 on that service charge
              </p>
              <p>4. Have remedial works been identified , If yes:</p>
              <div>
                <p>a. Who will pay for the remedial works?</p>
                <p>b. When will the works be done?</p>
                <p>c. Will the works be disruptive to the leaseholder?</p>
                <p>d. Are there additional safety measures required until the work is complete eg waking watch.</p>
              </div>
              <p>
                5. the building owner/responsible person has or will have complied with their legal obligations in relation to the safety of the building in all
                aspects
              </p>
              <p>6. whether or not you will be a qualifying leaseholder,</p>
              <p>7. what protections (if any) under the Building Safety Act 2022 you will qualify for,</p>
              <p>8. what building safety costs (if any) you will be liable for</p>
              <p>9. The implications on the ability to sell and or mortgage the property</p>
              <p>
                10. Whether the conditions are met for you to qualify for protection under the Building Safety Act including protection against the cost of
                remediation ( including but not limited to the whether the property is a relevant property)
              </p>
              <div>
                11. Is the property greater than 5 storeys or 11m? If no, other fire safety regulations may apply, see below.
                <br />
                <p>If yes:</p>
              </div>
              <div>
                <p>a. Has a Leaseholder Deed of Certificate been served detailing the qualification of the leaseholder on 14 02 22?</p>
                <p>b. has the property had a building safety assessment, to the Publicly Available Specification: PAS9980 standard, in the last 5 years?</p>
              </div>
              <p>
                12 .Will the incoming Leaseholder be the Responsible Person under the Fire Safety (England) Regulations 2022 and own common parts the use of
                which would be necessary when escaping from a fire?
              </p>
              <p>
                13. If no, the responsible person should be in touch after they take up occupation to identify to them the relevant fire safety information eg
                how to escape from fire and the correct maintenance of fire doors etc.
              </p>
              <p>
                14. If Yes, have the Regulations been complied with and, in particular:- a. Have the occupants of the dwellings in the building been provided
                with the required information? b. When were the inspections last made to the fire doors to the flats and to the communal areas?
                <br />
              </p>
              <p>15. Whether the procedures and formalities required to qualify for protection have been complied with and/or dealt with correctly</p>
              <p>16. Carry out any checks to verify if the information provided to AVRillo is correct and can be relied on.</p>
              <p>
                17. The information that we receive is made available on the portal during the case and you are advised to download this as originals will not
                be retained
              </p>
              <p>18. On cladding and other less obvious fire safety defects</p>
              <p>19. Obligations and liabilities of the Responsible Person under the Fire Safety (England) Regulations 2022</p>
              <p>20. Obligations and liabilities of the Accountable Person or Principal Accountable Person under the Building Safety Act 2022</p>
              <p>
                21. Implications of the Building Safety Act on the value of the property and your ability to sell and or mortgage the property that the building
                owner/responsible person has or will have complied with their legal obligations in relation to the safety of the building in all aspects,
                whether or not you will be a qualifying leaseholder, what protections (if any) under the Building Safety Act 2022 you will qualify for, what
                building safety costs (if any) you will be liable for and whether this is likely to affect the marketability of the property in the future.
              </p>
            </div>
          </div>
          <p>
            (10) Recovery of service charge payments when acting on a sale - as AVRillo are neither surveyors nor landlord and tenants experts we will not be
            advising you on the potential recovery of any service charge payments that may already have been made for remediation costs for building safety
            works you will need to take independent, expert, advice in this regard.
          </p>

          <div>
            (11) In agreeing for this firm to act on your behalf in this transaction you acknowledge that our firm cannot be held liable for any loss,
            diminution, or inconvenience that you may experience as a result of –
            <div>
              <p>
                1. This retainer in connection with the explanation provided by this firm or information given concerning the application and implications of
                the BSA 2022.
              </p>
              <p>
                2. Not obtaining the survey, valuation or other specialist advice that might be necessary to determine whether the building where your new flat
                or apartment is situated requires remediation work to which the BSA 2022 applies.
              </p>
              <p>
                3. Relying on where appropriate the leaseholder deed of certificate and any information within it provided by the seller or the landlord or the
                landlord certificate and any information within it provided by the seller or the landlord as we are unable to warrant or verify that the
                information provided in either is correct or accurate.
              </p>
            </div>
          </div>
        </div>

        <p>
          By agreeing our terms you are declaring that you understand the above information and waive your right to bring about any action for professional
          negligence or breach of contract against this firm should you experience any loss or detriment of any kind resulting from or relating to the BSA 2022
          or any information, explanation or advice from this firm on the BSA 2022 (including but not limited remediation costs) following completion of your
          purchase. It is important that you understand and acknowledge that this firm does not owe you a duty of care and that there can be no contractual
          liability arising from this retainer from any loss or damage you sustain concerning the application of the BSA 2022 to your acquisition or any service
          charge liability arising from your acquisition. You proceed on you at your own risk with regard to future remediation costs or additional service
          charge costs arising from the BSA 2022 or due to the lack of protection afforded to you by the BSA 2022.
        </p>
      </div>
      <p>
        ix.
        <b><u>£39.00 Telegraphic bank transfer admin time for making a TT or BACS payment.</u></b>
        For the time in taking details, coding these into banking, checking, asking a partner to personally log in and send the transfer.
      </p>
      <p>
        x.
        <b><u>£49.97 Money laundering</u></b>
        admin time for the Online check to avoid client coming into the office (i.e. time spent in online research and entry and reviewing these online
        documents).
      </p>
      <p>
        xi.
        <b><u>xi. Archive fee £45:</u></b>
        for the storage and maintenance of documents electronically including access to client’s portal.
      </p>
    </div>

    <p>
      <b><u>C.2(2). OPTIONAL- UNIQUE to SALES.</u></b>
      In addition to C.2(1)-(but
      <u>only if required, no charge otherwise</u>
      )
      <br />
      £227.00 Additional enquiries only if seller has inadequate information or missing documents such that the buyer cannot buy. Note. The first two sets of
      enquiries are not charged. Subsequent charged at one off discounted fee of £227.00.
    </p>
    <b>C2(3). OPTIONAL- UNIQUE to PURCHASE.</b>
    In addition in C.2.(1). No charge if not required.
    <p></p>
    <div>
      <p>
        I.
        <b><u>Tax form.</u></b>
        £187.00. If you want us to complete and submit your Stamp Duty Land Tax form and take liability if we get it wrong.
      </p>
      <p>
        II.
        <b><u>Cash Gifts from third parties:</u></b>
        £180 Cash Gift solicitors work (needed by your lender, if any, to allow them to lend to you).
      </p>
      <div>
        III.
        <b><u>Only if applicable if leasehold:</u></b>
        <p>
          (a).£67 Notice/s to be filed but only applicable for leases and only if your Landlord requires these to be served for them to recognise you as the
          owner on the deeds. We will not know if they require a notice (or what they charge) for each notice) until after they send their leasehold management
          pack to us. Our fee for drafting and serving the notice is free for a Notice of Assignment as we will only charge for subsequent notices, if any, at
          £67 per notice, such as for a Notice of a Deed of Covenant (if applicable); Certificate of Compliance (if applicable); Share Certificate (if
          applicable). If you have a Management Company, they may also charge separately for them to accept service of your notices and our charge will be £67
          per notice if applicable.
        </p>
        <p>
          (b.) £97. Interest in freehold purchase (but only if the freeholder allows you to buy a share in the freehold title in addition to your leasehold
          purchase.
        </p>
      </div>
      <p>
        IV.
        <b><u>Searches.</u></b>
        Option 1 (circa £463.80) * (full benefits explained below & further in section F, below) or option 2 (circa £250).
      </p>
    </div>

    <p>
      <u>
        <b>Searches Option 1:</b>
        A fuller personal 5 searches pack:
      </u>
      see section for conditions to qualify and benefits to you.
    </p>
    <p>
      <b><u>Conditions:</u></b>
      You can only purchase this at the start as it has discounted costs and attached benefits. Plus, a) Property must be under £909,000.00 for block indemnity
      insurance cover and b) Must be under £100,000,000 for personal searches,
    </p>
    <p>
      <b><u>Benefits and Costs:</u></b>
      Rather than just the mandatory 2 searches (*which means you are probably at risk of not only limiting your investigation, and discovery of expensive
      issues/ defects until later but also lead you to having to order the 3 other searches later at a higher non discounted costs). The benefits of the 5 pack
      search includes: The 5 searches (not 2); a block indemnity insurance policy (worth up to £500); a free 2nd set of the 5 searches (worth up to £463.80)
      plus our costs for facilitating all five searches:
    </p>
    <p>
      <b><u>What are the 5 searches?</u></b>
      Contamination Search (AVR fee £60.73 + vat + Conveyancing Data search company cost of £36.60; total £109.47); Chancel Search (AVR fee £70.17 + vat +
      Conveyancing Data cost of £24.66 disbursement; total £108.86);
    </p>
    <p>Flooding Search (AVR fee £66.57 +vat + Conveyancing Data cost of £29.28 disbursement; total £109.16);</p>
    <p>Local Authority search (AVR fee of £26.34 + £5.27 vat + 46.80 for the Conveyancing Data disbursement; total £78.41);</p>
    <p>
      Water Drainage search (AVR fee of 30.25 + £6.05 vat + 21.60 Conveyancing Data disbursement; total £57.90). If you need any other searches, such as tin if
      you are in Cornwall or Coal in the midlands areas, then we will let you know after we know, and open your case. Some times we will not even know unless
      other risks are revealed that need an additional search.
    </p>
    <p>
      <u>
        <b>Searches Option 2:</b>
        Basic mandatory only:
      </u>
      Official Local Authority and Water searches only (circa £250) but limited protection as they only search the water drainage, which has limited content and
      the Local Authority Register. If you require other searches in option 2, then you will need to pay for them separately, You also do not get Block policy
      as in option 1 nor do you get a free set of searches (as per option 1).
    </p>
    <p>
      <u>
        <b>Disclaimer</b>
        if you choose option 2:
      </u>
      Sometimes a lender or the area or an additional risk, will need a further search to be required by you to purchase (often those being set out in option 1.
      However, if you are buying these separately they will cost you more than option 1). Also note, in respect of option 2, AVRillo has to exclude liability
      for any loss you suffer as a result of not picking up defects, etc which would have been picked up or protected against if you had taken out the option 1
      option of the 5 search pack and other benefits.
    </p>
    <p>
      <b>Note:</b>
      If you are unsure, or want a cost price for anything outside the basic and fixed discounted additional options, ask your lawyer, they will explain your
      options and your choices.
    </p>
    <p>
      <u><b>C.2.4: Hourly Rate: OPTIONAL</b></u>
      Only if you need additional work not listed in the discounted lists in c.2.3 (i.e. such as a trust deed or a lease extension or new build) in which case
      such additional work is charged hourly rate of the conveyancer who looks after you as follows: - For Non Partners, £247 and for Partners £347 (Partner or
      Any Conveyancer with over 15 years' experience).
    </p>

    <p>
      <b>D: GENERAL TERMS (not relating to costs).</b>
    </p>
    <div>
      <p>D.1. Who will be looking after you?</p>
      <p>D.2. Copyright</p>
      <p>D.3. Complaints procedure</p>
      <p>D.4. Authorisation to amend documents</p>
      <p>D.5. Limitation of AVRillo's liability and advice:</p>
      <p>D.6. Communication & Hours of Work:</p>
      <p>D.7. Lender (if any) Conflict of interest risk.</p>
      <p>D.8. Compliance with the referral code</p>
      <p>D.9. Data Protection. Confidentiality and Disclosure.</p>
      <p>D.10. Co-operation:</p>
      <p>D.11. Conflict of interest.</p>
      <p>D.12. Termination.</p>
      <p>D.13. Third party Exclusion, Contracts (Right of Third Parties) Act 1999.</p>
    </div>

    <p>
      <u><b>D.1 Who will be looking after you?</b></u>
    </p>
    <p>
      The person primarily responsible for the conduct of your transaction will be notified to you in our first letter to you after receipt ofyour signed
      instructions. The Partners have complete discretion to deploy such of our lawyers, trainee lawyers, paralegals or other staff as he deems necessary or
      desirable to ensure appropriate delivery of our services to you. In order to give your case the benefit of the most proactive method of working and to
      speed up your transaction, we may from time to time ask other members of the AVRillo conveyancing to assist in certain steps.

      <br />
      Sometimes we ask other consultants or companies to do work for us on our files, to ensure that work is always done promptly. We will always seek a
      confidentiality agreement with any such providers. Note that some services will be offered or carrying out by other business entities or by operations a
      specialist team in India provides legal, account management, and administrative capability to AVRillos customers in the UK. By accepting these Terms of
      Business you accept that your transaction may be worked on by staff based in India as well as in the UK. This may include provision of deferred payment,
      AML services, no move no fee and other administrative services by V & M Property Services Ltd. Where these are provided by a third party the cost of this
      will be no more than a charge by AVRillo if they were to provide these.
      <br />
      By signing and agreeing these Terms and Conditions you consent to AVRillo transferring your personal data V& M Property Services ltd and or to a
      jurisdiction that does not provide the same level of data protection as the UK. This is for the purpose of allowing a specialist team in India provide
      legal, account management, and administrative capability to our clients. Please be assured that we apply extremely strict controls to any personal data
      that may be transferred and processed in this way and we take all reasonable steps to protect your personal data at all times.
    </p>

    <p>
      <u><b>D.2. Copyrights.</b></u>
      AVRillo retain copyright and all other intellectual rights to their documentation.
    </p>

    <p>
      <u><b>D.3 COMPLAINTS Procedure:</b></u>
    </p>
    <p>
      We aim to provide the highest standards of service, but we recognise that, as with any other service provider, misunderstandings can sometimes arise. All
      claims, complaints and disputes arising out of or in connection with the Services ("Disputes") will be resolved pursuant to this paragraph.
    </p>
    <div>
      <p>
        I. How to raise a complaint? The Legal Ombudsman will ask you to utilise our formal complaints procedure before they will get involved. Note. Our
        complaint procedure is sent out to you with our initial correspondence, and our initial client letter but once raised during or after your case, we will
        send you out a further complaints procedure with steps. Essentially though, you: Firstly, contact your conveyancer directly. If this does not resolve
        matters then please make your complaint in writing to either Mr Angelo or Mr Antonino (Tony) Piccirillo (they being the Partners responsible for
        complaint investigation) and also copy in your own conveyancer. You will then be sent our internal complaints procedure which complies with our
        Regulatory body, the Council of Licenced Conveyancer's guidelines (the CLC) as well as the Legal Ombudsman.
      </p>
      <div>
        II. If after we have tried to resolve your complaint you are still not satisfied then you have two alternatives. Either, you can refer your complaint to
        ADR (under the Alternative dispute resolution directive) to ask them to mediate and try to resolve the complaint if it is not resolved by the our
        internal complaints procedure or to address the complaint to the Legal Ombudsman.
        <p>
          *Option 1). You can address your complaint for ADR mediation to ProMediate Legal Complaints Service. They are competent to deal with complaints
          against legal services providers and can be used if you and AVRillo agree to that scheme. Their website is
          <u>
            <a href="http://www.promediate.co.uk/professionals-complaints/consumer-guidance/">
              http://www.promediate.co.uk/professionals-complaints/consumer-guidance/
            </a>
          </u>
          You simply write to us to confirm either that you do agree to use the ProMediate or you do not agree to use ProMediate or
          <u>alternatively.</u>
          Their contact details are: 0203 621 3908 OR 0782 796 1764 OR
          <a href="mailto:enquiries@promediate.co.uk"><u>enquiries@promediate.co.uk</u></a>
        </p>
        <p>
          *Option 2). You have the right to complain to the Legal Ombudsman, established under the Legal Services Act 2007, to deal with legal services
          complaints.
        </p>
        <p>
          The
          <em>Legal Ombudsman</em>
          can accept complaints up to 6 years from the date of the act/omission or 3 years from when the complainant should have known about the issue.
        </p>
        <p>Their address is:</p>
        <p>
          The Legal Ombudsman,
          <br />
          PO Box 6167,
          <br />
          Slough,
          <br />
          SL1 0EH
        </p>
        <p>Telephone: 0300 555 0333;</p>
        <p>
          Email address:
          <a title="enquiries@legalombudsman.org.uk" href="mailto:enquiries@legalombudsman.org.uk"><u>enquiries@legalombudsman.org.uk</u></a>
          ;
        </p>
        <p>
          Website:
          <a title="www.legalombudsman.org.uk" href="http://www.legalombudsman.org.uk/"><u>www.legalombudsman.org.uk</u></a>
        </p>
      </div>
      <p>
        III. Time scales for a complaint? A full copy of our complaints procedure is available on request and will be sent to you upon receipt of a formal
        complaint. It is important that you raise your complaint as soon as it is a lot easier to make a contemporaneous investigation if a complaint is
        received as soon as it is felt. Normally, however you will have to bring your complaint to the Legal Ombudsman within 6 months of receiving a final
        response from us about your complaint and 6 years from the date of the act or omission giving rise to the complaint or alternatively 3 years from the
        date you should reasonably have known there are grounds for complaint (if the act/omission took place before 6 October 2010 or was more than 6 years
        ago).
      </p>
    </div>
    <p>
      <u><b>D.4 Authorisation to amend documents:</b></u>
      In order to progress transactions as quickly as possible, we may issue documents to you for signing which require approval by another party or their
      conveyancer. If we are subsequently required to make amendments to document you have already signed, your acceptance of these Terms of Business provide
      your authority to do so. Any amendments will have been checked, and approved, by your conveyancer on you behalf. Should any substantial amendments be
      necessary we will, of course, take your specific instructions where appropriate. Similarly, we may sometimes be required to make amendments to your signed
      documents in order to correct minor errors such as spellings, full names, address etc. Again, by accepting these Terms of Business you are providing your
      authority to do so.
    </p>
    <p>
      <u><b>D.5. Disclaimers of AVRillo's Liability and Advice is strictly limited to advice for 'conveyancing only and for LLP only.</b></u>
    </p>
    <p>
      <u><b>D.5.1: This means no other advice will be given or can be implied to have been given</b></u>
      . Examples of advice we do not give (but these are not exhaustive) include: no Planning advice, no Financial advice, No Environmental advice, no long-term
      care planning advice, no Taxation advice, no Trust advice, no Wills advice, no Welfare benefits. However, you are strongly advised to take advice in
      respect of the aforesaid matters prior to you allowing us to exchange contracts.
    </p>
    <p>
      <u><b>D.5.2. No advice is given by AVRillo on the physical condition and/ or structure of the property</b></u>
      . This means that you will be relying on your own investigations via a surveyor and other structural surveyor to both the physical and structure of the
      property you are buying. This means that AVRillo will assume no defect to the property or structure as we leave such issues for you to sort out via an
      independent expert as above.
    </p>

    <p>
      <u><b>D.5.3. No advice is given by AVRillo in Planning Property transactions</b></u>
      . This means that you will be relying on taking your own planning advice prior to exchange of contracts.
    </p>

    <p>
      <u><b>D.5.4. No advice is given by AVRillo in respect of taxation matters</b></u>
      . This includes general tax advice regarding purchase of property but also Stamp Duty advice such as whether you are liable to lower or higher rate tax,
      in respect of which you should take advice from your accountant or professional tax advisor, again prior to exchange. This means that you will be relying
      on taking your independent professional taxation advice prior to exchange.
    </p>

    <p>
      <u><b>D.5.5. These terms exclude third party rights under the 'Contracts (Rights of Third Parties) Act 1999</b></u>
      . This means no other party can rely on our advice (other than you alone). So if any third party reads our advice and/or reports and/or documents then we
      hereby exclude our liability to the said third parties and in turn means a): You are liable if they rely on our advice and b) only you can sue under the
      contract between us; and c) you agree to indemnify us if the said third party suffers consequential or exemplary damages, costs or losses, lost profits or
      opportunities a result of relying on our aforesaid advice and information.
    </p>

    <p>
      <u><b>D.5.6. No personal liability for any member of AVRillo.</b></u>
      No member of AVRillo LLP) assumes any personal responsibility for any advice or opinions given. Your retainer is with the LLP and not with any member of
      the LLP in their personal capacity and it is not intended that any duty of care will arise between any member personally and you. As a Limited Liability
      Practice (LLP) our liability to you for a breach of your instructions shall be limited to our mandatory limit of insurance cover of £2million. We will not
      be liable for any consequential, special, indirect or exemplary damages, costs or losses, or any damages, costs or losses attributable to lost profits or
      opportunities. We can only limit our liability to the extent the law allows. In particular, we cannot limit our liability for death or personal injury
      caused by our negligence. Please ask us if you wish us to explain any of these terms.
    </p>

    <p>
      <u><b>D.5.7 No advice is given on the value of the property sold or purchased</b></u>
      . As this is clearly outside our remit and we will not be held liable if the property is under or overvalued. This means you must seek independent
      valuation for the value of your property, including from an independent valuer/surveyor who is familiar with the area where the property is situated and
      from a combination of other sources such as two or more independent estate agents familiar with the said area.
    </p>

    <p>
      <u><b>D.5.8 No advice is given by AVRillo in relation to ‘Consumer Protection from Unfair Trading Regulations 2008’ and ‘Material Information’.</b></u>
      Any replies or documents disclosed by the seller or Estate Agent either before or during your transaction in response to any additional questions raised
      in connection with ‘Material Information’ are provided to you on an information only basis and you will need to discuss these with your surveyor as they
      could affect the value of the property or your ability to mortgage it or sell it at full value.
    </p>
    <p>
      <u><b>D.6. Communication & Hours of work:</b></u>
    </p>
    <p>
      Hours of Work are between 9.00 am to 5.00 pm (Monday to Friday) unless at the discretion of your Conveyancer who may be able to assist where in cases of
      emergency or priority, to communicate with you outside those normal working hours. This is the Conveyancer's sole discretion and we ask clients not to
      abuse the Conveyancer's discretion by demanding emails or telephone calls to be replied to or work done outside their normal working hours. Communication
      between us: To ensure clients understand our legal advice and have the opportunity to keep a record of our advice for consideration at a later date and/
      or a more detailed consideration we prefer to provide our advice by letter and email but of course you are always welcome to telephone us and where
      appropriate we may either provide guidance by phone but where appropriate we will try to reassure by telephone and advise in writing to minimise the risk
      of error, or misunderstanding and/ or misinterpretation of our advice and guidelines on what you should do.
    </p>

    <p>
      <u><b>D.7 Lender conflict of interest (if we also act for your lender, if any):</b></u>
      Where you instruct to act for your lender it means that both of your and your lender are our client, and we act for each one of you independently. This
      means we will owe an equal duty to each of you. It also means that if a conflict of interest arises, we may have to stop acting for both of you. If this
      occurs, we will not make any charge for the legal work carried out on your behalf up to that point, but disbursements that we have incurred on your behalf
      will of course remain payable. We ask you, therefore, to please not put us in a compromising position, as we will have to disclose this to your lender
      even if it causes you to lose your mortgage offer (please note, we also exclude all liability for losses you suffer as a result of us acting in accordance
      with your obligations to your lender). A common example would be where you are purchasing a property and your funding is coming from a third party, the
      existence of which we would need to disclose to your lender, and you instruct us not to divulge this information to them.
    </p>

    <p></p>
    <p>
      <u><b>D.8. Compliance with the referrals and Introductions code:</b></u>
      AVRillo are independent and when instructed, you become our client and no one else except where you have a lender and you also instruct us to act for your
      lender; in which case we will be acting for both you and your lender. On some occasions an estate agent may be asked by their vendor or buyer to recommend
      a solicitor to deal with their conveyancing. If the estate agent recommends us to help their buyer or vendor then on some occasions, on a successful
      completion of a purchase or sale, we may make a referral fee payment to them which is paid from our own pocket and out of our overheads and will not
      affect the costs charged to our client. If such is done then this will be notified to you in our initial client care letter. Where paid the referral fee
      will vary from £50 per transaction to £300 per transaction. Please note that we do not accept any recommendations from cold callers or those which have
      been cold called by an introducer. If you have been cold called please notify us in advance as our policy is not to accept your instructions. We are sorry
      for any inconvenience this may cause but we want to maintain our independency from any introducer. The only exception is if you have been referred from a
      previous customer or client of ours or from a friend or family of that previous customer on the basis of the service we provided for the said previous
      customer.
    </p>
    <p></p>
    <p>
      <u><b>D.9. Data Protection:</b></u>
      We will comply with the Data Protection Act 1998.
    </p>
    <p>
      <b><u>Client Confidentiality and Disclosure.</u></b>
      How we may use information provided by our clients?
    </p>
    <p>
      We may be required to disclose confidential information to third parties such as your Lender, or HMRC and the like and the National Crime Agency-NCA (in
      respect of which are not allowed to notify you as that would amount to the criminal offence of 'tipping off'). Note also that you accept that we cannot be
      held liable for losses arising by our disclosure to these third parties. For example where your lender withdraws their mortgage offer because we have had
      to report a conflict, or where you have not co-operated and/ or not complied with your obligations to your lender under their CML conditions (such as gift
      procedure where you receive a gift). We also disclaim losses where you have asked us take improper action such as stamp duty avoidance and/ or fraud etc.
      Also to comply with quality accreditation boards, your file maybe selected at random for inspection and file audit by an outside auditor (such as the
      Legal Ombudsman, the CLC, teh NCA and other such compliance bodies). By these terms you hereby authorise us to make your file available for such
      inspection. Also you provide us with authority to share information with our mortgage broker and estate agent where we feel appropriate in order to better
      progress your case. If you do not authorise us to advise or disclose such information to any of the 3rd parties set out above then you must notify us in
      writing otherwise we will assume you have accepted these terms.
    </p>

    <p>
      <u><b>D.10. Co-operation:</b></u>
      You have a duty to co-operate by complying with requests made by AVRillo for specific information and/ or documents that we think fit to allow us to
      follow the appropriate codes and practices, including: The CLC (Council of Licenced Conveyancers) Code; your Lender obligations; CML (The Council of
      Mortgage Lender) Handbook; the Conveyancing Association, if we choose to adopt that. Also you must not ask us to mislead third parties such as the Stamp
      Duty office, your lender, your estate agent or other solicitor. Breach of this section may lead to us having to terminate of your instructions whereupon
      you become liable for costs under sections 1 and 2.
    </p>

    <p>
      <u><b>D.11</b></u>
      We are permitted by the Council for Licensed Conveyancers to act on both (or all) sides of a transaction provided that there is no apparent conflict of
      interest and we do not envisage that there is likely to be, and both parties agree in writing to this firm acting. This is on the clear understanding that
      should a conflict of interest arise during the course of the transaction, then it will be necessary, under our practising Rules, to ask one or both
      parties to seek alternative legal representation. If this occurs, we will not make any charge for the legal work carried out on your behalf up to that
      point, but disbursements that we have incurred on your behalf will of course remain payable.
    </p>

    <p>
      <u><b>D.12. Termination</b></u>
    </p>
    <p>
      <b><u>Termination by you:</u></b>
      You may terminate your instructions to us in writing at any time.
    </p>
    <p>
      <b><u>Termination by AVRillo:</u></b>
      We are entitled to terminate upon the following examples:
    </p>
    <div>
      <p>
        i): Where we suspect you intending to carry out an unethical act and/ or illegal act (e.g. Stamp duty avoidance) or providing misleading and/ or false
        and/ or hiding information from your Lender (e.g. gifts) or from other involved third parties such as the other solicitor or your estate agent. Also
        where you have been abusive in person or by telephone or by email, and/ or you have been defamatory;
      </p>
      <p>
        ii): Pursuant to the Court of Appeal Case of Underwood, Son & Piper v Lewis (held the solicitor may terminate the retainer before the end on reasonable
        notice on reasonable grounds); and
      </p>
      <p>
        iii): Guidelines on termination: In accordance with the code of conduct, our regulators allow us to cease acting for a client if there is good reason
        and on reasonable notice. Examples of reasons why we would cease acting include but not limited to: acting in the best interest of the client requires
        us to do so, lack of expertise in a complex area of law outside of conveyancing, conflict of interest arises, or a breakdown in confidence in you in us
        and vice-versa, or simply where the lawyer has been unable to obtain proper instructions, instructions are inconsistent, instructions are such that it
        affects our professional duty and/or ethical standards. In addition, we have the right to cease acting in circumstances where there is break drown of
        trust, examples of which include and not limited to, you do not allow us to advise, you use abusive language towards our staff, you talk over us, you do
        not allow us to manage your expectations. Further if you ask us to deal with additional work which we in our absolute discretion do not want to deal
        with then we will not carry out that additional work and at that point if we feel you are putting pressure on us to deal with the additional work then
        we reserve our right to terminate your original instructions.
      </p>
      <p>
        iv): Payment and notification on Termination: We will charge costs in accordance with Section 1 and 2 of our terms and hold onto all papers (legal
        'lien') until payment is received. We will also write to all relevant parties including lender and estate agent. Please note that if we have to
        terminate as above, we do exclude any liability for losses arising.
      </p>
    </div>

    <p>
      <u><b>D.13 Exclusion of Third party rights under The Contracts (Right of Third Parties) Act 1999.</b></u>
      Our advice is confidential to you and we shall not be responsible if you make it available to third parties. No person who is not a party to the agreement
      embodied in these terms of business shall, in the absence of any express agreement to the contrary, have any right under the Contracts (rights of Third
      Parties) Act 1999.
    </p>
    <p></p>
    <p>
      <b>E: MISCELLANEOUS COMPLIANCE AND FINANCIAL MATTERS</b>
    </p>
    <div>
      <p>E.1. Professional Indemnity insurance cover</p>
      <p>E.2. Money Laundering Compliance</p>
      <p>E.3. Cash and payments from third parties</p>
      <p>E.4. Destination of funds</p>
      <p>E.5. Request for breakdown of costs or description and evidence of work carried out</p>
      <p>E.6. Lien on unpaid bills</p>
      <p>E.7. Redemption Statements/ Your Estate Agent Commission.</p>
      <p>E.8. Your Estate Agent Commission</p>
      <p>E.9. Banking Collapse/ Failure</p>
      <p>E.10. The legal principle of 'force majeure'</p>
      <p>E.11. No Financial Advice</p>
      <p>E.12. Interest on our bills</p>
      <p>E.13. 'Unclaimed' Balance left on your account and 'un-cashed' balances</p>
      <p>E.14. Omission of charges</p>
      <p>E.15. Payment by you of disbursements and costs</p>
      <p>E.16. Money on account</p>
      <p>E.17. Commission for work</p>
    </div>
    <p></p>
    <p>
      <b><u>E.1. Professional Indemnity insurance cover to £2,000,000</u></b>
      (£2 million pounds aggregate of all liability arising out of your transaction): You have the benefit of our indemnity insurance cover in the event that we
      make a legal mistake. Our cover is with AmTrust Europe Limited & Liberty Mutual Insurance Europe SE (UK Branch) c/o Collegiate Management Services Ltd,
      2nd Floor, 18 Mansell Street, London, E1 8FE
    </p>
    <p>
      <b><u>E.2 Money Laundering Compliance:</u></b>
      You are requirement to comply with the Government’s anti-money laundering legislation. To assist you in doing this we will provide you with a link for our
      third-party AML solution provider, Checkboard. This enables us to both verify your identification and, where appliable, the source of any funds you are
      providing to us. The product works as follows:
    </p>
    <p>
      Verify your identity: Use your smart phone to take a video of your face on their app. Then, hover your smartphone camera over your photograph and
      signature page of your passport.
    </p>
    <p>
      Evidence of funds (where applicable) Continue on the app to the bank section and choose your bank account from which you will use funds to purchase your
      property. The government needs to ensure all buyers show their funds are not tainted with criminal laundered money, even if innocently done to reduce
      fraud and cyber crime - costing buyers millions of losses a year. Whereas email or posted bank statements can be intercepted by hackers or forged withing
      minutes, Checkboard will give you the security and protection of the government and FCA (Financial Conduct Authority) approved Open Banking Technology,
      ‘True Layer.’ Their stringent standards keep your data safer with reduced risk of it being stolen and your money lost, as the checks are down within the
      banking system, not on paper.
    </p>
    <p>
      <b><u>E.3 Cash and payments from third parties:</u></b>
      We do not accept payments in cash over £500.00, and you should speak to your lawyers before attempting to make any such payment to ensure we can
      accommodate this. Furthermore, we do not accept payments from third parties, with the exception of: a) funds on account for searches where our client is
      unable to make the payment and it will enable the transaction to proceed; b) payment of our invoice on an abortive transaction, provided that no such
      payment ever exceeds £1000.00. Payments made on behalf of you by third parties are still subject to the usual AML rules. Please notify us if you require
      another party to make payment on your behalf as we may require them to complete AML documents.
    </p>
    <p>
      <b><u>E.4. Destination of funds:</u></b>
      Where we need to pay money to you then this will be paid as follows: To the client's sole account (if a sole client) to the client's joint account (if a
      joint client). Also we do not pay out to third parties (unless an estate agent commission on a sale) and any payments are normally made by cheque, unless
      the transaction requires by TT or BACS.
    </p>
    <p>
      AVRillo Invoices and payment: We can send an invoice at any time but usually this is sent on completion (on a sale following which we deduct the same from
      your net sale proceeds). On a purchase this is sent on exchange with payment to clear in our account three working days prior to completion otherwise we
      may not be able to complete.
    </p>
    <p></p>
    <p>
      <b><u>E.5. Description of work and breakdown of costs.</u></b>
    </p>
    <p>
      If you ask for a description of work carried out by AVRillo then you will receive either (or a combination) of the following: a):We will itemise the
      number of hours spent on the file multiplied (x) by the hourly rate of your conveyancer; or b): send you an extract from Sections C.1.v 1 of our terms
      (the basic costs 'case plan') and any additional services you use from Sections 2 "costs menu"; or c): send you a screen print of your history screen of
      work done and dates worked on.
    </p>
    <p></p>
    <p>
      <b><u>E.6. Lien on unpaid Bills:</u></b>
      We are entitled to keep all your papers and documents (including deeds) while money is owing to us.
    </p>
    <p></p>
    <p>
      <b><u>E.7.Redemption Statements.</u></b>
      On a sale only: You authorise AVRillo to obtain redemption statements before and after exchange in relation to all mortgages or other debts secured and
      registered on the property and to discharge all such debts on completion.
    </p>
    <p></p>
    <p>
      <b><u>E.8 Your Estate Agent Commission.</u></b>
      On a sale only: We will send you your estate agents commission and assume you authorise AVRillo to discharge your estate agents commission (on a sale)
      unless you revoke this authority in writing prior to completion and simultaneously also notify your estate agent. We likewise will notify your agent if
      you revoke this authority.
    </p>
    <p>
      <b><u>E.9.Banking Collapse/ Failure:</u></b>
      We are duty bound to explain the FSCS scheme (Financial Services Compensation Scheme) in the event of a banking collapse as follows: AVRillo bank with
      Barclays Bank Plc which means that any client money is held with Barclays Bank. In the event of a bank collapse or bank failure and/or where the bank is
      unable to repay depositors in full and/or the bank's conduct has affecting our ability to obtain your money, then we hereby exclude liability for any loss
      arising. However, you may or may not be entitled to compensation under the Financial Services Compensation Scheme ("FSCS") up to a limit of £85,000. In
      the unlikely event of a deposit-taking institution failure, we shall presume (unless we hear from you in writing to the contrary) that we have your
      consent for the disclosure to FSCS of client details.
    </p>
    <p></p>
    <p>
      <b><u>E.10 The legal principle of 'force majeure'</u></b>
      will apply in that we cannot be held liable for delay (or financial or other loss arising) due to causes beyond our control (e.g.: where we have had to
      report to a third party such as the Lender or NCA and delay is caused because we cannot progress your transaction without their consent.
    </p>
    <p></p>
    <p>
      <b><u>E.11 No financial advice provided</u></b>
      We do not provide financial advice. Insurance Mediation: We are not authorised by the Financial Conduct Authority (FCA). However, we are included in the
      register maintained by the FCA so that we can carry on Insurance Distribution Activities, which is broadly advising on, selling and administration of
      insurance contracts. This part of our business is regulated by the Council for Licensed Conveyancers, and arrangements for complaint or redress if
      something goes wrong are subject to the jurisdiction of the Legal Ombudsman (contact details can be found in section D.3 above). The register can be
      accessed via the FCA website at https://register.fca.org.uk
    </p>
    <p></p>
    <p>
      <b><u>E.12. Interest:</u></b>
      Our interest policy is that do not open a special deposit account. Interest runs from the date the funds are cleared to the date that we issue the cheque
      but we do not account to you for interest accrued under £30, where interests which exceeds £30 we may make the appropriate reduction in your bill and will
      invoice the first £30 of each amount of interest to cover any of the ordinary administrative work. Interest on our bills: If all or part of our bill
      remains unpaid, we may be entitled to charge interest at the equivalent of the current court judgement rate of 8% pa on the unpaid amount. However, you
      have the right to object to your bill by making a complaint to the appropriate body referred to above.
    </p>
    <p></p>
    <p>
      <b><u>E.13 “Unclaimed” Balance</u></b>
      left on your account and “Un-cashed” balances (i.e., if cheque is not banked by you). If, following completion of your transaction or the transaction
      becoming abortive, we find there is a balance remaining on your account of less than £51.00, you agree that we can send this to you as stamps to your last
      known address. For balances of £51.00 or more, once we have taken all reasonable steps to try and return this money to you, the funds will be sent to our
      regulator, the Council for Licensed Conveyancers (CLC), in accordance with their requirements. The CLC will return this money to you upon receiving
      evidence you are the rightful recipient, and their contact details are shown at D.3 above.
    </p>

    <p></p>
    <p>
      <b><u>E.14 Omission of charges:</u></b>
      Where we have had omitted to fully charge you for additional work before completion then you specifically agree by this term that we can deduct our costs
      from any money which may be left on your account and provide an itemised invoice for the same.
    </p>
    <p></p>
    <p>
      <b><u>E.15 Payment by you of disbursements and costs:</u></b>
      You agree to indemnify us against expenses and disbursements and costs.
    </p>
    <p></p>
    <p>
      <b><u>E.16 Money on account:</u></b>
      AVRillo do not make it a policy to ask for up front for money on account of costs. Unusual circumstances may lead to a request for a payment on account
      for unusual circumstances where you instruct us to ask for a third party advice such as a barrister's advice or lease extension value. On such occasions
      we will agree an estimate for that 3rd party and also for our additional costs for dealing with that issue and ask you to make a payment in advance in
      respect of the same.
    </p>
    <p></p>
    <p>
      <b><u>E.17 Commission for work:</u></b>
      We do not receive any commission from third parties, but in the event that we did then we would credit this to you-our client.
    </p>
    <p>
      <b><u>E.18: The CLC Compensation fund:</u></b>
      The CLC Compensation Fund is an important protection mechanism for clients of licensed conveyancers. It ensures that clients can receive compensation in
      cases where they suffer financial loss due to the dishonest actions or failure to account for money by a CLC-regulated individual or firm. For more
      specific details or to initiate a claim, clients should directly consult with the CLC.
    </p>
    <p></p>
    <p></p>
    <p class="mt-8">
      <b><u>F. More detailed explanation of Option 1 fuller set of searches:</u></b>
    </p>
    <p>
      <u><b>F.1 Conditions</b></u>
      to qualify for benefits of option 1 fuller searches pack:
    </p>
    <div>
      <p>a) Property must be under £909,000.00 for block indemnity insurance cover and</p>
      <p>b) Must be under £100,000,000 for personal searches,</p>
    </div>

    <p>
      <b><u>F.2 What cover do you receive in option 1 searches for £463.80?</u></b>
    </p>
    <p>
      <b><u>Benefit 1:</u></b>
      Five personal searches: Local authority, Water, Flooding, Environmental (contamination) and Chancel;
    </p>
    <p>
      <b><u>Benefit 2:</u></b>
      A free set of 2nd searches
    </p>
    <div>
      <p>- Free 2nd Water search if your 1st purchase falls through</p>
      <p>- Free 2nd Local Authority Search if your first purchase falls through</p>
      <p>- Free 2nd Environmental Search if your first property falls through</p>
      <p>- Free 2nd Flooding search; plus</p>
    </div>

    <p>
      <b><u>Benefit Of Block Policy</u></b>
    </p>
    <p>Which will be included if the seller’s are not providing a policy with will cover any specific issue that is raised in relation to the property</p>
    <p>
      <b><u>Benefit 3.i.</u></b>
      A free block indemnity INSURANCE policy for freeholds which covers below list for free:-
    </p>
    <div>
      <p>- free missing planning permission</p>
      <p>- free missing building regulation completion certificate</p>
      <p>- free missing FENSA for</p>
      <p>- free breach of restrictive covenants</p>
    </div>

    <p>
      <b><u>Benefit 3.ii:</u></b>
      A free block indemnity INSURANCE policy for Leaseholds which covers below list for free:-
    </p>
    <div>
      <p>- free missing building regulation completion certificate</p>
      <p>- free missing FENSA</p>
    </div>
    <p>
      <b><u>Benefit of Block Indemnity Insurance</u></b>
      Policy: The block policy insurance can reduce delays because the policy can help resolve issues with the legal title, making it less risky to purchase.
      For example 'Restrictive Covenants' can exist over the property which can affect or restrict your use of the property. This can cause difficulties
      particularly if the property has been extended, altered or subject to other works (the policy does not relate to leasehold covenants). Another example is
      where planning permission and/or building regulations were required in respect of alterations or extensions to property (including replacement of windows
      and doors, boiler and electrical installations and other matters falling under building control) but where these are missing. In such situations a buyer
      can purchase a legal indemnity insurance. This is particularly common where the buyer is also obtaining a mortgage as the insurance helps protect the
      lenders loan over the property (again this does not relate to failure to obtain planning permission where a Property is a flat or maisonette. One of the
      biggest problems when it comes to purchasing indemnity insurance is who is going to pay for it (the seller or buyer). Typically a seller should pay but
      often the buyer purchases the insurance just to move things along, especially in a market where the prices are changing. Without this, negotiations to
      agree the policy and its cost with the seller's solicitor can take weeks and delay exchange of contracts. Depending on the issues found on your purchase
      (and whether you benefit from a 2nd set of searches for free) the value of this combined policy could be anywhere from £200- £800. The arrangement is with
      Isis Conveyancing Insurance Specialists the insurance is provided by Liberty Legal.
    </p>

    <p>
      <b><u>F.3. Other optional searches (only if relevant):</u></b>
      These will depend on your property and your status (e.g. if you are a company then you will need a company search) if the property is near HS2 railways
      works, near a Tin, Coal, Brine Mining works etc. Until we open your file (and reviewed your Land Registry title deeds/other documents) we won’t know which
      searches are relevant. For example: Tin mining search: (AVR fee of 32.50 + vat + 3rd party Conveyancing Data disbursement-£60.00: Total £99; Coal & Brine
      search (AVR fee of 64.20 + vat + Conveyancing Data disbursement £34.80: Total £99.
    </p>
  </div>
</template>
<style scoped>
p {
}

.custom-list {
  @apply space-y-2 pl-5 text-black;
  counter-reset: custom-list;
  list-style-type: none;

  li {
    position: relative;

    &::before {
      counter-increment: custom-list;
      content: counter(custom-list, lower-roman) '.';
      position: absolute;
      font-weight: semi-bold;
      color: var(--tw-prose-bullets);
      text-align: right;
      width: 3rem;
      left: -3rem;
    }

    &::marker {
      content: none;
    }
  }
}

.roman-list-lower {
  li {
    &::before {
      content: counter(custom-list, lower-roman) '.';
    }
  }
}

.roman-list-bracket-column {
  li {
    &::before {
      color: black;
      content: counter(custom-list, lower-roman) '):';
    }
  }
}

.roman-list-bracket-dot {
  li {
    &::before {
      content: counter(custom-list, upper-roman) ':';
    }
  }
}

.list-c-number {
  @apply pl-10;

  li {
    &::before {
      content: 'C.' counter(custom-list) '.';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}

.list-d-number {
  @apply pl-10;

  li {
    &::before {
      content: 'D.' counter(custom-list) '.';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}

.list-e-number {
  @apply pl-10;

  li {
    &::before {
      content: 'E.' counter(custom-list) '.';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}

.list-c2-number {
  @apply pl-10;
  counter-reset: custom-list20;
  li {
    &::before {
      color: #1f2937;
      counter-increment: custom-list20;
      content: '(' counter(custom-list20) ')';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
.list-c21-number {
  @apply pl-10;
  counter-reset: custom-list21;

  li {
    &::before {
      color: #1f2937;
      counter-increment: custom-list21;
      content: counter(custom-list21) '.';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
.list-c22-number {
  @apply pl-10;
  counter-reset: custom-list22;

  li {
    &::before {
      color: #1f2937;
      counter-increment: custom-list22;
      content: counter(custom-list22) '.';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
.list-c21-alpha {
  @apply pl-10;
  counter-reset: custom-list21-alpha;

  li {
    &::before {
      color: #1f2937;
      counter-increment: custom-list21-alpha;
      content: counter(custom-list21-alpha, lower-alpha) '.';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
.list-c21-alpha-bracket {
  @apply pl-10;
  counter-reset: custom-list21-alpha-br;

  li {
    &::before {
      color: #1f2937;
      counter-increment: custom-list21-alpha-br;
      content: counter(custom-list21-alpha-br, lower-alpha) ') ';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
.list-c21-alpha-dot {
  @apply pl-10;
  counter-reset: custom-list21-alpha-dot;
  li {
    &::before {
      color: #1f2937;
      counter-increment: custom-list21-alpha-dot;
      content: counter(custom-list21-alpha-dot, lower-alpha) '. ';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
.list-dash {
  @apply pl-10;
  counter-reset: customdash;
  li {
    &::before {
      color: #1f2937;
      counter-increment: customdash;
      content: '- ';
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
.list-c21-bullet {
  counter-reset: customlist21;
  @apply pl-10;
  li {
    &::before {
      counter-increment: customlist21;
      content: '•';
      font-size: large;
      font-family: 'Helvetica Neue', sans-serif;
    }
  }
}
</style>
