import Repeater from '~/components/Repeater.vue'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $el: 'h1',
          children: '(2.1) Who owns the freehold title?',
          attrs: {
            class: 'text-large mt-4 mb-4',
          },
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(2.1.1) Is this a company or person which the tenants do not control?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'ownership.is_company_or_person_tenants_do_not_control',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: '(2.1.2) Is this a company or person that the tenants control (i.e., a share in the freehold company or a share in the freehold title)?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'ownership.is_company_or_person_tenants_control',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $el: 'h1',
          if: `$formData.data.ownership.is_company_or_person_tenants_control === "Yes"`,
          children: 'Please provide the names of the company or person who owns the freehold',
          attrs: {
            class: 'text-sm section-indent',
          },
        },
        {
          $cmp: Repeater,
          if: `$formData.data.ownership.is_company_or_person_tenants_control === "Yes"`,
          props: useProps({
            prop: 'ownership_company_names',
            buttonText: '+ Add extra company or person',
            removeButton: true,
            min: 1,
            class: 'section-indent',
          }),
          children: [
            {
              $el: 'div',
              children: 'Name of the company or person',
              attrs: {
                class: 'text-sm required-label',
              },
            },
            {
              $cmp: 'el-form-item',
              props: { required: true },
              attrs: {
                class: 'mt-2',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: 'ownership.name_of_company_or_person',
                  validation: 'required',
                  validationMessages: {
                    required: 'This field is required',
                  },
                },
              ],
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(2.2) Is there a headlease (i.e., superior lease)?', class: 'mt-4', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'ownership.is_headlease',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.ownership.is_headlease === "Yes"`,
          props: {
            label:
              'Please provide the name of who owns the headlease (e.g. a person(s), a company not controlled by the leaseholders or a company controlled by the leaseholders)',
            class: 'section-indent',
            required: true,
          },
          children: [
            {
              $formkit: 'el-input',
              name: 'ownership.person_who_owns_headlease',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'el-form-item',
          props: { label: '(2.3) Who maintains the interior, exterior and common parts of the building?', class: 'flex-col', required: true },
          children: [
            {
              $formkit: 'el-checkbox-group',
              attrs: { class: 'flex flex-col' },
              name: `ownership.who_maintains_building`,
              validation: 'required',
              options: [
                { label: 'The freeholder', value: 'the_freeholder' },
                { label: 'The superior leaseholder', value: 'the_superior_leaseholder' },
                { label: 'Management Company / Managing Agent', value: 'mangement_company_managing_agent' },
                { label: 'Tenants formed management company', value: 'tenants_formed_management_company' },
                { label: 'Other (such as tenants, or resident association)', value: 'other' },
              ],
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.ownership.who_maintains_building.length > 0 && $formData.data.ownership.who_maintains_building.includes('other'))`,
          props: { label: 'Please provide details', class: 'section-indent', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'ownership.who_maintains_building_other_details',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(2.4) My management company is owned by residents and now dissolved/struck off ', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'ownership.my_management_company_owned_by_residents_owned_and_dissolved',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              "(2.5) Is there a tenants' management company or managing agent employed on behalf of the landlord or management company who collects ground rent and looks after the building?",
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'ownership.tenants_management_company_or_managing_agent_employed',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
      ],
    },
  ]
}
