import section_13_1 from '~/schemas/components/pif/utilities-and-service-list/section_13_1'
import section_13_2 from '~/schemas/components/pif/utilities-and-service-list/section_13_2'
import section_13_3 from '~/schemas/components/pif/utilities-and-service-list/section_13_3'
import section_13_4 from '~/schemas/components/pif/utilities-and-service-list/section_13_4'
import section_13_5 from '~/schemas/components/pif/utilities-and-service-list/section_13_5'
import section_13_6 from '~/schemas/components/pif/utilities-and-service-list/section_13_6'
import section_13_7 from '~/schemas/components/pif/utilities-and-service-list/section_13_7'
import section_13_8 from '~/schemas/components/pif/utilities-and-service-list/section_13_8'
import section_13_9 from '~/schemas/components/pif/utilities-and-service-list/section_13_9'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                section_13_1(parentPath),
                section_13_2(parentPath),
                section_13_3(parentPath),
                section_13_4(parentPath),
                section_13_5(parentPath),
                section_13_6(parentPath),
                section_13_7(parentPath),
                section_13_8(parentPath),
                section_13_9(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
