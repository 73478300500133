import Title from '~/schemas/components/title'

export default function (prop, propParent) {
  return {
    $el: 'div',
    children: [
      Title('Proof of Address', 'mb-4 font-normal text-xl'),
      {
        $cmp: 'ElFormItem',
        props: { label: 'First document upload one of the following', required: true },
        children: [
          {
            $formkit: 'el-radio-group',
            name: `${prop}.documents.0.type`,
            validation: 'required',
            options: [
              { value: 'bank_statement', label: 'Bank Statement in your name dated in last 3 months' },
              { value: 'utility_bill', label: 'Utility Bill in your name, dated in last 3 months, showing your home address' },
            ],
          },
        ],
      },
      {
        $cmp: 'ElFormItem',
        if: `$formData.data.${prop}.documents.0.type`,
        props: { class: 'pl-6', required: true },
        children: [
          {
            $formkit: 'file-upload',
            validation: 'required',
            validationLabel: 'File upload',
            validationVisibility: 'live',
            name: `${prop}.documents.0.files`,
            fileName: `${prop}.documents.0.files`,
          },
        ],
      },
      {
        $cmp: 'ElFormItem',
        if: `$formData.data.${propParent}.driver_license_has_address === false && $formData.data.${propParent}.document_type === "driving_license" || $formData.data.${propParent}.document_type === "passport"`,
        props: { label: 'Second document upload one of the following', required: true },
        children: [
          {
            $formkit: 'el-radio-group',
            name: `${prop}.documents.1.type`,
            validation: 'required',
            options: [
              { value: 'bank_statement', label: 'Bank Statement in your name dated in last 3 months' },
              { value: 'utility_bill', label: 'Utility Bill in your name, dated in last 3 months, showing your home address' },
            ],
          },
        ],
      },
      {
        $cmp: 'ElFormItem',
        if: `$formData.data.${prop}.documents.1.type`,
        props: { class: 'pl-6', required: true },
        children: [
          {
            $formkit: 'file-upload',
            validation: 'required',
            validationLabel: 'File upload',
            validationVisibility: 'live',
            name: `${prop}.documents.1.files`,
            fileName: `${prop}.documents.1.files`,
          },
        ],
      },
    ],
  }
}
