import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '16.1.',
            text: `<u>When is the final salary paid after termination?</u>`,
          },
          {
            number: '',
            text: `On the last calendar day of the month of termination, for the number
            of days you worked during the calendar month of termination, plus any
            days' holiday accrued to the termination date but not yet taken. If
            you are owed commission, then as this is paid in arrears, the
            commission will be paid at the end of the next calendar month payroll.
            Your P45 will follow when issued via the accountant after being
            processed by the inland revenue.`,
          },
          {
            number: '16.2.',
            text: `<u>Exceptions</u> to pay due after termination (for
              example, deductions and/or claims against the Employee for any breach
              of contract). This includes <u>deductions for example, if:</u>`,
          },
          {
            number: '16.2.(a).',
            text: `<u>If the Employee has not returned Company property/equipment</u>
            (e.g., computer, pc, laptop, monitor/s, webcam, telephone, mobile,
            headset, associated wires, and chargers etc.) within 2 days of their
            last working day, in the same condition as loaned to the Employee,
            then a deduction will be made from final payroll at a pre-assessed
            sum of £1500 (unless otherwise stated at the commencement of
            employment when delivered, if the equipment is more expensive).
            AVRillo may, at its discretion, organise a courier for the equipment
            to be picked up, at the Company's cost, within 24 hours after your
            final employment date. If you do not cooperate (i.e., If you do not
            talk to our office to arrange for us to pick up your equipment
            and/or you are not available for our courier to pick your equipment
            within this 24-hour period), then you will need to pay for your own
            courier to return the said property. Note, if you have not returned
            said equipment by your payroll date, then the said pre-assessed sum
            would be held back from final payroll until the equipment is
            returned in good order (and as such, the Company will hold a lien on
            that payment until the equipment is returned).`,
          },
          // { was commented out on old system
          //   number: '16.2.(b).',
          //   text: `<u>Recruitment expenses:</u><br /><br />No deductions will be made unless a third-party recruiter charges
          //   the Company a fee for recruiting you. Even then, no deduction will*
          //   be made if the recruiter waives or rebates their fee. If not, then
          //   the deduction will, in any event, be limited as follows: If you
          //   leave after 18 months or more, no deduction will be made for our
          //   recruitment expenses. If you leave within 13 to 17 months, you
          //   repay 25% of our expenses. If you leave within 7 to 12 months, you
          //   pay 50%. If you leave within 3-6 months, you pay 75% of our
          //   expenses. If you leave within 2 months, you pay 100% of our
          //   expenses`,
          // },
          {
            number: '16.2.(b).',
            text: `<u>
            Professional expenses to a third party, e.g., practising
            certificates, external training etc.
          </u> Professional expenses, including practising certificates, CPD
          training and other development and training expenses of the firm,
          are repayable if you leave this firm before a period of 24 months
          following the date of the paid expenses and/or training as per
          clause 8 of this contract.`,
          },
          {
            number: '16.2.(c).',
            text: `<u>Training and support costs:</u>`,
          },
          {
            number: '(c.i)',
            text: `Where the Company supports an Employee by incurring
            ‘cost’ on the Employee’s behalf (for example, time and costs
            associated with training that Employee, that cost will be repayable if
            the Employee terminates their employment within 12 months of that cost
            being incurred. But it is not repayable by the Employee if the firm
            terminates, unless by summary dismissed, i.e., due to misconduct.
            ‘Cost’ includes time (internally, capped at £897 and externally
            depending on what is spent on time, third party expenses (if
            applicable) for example, course costs, exam costs, time off to attend
            course or exam. This refund will apply whether or not the Employee
            actually gains a qualification.`,
          },
          {
            number: '(c.ii)',
            text: `If the Employee gains a qualification, including if
            Licenced Conveyancing (any level) or qualified solicitor following a
            training contract: Firstly, the Employee will not be required to repay
            the cost (as waived by us) if the Employee continues to work for
            AVRillo for 12 months after their qualification date, but is
            otherwise repayable by them, as in (i) aforesaid, but without a cap.
            Secondly, Where the Employee gains a qualification after completed
            their professional exams via the firm (e.g. After qualifying as
            Solicitor after a training contract, or Licenced Conveyancer, so as
            not to lose their employment rights and benefits accrued during the
            time they were employed prior to qualification, the pre-qualification
            contract will continue, with no interruption and without the need of a
            new contract upon qualification. As such, existing terms shall
            continue after qualification as well as employment rights applicable
            for the total period of employment.`,
          },
          {
            number: '16.2.(d).',
            text: `<u>
            If the Employee has given less notice than required under their
            contract, Employee will pay damages, and or said damages be clawed back
            from the Employee damages as calculated the damages formula set out in 14.2 aforesaid.
          </u>`,
          },
        ]),
      ],
    },
  ]
}
