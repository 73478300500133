import SectionCard from '~/components/SectionCard.vue'
import innerComponents from '~/schemas/components/gift-declaration/common/inner-component-loop'

export default function amlDeclaration() {
  return {
    $cmp: SectionCard,
    props: useProps({ tab: 4, title: 'Declaration of Cybercrime, fraud risk and how to transfer my gift' }),
    attrs: {
      class: 'mb-4',
    },
    children: [
      ...innerComponents([
        {
          number: '4.1.',
          text: 'Who to transfer my gift money to?',
          extraClasses: 'underline',
        },
        {
          number: '(a).',
          text: 'Who to send my gift to?',
        },
        {
          number: '',
          text: 'I will only send my gift money to the Donee.',
          extraClasses: '',
        },
        {
          number: '(b).',
          text: 'I confirm that I will not send my gift to AVRillo.',
        },
        {
          number: '4.2.',
          text: 'How to reduce my risk of losing my gift money and cyber fraud attacks.',
          extraClasses: 'underline',
        },
        {
          number: '(a).',
          text: "By ensuring that I only transfer my gift Donee's correct account as provided to me by the Donee. ",
        },
        {
          number: '(b).',
          text: "By ensuring that I never transfer money to AVRillo or any other lawyer asking for my gift to be transferred to them as I've understood, I must only transfer my gift directly to the Donee. That's even if I receive a purported email, text, letter, or call pretending to be from AVRillo (setting out their bank details and request payment). Any such correspondence will be from a fraudster (even if they feel legitimate). It will not be from AVRillo, and as such, I confirm that I will never transfer my gift to AVRillo or any other lawyer.",
        },
      ]),
    ],
  }
}
