import section_15_1 from '~/schemas/components/pif/additional-questions/section_15_1'
import section_15_2 from '~/schemas/components/pif/additional-questions/section_15_2'
import section_15_3 from '~/schemas/components/pif/additional-questions/section_15_3'
import section_15_4 from '~/schemas/components/pif/additional-questions/section_15_4'
import section_15_5 from '~/schemas/components/pif/additional-questions/section_15_5'
import section_15_6 from '~/schemas/components/pif/additional-questions/section_15_6'
import section_15_7 from '~/schemas/components/pif/additional-questions/section_15_7'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                section_15_1(parentPath),
                section_15_2(parentPath),
                section_15_3(parentPath),
                section_15_4(parentPath),
                section_15_5(parentPath),
                section_15_6(parentPath),
                section_15_7(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
