import moment from 'moment'
// import AVRilloLTD from '~/assets/images/signatures/avrillo-sig-ltd.png'
import avrilloSigLLP from '~/components/staff-contract/avrillo-signature-llp.vue'
import avrilloSigLTD from '~/components/staff-contract/avrillo-signature-ltd.vue'
import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: 'Date:',
            text: moment(Date.now()).format('DD MMMM YYYY'),
            extraClasses: 'font-bold',
          },
          {
            number: '',
            text: {
              if: `${`$employeeCase.company_code`} === '1'`,
              then: `<span class='text-red-500'>Signed By: Employer (signed as a partner for and on behalf of AVRillo LLP)</span>`,
              else: `<span class='text-red-500'>Signed By: Employer (signed as a partner for and on behalf of AVRillo LTD)</span>`,
            },
          },
        ]),
        {
          if: `${`$employeeCase.company_code`} === '1'`,
          $cmp: avrilloSigLLP,
        },
        {
          if: `${`$employeeCase.company_code`} === '2'`,
          $cmp: avrilloSigLTD,
        },
        ...innerComponents([
          {
            number: 'Date:',
            text: moment(Date.now()).format('DD MMMM YYYY'),
            extraClasses: 'font-bold',
          },
          {
            number: '',
            text: `$: "<span class='text-red-500'>Signed By: " + $employeeCase.name + "</span><br/><br/>"`,
          },
        ]),
        {
          $formkit: 'signature-input',
          name: 'signature_of_employee',
          validation: 'required',
          validationMessages: {
            required: 'This field is required',
          },
        },
      ],
    },
  ]
}
