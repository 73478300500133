import SectionCard from '~/components/SectionCard.vue'

export default function amlDeclaration() {
  return {
    $cmp: SectionCard,
    props: useProps({ title: 'E -sign declaration' }),
    children: [
      {
        $el: 'div',
        children:
          'By e-signing, this document I confirm that I have read, understood, and agreed to all statements and steps referred to in this document declaration made by me. I further understand that AVRillo cannot and do not act for me but instead act solely for the Donee as their lawyers. I understand, therefore, that they can only look after the interests of the Donee and are not advising on mine. I also understand that I should seek independent legal and financial advice before transferring my gift and signing this and other documents sent to me. This is because as I am gifting money for pure love and affection, I have no legal, implied, or equitable interest in the property being purchased by the Donee or in the gift money I transfer. I understand that whether I obtain such independent advice is purely my choice and is not a mandatory pre-requirement of my making gift and nor is it is a precondition of my E-signing this and any other document sent to me by AVRillo as part of the gift process for the benefit of the Donee. I, therefore, understand that AVRillo will not check whether I have taken independent. They will assume that when I sign this document, I will have full knowledge of the consequences of my making gift, including as set out in my statements within this document. As such, AVRillo will be relying on the statements I have made in this document and will not go behind these statements.',
        attrs: {
          class: 'text-xs mt-4',
        },
      },
    ],
  }
}
