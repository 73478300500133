import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label:
                '(7.9) To your knowledge, has there been any other environmental issues that affect the property or garden (for example, these could include coastal erosion, mining, sink holes, quarrying or fracking)?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.environmental_issues`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            $cmp: 'el-form-item',
            if: isTrueCondition(`${parentPath}.environmental_issues === 'yes'`),
            props: {
              label: '(7.9.1) Please indicate which environmental issue.',
              required: true,
            },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.environmental_issues_select`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                className: 'flex flex-col',
                options: [
                  { label: 'Coal mining', value: 'coal_mining' },
                  { label: 'Coastal Erosion', value: 'coastal_erosion' },
                  { label: 'Fracking area', value: 'fracking_area' },
                  { label: 'Sink holes', value: 'sink_holes' },
                  { label: 'Quarrying', value: 'quarrying' },
                ],
              },
            ],
          },
          {
            if: `${isTrueCondition(`${parentPath}.environmental_issues_select`)} && ${isTrueCondition(`${parentPath}.environmental_issues_select.includes('fracking_area')`)}`,
            $cmp: 'el-form-item',
            props: {
              label: '(7.9.1.1) Please provide details for example the location of fracking and whether any action has been taken to prevent harm',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.environmental_issues_details`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
