import DatePicker from '~/schemas/components/date-picker.js'
import RadioGroup from '~/schemas/components/radio-group'
import BankDetails from '~/schemas/components/sof/source-of-funds-sections/bank-details'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (data, clientName) {
  return {
    $el: 'div',
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
        },
        children: [
          TextInput(
            `$: "How much money from the divorce settlement is " + ${clientName} + " using to purchase the property?"`,
            data + '.how_much_money_from_divorce_settlement',
            'number',
            true,
            { validation: 'required' },
          ),
        ],
      },
      RadioGroup(`$: "Are the funds already in an account in your name of " + ${clientName} + "?"`, data + '.funds_already_in_account_name', true),
      {
        $el: 'div',
        if: '$formData.data.' + data + '.funds_already_in_account_name',
        attrs: {
          class: 'section-indent',
        },
        children: [
          {
            $el: 'div',
            if: '$formData.data.' + data + '.funds_already_in_account_name === "yes"',
            attrs: {
              class: 'mb-8',
            },
            children: [...BankDetails(data + '.bank_details')],
          },
          {
            $el: 'div',
            if: '$formData.data.' + data + '.funds_already_in_account_name === "no"',
            children: [
              RadioGroup('Has a Final Order been made by the Court for distribution of funds?', data + '.has_final_court_order_been_made', true),
              {
                $el: 'div',
                attrs: {
                  class: 'grid grid-cols-3 gap-4',
                },
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'col-span-2',
                    },
                    children: [
                      {
                        $el: 'div',
                        if: '$formData.data.' + data + '.has_final_court_order_been_made === "yes"',
                        attrs: {
                          class: 'section-indent',
                        },
                        children: [
                          DatePicker('What is the date of the Final Court Order?', data + '.date_of_final_court_order', true),
                          RadioGroup('Do you have a copy of the Final Court Order?', data + '.copy_of_final_court_order', true),
                          {
                            $cmp: 'el-form-item',
                            props: { required: true },
                            if: '$formData.data.' + data + '.copy_of_final_court_order === "yes"',
                            children: [
                              Title('Please upload your Final Court Order.', 'mb-4 mt-4'),
                              {
                                $formkit: 'file-upload',
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                                name: `${data}.final_court_order.files`,
                                fileName: `${data}.final_court_order.files`,
                              },
                            ],
                          },
                          {
                            $el: 'div',
                            attrs: {
                              class: 'text-red-500 mt-2 text-sm',
                            },
                            if: '$formData.data.' + data + '.copy_of_final_court_order === "no"',
                            children: 'You can submit the form but you cannot proceed without this document.',
                          },
                          {
                            $el: 'div',
                            attrs: {
                              class: 'mt-4',
                            },
                            children: [DatePicker('What date do you expect the money to be in your account?', data + '.date_of_money_in_account', true)],
                          },
                        ],
                      },
                      {
                        $el: 'div',
                        if: '$formData.data.' + data + '.has_final_court_order_been_made === "no"',
                        attrs: {
                          class: 'section-indent',
                        },
                        children: [
                          DatePicker('What approximate date do you expect the Final Court Order to be made?', data + '.date_of_final_court_order', true),
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        $el: 'div',
        attrs: {
          class: 'mt-4',
        },
        children: [
          Title(`$: "Please give the name, email and case ref of the lawyers acting for " + ${clientName} + " in the divorce."`, 'mb-2'),
          {
            $el: 'div',
            attrs: {
              class: 'grid grid-cols-1 md:grid-cols-3 gap-4',
            },
            children: [
              TextInput('Name of your divorce lawyer', data + '.name_of_divorce_lawyer', '', false, { validation: 'required' }),
              TextInput('Email of your divorce lawyer', data + '.email_of_divorce_lawyer', '', false, { validation: 'required|email' }),
              TextInput('Case ref', data + '.divorce_case_reference', '', false, { validation: 'required' }),
            ],
          },
        ],
      },
    ],
  }
}
