import RadioGroup from '~/schemas/components/radio-group'
import SellingSharesBankAccountDetails from '~/schemas/components/sof/source-of-funds-sections/selling-shares-bank-account-details'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (data, clientName) {
  return {
    $el: 'div',
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      RadioGroup('Have you already sold your shares?', `${data}.sold_shares`, true),
      {
        $el: 'div',
        attrs: {
          class: 'mt-4',
        },
        if: `$formData.data.${data}.sold_shares === 'yes'`,
        children: [
          TextInput(
            '(a) Please provide full details of the shares already sold.\n' + '(Name, number of shares, approximate value of each share)',
            `${data}.share_details`,
            'textarea',
            '',
            { validation: 'required' },
          ),
          {
            $el: 'div',
            attrs: {
              class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
            },
            children: [
              TextInput('(b) How much money have the shares produced when sold?', `${data}.how_much_money_when_sold`, 'number', true, {
                validation: 'required',
              }),
              TextInput('(c) What is the name of the bank you will send your shares money to?', `${data}.bank_name`, '', '', { validation: 'required' }),
            ],
          },
          {
            $el: 'div',
            attrs: {
              class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
            },
            children: [
              TextInput('(d) Sort Code', `${data}.bank_details.sort_code`, '', false, { validation: 'required' }),
              TextInput('(e) Account Number', `${data}.bank_details.account_number`, '', false, { validation: 'required' }),
            ],
          },
          Title('(f) Please upload a bank statement dated within the last 3 months.', 'mb-4'),
          {
            $cmp: 'el-form-item',
            props: { required: true },

            children: [
              {
                $formkit: 'file-upload',
                key: 'bank_statement',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: `${data}.bank_statement.files`,
                fileName: `${data}.bank_statement.files`,
              },
            ],
          },
          Title(
            '(g) Please upload evidence of the shares were sold.\n' + '(e.g., a letter from your stockbroker or the company registrar showing shares sold)',
            'mb-4 mt-4',
          ),
          {
            $cmp: 'el-form-item',
            props: { required: true },
            children: [
              {
                $formkit: 'file-upload',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: `${data}.share_evidence.files`,
                fileName: `${data}.share_evidence.files`,
              },
            ],
          },
        ],
      },
      {
        $el: 'div',
        attrs: {
          class: 'mt-4',
        },
        if: `$formData.data.${data}.sold_shares === 'no'`,
        children: [
          TextInput(
            '(a) Please provide full details of the shares being sold. (Name, number of shares, approximate value of each share)',
            `${data}.share_details`,
            'textarea',
            false,
            { validation: 'required' },
          ),
          {
            $el: 'div',
            attrs: {
              class: 'grid grid-cols-2 gap-4',
            },
            children: [
              TextInput('(b) How much money will the shares produce when sold?', `${data}.how_much_money_when_sold`, 'number', true, {
                validation: 'required',
                class: 'mt-5',
              }),
              TextInput('(c) What is the name of the bank account the proceeds from the sale of your shares are held?', `${data}.bank_name`, '', false, {
                validation: 'required',
              }),
            ],
          },
          RadioGroup(`$: "(d) Is the bank account in your name " + ${clientName} + "?"`, `${data}.bank_account_in_client_name`, true),
          {
            $el: 'div',
            if: `$formData.data.${data}.bank_account_in_client_name === 'yes'`,
            children: [...SellingSharesBankAccountDetails(data)],
          },
          {
            $el: 'div',
            attrs: {
              class: 'section-indent',
            },
            if: `$formData.data.${data}.bank_account_in_client_name === 'no'`,
            children: [
              RadioGroup(
                'Is this bank account still yours but slightly different on the account name?',
                `${data}.is_account_yours_but_in_slightly_different_name`,
                true,
              ),
              {
                $el: 'div',
                if: `$formData.data.${data}.is_account_yours_but_in_slightly_different_name === 'yes'`,
                children: [...SellingSharesBankAccountDetails(data)],
              },
              {
                $el: 'div',
                if: `$formData.data.${data}.is_account_yours_but_in_slightly_different_name === 'no'`,
                attrs: {
                  class: 'text-red-600 mt-4',
                },
                children: [
                  {
                    $el: 'div',
                    children:
                      'As this account is in a third party name (i.e., not yours) this seems to be a gift. So, please enter this money in the gift section above, and also email your conveyancer that your shares are being transferred to a third party account as this can cause issues. (Please email to your conveyancer: ea@avrillo.co.uk)',
                  },
                  {
                    $formkit: 'hidden',
                    key: 'not_allowed',
                    name: `${data}.not_allowed`,
                    validation: 'required',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
