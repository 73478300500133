import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '34.2.',
            text: `You will need to follow guidelines and training, given
            verbally or in writing or Talent LMS video records or recorded
            telephone conversations on how to give out a quote, how to sell
            better, how to convert and close, how to handle objections, by knowing
            our product and service. Follow an already successful pitch for
            quotes, back-end script of USP, and closings, but not to read it word
            by word as all you need to do is use the relevant parts to get you to
            your target conversion, see below.`,
          },
          {
            number: '34.3.',
            text: `To liaise with the conveyancers, BDM's, partners etc., to
            help improve the conversion of quotes to live cases.`,
          },
          {
            number: '34.4.',
            text: `To attend training, take notes, dissect those notes, and
            learn, so you can better carry out your role and follow the contract
            training requirements.`,
          },
          {
            number: '34.5.',
            text: `<u>Examples of your role include the following:</u>`,
          },
          {
            number: '',
            text: `To give quote estimates out to clients within 10 minutes of receipt
            (or at latest within 30 minutes of receiving the quote). If you cannot
            meet that deadline, then inform the fee earner. To keep track of
            quotes, you cannot send them out within 30 minutes. Follow up with the
            estate agent after each quote you give by telephone and explain if the
            lead was hot, cold, i.e., whether the client was interested or not
            etc. To follow up with the client you have sent the quote to, within
            half a day, so in the afternoon if the quote is given in the morning,
            or the following morning if the quote is given in the afternoon. Book
            an exact follow-up time with the client as to when the follow-up call
            will be made by you. To obtain the client's full names, including
            middle names, as otherwise, it will affect the legal title. To get the
            correct sale price, the estate agent name and negotiator and add them
            to the quote dropdown. To try to close down the quote on the day, it
            is sent as we have a money-back guarantee where the client is not
            charged our costs if they change their mind within 30 days (sometimes
            60 days on offer). If not closed on the same day, then to keep calling
            until you get through to them, and if the client says no, then to
            update the agent, to explain, and also close off the file on the quote
            app and give reasons which you must ask the client to provide you with
            for not using you so we can track and improve. To listen to training
            and take part in role-plays to help improve your performance and
            ability to earn commission on the number of quotes you convert to live
            instructed cases (which mean the instruction has to be followed by a
            memorandum of sale and the first letters out sent) as until then the
            client may or may not start work with us so not counted in your
            targets. To hit the target, set from time to time, including a
            conversion rate ranging from 70% to 90% plus (noting that this target
            conversion will be lowered for you). The higher the conversion rate,
            the more commission you will earn on converted live cases. To build a
            rapport and business relationship the fee earners and the estate
            agents you quote for. To find out what is working well, what is not so
            well for the estate agent and inform the partners and your team leader
            so it can be improved. To chase up any memorandums after the client
            submitted their instruction form, as the case is not started without
            it. For then to pass that memo to the file opening team. To attend
            daily meetings to discuss figures quotes converted, conversion rates,
            discuss and brainstorm what can work better and what is working well.
            Better closing techniques and role-plays to help you close etc.`,
          },
        ]),
      ],
    },
  ]
}
