import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    if: isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`),
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.property_applicable_septic_tank === 'Sewage Treatment Plant'`),
            $cmp: 'el-form-item',
            props: {
              label: '(12.8) When was the sewage treatment plant last serviced?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.property_sewage_treatment_plant_last_serviced`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
