import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(8.8) Are you aware of any pipes, wires, cables or drains taking services to neighbouring property which cross this property?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.services_cross_property`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                  { label: 'Not known', value: 'notKnown' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.services_cross_property === 'yes'`),
            $cmp: 'el-form-item',
            props: {
              label: '(8.8.1) Please provide details of the pipes, wire, cables or drains that you are referring to',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.services_cross_property_details`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
