export default function () {
  return [
    {
      $formkit: 'hidden',
      name: 'relevant_documents.is_section_seen',
      validation: 'required',
    },
    {
      $el: 'h1',
      children: '(3.1) Please tick to confirm you will supply copies of the following documents, where applicable',
      attrs: {
        class: 'text-large mb-4 mt-4',
      },
    },
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $cmp: 'ElFormItem',
          props: { class: 'indent-content' },
          children: [
            {
              $el: 'h1',
              children: '(3.2) Correspondence',
              attrs: {
                class: 'text-large mb-2',
              },
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.any_correspondence_between_you_and_the_landlord',
              text: 'Any correspondence between you and the landlord or the management company or managing agent',
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.notices_if_any_e_g_license_to_assign',
              text: 'Notices (if any) e.g License to Assign',
            },
            {
              $el: 'h1',
              children: '(3.3) Invoices/statements',
              attrs: {
                class: 'text-large mb-2',
              },
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.service_charge_and_maintenance_accounts',
              text: 'Service charge and maintenance accounts (i.e. invoices, demands, payment receipts and statements)',
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.ground_rent_accounts_for_the_last_3_years',
              text: 'Ground rent accounts for the last 3 years',
            },
            {
              $el: 'h1',
              children: '(3.4) Building Insurance',
              attrs: {
                class: 'text-large mb-2',
              },
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.buildings_insurance_policy_and_schedule',
              text: 'Buildings insurance policy and schedule',
            },
            {
              $el: 'h1',
              children: '(3.5) Memoes and share / membership certs',
              attrs: {
                class: 'text-large mb-2',
              },
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.memorandum_of_association_articles_of_association_and_share_of_membership_certificate',
              text: 'Memorandum of Association, Articles of Association and Share of Membership Certificate, if Management Company exists',
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.management_companies_last_three_years_published_accounts_if_management_company_exists',
              text: 'Management companies last three years published accounts (if management company exists',
            },
            {
              $el: 'h1',
              children: '(3.6) Deeds',
              attrs: {
                class: 'text-large mb-2',
              },
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.deed_of_variation_varying_the_terms_of_the_lease_of_the_property',
              text: 'Deed of Variation varying the terms of the lease of the property',
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.lease_and_supplemental_deeds_if_any',
              text: 'Lease and supplemental deeds (if any)',
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.regulations_if_any_made_by_the_landlord_or_management_company',
              text: 'Regulations (if any) made by the landlord or management company',
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.deed_of_covenant',
              text: 'Deed of Covenant',
            },
            {
              $el: 'h1',
              children: '(3.7) Reports',
              attrs: {
                class: 'text-large mb-2',
              },
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.asbestos_survey_for_common_parts',
              text: 'Asbestos survey for common parts',
            },
            {
              $formkit: 'el-checkbox',
              name: 'relevant_documents.fire_risk_assessment_for_common_parts',
              text: 'Fire Risk Assessment for common parts',
            },
          ],
        },
      ],
    },
  ]
}
