import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '26.1.',
            text: `To protect the Company, and those employed by it, the
            Company will pursue such breaches as set out in this contract, and for
            the avoidance of doubt, will include comments by the Employee (Former
            Employee) whether made in writing (libel) or spoken (slander). The
            Company will write to the said Employee (or former Employee) at their
            home as well as their new work address because defamation is so
            serious as to amount to a substantial loss in financial damage as well
            as reputation and is contrary to the professional and ethical
            standards required of a person, i.e., that the new Employer will need
            to be made aware of their risk of loss, as well as the former
            Employee. The Company reserves its right to take litigation action
            against the former Employee for such defamatory statements, breach of
            Copyright, confidentiality, or intellectual property breaches and
            other breaches set out in this contract (noting examples set out in
            clauses 18, 19 and 20).`,
          },
        ]),
      ],
    },
  ]
}
