import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`),
            $cmp: 'el-form-item',
            props: {
              label: `$: "(12.6) Do other properties connect to the " + (${`$formData.data.${parentPath}.property_applicable_septic_tank`} || '_________') + "?"`,
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.property_other_properties_connect_to_septic_tank`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.property_other_properties_connect_to_septic_tank === 'yes'`),
            $cmp: 'el-form-item',
            props: {
              label: `$: "(12.6.1) Please confirm how many properties connect to the " + (${`$formData.data.${parentPath}.property_applicable_septic_tank`} || '_________') + " and confirm which properties you are referring to?"`,
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.property_septic_tank_other_properties`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
