import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            if: isTrueCondition(`${parentPath}.property_connected_to_mains_foul_water_drainage === 'no'`),
            $cmp: 'el-form-item',
            props: {
              label: `$: "(12.7) Please state when the " + (${`$formData.data.${parentPath}.property_applicable_septic_tank`} || '____________') + " was last emptied?"`,
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.property_septic_tank_last_emptied`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
        ],
      },
    ],
  }
}
