import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '25.1.',
            text: `To help reduce the risk of Covid to our employees during
            office hours, we will follow government guidelines as and when in
            force, such as requiring the wearing of masks, working from home,
            isolation periods, vaccination jabs, Covid/NHS passport, lateral flow
            test and/or a PCR test, depending on symptoms, a return-to-work
            fitness certificate etc.,`,
          },
        ]),
      ],
    },
  ]
}
