import { isBaspiOnly, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(13.7) Please confirm the location of the stopcock',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.confirm_location_of_stopcock`,
                inputType: 'textarea',
                rows: 6,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
          {
            if: isBaspiOnly(),
            $el: 'div',
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label: '(13.7.1) Please provide a photograph of the location of the stop cock and water meter',
                  class: 'pl-6',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.stopcock_and_water_meter_location_photograph`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    options: [
                      { label: 'Attached', value: 'attached' },
                      { label: 'To Follow', value: 'toFollow' },
                    ],
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.stopcock_and_water_meter_location_photograph === 'attached'`),
                $cmp: 'el-form-item',
                props: { label: '(13.7.1.1) Please upload the photo', class: 'pl-6', required: true },
                children: [
                  {
                    $formkit: 'file-upload',
                    name: `${parentPath}.stopcock_and_water_meter_location_photograph_files`,
                    fileName: `${parentPath}.stopcock_and_water_meter_location_photograph_files`,
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
