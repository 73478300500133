export default function (label, name, inputType = '', money = false, extras = {}, percentage = false) {
  const isRequired = extras?.validation?.includes('required')

  const updatedExtras = isRequired
    ? {
        ...extras,
        validationMessages: {
          ...(extras.validationMessages || {}),
          required: 'This field is required',
        },
      }
    : extras

  return {
    $cmp: 'ElFormItem',
    props: {
      class: 'el-form-item--large',
      label: label,
      required: isRequired,
    },
    children: [
      {
        $formkit: 'el-input',
        inputType: inputType,
        money: money,
        name: name,
        ...updatedExtras,
        percentage: percentage,
      },
    ],
  }
}
