import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(15.2) Please confirm the current annual Council tax rate',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.current_annual_council_tax_rate`,
                inputType: 'text',
                validation: 'required',
                value: {
                  if: '$saleReadyPack.report.propertyDetails.councilTax.tax != "TBC in Property Information Form when completed by the vendor"',
                  then: '$saleReadyPack.report.propertyDetails.councilTax.tax',
                  else: '',
                },
                validationMessages: {
                  required: 'This field is required',
                },
              },
            ],
          },
          {
            $el: 'div',
            attrs: {
              class: 'pl-8',
            },
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label:
                    '(15.2.1) Have you altered the property in any way to the extent that it might change the Council Tax band eg added a bedroom or substantially extended the property?',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.altered_property_change_council_tax_band`,
                    options: [
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                    ],
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.altered_property_change_council_tax_band === 'yes'`),
                $cmp: 'el-form-item',
                props: {
                  label: '(15.2.1.1) Please provide details',
                  class: 'pl-8',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-input',
                    name: `${parentPath}.altered_property_change_council_tax_band_details`,
                    inputType: 'textarea',
                    rows: 6,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                props: {
                  label: '(15.2.2) Has the Council Tax band for the property been deleted due to the property being derelict or in disrepair',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.council_tax_band_deleted_derelict_or_disrepair`,
                    options: [
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                    ],
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.council_tax_band_deleted_derelict_or_disrepair === 'yes'`),
                $cmp: 'el-form-item',
                props: {
                  label: '(15.2.2.1) Please provide details',
                  class: 'pl-6',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-input',
                    name: `${parentPath}.council_tax_band_deleted_derelict_or_disrepair_details`,
                    inputType: 'textarea',
                    rows: 6,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                props: {
                  label: '(15.2.3) Is the property exempt from Council Tax',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.property_exempt_from_council_tax`,
                    options: [
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' },
                    ],
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.property_exempt_from_council_tax === 'yes'`),
                $cmp: 'el-form-item',
                props: {
                  label: '(15.2.3.1)  Please provide details',
                  class: 'pl-6',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-input',
                    name: `${parentPath}.property_exempt_from_council_tax_details`,
                    inputType: 'textarea',
                    rows: 6,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
