import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '6.1.',
            text: `Days: Monday to Friday`,
          },
          {
            number: '6.2.',
            text: `Office hours: These are as set out in our offer
            letter to you. If they are based on a full working week, they will
            be calculated on a 40-hour per week, between 8.00am to 5.00pm, or
            otherwise as mutually agreed in writing with the partners e.g.,
            pro-rata for part time Employees.
            <br /><br /> Note: If there are times where you work on cases beyond
            those hours (e.g., depending on your client expectations, doing
            work outside your remit for the client, having too many cases, not
            understanding, or following Company protocols, work systems etc.),
            please notify the Employer who will help you reduce the need for
            your additional hours.`,
          },
        ]),
      ],
    },
  ]
}
