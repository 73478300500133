export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50, class: 'mt-6' },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElFormItem',
            children: [
              {
                $formkit: 'el-checkbox-group',
                attrs: {
                  class: 'flex flex-inline',
                },
                name: `${parentPath}.mortgage_lender_option`,
                options: [{ label: 'Mortgage Lender: ', value: 'accepted' }],
              },
            ],
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              'I confirm I have a mortgage and I want you to work for my lender (as opposed to my instructing another independent solicitor to do this).',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '1) What is Lender’s work? It is separate work which your lender will need you to carry out to allow you to move. It is common for lawyers to get this wrong. As a buyer, you risk losing your 10% deposit and being sued for tens of thousands of pounds for breach of contract. As a seller you risk failing to sell good legal title and being sued for financial loss.',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '2) Guarantee of no financial loss? Unfortunately, solicitors can, and do get lender’s work wrong. Our advice, don’t scrimp on a few pounds for a cheap solicitor. Why? Because over 30% of conveyancing negligence involves solicitor mistakes on lender work. AVRillo guarantee no financial loss to you. Firstly, because of our 0% lender negligence record. Secondly, because we guarantee you £5 million cover, in the unlikely event of our lender work being negligent.',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '3) What to do next? Tick the box and we’ll take on your lender work, liability and financial loss if we get it wrong. Alternatively, do not tick the option and you just need to notify us of the independent solicitor who will do that work for you. However, be careful on who you choose. Cheap fees for lender work can lead to mistakes by them and you losing tens of thousands of pounds.',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '4) Cost? By ticking the box you receive a discounted fee for your lender’s work of £297 + vat on a sale which includes our promise and obligation to pay off your mortgage even if you fail to pay this off (which both your buyer solicitor, and your own lender, will need before they allow you to sell). On a purchase it will be discounted to a one off £397 + vat to include our additional time and the risk and burdens of we being sued by your lender if we get it wrong. These fees are discounted only because you will be using us on your conveyancing. For example, we have solicitors charging us far more for lender work than the discounted fees we have agreed here for you.',
          },
        ],
      },
    ],
  }
}
