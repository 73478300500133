import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '28.1.',
            text: `<u>During employment:</u> The Company reserves its right
            to bring any action open to it for any breach of Employee obligations
            or other contract clauses by the Employee, in addition to any potential
            summary dismissal made (for reasons set out aforesaid in this
            contract, depending on severity and consequences etc.). As such, any
            breach will be subject to Court action, including compensation for
            losses suffered or to be suffered in consequence of the breach of
            contract and tort. Such action will be enforced together with full
            indemnity costs. These apply both during and post-termination of
            employment.`,
          },
          {
            number: '28.2.',
            text: `<u>Post post-termination conduct</u> by you that is so
            harmful to the Company that you will be subject to a claim, post-
            termination, including via the courts if necessary. These include, for
            example, breach of our restrictive covenant, copyright, intellectual
            property, confidential information, secrets, protocols, guidelines,
            training clauses.`,
          },
        ]),
      ],
    },
  ]
}
