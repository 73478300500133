import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '31.1.',
            text: `No variation or agreed termination of this agreement
            shall be effective unless it is in writing and signed by the parties
            (or their authorised representatives).`,
          },
        ]),
      ],
    },
  ]
}
