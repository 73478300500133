import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(12.2) Since 1st January 2005, has any electrical work been carried out to the property?', required: true },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.electrical_work_carried_out`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.electrical_work_carried_out === 'yes'`),
            $cmp: 'el-form-item',
            props: {
              label:
                '(12.2.1) Please supply one of the following; copy signed BS7671 Electrical Safety Certificate, installer’s Building Regulations Compliance Certificate, Building Control Completion Certificate.',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.electrical_work_carried_out_certificate`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Attached', value: 'attached' },
                  { label: 'To Follow', value: 'toFollow' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.electrical_work_carried_out_certificate === 'attached'`),
            $cmp: 'el-form-item',
            props: { label: '(12.2.1.1) Please upload the evidence', class: 'pl-6', required: true },
            children: [
              {
                $formkit: 'file-upload',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: `${parentPath}.electrical_work_carried_out_certificate_files`,
                fileName: `${parentPath}.electrical_work_carried_out_certificate_files`,
              },
            ],
          },
        ],
      },
    ],
  }
}
