import section_7_1 from '~/schemas/components/pif/environmental-matters/section_7_1'
import section_7_2 from '~/schemas/components/pif/environmental-matters/section_7_2'
import section_7_3 from '~/schemas/components/pif/environmental-matters/section_7_3'
import section_7_4 from '~/schemas/components/pif/environmental-matters/section_7_4'
import section_7_5 from '~/schemas/components/pif/environmental-matters/section_7_5'
import section_7_6 from '~/schemas/components/pif/environmental-matters/section_7_6'
import section_7_7 from '~/schemas/components/pif/environmental-matters/section_7_7'
import section_7_8 from '~/schemas/components/pif/environmental-matters/section_7_8'
import section_7_9 from '~/schemas/components/pif/environmental-matters/section_7_9'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                section_7_1(parentPath),
                section_7_2(parentPath),
                section_7_3(parentPath),
                section_7_4(parentPath),
                section_7_5(parentPath),
                section_7_6(parentPath),
                section_7_7(parentPath),
                section_7_8(parentPath),
                section_7_9(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
