import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '1.1.',
            text: 'This contract includes both contractual terms set out within the contract but will also give the Employee statutory rights as set out in The Employment Rights Act 1996 as amended principally by the Employment Relations Act 1999 and by subsequent relevant legislation, as well as other statutory rights which apply to the Employee/Employee relationship. In case of a conflict between contractual and statutory rights, the statutory ones will prevail.',
          },
          {
            number: '1.2.',
            text: 'The headings in this agreement are inserted for convenience only and shall not affect its construction.',
          },
          {
            number: '1.3.',
            text: 'A reference to a particular law is a reference to it as it is in force for the time being taking account of any amendment, extension or re-enactment and includes any subordinate legislation for the time being in force made under it.',
            extraClasses: '',
          },
        ]),
      ],
    },
  ]
}
