import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'ElFormItem',
            props: {
              label:
                '(2.1) Have any disputes or complaints occurred, or any actions taken place which might lead to a dispute, regarding this property? Eg boundary disagreement, noise, trespass etc?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.disputes`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.disputes === 'yes'`),
            $cmp: 'ElFormItem',
            props: {
              label: '(2.1.1) Please kindly provide further information',
              class: 'pl-6',
              required: true,
            },
            children: [
              {
                $formkit: 'el-input',
                name: `${parentPath}.disputes_clarify`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                inputType: 'textarea',
                rows: 6,
              },
            ],
          },
        ],
      },
    ],
  }
}
