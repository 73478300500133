import section_9_1 from '~/schemas/components/pif/parking/section_9_1'
import section_9_2 from '~/schemas/components/pif/parking/section_9_2'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [section_9_1(parentPath), section_9_2(parentPath)],
            },
          ],
        },
      ],
    },
  ]
}
