import section_8_1 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_1'
import section_8_2 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_2'
import section_8_3 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_3'
import section_8_4 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_4'
import section_8_5 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_5'
import section_8_6 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_6'
import section_8_7 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_7'
import section_8_8 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_8'
import section_8_9 from '~/schemas/components/pif/rights-and-informal-arrangements/section_8_9'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                section_8_1(parentPath),
                section_8_2(parentPath),
                section_8_3(parentPath),
                section_8_4(parentPath),
                section_8_5(parentPath),
                section_8_6(parentPath),
                section_8_7(parentPath),
                section_8_8(parentPath),
                section_8_9(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
