import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '18.1.',
            text: `The Employer must not breach intellectual property
            generally (and as further defined in this clause 19). Hereinafter
            (from now on), intellectual property rights and information are
            interchangeable with the words intellectual property. The
            obligations in this clause 19 apply to you both during your
            employment and after the termination of employment, and includes
            whether the intellectual property is patented, copyrighted,
            trademarked or not; and if breached (including passing off), you
            will be pursued by the Company by proceedings including a claim
            for damages: loss and costs.`,
          },
          {
            number: '18.2.',
            text: `What is included as intellectual property?`,
          },
          {
            number: '',
            text: `It includes (but is not limited to) the following examples:`,
          },
          {
            number: '18.2.(a).',
            text: `Protocols, rules, guidelines, training, and
            workflows the Company have invented and/or innovated and/or
            adapted and/or incorporated into the Company such as; Proclaim
            updates, dashboards, reports, reporting analysis, its systems such
            as its straight-line Conveyancing methods, strategies on winning
            and retaining business from clients and agents, including chain
            chasing, first calls, triggered precedent updates, conference
            calls, call surgeries, online payment, agent portal, client
            portal, quote apps, quote app portals, integrations with third
            parties, management of teams, work, clients, pitching, quoting,
            closing scripts, pre-sale property marketing material, quotes
            tracking, as well as ideas and implementation into how the Company
            runs and interacts inside the Company (e.g. management of its team
            and work, workflows, systems and collaboration guidelines etc.)
            but also with the outside world including clients and estate
            agents such as networking, marketing etc. to them.`,
          },
          {
            number: '18.2.(b).',
            text: `Software, coding language (created by or on
              behalf of the Company, whether in PHP, proclaim/progress coding or
              any other coding language) and work done in integrating and
              collaborating with third parties (e.g., Conveyancing Data,
                Checkboard, Coadjute, Sprift, Mio and all other third parties).`,
          },
          {
            number: '18.2.(c).',
            text: `Ideas, either verbally in video or in writing,
            such as workflows, development, improvement, innovation from the
            partners and/or under the direction of the partners; those made by
            or on behalf of the Company whether or not patentable or capable
            of registration.`,
          },
          {
            number: '18.2.(d).',
            text: `All documents drafted and/or amended by the
            Company used for and/or in the Company, for example, set out in
            their case management system, other applications, systems,
            databases, scripts, training material, guidelines, video, reports,
            emails, and the like, sent to Employees etc., are all intellectual
            property and therefore are deemed as the Company's trade secrets
            and confidential.`,
          },
          {
            number: '18.3.',
            text: `The Employee's obligations in respect of intellectual
            property and property rights, including e.g.’s in 19.2:`,
          },
          {
            number: '18.3.(a).',
            text: `to keep confidential the details of all
            intellectual property rights, innovations and works.`,
          },
          {
            number: '18.3.(b).',
            text: `whenever requested to do so by the Employer and
            in any case no later than 2 working days prior to their last date
            of employment, promptly to deliver to the Employer's partners, all
            correspondence, documents, papers, and records on all media (and
            all copies or abstracts of them), recording or relating to any
            part of the Works and the process of their creation which are in
            their possession, custody, or power.`,
          },
          {
            number: '18.3.(c).',
            text: `not to register nor attempt to register any
            Intellectual Property Rights in the Works, nor any of the
            Inventions unless for the benefit of the Employer; and`,
          },
          {
            number: '18.3.(d).',
            text: `not to give permission to any third party to use
            any of the Works or the Inventions, nor any of the Intellectual
            Property Rights in the Works.`,
          },
          {
            number: '18.3.(e).',
            text: `to give the equity Partners full written details
            of all Inventions and of all works embodying Intellectual Property
            Rights made wholly or partially by the Employee at any time during
            the course of their employment with the Company (which relate to,
            or are reasonably capable of being used in, the Company).`,
          },
          {
            number: '18.3.(f).',
            text: `The Employee acknowledges that all Intellectual
            Property Rights subsisting (or which may in the future subsist) in
            all such Inventions and works shall automatically, on creation,
            vest in the equity partners absolutely. To the extent that they do
            not vest automatically, you hold them on trust for the equity
            partners. You agree promptly to execute all documents and do all
            acts as may, in the Partners' opinion, be necessary to give effect
            to this contract.`,
          },
          {
            number: '18.3.(g).',
            text: `The Employee hereby irrevocably waives all moral
            rights under the Copyright, Designs and Patents Act 1988 (and all
            similar rights in other jurisdictions) which you have or will have
            in any existing or future works referred to in the contract.`,
          },
          {
            number: '18.3.(h).',
            text: `The Employee hereby assigns to the Company all
            existing and future Intellectual Property Rights in the Works and
            the Inventions and all materials embodying such rights to the
            fullest extent permitted by law of those worked on whilst in the
            Employ of the Company.`,
          },
        ]),
      ],
    },
  ]
}
