import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '7.1.',
            text: `Salary`,
          },
          {
            number: '',
            text: `Your salary (as agreed between Employer and Employee and set out
              in our offer letter to you) is payable monthly in arrears on the
              last calendar day of each calendar month by BACS into your bank
              account (according to the written details you provide, including
              sort code, account number, bank name, your full name as shown at
              your bank).`,
          },
          {
            number: '7.2.',
            text: `Commission (if applicable):`,
          },
          {
            number: '',
            text: `Where the Employer has, or may in the future, offer to the
            Employee a commission-based structure, with conditions, the
            Employee must always meet those conditions to continue to be
            offered such commission. These will be set out in the separate
            commission if and when offered in writing. All commission
            structures will in any event be subject to the overriding
            condition that the Employee must fully exploit the commission
            opportunities as by its very nature the purpose of commission is
            to incentivise the person on commission to take full advantage
            of the opportunities provided under the commission structure by
            the Company. So, for example, the commission potential expected
            by the Company for the Employee must be reached for the Employee
            to continue to be on commission. To illustrate: a required
            percentage of the estate agent’s work instructed each month to
            AVRillo must be met consistently over the period prescribed
            (monthly and/or quarterly and/or yearly as prescribed by the
            Employer) by an Employee who gets commission based on the number
            of instructions that become fully operational clients each
            month, and e.g., conversion rate. So, if an agent says they
            will refer 70% of their stock as new instructions, but they only
            refer e.g., 10%, then the commission structure may be withdrawn
            unless that Employee’s target level reaches 70%. The same
            principle applies across those offered commission. E.g., on
            number of completions etc. If the Employee is unable to meet
            those conditions, the commission will be withdrawn for that
            Employee, e.g., on that agent, or for all agents, with one
            month’s notice. Note also, as the Company changes, the
            commission basis may alter from time to time but not without
            prior consultation and notice. The overall objective though will
            be to grow the commission amount for the Employee based on
            performance. Commission is payable in arrears to allow for more
            accurate payment and ease. So, for example, the commission is
            calculated to the end of the month and paid in the following
            month to allow for full calculation of the Employee’s commission
            in the previous month.`,
          },
        ]),
      ],
    },
  ]
}
