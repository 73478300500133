import TextInput from '~/schemas/components/text-input'

export default function (appendedText, data) {
  const clientName = '$formData.data.general_property_information.clients.client1.name'

  return [
    {
      $el: 'div',
      attrs: {
        class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
      },
      children: [
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [
            TextInput('Title of the ' + appendedText + ' person making the gift (i.e., 1st Donor)', data + '.title', '', false, { validation: 'required' }),
          ],
        },
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [
            TextInput('First Name of the ' + appendedText + ' person making the gift', data + '.first_name_of_person', '', false, { validation: 'required' }),
          ],
        },
      ],
    },
    {
      $el: 'div',
      attrs: {
        class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
      },
      children: [
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [
            TextInput('Last Name of the ' + appendedText + ' person making the gift', data + '.last_name_of_person', '', false, { validation: 'required' }),
          ],
        },
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [
            TextInput('Email of the ' + appendedText + ' person making the gift', data + '.email_of_person', '', false, { validation: 'required|email' }),
          ],
        },
      ],
    },
    {
      $el: 'div',
      attrs: {
        class: 'flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0',
      },
      children: [
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [
            TextInput('Mobile Phone number of the ' + appendedText + ' person making the gift', data + '.mobile_phone_of_person', '', false, {
              validation: 'required',
            }),
          ],
        },
        {
          $el: 'div',
          attrs: {
            class: 'flex-grow',
          },
          children: [
            {
              $cmp: 'el-form-item',
              props: {
                class: 'el-form-item--large',
                label: `$: "Relationship of the " + ${appendedText} + " donor to " + ${clientName} + " (see the scroll options in the box below)"`,
                required: true,
              },
              children: [
                {
                  $formkit: 'el-select',
                  validation: 'required',
                  name: data + '.relationship_to_client',
                  options: ['Mother', 'Father', 'Brother', 'Sister', 'Grandparent', 'Child', 'Uncle', 'Aunt', 'Foster parent: legal Guardian', 'Cohabitee'],
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
