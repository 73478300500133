export default function (parentPath, matterTypeText) {
  return [
    {
      $cmp: 'ElRow',
      props: { gutter: 20 },
      children: [
        {
          $cmp: 'ElCol',
          props: { xs: 24, sm: 12 },
          children: [
            {
              $cmp: 'ElFormItem',
              props: {
                label: `Estate Agent (${matterTypeText})`,
                class: 'font-bold',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: parentPath + '.estate_agent',
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          $cmp: 'ElCol',
          props: { xs: 24, sm: 12 },
          children: [
            {
              $cmp: 'ElFormItem',
              props: {
                label: 'Branch Location',
                class: 'font-bold',
              },
              children: [
                {
                  $formkit: 'el-input',
                  name: parentPath + '.estate_agent_branch_location',
                  inputType: 'text',
                },
              ],
            },
          ],
        },
      ],
    },
  ]
}
