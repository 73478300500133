import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: { label: '(15.7) Has the property been adapted in any way for accessibility. Please state if:' },
            children: [
              {
                $formkit: 'el-checkbox-group',
                name: `${parentPath}.property_adapted_for_accessibility`,
                attrs: {
                  class: 'flex flex-col',
                },
                options: [
                  'Step-free access from the street to the inside the property (this can include ramps/lifts)',
                  'Wet room/level access shower',
                  'Lateral living (essential living accommodation all on the entrance level)',
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.property_adapted_for_accessibility.length > 0`),
            $el: 'div',
            attrs: {
              class: 'pl-6',
            },
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label:
                    '(15.7.1) If any of the works needed planning permission/building regulations approval, please attach the consents and final certificate:',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.consents_and_final_certificate`,
                    validation: 'required',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    options: ['Attached', "Can't locate", 'To Follow'],
                  },
                ],
              },
              {
                if: isTrueCondition(`${parentPath}.consents_and_final_certificate === 'Attached'`),
                $cmp: 'el-form-item',
                props: {
                  label: '(15.7.1.1) Please attach consents and final certificate',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'file-upload',
                    name: `${parentPath}.consents_and_final_certificate_file`,
                    fileName: `${parentPath}.consents_and_final_certificate_file`,
                    validation: 'required',
                    validationLabel: 'File upload',
                    validationVisibility: 'live',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
