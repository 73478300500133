import { fullOccupierName, isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50 },
    children: [
      {
        if: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'yes'`),
        $cmp: 'ElCol',
        props: { span: 24, class: 'mb-4' },
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'formkit-label mt-4 mb-2',
            },
            children: [
              {
                if: isTrueCondition(`${parentPath}.occupier1.occupier_tenant_or_lodger === 'yes'`),
                $el: 'span',
                children: `$: "(11.4) " + ${fullOccupierName(parentPath)} + " is a tenant or lodger"`,
              },
              {
                if: isTrueCondition(`${parentPath}.occupier1.occupier_tenant_or_lodger === 'no'`),
                $el: 'span',
                children: `$: "(11.4) " + ${fullOccupierName(parentPath)} + " is not a tenant or lodger"`,
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.other_occupier_aged_17_or_over === 'yes'`),
            $el: 'div',
            attrs: {
              class: 'formkit-label mt-4 mb-2',
            },
            children: [
              {
                if: isTrueCondition(`${parentPath}.occupier2.occupier_tenant_or_lodger === 'yes'`),
                $el: 'span',
                children: `$: "(11.4) " + ${fullOccupierName(parentPath, 'occupier2')} + " is a tenant or lodger"`,
              },
              {
                if: isTrueCondition(`${parentPath}.occupier2.occupier_tenant_or_lodger === 'no'`),
                $el: 'span',
                children: `$: "(11.4) " + ${fullOccupierName(parentPath, 'occupier2')} + " is not a tenant or lodger"`,
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.anyone_aged_over_17_lives_at_property === 'no'`),
            $el: 'div',
            attrs: {
              class: 'text-sm font-semibold bg-gray-100 p-1 text-gray-600 italic',
            },
            children: 'There is no other occupier aged 17 or over other then the owner(s)',
          },
        ],
      },
    ],
  }
}
