<script setup>
defineProps({
  context: {
    type: Object,
    required: true,
  },
})

function ordinalSuffix(i) {
  var j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return 'st'
  }
  if (j == 2 && k != 12) {
    return 'nd'
  }
  if (j == 3 && k != 13) {
    return 'rd'
  }
  return 'th'
}
</script>

<template>
  <el-card class="box-card" body-class="px-6">
    <h1 class="mb-6 text-xl font-medium">Seller Details</h1>

    <el-form label-position="top" size="large" class="mb-12 grid grid-cols-1 gap-6 md:grid-cols-2">
      <div class="flex flex-col gap-6">
        <el-form-item v-if="context.conveyancingCase?.client" label="Full Name of the 1st Seller" class="mb-0">
          <el-input :value="context.conveyancingCase?.client.name" disabled />
        </el-form-item>

        <el-form-item v-if="context.conveyancingCase?.client2" label="Full Name of the 2nd Seller" class="mb-0">
          <el-input :value="context.conveyancingCase?.client2.name" disabled />
        </el-form-item>

        <el-form-item v-if="context.conveyancingCase?.client3" label="Full Name of the 3rd Seller" class="mb-0">
          <el-input :value="context.conveyancingCase?.client3.name" disabled />
        </el-form-item>

        <el-form-item v-if="context.conveyancingCase?.client4" label="Full Name of the 4th Seller" class="mb-0">
          <el-input :value="context.conveyancingCase?.client4.name" disabled />
        </el-form-item>

        <template v-if="context.saleReadyPack && context.saleReadyPack?.report?.proprietors">
          <el-form-item
            v-for="(proprietor, index) in context.saleReadyPack?.report?.proprietors"
            :key="index"
            :label="'Full Name of the ' + (index + 1) + ordinalSuffix(index + 1) + ' Seller'"
            class="mb-0"
          >
            <el-input :value="proprietor.name" disabled />
          </el-form-item>
        </template>
      </div>

      <el-form-item label="Address of the property" class="mb-0">
        <el-input
          :value="context.conveyancingCase?.sale_property?.full_address ?? context.saleReadyPack?.report?.propertyInfo?.addressBlock"
          type="textarea"
          rows="6"
          disabled
        />
      </el-form-item>

      <h2 v-if="!context.saleReadyPack?.uuid" class="font-semibold md:col-span-2">Seller's Conveyancer</h2>

      <el-form-item v-if="!context.saleReadyPack?.uuid" label="Name of Firm" class="mb-0">
        <el-input value="AVRillo" disabled />
      </el-form-item>

      <el-form-item v-if="!context.saleReadyPack?.uuid" label="Address" class="mb-0">
        <el-input value="`257 Green Lanes,\nLondon, N13 4XE`" type="textarea" rows="6" disabled />
      </el-form-item>

      <el-form-item label="Email" class="mb-0">
        <el-input :value="context.conveyancingCase?.fee_earner?.email" disabled />
      </el-form-item>

      <el-form-item label="Reference" class="mb-0">
        <el-input :value="context.conveyancingCase?.con_case_ref ?? context.saleReadyPack?.uuid" disabled />
      </el-form-item>
    </el-form>

    <general-header-fif-text v-if="context.formType === 'fif'" :context="context" />

    <general-header-slifa-text v-if="context.formType === 'slifa'" :context="context" />
  </el-card>
</template>
