import section_2_1 from '~/schemas/components/pif/disputes-complaints/section_2_1'
import section_2_10 from '~/schemas/components/pif/disputes-complaints/section_2_10'
import section_2_2 from '~/schemas/components/pif/disputes-complaints/section_2_2'
import section_2_3 from '~/schemas/components/pif/disputes-complaints/section_2_3'
import section_2_4 from '~/schemas/components/pif/disputes-complaints/section_2_4'
import section_2_5 from '~/schemas/components/pif/disputes-complaints/section_2_5'
import section_2_6 from '~/schemas/components/pif/disputes-complaints/section_2_6'
import section_2_7 from '~/schemas/components/pif/disputes-complaints/section_2_7'
import section_2_8 from '~/schemas/components/pif/disputes-complaints/section_2_8'
import section_2_9 from '~/schemas/components/pif/disputes-complaints/section_2_9'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                section_2_1(parentPath),
                section_2_2(parentPath),
                section_2_3(parentPath),
                section_2_4(parentPath),
                section_2_5(parentPath),
                section_2_6(parentPath),
                section_2_7(parentPath),
                section_2_8(parentPath),
                section_2_9(parentPath),
                section_2_10(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
