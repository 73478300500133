import CryptoCurrency from '~/schemas/components/sof/source-of-funds-sections/crypto-currency'
import DivorceSettlement from '~/schemas/components/sof/source-of-funds-sections/divorce-settlement'
import GiftFromAThirdParty from '~/schemas/components/sof/source-of-funds-sections/gift-from-a-third-party'
import Inheritance from '~/schemas/components/sof/source-of-funds-sections/inheritance'
import MortgageLoan from '~/schemas/components/sof/source-of-funds-sections/mortgage-loan'
import OtherFunds from '~/schemas/components/sof/source-of-funds-sections/other-funds'
import OtherIncome from '~/schemas/components/sof/source-of-funds-sections/other-income'
import Pension from '~/schemas/components/sof/source-of-funds-sections/pension'
import ReMortgaging from '~/schemas/components/sof/source-of-funds-sections/re-mortgaging'
import Savings from '~/schemas/components/sof/source-of-funds-sections/savings'
import SellingAProperty from '~/schemas/components/sof/source-of-funds-sections/selling-a-property'
import SellingShares from '~/schemas/components/sof/source-of-funds-sections/selling-shares'

export default function () {
  return [
    GiftFromAThirdParty(),
    Savings(),
    OtherIncome(),
    MortgageLoan(),
    SellingAProperty(),
    ReMortgaging(),
    SellingShares(),
    DivorceSettlement(),
    Inheritance(),
    Pension(),
    CryptoCurrency(),
    OtherFunds(),
  ]
}
