import Title from '~/schemas/components/title.js'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: 'mt-4' },
      children: [
        {
          $cmp: 'el-form-item',
          props: {
            label: `(1) As our ID Check provider (Checkboard) couldn't verify your address, please upload one of the following documents.`,
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              class: 'w-full',
              name: `poa.document_type`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
              options: [
                { label: 'Bank statement dated within the last 3 months', value: 'bank_statement' },
                { label: 'Utility bill dated within the last 3 months', value: 'utility_bill' },
                { label: 'Council tax bill for the current year', value: 'council_tax_bill' },
                { label: 'Service charge invoice (current period)', value: 'service_charge_invoice' },
                { label: 'Ground rent invoice (current period)', value: 'ground_rent_invoice' },
                { label: 'Insurance policy schedule (current)', value: 'insurance_policy_schedule' },
                { label: 'Mortgage statement (most recent)', value: 'mortgage_statement' },
                { label: 'Tenancy agreement', value: 'tenancy_agreement' },
              ],
            },
          ],
        },
        {
          $cmp: 'el-form-item',
          props: { required: true },
          if: `$formData.data.poa.document_type !== undefined`,
          children: [
            Title(`(2) Please upload your document(s).`, 'mb-4'),
            {
              $formkit: 'file-upload',
              key: 'address_proof',
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
              name: `poa.address_proof.files`,
              fileName: `poa.address_proof.files`,
            },
          ],
        },
      ],
    },
  ]
}
