import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '12.1.',
            text: `An Employee may be offered additional benefits on
            commencement, and it will be up to the Employee to agree if they
            want to be offered and if offered, whether to accept those or not.
            As benefits are classed as a taxable ‘benefit in kind’ by the
            Inland Revenue, some Employees prefer not to take additional
            benefits such as health.`,
          },
        ]),
      ],
    },
  ]
}
