import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import RadioGroup from '~/schemas/components/radio-group'

export default function (tab, title, radioProp, children, subtitle = '') {
  return {
    $cmp: SectionCard,
    props: useProps({ tab: tab, title: title, sectionClass: 'mb-0', subtitle: subtitle }),
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'ml-6',
        },
        children: [RadioGroup('', radioProp, true), ...children],
      },
    ],
  }
}
