import moment from 'moment'
import SectionCard from '~/components/SectionCard.vue'
import TextWithClients from '~/components/TextWithClients.vue'
import TextWithDonors from '~/components/TextWithDonors.vue'
import innerComponents from '~/schemas/components/gift-declaration/common/inner-component-loop'
import signatureLetterToLender from '~/schemas/components/gift-declaration/common/signature-letter-to-lender'

export default function amlDeclaration() {
  return {
    if: '$conveyancingCase.mortgage_req_purchase_code === "Y" || $conveyancingCase.mortgage_req_purchase_code === "y"',
    $cmp: SectionCard,
    props: useProps({ title: 'Letter to lender.' }),
    children: [
      ...innerComponents([
        {
          number: 'To:',
          text: `$: (${`$conveyancingCase.lender_name_purchase`} || '')`,
        },
        {
          number: 'Gift made by:',
          text: [
            {
              $cmp: TextWithDonors,
            },
          ],
        },
        {
          number: 'The value of my gift:',
          text: `$: "£" + (${`$conveyancingCase.gift_amount`} || '')`,
        },
        {
          number: 'The gift is made to:',
          text: [
            {
              $cmp: TextWithClients,
            },
          ],
        },
        {
          number: 'Date:',
          text: moment(Date.now()).format('DD MMMM YYYY'),
        },
        {
          number: '',
          text: `$: "Dear " + (${`$conveyancingCase.lender_name_purchase`} || '')`,
        },
        {
          number: 'Re:',
          text: `$: "Purchase of " + (${`$conveyancingCase.purchase_property.full_address`} || '')`,
        },
        {
          number: '',
          text: { if: '$conveyancingCase.joint', then: 'We confirm the following:', else: 'I confirm the following:' },
        },
      ]),
      {
        $el: 'div',
        children: 'My Gift to the Donee:',
        attrs: {
          class: 'text-large mt-4 font-medium',
        },
      },
      //Paragraph code
      {
        $el: 'div',
        children: [
          {
            $el: 'span',
            children: { if: '$conveyancingCase.joint', then: 'We ', else: 'I' },
          },
          {
            $el: 'span',
            children: 'confirm that ',
          },
          {
            $el: 'span',
            children: { if: '$conveyancingCase.joint', then: 'we are', else: 'I am' },
          },
          {
            $el: 'span',
            children: `$: " gifting the total sum of £" + (${`$conveyancingCase.gift_amount`} || '') + " to "`,
          },
          {
            $cmp: TextWithClients,
          },
          {
            $el: 'span',
            children: ' in respect of the purchase of the above property. ',
          },
          {
            $el: 'span',
            children: { if: '$conveyancingCase.joint', then: 'We ', else: 'I' },
          },
          {
            $el: 'span',
            children: ' also confirm that this gift is non-refundable and non-interest bearing and that ',
          },
          {
            $el: 'span',
            children: { if: '$conveyancingCase.joint', then: 'we ', else: 'I' },
          },
          {
            $el: 'span',
            children: ' will not retain any interest in the property whatsoever and there are absolutely no conditions attached to the said gift.',
          },
        ],
        attrs: {
          class: 'text-xs mt-4 mb-4',
        },
      },
      {
        $el: 'div',
        attrs: {
          class: 'text-xs mt-4 font-medium',
        },
        children: [
          {
            $el: 'span',
            children: { if: '$conveyancingCase.joint', then: 'Our ', else: 'My ' },
            attrs: {
              class: 'font-bold text-xs',
            },
          },
          {
            $el: 'span',
            children: 'relationship to the Donee',
            attrs: {
              class: 'font-bold text-xs',
            },
          },
          {
            $el: 'span',
            children: ' is that of: ',
            attrs: {
              class: 'text-xs',
            },
          },
          {
            $el: 'span',
            children: `$: (${`$conveyancingCase.donors_relationships`} || '')`,
            attrs: {
              class: 'text-xs',
            },
          },
        ],
      },
      {
        $el: 'div',
        children: 'Signature',
        attrs: {
          class: 'text-lg font-semibold mt-4 mb-4',
        },
      },
      {
        $el: 'span',
        children: [signatureLetterToLender()],
      },
      {
        $el: 'div',
        children:
          "By pressing 'Submit' you confirm that this substitutes your signature as confirmation that you have understood and agreed the contents of this document.",
        attrs: {
          class: 'text-xs mt-4',
        },
      },
    ],
  }
}
