import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '13.1.',
            text: `The Company will comply with current GDPR legislation
            by keeping records on you as required and destroying these where
            required. The only exception is where we need to keep such records
            required by law or a court process or governing body, regulator.`,
          },
        ]),
      ],
    },
  ]
}
