import TermsAndConditionsText from '~/components/TermsAndConditionsText.vue'
import { useProps } from '~/composables/useProps'

export default function (parentPath) {
  return {
    $cmp: 'ElCol',
    props: { span: 24 },
    children: [
      {
        $cmp: 'ElFormItem',
        props: { required: true },
        children: [
          {
            $formkit: 'el-checkbox-group',
            attrs: {
              class: 'flex flex-inline required',
            },
            name: `${parentPath}.instructions_accepted`,
            options: [{ label: 'Tick to instruct & accept our Terms & Conditions:', value: 'accepted' }],
            validation: 'required',
            validationMessages: {
              required: 'This field is required',
            },
            inline: true,
            inlinePrompt: true,
          },
        ],
      },
      {
        $el: 'div',
        attrs: { class: 'text-sm' },
        children: [
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              '1) I confirm that I have accurately completed this instruction form (including correct spellings) so AVRillo can place full reliance on this to start drafting all legal documents on my behalf. I also confirm that my ticking the terms box is a valid replacement for my written signature.',
          },
          {
            $el: 'div',
            attrs: { class: 'mb-4' },
            children:
              "2) I confirm that I have read, understood and agreed AVRillo's estimate letter, and 'terms' (found in the scroll down in the Terms of Business box) and will only tick for optional additional services (if required) and understand if I do not tick them now they will be closed to me if I try to choose them later.",
          },
          {
            $el: 'div',
            attrs: {
              class: 'overflow-scroll h-96 rounded-md text-small border border-2 border-gray-300 border-dashed text-xs',
            },
            children: [
              {
                $cmp: TermsAndConditionsText,
                props: useProps(),
              },
            ],
          },
        ],
      },
    ],
  }
}
