import { isTrueCondition } from '~/schemas/components/common/helpers'

export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 20 },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $cmp: 'el-form-item',
            props: {
              label: '(4.8) To your knowledge, does a tree preservation order apply to any trees within the boundaries of the property?',
              required: true,
            },
            children: [
              {
                $formkit: 'el-radio-group',
                name: `${parentPath}.tree_preservation_order`,
                validation: 'required',
                validationMessages: {
                  required: 'This field is required',
                },
                options: [
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' },
                  { label: 'Not known', value: 'notKnown' },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.tree_preservation_order === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(4.8.1) If any work has been carried out to any trees which are protected by the order, were the relevant consents obtained?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.tree_preservation_order_consent`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not applicable', value: 'notApplicable' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.tree_preservation_order_consent === 'yes'`),
                    $cmp: 'ElRow',
                    props: { gutter: 20, class: 'pl-8' },
                    children: [
                      {
                        $cmp: 'ElCol',
                        props: { span: 24 },
                        children: [
                          {
                            $cmp: 'el-form-item',
                            props: { label: '(4.8.1.1) Please supply a copy of the relevant consent(s)', required: true },
                            children: [
                              {
                                $formkit: 'el-radio-group',
                                name: `${parentPath}.tree_preservation_order_consent_documents`,
                                validation: 'required',
                                validationMessages: {
                                  required: 'This field is required',
                                },
                                options: [
                                  { label: 'Attached', value: 'attached' },
                                  { label: "Can't locate", value: 'cantLocate' },
                                  { label: 'Did not obtain', value: 'didNotObtain' },
                                  { label: 'To Follow', value: 'toFollow' },
                                ],
                              },
                            ],
                          },
                          {
                            if: isTrueCondition(`${parentPath}.tree_preservation_order_consent_documents === 'attached'`),
                            $cmp: 'el-form-item',
                            props: { label: '(4.8.1.1.1) Please upload the relevant document(s)', required: true },
                            children: [
                              {
                                $formkit: 'file-upload',
                                name: `${parentPath}.tree_preservation_order_consent_documents_files`,
                                fileName: `${parentPath}.tree_preservation_order_consent_documents_files`,
                                validation: 'required',
                                validationLabel: 'File upload',
                                validationVisibility: 'live',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            if: isTrueCondition(`${parentPath}.tree_preservation_order === 'yes'`),
            $cmp: 'ElRow',
            props: { gutter: 20, class: 'pl-8' },
            children: [
              {
                $cmp: 'ElCol',
                props: { span: 24 },
                children: [
                  {
                    $cmp: 'el-form-item',
                    props: {
                      label: '(4.8.2) Have the terms of the Order been breached (i.e. you have not complied with the terms of the Tree preservation order)?',
                      required: true,
                    },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.tree_preservation_order_breached`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Yes', value: 'yes' },
                          { label: 'No', value: 'no' },
                          { label: 'Not known', value: 'notKnown' },
                        ],
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { label: '(4.8.3) Please supply a copy of the Tree Preservation Order', required: true },
                    children: [
                      {
                        $formkit: 'el-radio-group',
                        name: `${parentPath}.tree_preservation_order_documents`,
                        validation: 'required',
                        validationMessages: {
                          required: 'This field is required',
                        },
                        options: [
                          { label: 'Attached', value: 'attached' },
                          { label: "Can't locate", value: 'cantLocate' },
                          { label: 'To Follow', value: 'toFollow' },
                        ],
                      },
                    ],
                  },
                  {
                    if: isTrueCondition(`${parentPath}.tree_preservation_order_documents === 'attached'`),
                    $cmp: 'el-form-item',
                    props: { label: '(4.8.3.1) Please upload the relevant document(s)', required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        name: `${parentPath}.tree_preservation_order_documents_files`,
                        fileName: `${parentPath}.tree_preservation_order_documents_files`,
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
