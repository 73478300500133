import section_6_1 from '~/schemas/components/pif/insurances/section_6_1'
import section_6_2 from '~/schemas/components/pif/insurances/section_6_2'
import section_6_3 from '~/schemas/components/pif/insurances/section_6_3'
import section_6_4 from '~/schemas/components/pif/insurances/section_6_4'
import section_6_5 from '~/schemas/components/pif/insurances/section_6_5'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [section_6_1(parentPath), section_6_2(parentPath), section_6_3(parentPath), section_6_4(parentPath), section_6_5(parentPath)],
            },
          ],
        },
      ],
    },
  ]
}
