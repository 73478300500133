export default function (label, name, required = false, classProp = 'w-full') {
  return {
    $cmp: 'ElFormItem',
    props: {
      label: label,
      required: required,
    },
    children: [
      {
        $formkit: 'el-date-picker',
        name: name,
        class: classProp,
        placeholder: 'DD/MM/YYYY',
        validation: required ? 'required' : '',
        validationMessages: {
          required: 'This field is required',
        },
      },
    ],
  }
}
