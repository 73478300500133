import RadioGroup from '~/schemas/components/radio-group'
import BankDetails from '~/schemas/components/sof/source-of-funds-sections/bank-details'
import Title from '~/schemas/components/title'

export default function (data) {
  return [
    ...BankDetails(`${data}.bank_details`),
    {
      $el: 'div',
      attrs: {
        class: 'mt-4',
      },
    },
    RadioGroup('Please upload your share certificate or portfolio.', `${data}.share_certificate_or_portfolio`, true, ['Portfolio', 'Shared Certificate']),
    {
      $el: 'div',
      if: `$formData.data.${data}.share_certificate_or_portfolio`,
      children: [
        {
          $el: 'div',
          attrs: {
            class: 'mb-4 mt-4',
          },
          children: {
            if: `$formData.data.${data}.share_certificate_or_portfolio === "Portfolio"`,
            then: Title('Please add portfolio document'),
            else: Title('Please add share certificate document'),
          },
        },
        {
          $cmp: 'el-form-item',
          props: { required: true },
          children: [
            {
              $formkit: 'file-upload',
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
              name: `${data}.share_certificate_or_portfolio_files`,
              fileName: `$formData.data.${data}.share_certificate_or_portfolio_files`,
            },
          ],
        },
      ],
    },
  ]
}
