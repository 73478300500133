export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        {
          $el: 'h1',
          children: '(6.1) Notices from landlord or management company to you.',
          attrs: {
            class: 'text-large mb-4 mt-4',
          },
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(6.1.1) Have you received a notice from the landlord that they wish to sell the property?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'notices.have_you_received_a_notice_from_the_landlord_that_they_wish_to_sell_the_property',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(6.1.2) Please provide a copy of the notice', required: true },
          if: `$formData.data.notices.have_you_received_a_notice_from_the_landlord_that_they_wish_to_sell_the_property === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'notices.provide_a_copy_of_the_notice_from_the_landlord',
              options: ['Attached', "Can't Locate", 'Did not obtain', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.notices.provide_a_copy_of_the_notice_from_the_landlord === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `notices.provide_a_copy_of_the_notice_from_the_landlord_files`,
              fileName: `notices.provide_a_copy_of_the_notice_from_the_landlord_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label:
              '(6.2) Have you received any notice/s regarding your lease and/or your property, for example relating to: use of the property, its condition, repair, or maintenance?',
            required: true,
          },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'notices.have_you_received_any_notices_regarding_your_lease_and_or_your_property',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(6.2.1) Please provide a copy of the notice', required: true },
          if: `$formData.data.notices.have_you_received_any_notices_regarding_your_lease_and_or_your_property === "Yes"`,
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'notices.provide_a_copy_of_the_notice_your_lease_and_or_your_property',
              options: ['Attached', "Can't Locate", 'Did not obtain', 'To Follow'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          if: `$formData.data.notices.provide_a_copy_of_the_notice_your_lease_and_or_your_property === "Attached"`,
          props: { class: 'pl-6 section-indent', required: true },
          children: [
            {
              $formkit: 'file-upload',
              name: `notices.provide_a_copy_of_the_notice_your_lease_and_or_your_property_files`,
              fileName: `notices.provide_a_copy_of_the_notice_your_lease_and_or_your_property_files`,
              validation: 'required',
              validationLabel: 'File upload',
              validationVisibility: 'live',
            },
          ],
        },
        {
          $el: 'h1',
          children: '(6.3) Notices from you to the landlord and/ or management company',
          attrs: {
            class: 'text-large mb-4',
          },
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(6.3.1) To what address should notices be sent?', required: true },
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'notices.where_should_notices_be_sent',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(6.3.2) Is a Notice of Assignment and Charge required?', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'notices.notice_of_assignment_and_charge_required',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: 'What is landlord and/or management company cost?', class: 'section-indent', required: true },
          if: `$formData.data.notices.notice_of_assignment_and_charge_required === "Yes"`,
          children: [
            {
              $formkit: 'el-input',
              name: 'notices.landlord_and_or_management_company_cost',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: { label: '(6.3.3) Are you required to take a share in the management company', required: true },
          children: [
            {
              $formkit: 'el-radio-group',
              name: 'notices.are_you_required_to_take_a_share_in_the_management_company',
              options: ['Yes', 'No'],
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $cmp: 'ElFormItem',
          props: {
            label: 'Please provide details of the procedure and fees charged by the landlord and/or management company.',
            class: 'section-indent',
            required: true,
          },
          if: `$formData.data.notices.are_you_required_to_take_a_share_in_the_management_company === "Yes"`,
          children: [
            {
              $formkit: 'el-input',
              inputType: 'textarea',
              rows: 4,
              name: 'notices.please_provide_details_of_the_procedure_and_fees_charged_by_the_landlord_and_or_management_company',
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
      ],
    },
  ]
}
