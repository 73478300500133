import RadioGroup from '~/schemas/components/radio-group'
import BankDetails from '~/schemas/components/sof/source-of-funds-sections/bank-details'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function (clientName) {
  return {
    $el: 'div',
    attrs: {
      class: 'el-form--label-top',
    },
    children: [
      RadioGroup('Have you already sold your shares?', `sold_shares`, true),
      {
        $el: 'div',
        attrs: {
          class: 'mt-4',
        },
        if: `$value.sold_shares === 'yes'`,
        children: [
          TextInput(
            '(a) Please provide full details of the shares already sold.\n' + '(Name, number of shares, approximate value of each share)',
            `share_details`,
            'textarea',
            false,
            { validation: 'required' },
          ),
          {
            $el: 'div',
            attrs: {
              class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
            },
            children: [
              TextInput('(b) How much money have the shares produced when sold?', `how_much_money_when_sold`, 'number', true, { validation: 'required' }),
              TextInput('(c) What is the name of the bank you will send your shares money to?', `bank_name`, '', false, {
                validation: 'required',
              }),
            ],
          },
          {
            $el: 'div',
            attrs: {
              class: 'grid grid-cols-1 md:grid-cols-2 gap-4',
            },
            children: [
              TextInput('(d) Sort Code', `bank_details.sort_code`, '', false, { validation: 'required' }),
              TextInput('(e) Account Number', `bank_details.account_number`, '', false, { validation: 'required' }),
            ],
          },
          Title('(f) Please upload a bank statement dated within the last 3 months.', 'mb-4'),
          {
            $cmp: 'el-form-item',
            props: { required: true },

            children: [
              {
                $formkit: 'file-upload',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: `bank_details.files`,
                fileName: `$value.bank_details.files`,
              },
            ],
          },
          Title(
            '(g) Please upload evidence of the shares were sold.\n' + '(e.g., a letter from your stockbroker or the company registrar showing shares sold)',
            'mb-4 mt-6',
          ),
          {
            $cmp: 'el-form-item',
            props: { required: true },
            children: [
              {
                $formkit: 'file-upload',
                validation: 'required',
                validationLabel: 'File upload',
                validationVisibility: 'live',
                name: `share_evidence.files`,
                fileName: `$value.share_evidence.files`,
              },
            ],
          },
        ],
      },
      {
        $el: 'div',
        if: `$value.sold_shares === 'no'`,
        children: [
          TextInput(
            '(a) Please provide full details of the shares being sold. (Name, number of shares, approximate value of each share)',
            `share_details`,
            'textarea',
            false,
            { validation: 'required' },
          ),
          {
            $el: 'div',
            attrs: {
              class: 'grid grid-cols-2 gap-4',
            },
            children: [
              TextInput('(b) How much money will the shares produce when sold?', `how_much_money_when_sold`, 'number', true, {
                validation: 'required',
                class: 'mt-5',
              }),
              TextInput('(c) What is the name of the bank account the proceeds from the sale of your shares are held?', `bank_name`, '', false, {
                validation: 'required',
              }),
            ],
          },
          RadioGroup(`$: "(d) Is the bank account in your name " + ${clientName} + "?"`, `bank_account_in_client_name`, true),
          {
            $el: 'div',
            if: `$value.bank_account_in_client_name === 'yes'`,
            children: [
              ...BankDetails(`bank_details`),
              {
                $el: 'div',
                attrs: {
                  class: 'mt-4',
                },
              },
              RadioGroup('Please upload your share certificate or portfolio.', `share_certificate_or_portfolio`, true, ['Portfolio', 'Shared Certificate']),
              {
                $el: 'div',
                if: `$value.share_certificate_or_portfolio`,
                children: [
                  {
                    $el: 'div',
                    attrs: {
                      class: 'mb-4 mt-4',
                    },
                    children: {
                      if: `$value.share_certificate_or_portfolio === "Portfolio"`,
                      then: Title('Please add portfolio document'),
                      else: Title('Please add share certificate document'),
                    },
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { required: true },
                    children: [
                      {
                        $formkit: 'file-upload',
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                        name: `share_certificate_or_portfolio.files`,
                        fileName: `$value.share_certificate_or_portfolio.files`,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            $el: 'div',
            attrs: {
              class: 'section-indent',
            },
            if: `$value.bank_account_in_client_name === 'no'`,
            children: [
              RadioGroup(
                'Is this bank account still yours but slightly different on the account name?',
                `is_account_yours_but_in_slightly_different_name`,
                true,
              ),
              {
                $el: 'div',
                if: `$value.is_account_yours_but_in_slightly_different_name === 'yes'`,
                children: [
                  ...BankDetails(`bank_details`),
                  {
                    $el: 'div',
                    attrs: {
                      class: 'mt-4',
                    },
                  },
                  RadioGroup('Please upload your share certificate or portfolio.', `share_certificate_or_portfolio`, true, ['Portfolio', 'Shared Certificate']),
                  {
                    $el: 'div',
                    if: `$value.share_certificate_or_portfolio`,
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'mb-4 mt-4',
                        },
                        children: {
                          if: `$value.share_certificate_or_portfolio === "Portfolio"`,
                          then: Title('Please add portfolio document'),
                          else: Title('Please add share certificate document'),
                        },
                      },
                      {
                        $cmp: 'el-form-item',
                        props: { required: true },
                        children: [
                          {
                            $formkit: 'file-upload',
                            validation: 'required',
                            validationLabel: 'File upload',
                            validationVisibility: 'live',
                            name: `share_certificate_or_portfolio.files`,
                            fileName: `share_certificate_or_portfolio.files`,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                $el: 'div',
                if: `$value.is_account_yours_but_in_slightly_different_name === 'no'`,
                attrs: {
                  class: 'text-red-600 mt-4',
                },
                children: [
                  {
                    $el: 'div',
                    children:
                      'As this account is in a third party name (i.e., not yours) this seems to be a gift. So, please enter this money in the gift section above, and also email your conveyancer that your shares are being transferred to a third party account as this can cause issues. (Please email to your conveyancer: ea@avrillo.co.uk)',
                  },
                  {
                    $formkit: 'hidden',
                    key: 'not_allowed',
                    name: 'third_party.not_allowed',
                    validation: 'required',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
