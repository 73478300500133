import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '17.1.',
            text: `This clause protects copyright documents and material
            (from now on referred to as 'documents' for ease of reference),
            including those illustrated in this clause.`,
          },
          {
            number: '17.2.',
            text: `Copyrighted documents include inter alia (among other
              things), documents, workflows, and precedents produced by or on
              behalf of the Company, whether on physical written or email
              documents or as contained in the case management system (e.g.,
              Proclaim) and any other system described within this contract
              generally.`,
          },
          {
            number: '17.3.',
            text: `This clause confirms that the Copyright of all
            documents, as mentioned earlier, will remain the Copyright of the
            firm, both during and after your employment ends.`,
          },
          {
            number: '17.4.',
            text: `On termination of your employment, you will hand over
            all such original and copy precedents and documents to the firm.`,
          },
          {
            number: '17.5.',
            text: `Breach of this clause will trigger immediate action
            (and you agree to waive the protocol notice period) for the
            Employer to sue you for breach of Copyright. The aforesaid court
            action can range from a court injunction to a claim for financial
            loss and other loss arising for any breach of this clause and the
            confidentiality and disclosure of Copyright as above (whether,
            e.g., disclosure is made during or after your employment ends).`,
          },
        ]),
      ],
    },
  ]
}
