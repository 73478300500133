export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: {
      gutter: 20,
    },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $el: 'div',
            children: '(15.5) Please confirm the number of rooms in the property',
            attrs: {
              class: 'text-sm mb-4',
            },
          },
          {
            $el: 'div',
            attrs: {
              class: 'text-sm mb-2 pl-6',
            },
            children: [
              {
                $cmp: 'el-form-item',
                props: {
                  label: '(15.5.1) Bedrooms',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.bedrooms`,
                    validation: 'required',
                    value: '$saleReadyPack.request.bedrooms',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    options: ['1', '2', '3', '4', '5', '6+'],
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                props: {
                  label: '(15.5.2) Bathrooms',
                  required: true,
                },
                children: [
                  {
                    $formkit: 'el-radio-group',
                    name: `${parentPath}.bathrooms`,
                    validation: 'required',
                    value: '$saleReadyPack.request.bathrooms',
                    validationMessages: {
                      required: 'This field is required',
                    },
                    options: ['1', '2', '3', '4', '5', '6+'],
                  },
                ],
              },
              {
                $cmp: 'el-form-item',
                props: { label: '(15.5.3) Please confirm if there are any other types of rooms' },
                children: [
                  {
                    $formkit: 'el-checkbox-group',
                    name: `${parentPath}.other_rooms`,
                    options: ['Reception room', 'Dining room', 'Utility room', 'Conservatory', 'Outhouse'],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
