import { useMobileStore } from '~/stores/mobile.js'

export function isFalseCondition(path) {
  return `$formData.data.${path} == false || $formData.data.${path} == undefined`
}
export function isTrueCondition(pathOrCondition) {
  return `$formData.data.${pathOrCondition}`
}
export function isClientExpression(parentPath, client = 1) {
  return `${parentPath} == clients.client${client}`
}
export function isBaspiOnly() {
  return `$formData.is_baspi_visible`
}
export function fullOccupierName(parentPath, occupier = 'occupier1') {
  return `(${`$formData.data.${parentPath}.${occupier}.title`} || "") + ' ' + (${`$formData.data.${parentPath}.${occupier}.forename`} || "") + ' ' + (${`$formData.data.${parentPath}.${occupier}.surname`} || "")`
}
export const phoneValidation = async function ({ value }) {
  const store = useMobileStore()
  return await store.getMobileVerification(value)
}
export const trueValidation = async function ({ value }) {
  return [true, 'true', 1, '1'].includes(value)
}
export const phoneFormatValidation = function ({ value }) {
  const phoneRegex =
    /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/
  return phoneRegex.test(value)
}
export const compareTwoValues = function ({ value }, args) {
  const formStore = useFormStore()
  return value !== formStore?.payload[args] ?? ''
}
export const isStandalone = function () {
  const formStore = useFormStore()
  return formStore?.form?.is_standalone === true
}
export function isMatterType(targetMatterType) {
  const matter_type_code = getCaseVariable('matter_type_code')
  const validTypes = matter_type_code === 'B' ? ['S', 'P'] : [matter_type_code]
  return validTypes.includes(targetMatterType)
}
export function getCaseVariable(propertyName) {
  const conveyancingCaseStore = useConveyancingCaseStore()
  const quoteCaseStore = useQuoteCaseStore()
  return conveyancingCaseStore.conveyancingCase?.[propertyName] || quoteCaseStore.quoteCase?.[propertyName]
}
