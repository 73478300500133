<script setup>
import moment from 'moment'

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
})

const leaseData = computed(() => props.context.saleReadyPack?.report?.propertyDetails?.leaseData)
const purchaseDate = computed(() => leaseData.value?.leasePurchaseDate)
const endDate = computed(() => leaseData.value?.leaseEndDate)
const term = computed(() => leaseData.value?.term)
const yearsLeft = computed(() => leaseData.value?.leaseYearsLeft)
</script>

<template>
  <div>
    <h1 class="text-large mb-4">Lease Information</h1>
    <el-form-item v-if="purchaseDate" label="(1.4.1) Lease Start Date" class="mb-2">
      <el-input :value="moment(purchaseDate).format('DD MMMM YYYY')" disabled />
    </el-form-item>
    <el-form-item v-if="endDate" label="(1.4.2) Lease End Date" class="mb-2">
      <el-input :value="moment(endDate).format('DD MMMM YYYY')" disabled />
    </el-form-item>
    <el-form-item v-if="term" label="(1.4.3) Lease Term" class="mb-2">
      <el-input :value="`${term} year${term > 1 ? 's' : ''}`" disabled />
    </el-form-item>
    <el-form-item v-if="yearsLeft" label="(1.4.4) Lease Remaining" class="mb-2">
      <el-input :value="`${yearsLeft} year${yearsLeft > 1 ? 's' : ''}`" disabled />
    </el-form-item>
  </div>
</template>
