import section_5_1 from '~/schemas/components/pif/guarantees-warranties/section_5_1'
import section_5_2 from '~/schemas/components/pif/guarantees-warranties/section_5_2'
import section_5_3 from '~/schemas/components/pif/guarantees-warranties/section_5_3'
import section_5_4 from '~/schemas/components/pif/guarantees-warranties/section_5_4'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [section_5_1(parentPath), section_5_2(parentPath), section_5_3(parentPath), section_5_4(parentPath)],
            },
          ],
        },
      ],
    },
  ]
}
