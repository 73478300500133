import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '29.1.',
            text: `This agreement and any document referred to in it
            constitutes the entire agreement between the parties and supersedes
            and extinguishes all previous agreements, promises, assurances,
            warranties, representations, and understandings between them, whether
            written or oral relating to its subject matter. Each party
            acknowledges that in entering into this agreement, it does not rely
            on, and shall have no remedies in respect of, any statement,
            representation, assurance, or warranty (whether made innocently or
            negligently) that is not set out in this agreement. Each party agrees
            that it shall have no claim for innocent or negligent
            misrepresentation or negligent misstatement based on any statement in
            this agreement.`,
          },
        ]),
      ],
    },
  ]
}
