import innerComponents from '~/schemas/components/common/inner-component-loop'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '10.1.',
            text: `The Employee shall be entitled to 33 days' paid
            holiday, calculated as follows: 25 days' paid holiday in each
            calendar year each year (calculated pro-rata) based on 40-hour
            contractual working weeks. Plus, the Company will pay additional
            public holidays for hours normally worked on those days, thereby
            making a total of 33 days' holiday paid by the Company. The
            Company reserves the right to ask the Employee to allocate 4 of
            those 25 days' paid holiday to be taken during Christmas and the
            New Year break each year (not falling on public Bank Holidays)
            during that period. If the employment ends and the Employee has
            taken more than their accrued holiday entitlement, the Employee
            must return the overpayment. The Employee cannot carry forward any
            accrued but untaken holiday entitlement to a subsequent holiday
            year.`,
          },
          {
            number: '10.2.',
            text: `Holiday requests must be made using the firm’s
            holiday request form, in advance (below) to plan for cover and
            subject to agreement. Within reason the partners will consider
            shorter advance notice periods depending on the circumstances.
            Where requests made for holiday dates overlap with other staff
            within the same department (or risk disruption to the Employee’s
            clients and/or agents and/or the firm) the holiday request will be
            considered for that said period on a first come first allocated
            basis unless the party who requests first allows you to take
            precedence on a goodwill basis and approved by the partners.
            Normal advance notice periods for holiday are:
            <br /><br />
            1-2 consecutive working days, 2 weeks' advance notice
            <br /><br />
            3 consecutive working days, 3 weeks' advance notice
            <br /><br />
            4 consecutive working days, 4 weeks' advance notice
            <br /><br />
            5-10 consecutive working days, 6 weeks' advance notice
            <br /><br />
            11+ consecutive working days, 12 weeks' advance notice`,
          },
        ]),
      ],
    },
  ]
}
