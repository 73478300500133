import innerComponents from '~/schemas/components/common/inner-component-loop'
import partner1 from '~/schemas/components/staff-contract/roles/sub-partner/Partner1'

export default function () {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: '' },
      children: [
        ...innerComponents([
          {
            number: '35.',
            text: `<b>Salaried Partnership clauses applicable to you.</b>`,
          },
          {
            number: '35.1.',
            text: `Your appointment will be as a Salaried Partner, but you keep your employment status and benefits.`,
          },
          {
            number: '35.1.1.',
            text: `This clause confirms that your appointment as a salaried partner does not affect your status as an employee, does not affect your employee employment rights, nor affect your continuous length of service from your original start date at AVRillo, nor affect your HMRC taxation status as an employee.`,
          },
          {
            number: '35.1.2.',
            text: `This clause also records the fact that third parties and bodies such as lenders, court, debtors, HMRC (and their practice note "partnerships, salaried members, taxation of individual members of the LLP") are allowed to look behind your label and consider instead the actual characteristics of your role to assess your actual position. This clause further sets out some of the characteristics you can show to prove to such bodies that you are still an employee in your position as a salaried partner, including the following examples:`,
          },
          {
            number: '(a).',
            text: `You continue to be a fee earner conveyancer and team leader, with additional time spent on assisting in management,`,
          },
          {
            number: '(b).',
            text: `You do not share in the losses, or profits, of the equity partners, the LLP partnership,`,
          },
          {
            number: '(c).',
            text: `You do not have the obligations, responsibilities and liabilities of an equity partner or member of AVRillo`,
          },
          {
            number: '(d).',
            text: `You do not have a right to dissolve the AVRillo LLP partnership`,
          },
          {
            number: '(e).',
            text: `You cannot override the ultimate decision of an equity partner`,
          },
          {
            number: '(f).',
            text: `You have not made a contribution of money into buying a share in the equity partnership. as you have need appointed s=as a salaried partner, in the status of an employee.`,
          },
          {
            number: '(g).',
            text: `Your employee status will not be changed by, for example, lenders, LMS, Lender Exchange, Companies House or Regulator, requesting and you complying by your agreement here, to comply with requests (made by the described aforesaid third party bodies) for you to be named as a Partner and comply with their registrations or records requirements.`,
          },
          {
            number: '35.1.3.',
            text: `This clause can also be shown to the HMRC to evidence that you are an employee, and therefore, they should deal with you as such.`,
          },
          {
            number: '35.2.',
            text: `Benefits to you as a salaried partner (employee), provided by the equity partners on behalf of AVRillo LLP partnership, subject to the content of the clauses set out below (including the note sections):`,
          },
          {
            number: '',
            text: `Note 1. Benefit paid to you under clauses 35.2.4 to 32.2.8 be repayable or clawed back by AVRillo if you leave within a certain time from the date of the benefit paid because whilst these are paid loyalty payment as salaried partner they also relate to your conveyancing fee earning performance. If you stop working within rather dates provided in these clauses, it goes against the spirit of the loyalty of a salaried partner being the the essence of you being appointed as a salaried partner.`,
          },
          {
            number: '',
            text: `Note 2. For goodwill, we will exempt you  you (in full or partially, depending on the individual benefit, below) if you leave due to unforeseen personal circumstances (health reasons or for you to care for someone) but providing you do not work in the legal industry for 12 months after you stop date, whether your work elsewhere as self-employed or as an employee, lawyer, conveyancer, manager, consultant, in house legal or legal operations team or any other similar position).`,
          },
          {
            number: '',
            text: `Note 3. Any reference to a gross benefit figure means the equivalent net after the  gross has been adjusted down after tax has been deducted (as the said taxation is outside our control and only within HMRC control). This definition of "gross" will be applied throughout this contract where the word "gross" is referred to`,
          },
          {
            number: '35.2.1.',
            text: `<b>1st benefit: One extra day for your Birthday off</b> agreed by on behalf of the partnership.`,
          },
          {
            number: '',
            text: `As a salaried partner employee, you will receive an entire day off work, paid as a gift from the equity partnership. If you want to defer that day off, say to a Monday or Friday, and your Birthday does not fall on that day, you can do this with prior authority if your cases are reasonably covered; this clause is again subject to the confidentiality clause to ensure no other employee finds out about this bespoke provision for you.`,
          },
          {
            number: '35.2.2.',
            text: `<b>2nd benefit: Five additional holidays paid</b> by the equity partners on behalf of the partnership.`,
          },
          {
            number: '',
            text: `As a salaried partner employee, you will receive an additional 5 days off on top of your 20 working days, plus an additional birthday day off paid holiday, making 26 working days holiday, plus the additional 8 bank holidays paid for by the company. This means that you, as a salaried partner employee, have 34 days paid holiday a year (subject to the provisions on holidays cited elsewhere in this contract and the confidentiality provisions, to ensure no other employee finds out about this bespoke provision for you).`,
          },
          {
            number: '35.2.3.',
            text: `<b>3rd benefit: Increase of £10,000</b> gross (adjusted to net after tax ) added to your salary from the 1st March 2022.`,
          },
          {
            number: '35.2.4.',
            text: `<b>4th benefit: Payment of £20,000 *gross</b> (adjusted to net after tax ) over 5 years following your appointment to Salary Partner on 22nd October 2021, as follows:`,
          },
        ]),
        {
          $el: 'span',
          children: partner1(),
        },
        ...innerComponents([
          {
            number: '',
            text: `Note 1: Rather than the £20,000 gross (adjusted to net of tax), being repayable in full if you stop working for AVRillo within 5 full years of you being appointed as salaried partner, you will instead only ever be liable to pay (if you stop working before the 5th year anniversary), the last instalment of £4000 gross (adjusted to net after tax), for the instalment paid in the period prior to you stopping working at avrillo.`,
          },
          {
            number: '',
            text: `<u>Note 2: Exemption and conditions to gain exemption for repayment after stopping work for unusual personal circumstances:</u>
            <br /><br />
            If the salaried partner Employee stops working for AVRillo for reasons of ill health or attending to the care of family members, or any other such unforeseen personal circumstances, the Employee's requirement to repay the last instalment of £4000 gross (adjusted to net of tax) shall be waived providing the Employee, of course, does not work in the legal industry in the 12 months following their stop date. The said 12 months is a reasonable period because the benefit paid represents a payment to their continued loyalty and future in AVRillo. Therefore if the Employee returns to the legal industry within 12 months but does not return to AVRillo but instead works elsewhere in the legal industry, they will have defeated the objective and spirit of the loyalty benefit (being part of the £20,000 loyalty salaried partner benefit paid over five years). It will be inequitable and against the spirit of the loyalty payment for the salaried partner to use the exemption for stopping work at avrillo for unforeseen personal circumstances but then return to the legal industry within 12 months but for someone other than AVRillo who paid them the loyalty payment and who can accommodate them by remote working or any other adjustment needed (e.g. of any other adjustment includes if the salaried partner needs to return to work part-time, the other company can accommodate them, but so can AVRillo, in which the clawback applies).`,
          },
          {
            number: '',
            text: `Note 3. The principle described above in Note 2 (that of repayment periods for exemptions if the salaried partner elsewhere in the legal industry within 12 months as aforesaid) applies to all benefits between 35.2.4 to 35.2.8.`,
          },
          {
            number: '',
            text: `Note 4. The definition of the legal industry for the purpose of this contract means a firm anywhere in England & Wales (as we operate nationally) and whether or not regulated by the CLC or SRA, or a company who deals with conveyancing but is not regulated; and applies whether your position-is in house, panel manager, in operations etc.or as licensed conveyancer, solicitor, conveyancer, assistant, , , consultant, supervisor etc., and whether as employee or self-employed.`,
          },
          {
            number: '35.2.5.',
            text: `<b>5th benefit: £10,000 maternity gross for your first maternity period as a salaried partner</b> agreed by the equity partners on behalf of the partnership as a goodwill payment to reward a combination for your performance to date of maternity, a return to the same performance and future loyalty as otherwise rather firm does not pay any goodwill maternity pay, as is set out here.`,
          },
          {
            number: '',
            text: `Note 1: This is an optional opt-in benefit for salaried partners who have not yet taken this benefit. If  you decide not to opt for this when you give notice of yiur first maternity leave, we will assume you do not want this benefit, and as such, you will not be paid this sum. Therefore, this clause will only be triggered if you specially request the maternity goodwill benefit.`,
          },
          {
            number: '',
            text: `Note 2: The said £10,000 gross (adjusted to net after tax), will be divided into 10 monthly payments of £1000 (gross). The first payment starts from the first month of maternity leave, subject to refund or clawback in (a) and (b).`,
          },
          {
            number: '',
            text: `<u>(a). First potential repayment, clawback</u> of the £10,000 maternity gross, is where you stop working for AVRillo within 36 actual working months after your return from maternity leave.`,
          },
          {
            number: '',
            text: `Note 1: The reason for this period of 36 months is because this additional payment is an additional loyalty benefit based on the salaried partner wanting to stay with AVRillo after their return from maternity and not leave within 36 months of their return from maternity leave`,
          },
          {
            number: '',
            text: `Note 2: * 'Actual working months' means you actually work on live cases for 36 months after you return from maternity leave.`,
          },
          {
            number: '',
            text: `Note 3: What happens if you take a subsequent period of maternity before the expiry of the 36 months from your return from your previous maternity leave? The balance of the first 36 months left to run will be added to the second post return to work 36 month period. So, e.g.  If you have worked for 24 months after you return from your first maternity leave, and start your second maternity leave, then the balance of 12 months will be added to the 36 months arising from your second period of maternity period. Or if you opt-out of the option to be paid a second contract benefit, then the 12 months will run from when you return from your 2nd period of maternity from which you were not paid the 2nd maternity benefit option.`,
          },
          {
            number: '',
            text: `Note 4: The 36 months is used because this payment intends to reward loyalty and long-term intention to stay when appointed as a salaried partner. As such, the exemption to repayment has been given because you will have informed us that you had stopped working due to unforeseen personal circumstances, not because you no longer wanted to work for AVRillo.`,
          },
          {
            number: '',
            text: `Note 5: Exemption to the repayment/clawback of the £10,000 maternity benefit gross.
            This exemption shall apply if you stop working at AVRillo within your repayment 36 period due to unforeseen personal circumstances but conditional that you do not then go back to working within the legal industry within 12 months after you have stated the reason for your leaving was due to personal circumstances (i.e., mot able to work in the legal industry).`,
          },
          {
            number: '',
            text: `Note 6. The definition of the legal industry is as defined in note 32.4.a`,
          },
          {
            number: '',
            text: `<u>(b). Second potential repayment, clawback</u> of the £10,000 gross, is where you repay a pro-rata *proportional part of the £10,000 gross (net equivalent-maternity benefit payment):`,
          },
          {
            number: '',
            text: `Note 7: Reason for this clause. Payment of this non legally obliged £10,000 gross benefit was based on a combination of your loyalty, intention to return to work for 36 months post your return to live cases after your maternity leave and pre maternity performance levels of a salaried partner and senior conveyancer.`,
          },
          {
            number: '',
            text: `<b>Note 3: Goodwill exemption to the repayment/clawback:</b>`,
          },
          {
            number: '',
            text: `We, the equity partners on behalf of avrillo, have set out a 9-month goodwill exception which means that within a grace period of 9 months from your return post-maternity leave to give you time to reach   your pre-maternity leave commission and targets (as aforesaid). This means that for the whole of the grace 9 month period, we will continue to pay you, as goodwill agreement herewith, based on your pre-maternity commission targets (even though you may not reach them during those 9 months), but on condition that you use your best endeavours (including you using direction and guidelines of the firm when marketing, management and networking to try to hit those t pre-maternity targets as soon as possible without having necessarily without waiting until the expiry of the 9 month grace period). If you have not reached the pre-maternity leave targets and pre-commission levels, we will start clawing back from the gross £10,000 (net equivalent) pro-rata monthly until you reach your . pre-maternity targets. If during your post-maternity start date you increase you exceed your pre-maternity figures, then you will, of course, continue to be paid at a higher rate in arrears for those increased figures.`,
          },
          {
            number: '35.2.6.',
            text: `<b>6th benefit: Second maternity benefit: £5,000 gross</b> (net equivalent)`,
          },
          {
            number: '',
            text: `Note 1. This is an optional opt-in benefit. So if you decide not to opt for this when you give notice of maternity, we will assume you do not want this benefit, and as such, you will not be paid this sum. Therefore, this clause will only be triggered if you specially request this benefit as aforesaid.`,
          },
          {
            number: '',
            text: `Note 2: Potentially,  the aforesaid agreed £5000 gross (net equivalent) may be increased at the equity partners discretion, depending on your performance against your target - as at the quarter period prior to you taking maternity leave and your handover. This will need a discussion with the equity partners when you give said maternity notice.`,
          },
          {
            number: '',
            text: `Note 3, the terms and conditions of this clause, for the second term of maternity, are identical to the terms as applicable to the first period of maternity as aforesaid (except swap out the reference from £10,000 to £5,000).`,
          },
          {
            number: '',
            text: `Note 4. each period of 36 months, eligibility and repayment clawback provisions will run consecutively. So, if, for example, you take two periods of maternity but stop working for avrillo prior to the expiry of each of the two maternity periods of months, you will be liable as per each period of refund clawback as above.`,
          },
          {
            number: '35.2.7.',
            text: `<b>7th benefit: Your last quarter commission before your maternity leave will be added as a benefit to your SMP calculation.</b>`,
          },
          {
            number: '',
            text: `Your statutory maternity pay will include a higher amount as it will not just be limited just to SMP Calculation but also include the commission for the quarter prior to you going on maternity leave as part of the SMP calculation.`,
          },
          {
            number: '35.2.8.',
            text: `<b>8th benefit: Six to nine months' worth of commission will still be paid (after you return from maternity) even if you don't reach your pre-maternity target figures</b> and commission, subject to and depending on the best endeavours condition below.`,
          },
          {
            number: '',
            text: `The equity partners, on behalf of avrillo, will, as goodwill, continue to pay for a period of 6 to 9 months your pre-maternity commission (calculated on the pre-maternity quarter figures), providing you use your best endeavours to reach your pre-maternity quarterly commission paid to you. The post return grace period is because that we expect you, with seniority and training (in legal's, our system, management of clients and estate agents and marketing, files and emotional intelligence of a trained salaried partner),
            to reach your pre-maternity targets and commission within the six months grace period, but if not, we will discuss together how close you are, your best endeavours used, what we can do to help, whether we can extend the commission from 6 to 7, 8 or 9 (subject to discussion at the time).`,
          },
          {
            number: '',
            text: `Note 1: This reasonability of this clause is because this AVRillo payment benefit is for goodwill as it is over and above your entitlement in law and your actual basic contractual salary. This is because we will be paying you commission for each of the six to nine months after you return, not based on your actual post-return completion numbers, if lower than prior to your leave, but on the higher pre-maternity completions numbers, for each of the six to nine months (i.e., up to three financial quarters) following your return date.`,
          },
          {
            number: '',
            text: `Note 2: *target figures meaning live cases excluding exchanged target; new instructions a month target based on live cases aforesaid; completions a month target based on live cases aforesaid), within six to nine months of your return date.`,
          },
          {
            number: '',
            text: `Note 3: * "subject to" refers to a potentially repayable part of this commission if yiu have not used your best endeavours and our training and guidance within the first six months of your return; if behind, how far behind are you behind, and how soon you can back to your pre-maternity target figures.`,
          },
          {
            number: '35.3.',
            text: `<b>Cover for you as salaried partner employee</b>, provided by the equity partners (benefit and evidence of your characteristics of an employee).`,
          },
          {
            number: '',
            text: `This clause confirms the benefit in cover between an "equity" partner, who will be self-employed, whose share in the partnership's profits and losses will vary in accordance with the profitability of the partnership (not you) and you as a "salaried" partner, who is an employee (you) with the title of salaried partner and as such benefits from indemnity against losses and expenses as below:`,
          },
          {
            number: '35.3.1.',
            text: `<b>Indemnity against losses and liabilities</b> benefit`,
          },
          {
            number: '',
            text: `As a salaried partner (and not an equity partner and not an AVRillo LLP member), you have the benefit of being an employee, which means the equity partners shall indemnify you, your estate, and successors against losses and liabilities resulting from any liabilities of the equity partners, partnership or a member of the LLP, other than any claim that arises from your act or default such as  you are holding yourself out as an equity partner or LLP member.`,
          },
          {
            number: '35.3.2.',
            text: `<b>Expenses benefit</b>`,
          },
          {
            number: '',
            text: `The equity Partners or LLP member shall reimburse (or procure the reimbursement of) all reasonable expenses wholly, properly and necessarily incurred by you in the performance of your duties as a Salaried Partner in the ordinary and proper conduct of the business or in respect of anything necessarily done by you for the preservation of the Business or Partnership Property, subject to you asking for prior authority from one of the equity partners for any reasonable expense for the benefit of the firm, over £250 with a limit not exceeding £2500 in any one year. Further, the aforementioned expenses are subject to the production of a VAT receipt or other appropriate evidence of payment.`,
          },
          {
            number: '35.4.',
            text: `<b>Characteristics and factors which evidence the fact that even though you have been appointed as a salaried partner, you confirm you continue in your legal status as an employee.</b>`,
          },
          {
            number: '35.4.1.',
            text: `<u>Whilst you are appointed as a salaried partner, you are still an employee.</u>`,
          },
          {
            number: '',
            text: `As such, the term salaried partner is interchangeable with the words salaried partner employee, i.e., an employee whose title is salaried partner, if you are not an equity partner and not a member of AVRillo LLP. This clause is inserted to reaffirm that your true working relationship is, in fact, as an employee in the event of a challenge over, for example, your taxation status, legal status or liability status etc., thereby avoiding the obligations, burdens, liabilities and responsibility of an equity partner/member of AVRillo LLP (which this contract confirms you are not), in case a third party wrongly alleges you are not a salaried employee partner but an equity partner.`,
          },
          {
            number: '35.4.2.',
            text: `The aforesaid "characteristics'' and "factors'' as apply to show your status as an employee and not an equity partner, LLP member are set out below. As such, you can show this to the Inland Revenue or any other body etc., if they seek to challenge that your true status is an employee and not an equity partner, or LLP member, include the following:`,
          },
          {
            number: '35.4.2.1.',
            text: `<u>Characteristic: "whether you are a member of AVRillo LLP (hereinafter also referred to as the LLP)". We confirm, for the record, you are not a member of the LLP</u> as per the principles in the judgement of Lady Hale, in the Supreme Court case of Clyde & Co LLP & another v Bates van Winkelhof [2014] UKSC 32 where she concluded that an individual cannot be a member of the LLP and employed by it, under any circumstances.`,
          },
          {
            number: '35.4.2.2.',
            text: `<u>Characteristic: You do not share in the losses and/or profits of the firm.</u>`,
          },
          {
            number: '',
            text: `As you (as salaried partner) do not share losses or profits with the equity partners, you continue to be paid your salary (after your appointment herewith as a salaried partner. Commission (when applicable) is regarded as an employee benefit arrangement, not an equity partnership relationship. Therefore, as you do not share the losses and/or profits, it is a characteristic of you being a salaried partner employee, thereby keeping your employee legal status, such as:`,
          },
          {
            number: '',
            text: `(i). Your statutory employment employee rights, including anti-discriminatory rights, pension rights, maternity rights, and the like.`,
          },
          {
            number: '',
            text: `(ii). Your employee taxation status means you continue to be paid a salary subject to income tax and Class 1 NIC paid through the PAYE taxation rules.`,
          },
          {
            number: '',
            text: `(iii). You continue to benefit from having benefits that are made available personally to you as an employee, which will continue to be treated as benefits in kind (e.g., Benenden Health).`,
          },
          {
            number: '35.4.2.3.',
            text: `Characteristic: whether you assume liabilities for partnership debts or obligations incurred while a salaried partner employee (whether incurred before you became a salaried partner, during your salaried partnership or as an outgoing salaried partner). <u>For the record, you will not assume any of the aforesaid, i.e., you will not assume liabilities for partnership debts or obligations unless</u>, of course, you have held yourself out as an equity partner/LLP member thereby personally incurring those liabilities and debts etc. Signatory rights limited to signing of undertakings, which are authorised as salaried partner status such as conveyancing day to day protocol matters and all reasonable and necessary undertakings.`,
          },
          {
            number: '35.4.2.4.',
            text: `Whether the individual is required to make a capital contribution or have any entitlement to share in the firm's surplus assets on a winding up. For the record, as a salaried partner employee, <u>you do not have the aforementioned requirement. You will not, nor be expected, and nor will be asked to, make an individual capital contribution</u> at all in return for your appointment as a salaried partner employee.`,
          },
          {
            number: '35.4.2.5.',
            text: `Characteristic: whether you receive a fixed remuneration payable irrespective of the firm's profitability or losses. For the record, <u>as a salaried partner employee, you will continue to receive a fixed salary regardless of the LLP's profits or losses.</u>`,
          },
          {
            number: '35.4.2.6.',
            text: `Characteristic: whether you receive an express or implied indemnity against debts and liabilities of the firm. For the record, as a salaried partner employee, you <u>receive indemnity against debts and liabilities (unless you hold yourself out as an equity partner/member of the LLP, which you are not</u>). Further, if you do hold yourself out as an equity partner/member of the LLP and/or contract with a third party, you will, in fact, be binding yourself personally, not the firm. As such, you will be personally liable under the terms of that contract with the third party etc. You are authorised to enter into contracts of employment , referral fees, contracts for the purposes of recruitment, and any contracts required for the normal day to day running of the business (the latter being under £1000 without consent of the equity partners).`,
          },
          {
            number: '35.4.2.7.',
            text: `Characteristic, whether you are a salaried partner employee employed by the LLP, is a matter of practice and fact. For the record, this contract reflects that <u>you as of “fact” continue to be an employee and not an equity partner or LLP member.</u>`,
          },
          {
            number: '35.4.2.8.',
            text: `Characteristic: whether you have the authority to sign cheques on behalf of the firm or authorise transfers from the office account. For the record, as a salaried partner employee, <u>you do not have the right to sign cheques or make transfers out of the firm's office account other than</u> to approve payments from client to office account or office account to client account for matters related to clients, referral fees and payment for all office expenses such as stationary, telephone bills etc.`,
          },
          {
            number: '35.4.2.9.',
            text: `Characteristic: whether you have the right to hire and dismiss employees. For the record, as a salaried partner, you can employ and dismissal for misconduct and performance, if the equity partners are not available at the time, providing this decision is notified in advance to, and agreed by, one of the equity partners via email or verbally or ratified by them as soon as the equity partners are available (if not available at the time).`,
          },
          {
            number: '35.4.2.10.',
            text: `Other examples of “Characteristics” of you being a salaried partner, (and you retaining the rights and obligations of an Employee, and not subject to the obligations and rights of an equity partner) include the following illustrations of what you have no authority to do on on behalf of AVRillo`,
          },
          {
            number: '',
            text: `(i). You shall not dispose of by loan, pledge, sale or otherwise any Company Property.`,
          },
          {
            number: '',
            text: `(ii). You shall not  enter into any arrangement as a result of which the Partners may risk loss or be made liable for payment.`,
          },
          {
            number: '',
            text: `(iii). You shall not give any guarantee or undertaking except the Law Society standard Conveyancing undertakings) apart from those specified in 35.4.2.3`,
          },
          {
            number: '',
            text: `(iv). You shall not derive any benefit from the use of the name of AVRillo or its business connections, whether during your employment or not, for your own personal use or to benefit another company such as a competitor.`,
          },
          {
            number: '',
            text: `(v). You shall not have any dealings with any person with whom or which the equity Partners have advised the salaried partners not to deal.`,
          },
          {
            number: '',
            text: `(vi). You shall not have control and influence over management and financial affairs of the firm are, in law, important factors in establishing an individual's true legal status: (Ready Mixed Concrete (South East) Limited v Minister of Pensions & National Insurance [1968] 2 QB 497). For the record, to keep your legal status, as a salaried partner employee, you cannot have 'significant' control and/or 'influence' over the affairs of the LLP, or you risk your employee status protection and thereby protection against contributing to the LLP losses (unless you personally incurred these by incorrectly holding yourself out as an equity partner/LLP member) etc. Put simply, the greater the power you have to command and control (such as through partnership decisions, votes, and participation in management), the more likely someone is to be regarded as a partner and not an employee. As such, for the record, your salaried partner employee management powers are subject to the level and extent of the right to participate in management decisions, management meetings and partnership votes. For the record, as a salaried partner employee, you have the right to give your opinions, make suggestions etc. at management meetings you are invited to attend by the equity partners/ LLP members or delegated to you by them to run, but subject to post-meeting sanctioning by the equity partners of proposals made at those said meetings. Further, whilst you are also entitled to vote in any salaried partner meetings, to satisfy the employee test, your salaried partner vote and/or the management decision cannot bind an equity partner/LLP member. This means as a salaried employee, your opinions and suggestions can be influential to the equity partners/LLP members; they cannot bind (nor take precedence over) one or more of the equity partners'/LLP members' decisions.`,
          },
          {
            number: '',
            text: `(vii). You do not acquire any 'equity' of AVRillo LLP and/or the rights to dissolve the business. For the record, as a salaried partner employee, you do not acquire the right to any 'equity' of AVRillo LLP and do not acquire the rights to dissolve the business.`,
          },
          {
            number: '35.5.',
            text: `<b>Applications to third parties, such as lender, LMS, Lender Exchange, Companies House, CLC or another regulator. </b>`,
          },
          {
            number: '',
            text: `This clause applies where AVRillo LLP (also referred to as the LLP etc), makes an application to a lender (for lender panel lender status etc.,) and/or to LMS and/or Lender Exchange and/or to Companies House and/or to a Regulator (CLC or SRA) and the aforesaid bodies request that you are registered and/or shown on their records as having been appointed a partner. This clause confirms that in such cases, you will first take all steps requested and required to comply with the requests made by the bodies as described above. Secondly, the aforesaid registration and records of the aforesaid bodies and the description they require in those records (regardless of whether they use the description ‘salaried partner’ or partner in their records) will not alter the fact that your employment status will continue to be as an employee of AVRillo LLP. Therefore, you do not acquire an equity share in the partnership, firm, LLP and do not share in its losses or profits because you do not acquire LLP rights or obligations or consequences of an LLP Member or partner, except as a salaried partner employee.`,
          },
        ]),
      ],
    },
  ]
}
