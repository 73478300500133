import SectionCard from '~/components/SectionCard.vue'
import { useSchema } from '~/composables/useSchema'
import proofOfAddress from '~/schemas/components/poa/proof-of-address.js'
import signatureDeclaration from '~/schemas/components/poa/signature-declaration.js'

export default useSchema({
  id: 'poa',
  title: 'Proof of Address',
  description: 'This form will help you to pass government AML regulations.',
  pdf_banner: 'Please do not upload any password-protected PDFs. We are not able to open or use them.',
  autosave: true,
  steps: false,
  signature: false,
  properties: [
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1', title: 'Documents' }),
      children: proofOfAddress(),
    },
    signatureDeclaration(),
  ],
})
