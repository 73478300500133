import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps.js'
import { getCaseVariable } from '~/schemas/components/common/helpers.js'
import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import BankDetails from '~/schemas/components/pid/potential-issues-sections/bank-details'
import JointBankAccountClients from '~/schemas/components/pid/potential-issues-sections/joint-bank-account-clients'
import RadioGroup from '~/schemas/components/radio-group'

export default function () {
  return {
    $el: 'div',
    props: { title: () => (getCaseVariable('client2') ? 'Joint Bank Account' : 'Bank Account Details') },
    children: [
      {
        $el: 'div',
        children: [
          {
            if: `$: $conveyancingCase.client2 == false || $conveyancingCase.client2 == undefined`,
            $cmp: SectionCard,
            props: useProps({ tab: '6', title: 'Please provide your bank account details', sectionClass: 'mb-0' }),
            children: [
              {
                $el: 'div',
                attrs: {
                  class: 'ml-6',
                },
                children: [...BankDetails('potential_issues.bank_account')],
              },
            ],
          },
          {
            $el: 'div',
            if: `$conveyancingCase.client2`,
            children: [
              TitleSectionRadioGroup(
                '6',
                'Joint Bank Account',
                'potential_issues.joint_bank_account.joint_bank_account',
                [
                  {
                    $el: 'div',
                    if: '$formData.data.potential_issues.joint_bank_account.joint_bank_account',
                    attrs: {
                      class: 'section-indent',
                    },
                    children: [
                      //Have joint bank account
                      {
                        $el: 'div',
                        if: '$formData.data.potential_issues.joint_bank_account.joint_bank_account === "yes"',
                        children: [
                          RadioGroup(
                            'Do you want to send the Net Sale Proceeds to the joint bank account?',
                            'potential_issues.joint_bank_account.send_net_sale_proceeds_to_joint_bank_account',
                            true,
                          ),
                          {
                            $el: 'div',
                            if: 'potential_issues.joint_bank_account.send_net_sale_proceeds_to_joint_bank_account',
                            attrs: {
                              class: 'section-indent el-form--label-top',
                            },
                            children: [
                              //Send to joint bank account
                              {
                                $el: 'div',
                                if: '$formData.data.potential_issues.joint_bank_account.send_net_sale_proceeds_to_joint_bank_account === "yes"',
                                children: [...BankDetails('potential_issues.joint_bank_account')],
                              },
                              //Do not send to joint bank account
                              {
                                $el: 'div',
                                if: '$formData.data.potential_issues.joint_bank_account.send_net_sale_proceeds_to_joint_bank_account === "no"',
                                children: [JointBankAccountClients()],
                              },
                            ],
                          },
                        ],
                      },
                      //Do not have joint bank account
                      {
                        $el: 'div',
                        attrs: {
                          key: 'not_joint',
                        },
                        if: '$formData.data.potential_issues.joint_bank_account.joint_bank_account === "no"',
                        children: [JointBankAccountClients()],
                      },
                    ],
                  },
                ],
                'Do you have a joint bank account in all in your names to pay the Net Sale Proceeds into?',
              ),
            ],
          },
        ],
      },
    ],
  }
}
