import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import { useSchema } from '~/composables/useSchema'
import { isMatterType } from '~/schemas/components/common/helpers'
import clientDetails from '~/schemas/components/instruction/client-details'
import buyingDetails from '~/schemas/components/instruction/purchase/buying-details'
import sellingDetails from '~/schemas/components/instruction/sale/selling-details'
import termsAndOptions from '~/schemas/components/instruction/terms-and-options'

export default useSchema({
  id: 'instruction',
  title: 'Instruction Form',
  description: 'This form details the property instructions and is required for the sale or purchase of the property.',
  autosave: true,
  steps: true,
  signature: true,
  properties: [
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1', title: 'Your Details' }),
      children: clientDetails('clients.client1', 'clients.client2'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1a', title: 'Second Client' }),
      children: clientDetails('clients.client2', 'clients.client1'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1b', title: 'Third Client' }),
      children: clientDetails('clients.client3', 'clients.client1'),
    },
    {
      $cmp: SectionCard,
      props: useProps({ tab: '1c', title: 'Fourth Client' }),
      children: clientDetails('clients.client4', 'clients.client1'),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        title: 'Details of the Property you are Selling',
        tab: '2',
        visible: () => isMatterType('S'),
      }),
      children: sellingDetails('selling_details'),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        title: 'Details of the Property you are Buying',
        tab: { if: '$conveyancingCase.matter_type_code === "B"', then: '3', else: '2' },
        visible: () => isMatterType('P'),
      }),
      children: buyingDetails('buying_details'),
    },
    {
      $cmp: SectionCard,
      props: useProps({
        title: 'Terms and Options',
        tab: { if: '$conveyancingCase.matter_type_code === "B" || $quoteCase.matter_type_code === "B"', then: '4', else: '3' },
      }),
      children: termsAndOptions('terms_and_options'),
    },
  ],
})
