import Repeater from '~/components/Repeater.vue'
import SectionCard from '~/components/SectionCard.vue'
import { useProps } from '~/composables/useProps'
import RadioGroup from '~/schemas/components/radio-group'
import TextInput from '~/schemas/components/text-input'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Any potential problems affecting your sale?' },
    children: [
      {
        $cmp: SectionCard,
        props: useProps({ tab: '3', title: 'Any potential problems affecting your sale?', sectionClass: 'mb-0' }),
        children: [
          {
            $el: 'div',
            attrs: {
              class: 'ml-6',
            },
            children: [
              {
                $el: 'ul',
                attrs: {
                  class: 'list-disc text-sm ml-4 text-black',
                },
                children: [
                  {
                    $el: 'li',
                    children: 'Court proceedings / Court',
                  },
                  {
                    $el: 'li',
                    children: 'Disputes over your property',
                  },
                  {
                    $el: 'li',
                    children: 'Loans or Lender disputes',
                  },
                  {
                    $el: 'li',
                    children: 'Other (please list)',
                  },
                ],
              },
              {
                $el: 'div',
                attrs: {
                  class: 'mt-6 text-sm text-black required-label',
                },
                children: [
                  {
                    $el: 'span',
                    attrs: {
                      class: 'font-medium',
                    },
                    children: 'Note: ',
                  },
                  {
                    $el: 'span',
                    children: 'Such problems may affect not only your sale, but also delay and add additional time and work.',
                  },
                ],
              },
              RadioGroup('', 'potential_issues.potential_problems.potential_problems', true),
              {
                $el: 'div',
                if: '$formData.data.potential_issues.potential_problems.potential_problems === "yes"',
                children: [
                  {
                    $cmp: Repeater,
                    props: useProps({
                      prop: `potential_issues.potential_problems.problems`,
                      buttonText: '+ Add another problem',
                      removeButton: true,
                      min: 1,
                    }),
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'border-solid border-[1px] p-4 pt-8',
                        },
                        children: [
                          {
                            $cmp: 'ElFormItem',
                            props: {
                              label: 'Please select potential problem',
                              class: 'el-form-item--large',
                              required: true,
                            },
                            children: [
                              {
                                $formkit: 'el-select',
                                validation: 'required',
                                name: 'problem',
                                options: ['Disputes', 'Other Charges', 'Loan', 'Lender Disputes', 'Court Order', 'Other'],
                              },
                            ],
                          },
                          TextInput('Please provide further details in the box below', 'details', 'textarea', false, { validation: 'required' }),
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
