import section_12_1 from '~/schemas/components/pif/utilities-and-services/section_12_1'
import section_12_10 from '~/schemas/components/pif/utilities-and-services/section_12_10'
import section_12_11 from '~/schemas/components/pif/utilities-and-services/section_12_11'
import section_12_12 from '~/schemas/components/pif/utilities-and-services/section_12_12'
import section_12_2 from '~/schemas/components/pif/utilities-and-services/section_12_2'
import section_12_3 from '~/schemas/components/pif/utilities-and-services/section_12_3'
import section_12_4 from '~/schemas/components/pif/utilities-and-services/section_12_4'
import section_12_5 from '~/schemas/components/pif/utilities-and-services/section_12_5'
import section_12_6 from '~/schemas/components/pif/utilities-and-services/section_12_6'
import section_12_7 from '~/schemas/components/pif/utilities-and-services/section_12_7'
import section_12_8 from '~/schemas/components/pif/utilities-and-services/section_12_8'
import section_12_9 from '~/schemas/components/pif/utilities-and-services/section_12_9'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'pl-10 pr-10 pt-4' },
              children: [
                section_12_1(parentPath),
                section_12_2(parentPath),
                section_12_3(parentPath),
                section_12_4(parentPath),
                section_12_5(parentPath),
                section_12_6(parentPath),
                section_12_7(parentPath),
                section_12_8(parentPath),
                section_12_9(parentPath),
                section_12_10(parentPath),
                section_12_11(parentPath),
                section_12_12(parentPath),
              ],
            },
          ],
        },
      ],
    },
  ]
}
