import Repeater from '~/components/Repeater.vue'
import TotalCounter from '~/components/TotalCounter.vue'
import SavingsClientDetailsSaving from '~/schemas/components/sof/source-of-funds-sections/savings-client-details-saving'
import Title from '~/schemas/components/title'

export default function (condition, clientName, data) {
  return {
    $el: 'div',
    if: condition,
    children: [
      {
        $el: 'div',
        attrs: {
          class: 'section-indent',
        },
        children: [
          Title(`$: "Is " + ${clientName} + " using their savings to buy property?"`, 'mb-2 required-label'),
          {
            $formkit: 'el-radio-group',
            validation: 'required',
            name: data + '.using_savings',
            options: [
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ],
          },
          {
            $el: 'div',
            if: '$formData.data.' + data + '.using_savings === "yes"',
            attrs: {
              class: 'section-indent',
            },
            children: [
              Title('Are your savings in a non-UK Account? (i.e., abroad)', 'mb-2 required-label'),
              {
                $formkit: 'el-radio-group',
                validation: 'required',
                name: data + '.savings_in_a_non_uk_account',
                error: {
                  if: '$formData.data.' + data + '.savings_in_a_non_uk_account === "yes"',
                  then: true,
                },
                options: [
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ],
              },
              {
                $el: 'div',
                if: '$formData.data.' + data + '.savings_in_a_non_uk_account === "yes"',
                attrs: {
                  class: 'text-red-500 text-sm mt-2',
                },
                children: 'Sorry, our UK Anti-Money Laundering Compliance Team cannot accept money from outside of the UK.',
              },
              {
                $el: 'div',
                if: '$formData.data.' + data + '.savings_in_a_non_uk_account === "no"',
                attrs: {
                  class: 'mt-4',
                },
                children: [
                  {
                    $cmp: Repeater,
                    props: useProps({
                      prop: data + '.saving_details',
                      buttonText: '+ Add new saving',
                      removeButton: true,
                      min: 1,
                    }),
                    children: [
                      {
                        $el: 'div',
                        attrs: {
                          class: 'border-solid border-[1px] p-4 pt-8',
                        },
                        children: [SavingsClientDetailsSaving(clientName, data + '.saving_details')],
                      },
                    ],
                  },
                  {
                    $cmp: TotalCounter,
                    props: {
                      dataToCountKey: 'amount',
                      dataArrayKey: data + '.saving_details',
                      label: 'Total savings =',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
}
