import TitleSectionRadioGroup from '~/schemas/components/common/title-section-radio-group'
import RadioGroup from '~/schemas/components/radio-group'
import TextInput from '~/schemas/components/text-input'
import Title from '~/schemas/components/title'

export default function () {
  return {
    $el: 'div',
    props: { title: 'Third-Party Payment' },
    children: [
      TitleSectionRadioGroup(
        '2',
        'Third-Party Payment',
        'potential_issues.third_party_payment.third_party_payment',
        [
          {
            $el: 'div',
            attrs: {
              class: 'section-indent',
            },
            if: '$formData.data.potential_issues.third_party_payment.third_party_payment === "yes"',
            children: [
              RadioGroup(
                'What is the reason for your payment to third party? Is this payment to be made to a third-party solicitor (i.e., acting separatelyon your purchase or other transaction) or to an individual?',
                'potential_issues.third_party_payment.third_party_payment_reason',
                true,
                [
                  { value: 'company', label: 'Company' },
                  { value: 'individual', label: 'Individual' },
                ],
              ),
              {
                $el: 'div',
                attrs: {
                  class: 'el-form--label-top mt-4',
                },
                if: '$formData.data.potential_issues.third_party_payment.third_party_payment_reason',
                children: [
                  //Company
                  {
                    $el: 'div',
                    if: '$formData.data.potential_issues.third_party_payment.third_party_payment_reason === "company"',
                    children: [
                      TextInput(
                        'What is the name of the company to whom the payment is to go to (i.e., enter the name of the third party)?',
                        'potential_issues.third_party_payment.third_party_payment_company_name',
                        '',
                        false,
                        { validation: 'required' },
                      ),
                    ],
                  },
                  //Individual
                  {
                    $el: 'div',
                    if: '$formData.data.potential_issues.third_party_payment.third_party_payment_reason === "individual"',
                    children: [
                      Title('What is the name of the person to whom the payment is to go to (i.e., enter the name of the third party)?', 'mb-4'),
                      {
                        $el: 'div',
                        attrs: {
                          class: 'grid grid-cols-3 gap-4',
                        },
                        children: [
                          TextInput('Title', 'potential_issues.third_party_payment.third_party_payment_title', '', false, { validation: 'required' }),
                          TextInput('First Name', 'potential_issues.third_party_payment.third_party_payment_first_name', '', false, { validation: 'required' }),
                          TextInput('Surname', 'potential_issues.third_party_payment.third_party_payment_surname', '', false, { validation: 'required' }),
                        ],
                      },
                    ],
                  },
                  //Always showing
                  {
                    $el: 'div',
                    attrs: {
                      class: 'grid grid-cols-3 gap-4',
                    },
                    children: [
                      TextInput('Payable to the 3rd party', 'potential_issues.third_party_payment.payable_to_the_third_party', 'number', true, {
                        validation: 'required',
                      }),
                    ],
                  },
                  Title('Please insert full bank details of the third party you want us to pay your net sale proceeds to', 'mb-4'),
                  {
                    $el: 'div',
                    attrs: {
                      class: 'grid grid-cols-2 gap-4',
                    },
                    children: [
                      TextInput('Third party full name', 'potential_issues.third_party_payment.third_party_full_name', '', false, { validation: 'required' }),
                      TextInput('Third party bank name', 'potential_issues.third_party_payment.third_party_bank_name', '', false, { validation: 'required' }),
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { required: true },
                    attrs: {
                      class: 'grid grid-cols-2 gap-4',
                    },
                    children: [
                      TextInput('Third party bank sort code', 'potential_issues.third_party_payment.third_party_sort_code', '', false, {
                        validation: 'required',
                      }),
                      TextInput('Third party bank account number', 'potential_issues.third_party_payment.third_party_account_number', '', false, {
                        validation: 'required',
                      }),
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { required: true },
                    if: '$formData.data.potential_issues.third_party_payment.third_party_payment_reason === "company"',
                    children: [
                      {
                        $formkit: 'file-upload',
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                        title: 'Please upload a copy of the letter confirming their bank details and they authorize payment to be made to them.',
                        name: `potential_issues.third_party_payment.confirmation_letter.files`,
                        fileName: `potential_issues.third_party_payment.confirmation_letter.files`,
                      },
                    ],
                  },
                  {
                    $cmp: 'el-form-item',
                    props: { required: true },
                    if: '$formData.data.potential_issues.third_party_payment.third_party_payment_reason === "individual"',
                    children: [
                      {
                        $formkit: 'file-upload',
                        validation: 'required',
                        validationLabel: 'File upload',
                        validationVisibility: 'live',
                        title:
                          'Please upload the bank statement of the third party to reduce the risk of payment being made to the wrong third party (i.e., avoid fraud) with their sort code and account number.',
                        name: `potential_issues.third_party_payment.bank_statement.files`,
                        fileName: `potential_issues.third_party_payment.bank_statement.files`,
                      },
                    ],
                  },
                ],
              },
              {
                $el: 'div',
                attrs: {
                  class: 'mt-16 text-sm text-black',
                },
                if: '$formData.data.potential_issues.third_party_payment.third_party_payment_reason',
                children: [
                  {
                    $el: 'span',
                    attrs: {
                      class: 'font-medium',
                    },
                    children: 'Note: ',
                  },
                  {
                    $el: 'span',
                    children:
                      'To reduce fraud, Conveyancing Protocol requires we send any net sale proceeds to the client not someone else (i.e., third party). If you need payment to a third party we will have to (on your behalf), prepare additional paperwork to safeguard you. Please confirm a) Reason for payment to be made to someone else; b) Their account details (sort code, account number and full account name). We will then draft and send a formal document, incorporating these replies, to sign off.',
                  },
                ],
              },
            ],
          },
        ],
        'Do you want any part of the Net Sale Proceeds to be paid to someone else (i.e., a third party any other person other than you)?',
      ),
    ],
  }
}
